import { ApprovalStatus } from "../common/Enums";
import { ProposalStage } from "../common/ProposalStage";
import { Approval } from "../common/Types";

export const calculateNumberOfApprovals = (
  approvals: Approval[],
  approvalType?: ApprovalStatus
): number => {
  const type = approvalType || "approved";
  let numberOfApprovals = 0;

  approvals.forEach((approval) => {
    approval?.status === type &&
      approval?.givenBy &&
      approval?.givenAt &&
      numberOfApprovals++;
  });

  return numberOfApprovals;
};

export const determineApprovalStage = (
  approvals?: Approval[],
  stage?: ProposalStage
) => approvals?.filter((approval) => approval?.stageName === stage);
