/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import FilterUnitPopUp from "../PopUp/FilterUnitPopUp";
import { useOnOutsideClick } from "../../hooks/UseOnOutsideClick";

interface FilterUnitButtonProps {
  disabled: boolean;
}

const FilterUnitButton: React.FC<FilterUnitButtonProps> = (props) => {
  const { disabled } = props;
  const ref = useRef<HTMLDivElement>(null);
  const unitList = useUnitListContext();

  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);

  const togglePopUp = () => {
    !disabled && setShowPopUp(!showPopUp);
  };

  const closePopUp = () => {
    setShowPopUp(false);
  };

  const countFilters = () => {
    let count: number = 0;
    count += unitList.filters.unitType.length;
    count += unitList.filters.orientation.length;
    count += unitList.filters.theme.length;
    count += unitList.filters.status.length;
    count += unitList.filters.noOfBedrooms.length;
    count += unitList.filters.floors.length;
    count = unitList.filters.wholeFloor ? count + 1 : count;

    if (count < 0) count = 0;

    setFilterCount(count);
  };

  useEffect(() => {
    countFilters();
  }, [unitList.filters]);

  useOnOutsideClick(ref, () => closePopUp());

  return (
    <div
      ref={ref}
      className="relative focus:outline-none select-none"
      tabIndex={1}
    >
      {/* button */}
      <button
        onClick={togglePopUp}
        className={classNames("secondary-button group", {
          "bg-secondary text-white": showPopUp,
          "cursor-not-allowed opacity-50": disabled,
        })}
      >
        Filters
        <PabloIcon
          icon={Icon.filter}
          className={classNames("secondary-icon", {
            "white-icon": showPopUp,
          })}
        />
        <span
          hidden={filterCount <= 0}
          className={classNames(
            "w-5 h-5 rounded-full group-hover:bg-white group-hover:text-secondary",
            {
              "bg-white text-secondary": showPopUp,
              "bg-secondary text-white ": !showPopUp,
            }
          )}
        >
          {filterCount}
        </span>
      </button>

      {/* pop up */}
      <div
        hidden={!showPopUp}
        className="z-10 origin-top-left absolute focus:outline-none"
        onMouseDown={(e) => e.preventDefault()}
      >
        <FilterUnitPopUp filterCount={filterCount} onClear={closePopUp} />
      </div>
    </div>
  );
};

export default FilterUnitButton;
