/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, {
  createContext,
  createRef,
  useContext,
  useEffect,
  useState,
} from "react";
import RequiredField from "../../views/generals/RequiredField";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface GroupExpandableCardProps {
  defaultValue?: string;
  onExpand: (value: string) => void;
  onToggle?: () => void;
}

export type ExpandableCardValidated = "success" | "error" | "warning" | "empty";

export const GroupExpandableCard: React.FC<GroupExpandableCardProps> = (
  props
) => {
  const [groupValue, setGroupValue] = useState<string | undefined>(
    props.defaultValue
  );

  const onClick = (value: string) => {
    props.onToggle && props.onToggle();

    if (value === groupValue) {
      setGroupValue(undefined);
    } else {
      setGroupValue(value);
      props.onExpand(value);
    }
  };

  return (
    <ExpandableCardContext.Provider value={{ groupValue, onClick }}>
      {props.children}
    </ExpandableCardContext.Provider>
  );
};

interface ExpandableCardProps {
  title: string;
  value: string;
  showRequired?: boolean;
  validated?: ExpandableCardValidated;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  innerClassName?: string;
  autoScroll?: boolean;
  background?: string;
  onDeleteAction?: () => void;
}

export const ExpandableCard: React.FC<ExpandableCardProps> = ({
  autoScroll = true,
  background = "bg-white",
  ...props
}) => {
  const { groupValue, onClick } = useExpandableCardContext();
  const reference = createRef<HTMLDivElement>();

  useEffect(() => {
    if (props.value === groupValue) {
      const element = reference.current;
      if (element && autoScroll) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [groupValue]);

  return (
    <div
      id={`${props.value}-expandable-card`}
      ref={reference}
      className={classNames(
        "col shadow rounded-sm relative",
        { "mx-7 my-4": !props.containerClassName },
        background,
        props.className,
        props.containerClassName
      )}
    >
      <span className="cursor-pointer" onClick={() => onClick(props.value)}>
        <div
          className={classNames(
            "row items-center",
            {
              "mx-8 my-5": !props.labelClassName,
            },
            props.labelClassName
          )}
        >
          <h2 className="capitalize mr-4 select-none">{props.title}</h2>

          {/* <div
            className={classNames(
              "font-light text-sm italic transition-opacity duration-300",
              {
                "opacity-0": !(
                  props.showRequired && groupValue === props.value
                ),
                "opacity-100": props.showRequired && groupValue === props.value,
              }
            )}
          >
            <span className="text-dark-red mr-1">*</span>
            <span>required fields</span>
          </div> */}

          <RequiredField
            show={props.showRequired && groupValue === props.value}
          />

          <div className="ml-auto" />

          {props.validated !== "empty" && (
            <div
              className={classNames(
                "mr-4 rounded-full w-5 h-5 flex justify-center items-center transition-all duration-200",
                {
                  "bg-dark-orange": props.validated === "warning",
                  "bg-dark-green": props.validated === "success",
                  "bg-dark-red": props.validated === "error",
                }
              )}
            >
              <PabloIcon
                icon={props.validated === "error" ? Icon.close : Icon.success}
                width={12}
                className={classNames("select-none", {
                  "white-icon": props.validated === "error",
                })}
              />
            </div>
          )}

          <PabloIcon
            icon={Icon.arrow}
            className={classNames(
              "transition-all duration-300 transform -rotate-90 select-none",
              {
                "transform rotate-90": groupValue === props.value,
              }
            )}
          />
        </div>
      </span>

      {props.onDeleteAction && (
        <PabloIcon
          icon={Icon.remove}
          className={classNames(
            "transition-all duration-200 hover:gray-icon absolute cursor-pointer right-20 my-6 mr-8"
          )}
          onClick={() => {
            props.onDeleteAction?.();
          }}
        />
      )}

      <div
        className={classNames(
          "transition-all duration-300",
          {
            "h-0 overflow-hidden opacity-0": groupValue !== props.value,
            "mb-6 opacity-100": groupValue === props.value,
            "mx-8": !props.innerClassName,
          },
          props.innerClassName
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

type ExpandableCardContextType = {
  groupValue?: string;
  onClick: (value: string) => void;
};

const defaultContext: ExpandableCardContextType = {
  groupValue: undefined,
  onClick: (value: string) => {},
};

const ExpandableCardContext =
  createContext<ExpandableCardContextType>(defaultContext);

const useExpandableCardContext = () => useContext(ExpandableCardContext);
