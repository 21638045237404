import classNames from "classnames";
import React from "react";
import { Radio } from "../General/Radio";

interface RadioFieldProps {
  id?: string;
  label: string;
  value: any;
  innerClassName?: string;
}

const RadioField: React.FC<RadioFieldProps> = (props) => {
  const { id, innerClassName, label, ...otherProps } = props;

  return (
    <label
      id={id}
      className={classNames(
        "gap-3 font-bold text-sm cursor-pointer row p-2",
        innerClassName
      )}
    >
      <Radio {...otherProps} />
      <span>{label}</span>
    </label>
  );
};

export default RadioField;
