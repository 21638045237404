import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from "react";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import Modal from "../../components/Modals/Modal";

type ModalContextType = {
  show: boolean;
  setShow: (show: boolean) => void;
  confirmation: (options: ModalOption) => void;
  info: (options: ModalOption) => void;
  view: (options: ModalOption) => void;
};

const defaultContext: ModalContextType = {
  show: false,
  setShow: (show: boolean) => {},
  confirmation: (options: {
    title: string;
    description?: string;
    content?: ReactElement;
    onConfirm?: { text?: string; action?: () => void };
  }) => {},
  info: (options: {
    title: string;
    description?: string;
    onConfirm?: { text?: string; action?: () => void };
  }) => {},
  view: (options: {
    title?: string;
    containerClassName?: string;
    modalClassName?: string;
    content?: ReactElement;
  }) => {},
};

const ModalContext = createContext<ModalContextType>(defaultContext);

export const useModalContext = () => useContext(ModalContext);

interface ModalOption {
  type?: "warning" | "success" | "error";
  title: string;
  description?: string;
  content?: ReactElement;
  dismissible?: boolean;
  isManualHide?: boolean;
  containerClassName?: string;
  modalClassName?: string;
  onConfirm?: { text?: string; action?: () => void };
  onCancel?: { text?: string; action?: () => void };
}

export const ModalContextProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState<ModalOption>();
  const [modalType, setModalType] = useState<
    "confirmation" | "info" | "view" | ""
  >("");

  const confirmation = (tempOptions: ModalOption) => {
    setModalType("confirmation");
    setOptions(tempOptions);
    setShow(true);
  };

  const info = (tempOptions: ModalOption) => {
    setModalType("info");
    setOptions(tempOptions);
    setShow(true);
  };

  const view = (tempOptions: ModalOption) => {
    setModalType("view");
    setOptions(tempOptions);
    setShow(true);
  };

  return (
    <ModalContext.Provider value={{ show, setShow, confirmation, info, view }}>
      {children}
      {options && modalType === "confirmation" && (
        <ConfirmationModal
          show={show}
          dismissible={options!.dismissible ?? true}
          onHide={() => setShow(false)}
          type={options!.type ?? "warning"}
          title={options!.title}
          description={options?.description}
          content={options?.content}
          isManualHide={options?.isManualHide || false}
          onConfirm={{
            text: options!.onConfirm?.text ?? "Confirm",
            action: options!.onConfirm?.action,
          }}
          onCancel={{
            text: options!.onCancel?.text ?? "Cancel",
            action: options!.onCancel?.action ?? (() => setShow(false)),
          }}
        />
      )}
      {options && modalType === "info" && (
        <Modal
          show={show}
          type={options!.type ?? "warning"}
          onHide={() => setShow(false)}
          dismissible={options!.dismissible ?? true}
        >
          <div className="col justify-around items-center h-full">
            <h2 className="self-center text-center">{options!.title}</h2>

            {options!.description && (
              <div className="pt-4 pb-10 text-justify">
                {options!.description}
              </div>
            )}

            <button
              onClick={options!.onConfirm?.action ?? (() => setShow(false))}
              className="primary-button h-[48px] w-[218px]"
            >
              {options!.onConfirm?.text ?? "Confirm"}
            </button>
          </div>
        </Modal>
      )}
      {options && modalType === "view" && (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dismissible={options!.dismissible ?? true}
          containerClassName={options?.containerClassName}
          modalClassName={options?.modalClassName}
        >
          {options?.title && (
            <h2 className="self-center text-center">{options.title}</h2>
          )}

          {options?.description && (
            <div className="pt-4 pb-10 text-center">{options!.description}</div>
          )}

          {options!.content}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};
