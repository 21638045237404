/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Validation } from "../../hooks/UseValidation";
import DropdownField from "../Fields/DropdownField";
import TextField from "../Fields/TextField";
import {
  ExpandableCard,
  ExpandableCardValidated,
} from "../Cards/ExpandableCard";
import Country from "../../common/Country";
import { salutations } from "../../common/Enums";
import { Customer } from "../../common/Types";
import { IFormSchema } from "../../common/Interfaces";
import Line from "../General/Line";

interface CustomerBasicInfoAccordianProps<T extends IFormSchema> {
  validation: Validation<T>;
  defaultValues?: Customer;
  prospectList?: Customer[];
}

const CustomerBasicInfoAccordian: React.FC<
  CustomerBasicInfoAccordianProps<any>
> = (props) => {
  const { defaultValues, prospectList } = props;
  const [validated, setValidated] = useState<ExpandableCardValidated>("empty");

  const register = props.validation.register;
  const customerBasicInfo = props.validation.watch("customerBasicInfo");
  const error = props.validation.errors.customerBasicInfo;

  const onProspectChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.validation.clearErrors("customerBasicInfo");

    const prospect = prospectList?.find(
      (tempProspect) => tempProspect?.id.toString() === e.target.value
    );

    props.validation.setValue("customerBasicInfo", {
      id: prospect?.id || 0,
      salutation: prospect?.salutation || "",
      fullName: prospect?.fullName || "",
      phoneCode: prospect?.phoneCode?.toString() || "",
      phoneNumber: prospect?.phoneNumber || "",
      country: prospect?.country || "",
      email: prospect?.email || "",
    });
  };

  useEffect(() => {
    if (
      customerBasicInfo?.salutation &&
      customerBasicInfo?.fullName &&
      customerBasicInfo?.phoneCode &&
      customerBasicInfo?.phoneNumber &&
      customerBasicInfo?.country
    ) {
      setValidated("success");
    } else {
      setValidated("empty");
    }

    if (error) setValidated("error");
  }, [error, props.validation.watch()]);

  return (
    <ExpandableCard
      value="customer-basic-information"
      title="Customer basic information"
      validated={validated}
      showRequired
    >
      <div className="col gap-5">
        {prospectList && prospectList?.length > 0 && (
          <>
            <div className="row mt-3 -mb-1 gap-4">
              <DropdownField
                id="prospect"
                key="prospect"
                label="Prospect"
                required={false}
                placeholder="Select a prospect"
                error={error?.id?.message}
                items={[
                  ...prospectList.map((prospect) => {
                    return {
                      text: prospect.fullName,
                      value: prospect.id.toString(),
                    };
                  }),
                  {
                    text: "New Customer",
                    value: "0",
                    className: "text-[#0880e3]",
                  },
                ]}
                defaultValue={defaultValues?.id?.toString() || "0"}
                searchable="true"
                {...props.validation.register("customerBasicInfo.id", {
                  value: defaultValues?.id?.toString() || "0",
                  onChange: (e) => onProspectChanged(e),
                })}
              />
              <div className="col w-full tablet:w-[498px]" />
            </div>

            <Line className="my-2" />
          </>
        )}

        <div className="row -mb-1 gap-4">
          <DropdownField
            id="customer-salutation"
            key="salutation"
            label="Salutation"
            placeholder="Select Salutation"
            error={error?.salutation?.message}
            items={salutations.map((salutation) => {
              return { text: salutation, value: salutation };
            })}
            defaultValue={defaultValues?.salutation || ""}
            searchable="true"
            autoFillable={true}
            {...register("customerBasicInfo.salutation", {
              value: defaultValues?.salutation || "",
            })}
          />
          <TextField
            id="customer-full-name"
            label="Full Name (as per IC/Passport)"
            type="text"
            error={error?.fullName?.message}
            defaultValue={defaultValues?.fullName}
            {...register("customerBasicInfo.fullName", {
              setValueAs: (v) => v?.trim(),
            })}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
            <DropdownField
              id="customer-dial-code"
              key="dial-code"
              label="Dial Code"
              error={error?.phoneCode?.message}
              items={Country.countryData.map((country) => {
                return {
                  text: `+${country.dialCode} - ${country.name}`,
                  value: country.dialCode,
                };
              })}
              placeholder="Select Dial Code"
              searchable="true"
              dynamicWidth={true}
              containerClassName="w-2/6"
              listClassName="min-w-[420px]"
              showValue={true}
              autoFillable={true}
              defaultValue={defaultValues?.phoneCode?.toString() || ""}
              {...register("customerBasicInfo.phoneCode", {
                value: defaultValues?.phoneCode?.toString() || "",
              })}
            />
            <TextField
              id="customer-contact-number"
              label="Contact No."
              type="tel"
              dynamicWidth={true}
              containerClassName="w-4/6"
              error={error?.phoneNumber?.message}
              defaultValue={defaultValues?.phoneNumber}
              {...register("customerBasicInfo.phoneNumber", {
                setValueAs: (v) => v?.trim(),
              })}
            />
          </div>

          <DropdownField
            id="customer-origin-country"
            key="origin-country"
            label="Country of Origin"
            error={error?.country?.message}
            placeholder="Select Country of Origin"
            items={Country.countryData.map((country) => {
              return {
                text: country.name,
                value: country.alpha2Code,
              };
            })}
            searchable="true"
            autoFillable={true}
            defaultValue={defaultValues?.country || ""}
            {...register("customerBasicInfo.country", {
              value: defaultValues?.country || "",
            })}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <TextField
            id="customer-email"
            label="Email"
            type="email"
            required={false}
            error={error?.email?.message}
            defaultValue={defaultValues?.email}
            {...register("customerBasicInfo.email", {
              setValueAs: (v) => v?.trim().toLowerCase(),
            })}
          />
          <div className="col w-full tablet:w-[498px]" />
        </div>
      </div>
    </ExpandableCard>
  );
};

export default CustomerBasicInfoAccordian;
