import classNames from "classnames";

interface AddItemButtonProps {
  id?: string;
  text: string;
  onClick: Function;
  containerClassName?: string;
  buttonClassName?: string;
}

const AddItemButton: React.FC<AddItemButtonProps> = (props) => {
  const { id, text, onClick, containerClassName, buttonClassName } = props;

  return (
    <div
      className={classNames(
        "col bg-white shadow rounded-sm mt-4 border border-[#53565A33]",
        containerClassName
      )}
    >
      <button
        id={id}
        className={classNames(
          "primary-button bg-white text-[#0880e3] hover:bg-[#494949] hover:text-white",
          buttonClassName
        )}
        onClick={() => onClick()}
      >
        {text}
      </button>
    </div>
  );
};

export default AddItemButton;
