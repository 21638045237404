export const stages = [
  { name: "1", percentage: 10 },
  { name: "2a", percentage: 15 },
  { name: "2b", percentage: 10 },
  { name: "2c", percentage: 10 },
  { name: "2d", percentage: 10 },
  { name: "2e", percentage: 10 },
  { name: "2f", percentage: 5 },
  { name: "2g", percentage: 5 },
  { name: "2h", percentage: 2.5 },
  { name: "2i", percentage: 2.5 },
  { name: "3", percentage: 12.5 },
  { name: "4", percentage: 2.5 },
  { name: "5a", percentage: 2.5 },
  { name: "5b", percentage: 2.5 },
] as const;

export const defaultStages = [
  { stage: "1", value: 0, stringValue: "" },
  { stage: "2a", value: 0, stringValue: "" },
  { stage: "2b", value: 0, stringValue: "" },
  { stage: "2c", value: 0, stringValue: "" },
  { stage: "2d", value: 0, stringValue: "" },
  { stage: "2e", value: 0, stringValue: "" },
  { stage: "2f", value: 0, stringValue: "" },
  { stage: "2g", value: 0, stringValue: "" },
  { stage: "2h", value: 0, stringValue: "" },
  { stage: "2i", value: 0, stringValue: "" },
  { stage: "3", value: 0, stringValue: "" },
  { stage: "4", value: 0, stringValue: "" },
  { stage: "5a", value: 0, stringValue: "" },
  { stage: "5b", value: 0, stringValue: "" },
] as const;

export type Stage = typeof stages[number];

export const stageName = (stageIndex: number) => {
  return stages[stageIndex].name;
};

export type StructurableType =
  | "App\\Models\\Instalment"
  | "App\\Models\\ScheduleH"
  | "App\\Models\\FlexibleInstalment";

export const structurableType = (type: StructurableType) => {
  const structurableTypes = {
    "App\\Models\\Instalment": "instalment",
    "App\\Models\\ScheduleH": "schedule-h",
    "App\\Models\\FlexibleInstalment": "flexible-instalment",
  };

  return structurableTypes[type] as PaymentStructureType;
};

export const paymentStructures = [
  "schedule-h",
  "instalment",
  "flexible-instalment",
] as const;

export type PaymentStructureType = typeof paymentStructures[number];

export const paymentStructureTypeName = (type: PaymentStructureType) => {
  const paymentStructureTypes = {
    "schedule-h": "Progressive Payment",
    instalment: "Fixed Instalment",
    "flexible-instalment": "Flexible Instalment",
  };

  return paymentStructureTypes[type];
};

export const disabledStages = ["1", "2a", "2b", "2c", "2d", "2e"] as const;

export type DisabledStages = typeof disabledStages[number];

export const reverseStructurableType = (type: PaymentStructureType) => {
  const reverseStructurableTypes = {
    "schedule-h": "App\\Models\\ScheduleH",
    instalment: "App\\Models\\Instalment",
    "flexible-instalment": "App\\Models\\FlexibleInstalment",
  };

  return reverseStructurableTypes[type] as StructurableType;
};
