/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { object, SchemaOf } from "yup";
import Country from "../../common/Country";
import { priorities } from "../../common/Enums";
import Path from "../../common/Path";
import {
  GeneralMessageResponse,
  ShowGeneralDataResponse,
} from "../../common/Responses";
import { Prospect } from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import {
  ExpandableCard,
  GroupExpandableCard,
} from "../../components/Cards/ExpandableCard";
import FormCard from "../../components/Cards/FormCard";
import DatePickerField from "../../components/Fields/DatePickerField";
import DropdownField from "../../components/Fields/DropdownField";
import TextField from "../../components/Fields/TextField";
import CommentsForm from "../../components/Forms/CommentsForm";
import Textarea from "../../components/General/Textarea";
import BottomBar from "../../components/Navs/BottomBar";
import { useLeadManagementContext } from "../../hooks/contexts/LeadManagementContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useValidation } from "../../hooks/UseValidation";
import api from "../../services/PabloApiService";
import { capitalize, listDropdownPhoneNumbers } from "../../utils/CommonUtils";
import { baseString } from "../../utils/SchemaUtil";

interface LeadProfileForm {
  fullName?: string;
  salesJourneyStatus: string;
  channel: string;
  koraMemberName?: string;
  jobPosition?: string;
  primarySalesPic: string;
  pairingSalesPic?: string;
  proposedUnit?: string;
  priority: string;
  country: string;
  dueDate?: string;
  website?: string;
  email?: string;
  phoneCode?: string;
  phoneNumber?: string;
  startDate: string;
  description?: string;
}

const schema: SchemaOf<LeadProfileForm> = object().shape({
  fullName: baseString().required().label("Prospect name"),
  salesJourneyStatus: baseString().required().label("Sales journey status"),
  channel: baseString().required().label("Channel"),
  koraMemberName: baseString().label("KORA member name"),
  jobPosition: baseString().label("Job position"),
  primarySalesPic: baseString().required().label("Primary sales catalyst"),
  pairingSalesPic: baseString().label("Pairing sales catalyst"),
  proposedUnit: baseString().label("Proposed unit"),
  priority: baseString().required().label("Priority"),
  country: baseString().required().label("Country"),
  dueDate: baseString().label("Due date"),
  website: baseString().label("Website"),
  email: baseString()
    .trim()
    .matches(
      /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
      "Email format is invalid"
    )
    .label("Email"),
  phoneCode: baseString().label("Dial code"),
  phoneNumber: baseString()
    .trim()
    .matches(/^[0-9]*$/, "Contact number must be in numbers.")
    .label("Contact number"),
  startDate: baseString().required().label("Due date"),
  description: baseString().label("Description"),
});

const LeadProfileFormPage: React.FC = () => {
  const { customerId } = useParams();
  const isEditProspect = !!customerId;
  const loadingPage = useLoadingPageContext();
  const navigate = useNavigate();
  const modal = useModalContext();
  const leadManagement = useLeadManagementContext();
  const validation = useValidation<LeadProfileForm>(schema);
  const { errors, watch, register, handleSubmit } = validation;
  const leadProfileInfo = watch();

  const [prospect, setProspect] = useState<Prospect>();

  const init = async () => {
    loadingPage.start();

    if (leadManagement.salesJourneys.length <= 0)
      await leadManagement.getSalesJourneyStatuses();

    if (leadManagement.channels.length <= 0) await leadManagement.getChannels();

    if (leadManagement.catalysts.length <= 0)
      await leadManagement.getCatalysts();

    if (leadManagement.unitNumbers.length <= 0)
      await leadManagement.getUnitList();

    if (isEditProspect) {
      const customerResponse = await api.get<ShowGeneralDataResponse<Prospect>>(
        `/customer/${customerId}`
      );

      if (customerResponse.success) setProspect(customerResponse.data.data);
    }

    loadingPage.end();
  };

  const setFormValues = () => {
    validation.setValue("fullName", prospect?.fullName || "");
    validation.setValue(
      "salesJourneyStatus",
      prospect?.latestSalesJourneyStatus || ""
    );
    validation.setValue("channel", prospect?.channel || "");
    validation.setValue("koraMemberName", prospect?.koraMember || "");
    validation.setValue("jobPosition", prospect?.title || "");
    validation.setValue(
      "primarySalesPic",
      prospect?.primarySalesPersonId?.toString() || ""
    );
    validation.setValue(
      "pairingSalesPic",
      prospect?.pairingSalesPersonId?.toString() || ""
    );
    validation.setValue("proposedUnit", prospect?.proposedUnit || "");
    validation.setValue("priority", prospect?.priority || "");
    validation.setValue("country", prospect?.country || "");
    validation.setValue("dueDate", prospect?.dueDate || "");
    validation.setValue("website", prospect?.socialMediaLink || "");
    validation.setValue("email", prospect?.email || "");
    validation.setValue("phoneCode", prospect?.phoneCode?.toString() || "");
    validation.setValue("phoneNumber", prospect?.phoneNumber || "");
    validation.setValue("startDate", prospect?.startDate || "");
    validation.setValue("description", prospect?.description || "");
  };

  const submitForm = () => {
    modal.confirmation({
      title: `Are you sure you want to ${
        isEditProspect ? "edit this" : "add this new"
      } ticket?`,
      onConfirm: {
        text: isEditProspect ? "Update" : "Create",
        action: submit,
      },
    });
  };

  const submit = async () => {
    loadingPage.start();

    const prospectBody = {
      fullName: leadProfileInfo?.fullName,
      description: leadProfileInfo?.description,
      channel: leadProfileInfo?.channel,
      koraMember: leadProfileInfo?.koraMemberName,
      title: leadProfileInfo?.jobPosition,
      primarySalesPersonId: leadProfileInfo?.primarySalesPic,
      pairingSalesPersonId: leadProfileInfo?.pairingSalesPic,
      proposedUnit: leadProfileInfo?.proposedUnit,
      priority: leadProfileInfo?.priority,
      country: leadProfileInfo?.country,
      dueDate: leadProfileInfo?.dueDate,
      socialMediaLink: leadProfileInfo?.website,
      email: leadProfileInfo?.email,
      phoneCode: leadProfileInfo?.phoneCode,
      phoneNumber: leadProfileInfo?.phoneNumber,
      startDate: leadProfileInfo?.startDate,
      salesJourneyValue: leadProfileInfo?.salesJourneyStatus,
    };

    const result = isEditProspect
      ? await api.patch<GeneralMessageResponse>(
          `/customer/${customerId}`,
          prospectBody
        )
      : await api.post<GeneralMessageResponse>("/customer", prospectBody);

    loadingPage.end();

    if (result)
      toast.success(
        `Prospect has been ${
          isEditProspect ? "updated" : "created"
        } successfully.`
      );
    else
      toast.error(`Prospect ${isEditProspect ? "update" : "creation"} failed`);

    navigate(Path.leadBoard);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => setFormValues(), [prospect]);

  return (
    <>
      {/* page */}
      <div className="col justify-between min-h-screen h-max">
        <div>
          <BackButton
            to={Path.leadBoard}
            text="Back to lead management board page"
          />

          <FormCard
            showRequired={true}
            title={isEditProspect ? "Edit Prospect" : "New Prospect"}
          >
            <div className="w-full flex justify-between gap-4">
              <div className="w-1/2 flex-grow">
                <div>
                  <h5 className="mb-4 font-bold">Description</h5>
                  <Textarea
                    rows={8}
                    dynamicWidth={true}
                    containerClassName="w-full"
                    innerClassName="p-4"
                    error={errors?.description?.message}
                    placeholder="Add a description"
                    defaultValue={prospect?.description || ""}
                    {...register("description", {
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                </div>

                {isEditProspect && <CommentsForm customerId={customerId} />}
              </div>

              <div className="w-1/2 flex-grow">
                <GroupExpandableCard
                  defaultValue="prospect-details"
                  onExpand={() => {}}
                >
                  <ExpandableCard
                    value="prospect-details"
                    title="Prospect Details"
                    containerClassName="m-0"
                    labelClassName="m-4"
                    innerClassName="mx-4"
                    background="bg-section-background"
                    showRequired={false}
                  >
                    <div className="row mb-4">
                      <TextField
                        id={`prospect-name`}
                        key={`prospect-name`}
                        label="Prospect Name"
                        type="text"
                        required={true}
                        error={errors?.fullName?.message}
                        defaultValue={prospect?.fullName}
                        {...register(`fullName`, {
                          setValueAs: (v) => v.trim(),
                        })}
                      />
                    </div>

                    {leadManagement.salesJourneys.length > 0 && (
                      <div className="row mb-4">
                        <DropdownField
                          id={`sales-journey-status`}
                          key={`sales-journey-status`}
                          label="Sales Journey Status"
                          placeholder="Select sales journey status"
                          error={errors?.salesJourneyStatus?.message}
                          items={leadManagement.salesJourneys.map(
                            (salesJourney) => {
                              return {
                                text: salesJourney.displayName,
                                value: salesJourney.value,
                              };
                            }
                          )}
                          searchable="true"
                          autoFillable={true}
                          defaultValue={
                            prospect?.latestSalesJourneyStatus ||
                            leadProfileInfo?.salesJourneyStatus ||
                            ""
                          }
                          {...register(`salesJourneyStatus`, {
                            value:
                              prospect?.latestSalesJourneyStatus ||
                              leadProfileInfo?.salesJourneyStatus ||
                              "",
                          })}
                        />
                      </div>
                    )}

                    <div className="row mb-4">
                      <TextField
                        id={`identification-number`}
                        key={`identification-number`}
                        label="IC No./Passport No."
                        required={false}
                        disabled={true}
                        value={prospect?.identificationNumber || ""}
                      />
                    </div>

                    {leadManagement.channels.length > 0 && (
                      <div className="row mb-4">
                        <DropdownField
                          id={`channel`}
                          key={`channel`}
                          label="Channel"
                          placeholder="Select channel"
                          error={errors?.channel?.message}
                          items={leadManagement.channels.map((channel) => {
                            return { text: channel.name, value: channel.name };
                          })}
                          searchable="true"
                          autoFillable={true}
                          defaultValue={
                            prospect?.channel || leadProfileInfo?.channel || ""
                          }
                          {...register(`channel`, {
                            value:
                              prospect?.channel ||
                              leadProfileInfo?.channel ||
                              "",
                          })}
                        />
                      </div>
                    )}

                    <div className="row mb-4">
                      <TextField
                        id={`kora-member-name`}
                        key={`kora-member-name`}
                        label="KORA Member Name"
                        type="text"
                        required={false}
                        error={errors?.koraMemberName?.message}
                        defaultValue={prospect?.koraMember}
                        {...register(`koraMemberName`, {
                          setValueAs: (v) => v.trim(),
                        })}
                      />
                    </div>

                    <div className="row mb-4">
                      <TextField
                        id={`job-position`}
                        key={`job-position`}
                        label="Job Position"
                        type="text"
                        required={false}
                        error={errors?.jobPosition?.message}
                        defaultValue={prospect?.title}
                        {...register(`jobPosition`, {
                          setValueAs: (v) => v.trim(),
                        })}
                      />
                    </div>

                    <div className="row mb-4">
                      <div className="col w-full tablet:w-[498px] opacity-50">
                        <div className="w-full input-border-dynamic cursor-not-allowed select-none">
                          <label className="uppercase">Deposit Collected</label>
                          <div className="mt-1 text-sm">
                            {prospect?.depositCollected?.map(
                              (depositDetails) => (
                                <>
                                  <div className="font-bold">
                                    {depositDetails.unitNumber}
                                  </div>
                                  <ul className="pt-2 pb-3 pl-4 list-disc">
                                    {depositDetails?.deposits?.map(
                                      (deposit) => (
                                        <li>{`${deposit.currencyCode} ${deposit.formattedAmount} (${deposit.paymentMethod})`}</li>
                                      )
                                    )}
                                  </ul>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {leadManagement.catalysts.length > 0 && (
                      <>
                        <div className="row mb-4">
                          <DropdownField
                            id={`primary-sales-pic`}
                            key={`primary-sales-pic`}
                            label="Sales Catalyst (Primary)"
                            placeholder="Select primary catalyst"
                            error={errors?.primarySalesPic?.message}
                            items={leadManagement.catalysts
                              .filter((catalyst) => catalyst.id)
                              .map((catalyst) => {
                                return {
                                  text: catalyst.fullName,
                                  value: catalyst?.id?.toString(),
                                };
                              })}
                            searchable="true"
                            autoFillable={true}
                            defaultValue={
                              prospect?.primarySalesPersonId?.toString() ||
                              leadProfileInfo?.primarySalesPic ||
                              ""
                            }
                            {...register(`primarySalesPic`, {
                              value:
                                prospect?.primarySalesPersonId?.toString() ||
                                leadProfileInfo?.primarySalesPic ||
                                "",
                            })}
                          />
                        </div>

                        <div className="row mb-4">
                          <DropdownField
                            id={`pairing-sales-pic`}
                            key={`pairing-sales-pic`}
                            label="Sales Catalyst (Pairing)"
                            required={false}
                            placeholder="Select pairing catalyst"
                            error={errors?.pairingSalesPic?.message}
                            items={leadManagement.catalysts
                              .filter((catalyst) => catalyst.id)
                              .map((catalyst) => {
                                return {
                                  text: catalyst.fullName,
                                  value: catalyst?.id?.toString(),
                                };
                              })}
                            searchable="true"
                            autoFillable={true}
                            defaultValue={
                              prospect?.pairingSalesPersonId?.toString() ||
                              leadProfileInfo?.pairingSalesPic ||
                              ""
                            }
                            {...register(`pairingSalesPic`, {
                              value:
                                prospect?.pairingSalesPersonId?.toString() ||
                                leadProfileInfo?.pairingSalesPic ||
                                "",
                            })}
                          />
                        </div>
                      </>
                    )}

                    {leadManagement.unitNumbers.length > 0 && (
                      <div className="row mb-4">
                        <DropdownField
                          id={`proposed-unit`}
                          key={`proposed-unit`}
                          label="Proposed Unit"
                          required={false}
                          placeholder="Select proposed unit"
                          error={errors?.proposedUnit?.message}
                          items={leadManagement.unitNumbers.map((unit) => {
                            return { text: unit, value: unit };
                          })}
                          searchable="true"
                          autoFillable={true}
                          defaultValue={
                            prospect?.proposedUnit ||
                            leadProfileInfo?.proposedUnit ||
                            ""
                          }
                          {...register(`proposedUnit`, {
                            value:
                              prospect?.proposedUnit ||
                              leadProfileInfo?.proposedUnit ||
                              "",
                          })}
                        />
                      </div>
                    )}

                    <div className="row mb-4">
                      <DropdownField
                        id={`priority`}
                        key={`priority`}
                        label="Priority"
                        placeholder="Select priority"
                        error={errors?.priority?.message}
                        items={priorities.map((priority) => {
                          return {
                            text: capitalize(priority),
                            value: priority,
                          };
                        })}
                        autoFillable={true}
                        defaultValue={
                          prospect?.priority || leadProfileInfo?.priority || ""
                        }
                        {...register(`priority`, {
                          value:
                            prospect?.priority ||
                            leadProfileInfo?.priority ||
                            "",
                        })}
                      />
                    </div>

                    <div className="row mb-4">
                      <DropdownField
                        id={`country`}
                        key={`country`}
                        label="country"
                        placeholder="Select country"
                        error={errors?.country?.message}
                        items={Country.countryData.map((country) => {
                          return {
                            text: country.name,
                            value: country.alpha2Code,
                          };
                        })}
                        searchable="true"
                        autoFillable={true}
                        defaultValue={
                          prospect?.country || leadProfileInfo?.country || ""
                        }
                        {...register(`country`, {
                          value:
                            prospect?.country || leadProfileInfo?.country || "",
                        })}
                      />
                    </div>

                    <div className="row mb-4">
                      <DatePickerField
                        id={`due-date`}
                        key={`due-date`}
                        label="Due Date"
                        required={false}
                        error={errors?.dueDate?.message}
                        defaultValue={prospect?.dueDate}
                        {...register(`dueDate`)}
                      />
                    </div>

                    <div className="row mb-4">
                      <TextField
                        id={`website`}
                        key={`website`}
                        label="Website"
                        type="text"
                        required={false}
                        error={errors?.website?.message}
                        defaultValue={prospect?.socialMediaLink}
                        {...register(`website`)}
                      />
                    </div>

                    <div className="row mb-4">
                      <TextField
                        id={`email`}
                        key={`email`}
                        label="Email"
                        type="email"
                        required={false}
                        error={errors?.email?.message}
                        defaultValue={prospect?.email}
                        {...register(`email`, {
                          setValueAs: (v) => v.trim().toLowerCase(),
                        })}
                      />
                    </div>

                    <div className="row mb-4">
                      <div className="w-full laptop:w-[498px] flex justify-start gap-4">
                        <DropdownField
                          id={`dial-code`}
                          key={`dial-code`}
                          label="Dial Code"
                          items={Country.countryData.map((country) =>
                            listDropdownPhoneNumbers(country)
                          )}
                          placeholder="Select Mobile Dial Code"
                          searchable="true"
                          required={false}
                          dynamicWidth={true}
                          containerClassName="w-2/6"
                          listClassName="min-w-[420px]"
                          showValue={true}
                          error={errors?.phoneCode?.message}
                          autoFillable={true}
                          defaultValue={
                            prospect?.phoneCode?.toString() ||
                            leadProfileInfo?.phoneCode ||
                            ""
                          }
                          {...register(`phoneCode`, {
                            value:
                              prospect?.phoneCode?.toString() ||
                              leadProfileInfo?.phoneCode ||
                              "",
                          })}
                        />
                        <TextField
                          id={`phone-number`}
                          key={`phone-number`}
                          label="Phone No."
                          type="tel"
                          required={false}
                          dynamicWidth={true}
                          containerClassName="w-4/6"
                          error={errors?.phoneNumber?.message}
                          defaultValue={prospect?.phoneNumber}
                          {...register(`phoneNumber`, {
                            setValueAs: (v) => v.trim(),
                          })}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <DatePickerField
                        id={`start-date`}
                        key={`start-date`}
                        label="Start Date"
                        required={true}
                        error={errors?.startDate?.message}
                        defaultValue={prospect?.startDate}
                        {...register(`startDate`)}
                      />
                    </div>

                    <div className="row">
                      <TextField
                        id={`created-by`}
                        key={`created-by`}
                        label="Created By"
                        required={false}
                        disabled={true}
                        value={prospect?.reporterName || ""}
                      />
                    </div>
                  </ExpandableCard>
                </GroupExpandableCard>
              </div>
            </div>
          </FormCard>
        </div>
      </div>

      <BottomBar>
        <button
          id="save-prospect-button"
          className="primary-button"
          onClick={handleSubmit(submitForm)}
        >
          Save
        </button>
      </BottomBar>
    </>
  );
};

export default LeadProfileFormPage;
