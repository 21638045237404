import React from "react";
import classNames from "classnames";
import { Checkbox } from "../General/Checkbox";

interface CheckFieldProps {
  label: string;
  checked: any;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  innerClassName?: string;
}

const CheckField: React.FC<CheckFieldProps> = (props) => {
  return (
    <label
      id={`${props.label}-checkbox`}
      className={classNames(
        "gap-3 font-light text-sm row justify-start items-center p-2 select-none",
        props.innerClassName,
        {
          "cursor-not-allowed": props.disabled,
          "cursor-pointer ": !props.disabled,
        }
      )}
    >
      <Checkbox
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      <span>{props.label}</span>
    </label>
  );
};

export default CheckField;
