export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

export type Month = typeof months[number];

export const towerACompletionDate = "06/2023" as const;
export const towerBCompletionDate = "06/2024" as const;
