import { createContext, useContext, useState } from "react";
import { Proposal } from "../../common/Types";
import {
  IndexProposalResponse,
  ShowProposalResponse,
  StoreProposalResponse,
} from "../../common/Responses";
import api from "../../services/PabloApiService";
import { proposalStatusName } from "../../common/Enums";

type ProposalListContextType = {
  proposals: Proposal[];
  isLoading: boolean;
  index: (append?: Array<string>) => Promise<boolean>;
  show: (id: number) => Promise<Proposal | undefined>;
  search: (keyword: string) => void;
  store: (body: any) => Promise<boolean>;
  update: (id: number, body: any) => Promise<boolean>;
};

const defaultContext: ProposalListContextType = {
  proposals: [],
  isLoading: true,
  index: async (append?: Array<string>) => false,
  show: async (id: number) => undefined,
  search: (keyword: string) => {},
  store: async (body: any) => false,
  update: async (id: number, body: any) => false,
};

const ProposalListContext =
  createContext<ProposalListContextType>(defaultContext);

export const useProposalListContext = () => useContext(ProposalListContext);

export const ProposalListContextProvider: React.FC = ({ children }) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [tempProposals, setTempProposals] = useState<Proposal[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const index = async (append?: Array<string>): Promise<boolean> => {
    const commaSeparatedText = append?.toString();

    setLoading(true);

    const { data, success } = await api.get<IndexProposalResponse>(
      `/proposals?append=${commaSeparatedText ?? ""}`
    );

    if (success) {
      setProposals(data.proposals);
      setTempProposals(data.proposals);
    }

    setLoading(false);

    return true;
  };

  const show = async (id: number): Promise<Proposal | undefined> => {
    setLoading(true);

    const { data, success } = await api.get<ShowProposalResponse>(
      `/proposals/${id}`
    );

    setLoading(false);

    return success ? data.proposal : undefined;
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const results = tempProposals.filter((proposal) => {
        const sameName = proposal?.customers?.some((customer) => {
          return customer.fullName.toLowerCase().includes(formattedKeyword);
        });
        const sameCompanyName = proposal?.companies?.some((company) => {
          return company.name.toLowerCase().includes(formattedKeyword);
        });
        const sameUnitNo = proposal?.unit?.number
          .toLowerCase()
          .includes(formattedKeyword);
        const sameStatus = proposalStatusName(proposal.status || "")
          .toLowerCase()
          .includes(formattedKeyword);
        return sameName || sameCompanyName || sameUnitNo || sameStatus;
      });

      setProposals(results);
    } else {
      setProposals(tempProposals);
    }
  };

  const store = async (body: any): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.post<StoreProposalResponse>(
      "/proposals",
      body
    );

    if (success) {
      let temp = proposals.slice();
      temp.push(data.proposal);
      setProposals(temp);
    }

    setLoading(false);

    return success;
  };

  const update = async (id: number, body: any): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.post<StoreProposalResponse>(
      `/proposals/${id}`,
      body
    );

    if (success) {
      let temp = proposals.slice();
      temp.push(data.proposal);
      setProposals(temp);
    }

    setLoading(false);

    return success;
  };

  return (
    <ProposalListContext.Provider
      value={{
        proposals,
        isLoading,
        index,
        show,
        search,
        store,
        update,
      }}
    >
      {children}
    </ProposalListContext.Provider>
  );
};
