import React from "react";
import { PEUnit } from "../../common/Types";
import { formatNumber } from "../../utils/CommonUtils";

interface UnitSelectionCardProps {
  units: PEUnit[];
}

const UnitSelectionCard: React.FC<UnitSelectionCardProps> = (props) => {
  return (
    <div
      className="col px-8 py-4 bg-light-red shadow rounded-sm mx-7"
      {...props}
    >
      <h3 className="uppercase">{props.units.length} Selected units</h3>

      <div className="w-9/12">
        <table className="bg-transparent table-fixed w-full">
          <thead>
            <tr>
              <th className="text-sm font-normal p-0">Unit no.</th>
              <th className="text-sm font-normal p-0">Unit type</th>
              <th className="text-sm font-normal p-0">Orientation</th>
              <th className="text-sm font-normal p-0">Build up</th>
              <th className="text-sm font-normal p-0">ID theme</th>
            </tr>
          </thead>

          <tbody className="p-0 m-0 h-min">
            {props.units.map((unit, index) => {
              return (
                <tr key={index} className="p-0 m-0 h-2 border-none">
                  <td className="font-semibold p-0 h-2">{unit.unitNo}</td>
                  <td className="font-semibold p-0 h-2">{unit.type}</td>
                  <td className="font-semibold p-0 h-2">{unit.orientation}</td>
                  <td className="font-semibold p-0 h-2">
                    {formatNumber(unit.buildup)}
                  </td>
                  <td className="font-semibold p-0 h-2 capitalize">
                    {unit.idTheme}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UnitSelectionCard;
