import { Booking } from "../../common/Types";
import ApprovalChip from "../Chips/ApprovalChip";
import PaymentInfoSummary from "./PaymentInfoSummary";

interface BookingInfoSummaryProps {
  booking: Booking;
  viewImage: (blobPath: string | null) => void;
}

const BookingInfoSummary: React.FC<BookingInfoSummaryProps> = (props) => {
  const { booking, viewImage } = props;

  return (
    <>
      <div className="px-5 pb-5 pt-3 mb-2 bg-section-background">
        <h4 className="uppercase mb-4">Booking Information</h4>

        <PaymentInfoSummary
          name="booking-info"
          paymentDetails={booking?.paymentDetails}
          viewImage={viewImage}
        />

        {booking?.status && (
          <>
            <div className="flex flex-wrap justify-start mt-6 mb-4 row items-center gap-5">
              <h4 className="uppercase">Booking Approval</h4>
              <ApprovalChip status={booking.status} />
            </div>

            <table className="table-fixed w-full bg-transparent">
              <thead>
                <tr className="h-6 border-none">
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                    {booking.status} By
                  </th>
                  <th className="min-w-[250px] desktop:w-[250px]" />
                  <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                    {booking.status} At
                  </th>
                  <th className="min-w-[250px] desktop:w-[250px]" />
                  <th className="min-w-[250px] desktop:w-[250px]" />
                </tr>
              </thead>

              <tbody>
                <tr className="h-6 border-none">
                  <td className="text-sm font-bold px-0">
                    {booking?.approvalGivenBy?.fullName}
                  </td>
                  <td />
                  <td className="text-sm font-bold px-0">{booking?.givenAt}</td>
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>

            {booking?.remarks && (
              <div className="row flex justify-start items-center gap-11 mt-6">
                <div className="w-full">
                  <p className="text-sm uppercase mb-1">Approval Remarks</p>
                  <span className="text-sm font-bold">{booking.remarks}</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BookingInfoSummary;
