/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React from "react";
import { FlexibleInstalmentDetails } from "../../common/Types";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import { forceDecimal, formatNumber } from "../../utils/CommonUtils";
import Line from "../General/Line";

interface FlexibleInstalmentTableDisplayProps {
  grossSellingPrice?: number;
  details?: FlexibleInstalmentDetails[];
}

const FlexibleInstalmentTableDisplay: React.FC<
  FlexibleInstalmentTableDisplayProps
> = (props) => {
  const unitList = useUnitListContext();
  const { unitPrices } = unitList;
  const { grossSellingPrice, details } = props;

  const totalPrice = grossSellingPrice || unitPrices?.totalGrossPrice || 0;

  return (
    <>
      <div className="bg-section-background py-5">
        <div
          className={classNames(
            "col w-full bg-white flex justify-start gap-2 py-2 pb-4"
          )}
        >
          <div className="row flex justify-start gap-5 py-2 px-4 text-sm font-bold">
            <div className="w-[25%] text-center">Item</div>
            <div className="w-[19%] text-center">Date Payable</div>
            <div className="w-[19%] text-right">Amount Payable (RM)</div>
            <div className="w-[12%] text-right">Percentage (%)</div>
            <div className="w-[25%] text-center">Remarks</div>
          </div>

          {details?.map((element, index) => (
            <div
              key={`details-display-${index}`}
              className="row flex justify-start gap-5 px-4 text-sm"
            >
              <div className="w-[25%]">{element.item}</div>
              <div className="w-[19%] text-center">
                {element?.datePayable || "-"}
              </div>
              <div className="w-[19%] text-right">
                {formatNumber(element.amountPayable)}
              </div>
              <div className="w-[12%] text-right">
                {forceDecimal(
                  (element.amountPayable / totalPrice) * 100
                ).toFixed(2) || "-"}
              </div>
              <div className="w-[25%] text-center">
                {element?.remarks || "-"}
              </div>
            </div>
          ))}

          <Line className={classNames("mx-4 mt-4")} />

          <div className="row flex justify-start gap-5 px-4 text-sm font-bold">
            <div className="w-[25%]" />
            <div className="w-[19%] uppercase text-right">Total</div>
            <div className="w-[19%] text-right">{formatNumber(totalPrice)}</div>
            <div className="w-[12%] text-right">100</div>
            <div className="w-[25%]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FlexibleInstalmentTableDisplay;
