import React from "react";
import Path from "../../common/Path";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import Icon from "../Icons/Icon";
import CollapsibleItem from "./CollapsibleItem";
import NavItem from "./NavItem";
import NavSection from "./NavSection";

interface SideBarProps {
  onHide?: React.MouseEventHandler;
}

const SideBar: React.FC<SideBarProps> = ({ onHide }) => {
  const auth = useAuthContext();

  const showTopSection =
    auth.can(["create-proposal"]) ||
    auth.can(["my-proposals"]) ||
    auth.can(["tableau-dashboard"]);
  const showAdmin = auth.can(["manage-agent"]) || auth.can(["manage-user"]);
  const showManagement =
    auth.can(["management-approval"]) ||
    auth.can(["profit-margin"]) ||
    auth.can(["exco-variables-listing"]) ||
    auth.can(["proposal-listing"]) ||
    auth.can(["reserved-extension"]);

  return (
    <div className="fixed z-20 col h-screen p-3 bg-[#2C2C2C] flex-shrink-0 transition-[height] duration-200 overflow-y-auto w-[235px] tablet:pt-[70px]">
      {onHide && <CollapsibleItem onHide={onHide} />}

      <NavSection show={showTopSection}>
        <NavItem
          to={Path.units}
          label="Unit Price List"
          show={auth.can(["create-proposal"])}
          icon={Icon.fillNewProposal}
          onHide={onHide}
        />
        <NavItem
          to={Path.myProposals}
          label="My Proposals"
          show={auth.can(["my-proposals"])}
          icon={Icon.fillMyProposal}
          onHide={onHide}
        />
        <NavItem
          to="/tableau"
          label="Tableau Dashboard"
          show={auth.can(["tableau-dashboard"])}
          icon={Icon.fillDashboard}
          onHide={onHide}
        />
      </NavSection>

      <NavSection title="Lead Management" show={true}>
        <NavItem
          to={Path.leadBoard}
          label="Board"
          show={true}
          icon={Icon.fillDashboard}
          onHide={onHide}
        />
        <NavItem
          to={`${process.env.REACT_APP_LOOKER_STUDIO_LINK}`}
          label="Summary"
          show={true}
          icon={Icon.fillExcoListing}
          isExternal={true}
          target="_blank"
          rel="noopener noreferrer"
          onHide={onHide}
        />
        <NavItem
          to={Path.leadTimeline}
          label="Timeline"
          show={true}
          icon={Icon.fillExcoListing}
          onHide={onHide}
        />
      </NavSection>

      <NavSection title="Management" show={showManagement}>
        <NavItem
          to={Path.managementApprovals}
          label="Management Approval"
          show={auth.can(["management-approval"])}
          icon={Icon.fillManagementApproval}
          onHide={onHide}
        />
        <NavItem
          to="/profit-margins"
          label="Profit Margin Nearing Threshold"
          show={auth.can(["profit-margin"])}
          icon={Icon.fillProfitMargin}
          onHide={onHide}
        />
        <NavItem
          to="/exco-variables"
          label="Exco Variables Listing"
          show={auth.can(["exco-variables-listing"])}
          icon={Icon.fillExcoListing}
          onHide={onHide}
        />
        <NavItem
          to={Path.proposals}
          label="Proposal Listing"
          show={auth.can(["proposal-listing"])}
          icon={Icon.fillProposalListing}
          onHide={onHide}
        />
        <NavItem
          to={Path.legacyProposals}
          label="Legacy Proposal Listing"
          show={auth.can(["booking-approval"])}
          icon={Icon.fillProposalListing}
          onHide={onHide}
        />
        <NavItem
          to="/reserved-extensions"
          label="Reserved Extension"
          show={auth.can(["reserved-extension"])}
          icon={Icon.fillReservedExtension}
          onHide={onHide}
        />
        <NavItem
          to={Path.bookingApprovals}
          label="CE Approval Listing"
          show={auth.can(["booking-approval"])}
          icon={Icon.fillProposalListing}
          onHide={onHide}
        />
      </NavSection>

      <NavSection title="Admin" show={showAdmin}>
        <NavItem
          to={Path.agencies}
          label="Manage Agency"
          show={auth.can(["manage-agent"])}
          icon={Icon.fillAgent}
          onHide={onHide}
        />
        <NavItem
          to={Path.agents}
          label="Manage Agent"
          show={auth.can(["manage-agent"])}
          icon={Icon.fillAgent}
          onHide={onHide}
        />
        <NavItem
          to={Path.users}
          label="Manage User"
          show={auth.can(["manage-user"])}
          icon={Icon.fillUser}
          onHide={onHide}
        />
      </NavSection>
    </div>
  );
};

export default SideBar;
