import React from "react";

interface TableHeaderProps
  extends React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > {
  header: string;
  className?: string;
}

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  return (
    <th tabIndex={-1} className={`focus:outline-none ${props.className}`}>
      <div className="row justify-start items-center gap-3 focus:outline-none truncate">
        {props.header}
      </div>
    </th>
  );
};

export default TableHeader;
