/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import classNames from "classnames";
import { usePropertyContext } from "../../hooks/contexts/PropertyContext";
import { Building } from "../../common/Types";
import Path from "../../common/Path";
import { useOnOutsideClick } from "../../hooks/UseOnOutsideClick";

const BuildingDropdown: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const property = usePropertyContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedBuilding, setSelectedBuilding] = useState<Building>(
    property.selectedBuilding
  );
  const [lock, setLock] = useState<boolean>(false);

  useEffect(() => {
    const unlockedPaths = [Path.units];
    setLock(!unlockedPaths.includes(window.location.pathname));
  }, [window.location.pathname]);

  const toggle = () => {
    if (lock) return;
    setIsOpen(!isOpen);
  };

  const close = () => setIsOpen(false);

  const handleClick = (building: Building) => {
    if (!lock && selectedBuilding.value !== building.value) {
      property.onChanged(building);
      setSelectedBuilding(building);
      close();
    }
  };

  useOnOutsideClick(containerRef, () => close());

  return (
    <div ref={containerRef} className="col ml-auto mr-8">
      <div
        id="building-dropdown"
        tabIndex={-1}
        onBlur={close}
        onClick={toggle}
        className={classNames(
          "focus:outline-none w-[190px] h-[40px] px-5 py-2 self-start text-[14px] text-gray-400 ring-1 ring-[#1F1F1F4D] select-none",
          {
            "cursor-pointer": !lock,
            "cursor-not-allowed text-gray": lock,
          }
        )}
      >
        <div className="row justify-between items-center">
          <span className="truncate font-extrabold focus:outline-none ">
            {selectedBuilding?.name}
          </span>

          <PabloIcon
            icon={Icon.arrow}
            className={classNames(
              "transition-all duration-300 transform -rotate-90",
              {
                "transform rotate-90": isOpen,
                "gray-icon": lock,
              }
            )}
          />
        </div>

        <div
          className={classNames(
            "w-[192px] relative right-[21px] mt-[8px] min-w-48 ring-1 ring-black ring-opacity-5 z-10 font-semibold cursor-pointer bg-[#2C2C2C] inline-block",
            {
              hidden: !isOpen,
            }
          )}
        >
          {property.buildings.map((building, index) => {
            return (
              <button
                id={`building-${building.value}-${index}`}
                key={index}
                onMouseDown={(e) => e.preventDefault()}
                className={classNames(
                  "w-[192px] h-[40px] px-5 py-2 hover:bg-[#494949] text-left truncate focus:outline-none focus:bg-[#494949]",
                  {
                    "bg-[#494949] text-white cursor-default":
                      building.name === selectedBuilding.name,
                    "text-[#FFFFFF80]": building.name !== selectedBuilding.name,
                  }
                )}
                type="button"
                onBlur={close}
                onClick={() => {
                  building.name !== selectedBuilding.name &&
                    handleClick(building);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" &&
                    building.name !== selectedBuilding.name &&
                    handleClick(building);
                }}
              >
                {building.name}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BuildingDropdown;
