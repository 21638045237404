import React from "react";
import { ProposalStatus } from "../../common/Enums";

interface ProposalStatusChipProps {
  status: ProposalStatus;
}

const ProposalStatusChip: React.FC<ProposalStatusChipProps> = ({ status }) => {
  let className = "px-3 py-1 text-center w-[fit-content] truncate ";
  let name = "";

  switch (status) {
    case "draft":
      className += "text-dark-orange bg-light-orange";
      name = "Draft";
      break;

    case "pending-approval":
      className += "text-dark-brown bg-light-brown";
      name = "Pending Approval";
      break;

    case "approved":
      className += "text-dark-green bg-light-green";
      name = "Approved";
      break;

    case "declined-booking":
      className += "text-dark-red bg-light-red";
      name = "Declined Booking";
      break;

    case "pending-booking":
      className += "text-dark-brown bg-light-brown";
      name = "Pending Booking";
      break;

    case "resubmit-booking":
      className += "text-dark-orange bg-light-orange";
      name = "Resubmit for Booking";
      break;

    case "booked":
      className += "text-dark-blue bg-light-blue";
      name = "Booked";
      break;

    case "declined-approval":
      className += "text-dark-red bg-light-red";
      name = "Declined Approval";
      break;

    case "resubmit-approval":
      className += "text-dark-orange bg-light-orange";
      name = "Resubmit for Approval";
      break;

    case "sold":
      className += "text-dark-blue bg-light-blue";
      name = "Sold";
      break;

    case "expired":
      className += "text-dark-red bg-light-red";
      name = "Expired";
      break;

    case "withdrawn":
      className += "text-dark-red bg-light-red";
      name = "Withdrawn";
      break;

    case "unsuccessful":
      className += "text-dark-red bg-light-red";
      name = "Unsuccessful";
      break;

    case "pending-cancellation":
      className += "text-dark-brown bg-light-brown";
      name = "Pending Cancellation";
      break;

    case "cancelled":
      className += "text-dark-red bg-light-red";
      name = "Cancelled";
      break;

    default:
      break;
  }

  return <div className={className}>{name}</div>;
};

export default ProposalStatusChip;
