import { CancelledDetail } from "../../common/Types";
import { formatNumber } from "../../utils/CommonUtils";

interface CancelledInfoSummaryProps {
  cancelledDetail: CancelledDetail;
}

const CancelledInfoSummary: React.FC<CancelledInfoSummaryProps> = (props) => {
  const { cancelledDetail } = props;

  return (
    <>
      <div className="px-5 pb-5 pt-3 mb-2 bg-section-background">
        <h4 className="uppercase mb-4">Cancellation Information</h4>

        <table className="table-fixed w-full bg-transparent">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Forfeiture Amount
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Late Payment Interest
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Legal Fees
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Agent Commission
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Misc (Expenses)
              </th>
            </tr>
          </thead>

          <tbody>
            <tr className="h-6 border-none">
              <td className="text-sm font-bold p-0">
                RM {formatNumber(cancelledDetail?.forfeitureAmount || 0)}
              </td>
              <td className="text-sm font-bold p-0">
                RM {formatNumber(cancelledDetail?.latePaymentInterest || 0)}
              </td>
              <td className="text-sm font-bold p-0">
                RM {formatNumber(cancelledDetail?.legalFees || 0)}
              </td>
              <td className="text-sm font-bold p-0">
                RM {formatNumber(cancelledDetail?.commission || 0)}
              </td>
              <td className="text-sm font-bold p-0">
                RM {formatNumber(cancelledDetail?.miscExpense || 0)}
              </td>
            </tr>

            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 pt-6 min-w-[250px] desktop:w-[250px]">
                Misc (Profit)
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="min-w-[250px] desktop:w-[250px]" />
            </tr>

            <tr className="h-6 border-none">
              <td className="text-sm font-bold px-0 pt-3">
                RM {formatNumber(cancelledDetail?.miscProfit || 0)}
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        </table>

        <table className="mt-4 table-fixed w-full bg-transparent">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                Cancelled By
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase">
                Cancelled At
              </th>
              <th className="min-w-[250px] desktop:w-[250px]" />
              <th className="min-w-[250px] desktop:w-[250px]" />
            </tr>
          </thead>

          <tbody>
            <tr className="h-6 border-none">
              <td className="text-sm font-bold px-0">
                {cancelledDetail.cancelledBy.fullName}
              </td>
              <td />
              <td className="text-sm font-bold px-0">
                {cancelledDetail.cancelledAt}
              </td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>

        {cancelledDetail?.remarks && (
          <div className="flex flex-wrap justify-start mt-8 mb-4 row items-center gap-4">
            <h4 className="uppercase">Cancellation Remarks</h4>

            <div className="w-full">
              <span className="text-sm font-bold">
                {cancelledDetail.remarks}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CancelledInfoSummary;
