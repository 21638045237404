import { createContext, useContext, useState } from "react";
import api from "../../services/PabloApiService";
import { Agency, SalesRep } from "../../common/Types";
import {
  IndexAgencyResponse,
  StoreAgencyResponse,
  UpdateAgencyResponse,
} from "../../common/Responses";

type AgencyListContextType = {
  agencies: Agency[];
  salesReps: SalesRep[];
  isLoading: boolean;
  index: (param?: string, append?: Array<string>) => Promise<boolean>;
  search: (keyword: string) => void;
  store: (body: any, append?: Array<string>) => Promise<boolean>;
  update: (id: number, body: any) => Promise<boolean>;
  destroy: (id: number) => Promise<boolean>;
};

const defaultContext: AgencyListContextType = {
  agencies: [],
  salesReps: [],
  isLoading: true,
  index: async (param?: string, append?: Array<string>) => false,
  search: (keyword: string) => {},
  store: async (body: any, append?: Array<string>) => false,
  update: async (id: number, body: any) => false,
  destroy: async (id: number) => false,
};

const AgencyListContext = createContext<AgencyListContextType>(defaultContext);

export const useAgencyListContext = () => useContext(AgencyListContext);

export const AgencyListContextProvider: React.FC = ({ children }) => {
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [tempAgencies, setTempAgencies] = useState<Agency[]>([]);
  const [salesReps, setSalesReps] = useState<SalesRep[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const index = async (
    param?: string,
    append?: Array<string>
  ): Promise<boolean> => {
    const commaSeparatedText = append?.toString();

    setLoading(true);

    const { data, success } = await api.get<IndexAgencyResponse>(
      `/agencies?status=${param ?? ""}&append=${commaSeparatedText ?? ""}`
    );

    if (success) {
      setAgencies(data.agencies);
      setTempAgencies(data.agencies);
      setSalesReps(data.salesReps);
    }

    setLoading(false);

    return success;
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const results = tempAgencies.filter((agency) => {
        const sameName = agency.name.toLowerCase().includes(formattedKeyword);
        const sameSalesRep = agency.salesRep?.fullName
          .toLowerCase()
          .includes(formattedKeyword);
        return sameName || sameSalesRep;
      });

      setAgencies(results);
    } else {
      setAgencies(tempAgencies);
    }
  };

  const store = async (body: any, append?: Array<string>): Promise<boolean> => {
    const commaSeparatedText = append?.toString();

    setLoading(true);

    const { data, success } = await api.post<StoreAgencyResponse>(
      `/agencies?append=${commaSeparatedText ?? ""}`,
      body
    );

    if (success) {
      let temp = agencies.slice();
      temp.push(data.agency);
      setAgencies(temp);
    }

    setLoading(false);

    return success;
  };

  const update = async (id: number, body: any): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.patch<UpdateAgencyResponse>(
      `/agencies/${id}`,
      body
    );

    if (success) {
      let temp = agencies.slice();
      const updatingAgency = temp.filter((agency) => agency.id === id)[0];
      const agencyIndex = temp.indexOf(updatingAgency);
      temp[agencyIndex] = data.agency;
      setAgencies(temp);
    }

    setLoading(false);

    return success;
  };

  const destroy = async (id: number): Promise<boolean> => {
    setLoading(true);

    const { success } = await api.delete(`/agencies/${id}`);

    if (success) {
      const temp = agencies.slice();
      const updatedAgencies = temp.filter((item: Agency) => item.id !== id);
      setAgencies(updatedAgencies);
      setTempAgencies(updatedAgencies);
    }

    setLoading(false);

    return success;
  };

  return (
    <AgencyListContext.Provider
      value={{
        agencies,
        salesReps,
        isLoading,
        index,
        search,
        store,
        update,
        destroy,
      }}
    >
      {children}
    </AgencyListContext.Provider>
  );
};
