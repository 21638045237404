import classNames from "classnames";
import React, { useState } from "react";
import { SortingOption } from "../../common/Types";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface SortCaretProps
  extends React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > {
  header: string;
  onToggle: () => void;
}

const SortCaret: React.FC<SortCaretProps> = (props) => {
  const [state, setState] = useState<SortingOption | "na">("na");

  const toggle = () => {
    setState(state === "asc" ? "desc" : "asc");
    props.onToggle();
  };

  const onBlur = () => {
    setState("na");
  };

  return (
    <th
      onClick={toggle}
      onBlur={onBlur}
      tabIndex={-1}
      className={`focus:outline-none ${props.className}`}
    >
      <div className="row justify-start items-center gap-3 cursor-pointer focus:outline-none truncate">
        {props.header}
        <div className="col justify-center items-center w-4">
          <PabloIcon
            icon={Icon.arrow}
            width={16}
            className={classNames("rotate-90 -mb-1 select-none", {
              "gray-icon": state !== "asc",
            })}
          />
          <PabloIcon
            icon={Icon.arrow}
            width={16}
            className={classNames("-rotate-90 -mt-1 select-none", {
              "gray-icon": state !== "desc",
            })}
          />
        </div>
      </div>
    </th>
  );
};

export default SortCaret;
