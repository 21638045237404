/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import { Proposal } from "../../common/Types";
import MoreButton from "../../components/Buttons/MoreButton";
import BaseCard from "../../components/Cards/BaseCard";
import ProposalStatusChip from "../../components/Chips/ProposalStatusChip";
import CancelProposalForm from "../../components/Forms/CancelProposalForm";
import UpdateSolicitorForm from "../../components/Forms/UpdateSolicitorForm";
import UpdateSPADateForm from "../../components/Forms/UpdateSPADateForm";
import UpdateSpecialRebateForm from "../../components/Forms/UpdateSpecialRebateForm";
import Icon from "../../components/Icons/Icon";
import PopUpItem from "../../components/PopUp/PopUpItem";
import SortCaret from "../../components/Tables/SortCaret";
import TableV2 from "../../components/Tables/TableV2";
import HeaderText from "../../components/Texts/HeaderText";
import { useBookingApprovalListContext } from "../../hooks/contexts/BookingApprovalListContext";
import { useLegacyProposalListContext } from "../../hooks/contexts/LegacyProposalListContext";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useFilter } from "../../hooks/Filter";
import { formatNumber } from "../../utils/CommonUtils";

const LegacyProposalListPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const proposalList = useLegacyProposalListContext();
  const bookingApprovalList = useBookingApprovalListContext();
  const loadingBar = useLoadingBarContext();
  const modal = useModalContext();
  const { items, sort } = useFilter<Proposal>(proposalList.proposals);

  const view = (proposal: Proposal) => {
    navigate(Path.viewLegacyProposal(proposal.id), { state: proposal });
  };

  const generateLetters = (proposal: Proposal) => {
    navigate(Path.viewGenerateLetters(proposal.id, "legacy-proposal"), {
      state: proposal,
    });
  };

  const onCancelPreview = async (proposal: Proposal) => {
    modal.view({
      title: "",
      dismissible: false,
      containerClassName: "w-10/12 h-[90%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <CancelProposalForm
          proposalId={proposal.id}
          proposalStatus={proposal.status}
          detail={proposal?.cancelledDetail}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => proposalList.index()}
        />
      ),
    });
  };

  const onUpdateSPADatePreview = async (proposal: Proposal) => {
    modal.view({
      title: "Update SPA Signed Date",
      description: "Are you sure you want to update the SPA signed date?",
      dismissible: false,
      containerClassName: "w-[550px] h-[45%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <UpdateSPADateForm
          proposalId={proposal.id}
          detail={proposal?.soldDetail}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => proposalList.index()}
        />
      ),
    });
  };

  const onUpdateSolicitorPreview = async (proposal: Proposal) => {
    modal.view({
      title: "Update Solicitor",
      description: "Are you sure you want to update the solicitor?",
      dismissible: false,
      containerClassName: "w-[550px] h-[65%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <UpdateSolicitorForm
          proposalId={proposal.id}
          solicitorList={bookingApprovalList?.solicitors}
          defaultSolicitorId={proposal?.solicitor?.id}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => proposalList.index()}
        />
      ),
    });
  };

  const onUpdateSpecialRebatePreview = async (proposal: Proposal) => {
    modal.view({
      title: "Update Special Rebate",
      description: "Are you sure you want to update the special rebate?",
      dismissible: false,
      containerClassName: "w-[550px] h-[65%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <UpdateSpecialRebateForm
          proposalId={proposal.id}
          detail={proposal?.salesPackage}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => proposalList.index()}
        />
      ),
    });
  };

  const listActions = (proposal: Proposal, index: number) => {
    const hasEditedOwner = proposal?.ceActionsHistory?.some(
      (action) => action.category === "owner"
    );
    const isGeneratable = proposal?.solicitor && hasEditedOwner;

    const defaultActions = [
      <PopUpItem icon={Icon.eye} label="View" onClick={() => view(proposal)} />,
    ];
    let additionalActions: JSX.Element[] = [];

    switch (proposal.status) {
      case "cancelled":
        additionalActions = [
          <PopUpItem
            icon={Icon.edit}
            label="Edit Cancellation"
            onClick={() => onCancelPreview(proposal)}
          />,
        ];
        break;

      case "sold":
        additionalActions = [
          <PopUpItem
            icon={Icon.proposalListing}
            label="Generate 7 Letters"
            disabled={isGeneratable ? false : true}
            hasTooltip={isGeneratable ? false : true}
            tooltipContent={
              <>
                <ul className="list-requirements list-disc">
                  <strong>Complete the following first:</strong>
                  {!proposal?.solicitor && (
                    <li className="ml-5">Update solicitor</li>
                  )}
                  {!hasEditedOwner && (
                    <li className="ml-5">Update ownership</li>
                  )}
                </ul>
              </>
            }
            onClick={() => generateLetters(proposal)}
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit Ownership"
            onClick={() =>
              navigate(Path.editBuyers(proposal.id, "legacy-proposal"), {
                state: proposal,
              })
            }
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit Solicitor"
            onClick={() => onUpdateSolicitorPreview(proposal)}
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit SPA Signed Date"
            onClick={() => onUpdateSPADatePreview(proposal)}
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit Special Rebate"
            onClick={() => onUpdateSpecialRebatePreview(proposal)}
          />,
          <PopUpItem
            icon={Icon.close}
            label={"Cancel"}
            onClick={() => onCancelPreview(proposal)}
          />,
        ];
        break;

      default:
        break;
    }

    return [...defaultActions, ...additionalActions];
  };

  useEffect(() => {
    bookingApprovalList.getSolicitors();
    bookingApprovalList.getHandlers();
  }, [location]);

  useEffect(() => {
    if (proposalList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [proposalList.isLoading]);

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="Legacy Proposal Listing" />

        <BaseCard>
          <TableV2
            isLoading={proposalList.isLoading}
            searchable={true}
            searchHint="Search by buyer name or unit number"
            searchApiCallback={proposalList.search}
            headers={[
              <SortCaret
                key="customer-name"
                header="Buyer name"
                onToggle={() => sort("customers[0].fullName,companies[0].name")}
              />,
              <SortCaret
                key="sold-date"
                header="Sold date"
                onToggle={() => sort("soldDetail.soldAt")}
              />,
              <SortCaret
                key="unit"
                header="Unit"
                onToggle={() => sort("unit.number")}
              />,
              <SortCaret
                key="buildup"
                header="Build up"
                onToggle={() => sort("unit.type.buildUp")}
              />,
              <SortCaret
                key="type"
                header="Type"
                onToggle={() => sort("unit.type.name")}
              />,
              <SortCaret
                key="proposal-status"
                header="Proposal Status"
                onToggle={() => sort("status")}
              />,
            ]}
            paginationDetails={proposalList.paginationDetails}
            paginationApiCallback={proposalList.index}
            body={items.map((proposal, index) => (
              <tr
                key={`proposal-${proposal.id}-${index}`}
                className={classNames("h-0 align-top", {
                  "bg-opacity-50 bg-ink-well": proposal.status === "sold",
                })}
              >
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal.customers?.length
                    ? proposal.customers?.map((customer, cusomterIndex) => (
                        <div key={`customer-${cusomterIndex}`}>
                          {customer.fullName}
                        </div>
                      ))
                    : proposal.companies?.map((company, companyIndex) => (
                        <div key={`company-${companyIndex}`}>
                          {company.name}
                        </div>
                      ))}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal?.soldDetail?.soldAt}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal?.unit?.number}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {formatNumber(proposal?.unit?.type?.buildUp || "-")}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal?.unit?.type?.name || "-"}
                </td>
                <td className="py-2">
                  <ProposalStatusChip status={proposal.status} />
                </td>
                <td className="flex justify-end items-center h-11 pl-0">
                  <MoreButton popupClassName="w-max">
                    {listActions(proposal, index).map((action, actionIndex) => (
                      <Fragment key={`action-${actionIndex}`}>
                        {action}
                      </Fragment>
                    ))}
                  </MoreButton>
                </td>
              </tr>
            ))}
          />
        </BaseCard>
      </div>
    </>
  );
};

export default LegacyProposalListPage;
