import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import packageJson from "../../../package.json";
import Path from "../../common/Path";
import { User } from "../../common/Types";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useDeviceContext } from "../../hooks/contexts/DeviceContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import api from "../../services/PabloApiService";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import KskLogo from "../Images/KskLogo";
import Sidebar from "../Modals/TabletSideBar";
import AccountTile from "../PopUp/AccountTile";
import PopUpItem from "../PopUp/PopUpItem";
import BuildingDropdown from "./BuildingDropdown";

interface TopBarProps {
  user: User | undefined;
}

const TopBar: React.FC<TopBarProps> = ({ user }) => {
  const auth = useAuthContext();
  const device = useDeviceContext();
  const navigate = useNavigate();
  const loadingPage = useLoadingPageContext();
  const [expand, setExpand] = useState<boolean>(false);
  const versionNumber = packageJson.version;

  const changePassword = () => {
    navigate(Path.changePassword);
  };

  const logout = async () => {
    loadingPage.start();
    await api.post("/logout");
    loadingPage.end();

    auth.onLogout();
  };

  const toggle = () => setExpand(!expand);

  return (
    <>
      <header className="fixed w-full top-0 z-30 px-5 py-3 bg-section-background border-b-[1px] border-input-border h-14 row justify-between items-center">
        {device.isTablet && !device.isPortrait && (
          <PabloIcon
            icon={Icon.collapseArrow}
            className="black-icon pl-5 rotate-180 cursor-pointer"
            onClick={toggle}
          />
        )}

        <KskLogo
          onClick={() =>
            navigate(device.isPortrait ? Path.calculator : Path.main)
          }
          className="hover:cursor-pointer w-10"
        />

        <div
          className={classNames("text-sm text-gray", {
            "pl-5": !device.isPortrait,
          })}
        >
          {`${process.env.REACT_APP_ENV} - v${versionNumber}`}
        </div>

        {!device.isPortrait && <BuildingDropdown />}

        <AccountTile user={user}>
          {!device.isPortrait && (
            <>
              {process.env.REACT_APP_ENV !== "PROD" && (
                <PopUpItem
                  icon={Icon.edit}
                  label="Change password"
                  onClick={changePassword}
                />
              )}

              <PopUpItem
                icon={Icon.excoListing}
                label="User guide"
                onClick={() => {
                  /* Not applicable */
                }}
                link={{
                  isLink: true,
                  href: "https://sites.google.com/kskgroup.com/pablo-web-app/user-guide",
                }}
              />
            </>
          )}

          <PopUpItem icon={Icon.logout} label="Logout" onClick={logout} />
        </AccountTile>
      </header>
      <Sidebar show={expand} onHide={() => setExpand(false)} />
    </>
  );
};

export default TopBar;
