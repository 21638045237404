import classNames from "classnames";
import React, { Children, useState } from "react";
import Dot from "../General/Dot";

interface TabBarProps {
  tabs: string[];
  hasIndicator?: boolean;
  initialIndex?: number;
  indexOnChanged?: (index: number) => void;
  className?: string;
}

const TabBar: React.FC<TabBarProps> = ({
  initialIndex = 0,
  hasIndicator = false,
  tabs,
  children,
  className,
  indexOnChanged,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex);
  const [openedTabs, setOpenedTabs] = useState<number[]>(
    hasIndicator ? [initialIndex] : []
  );

  const onClick = (index: number) => {
    setActiveIndex(index);
    hasIndicator &&
      !openedTabs.includes(index) &&
      setOpenedTabs([...openedTabs, index]);
    indexOnChanged?.(index);
  };

  return (
    <div className="col">
      <div className={`row space-x-12 ${className}`}>
        {tabs.map((tab, index) => {
          return (
            <button
              key={`tab-bar-${index}`}
              onClick={() => onClick(index)}
              className={classNames(
                "uppercase text-xs font-bold pb-2 cursor-pointer transition-colors duration-200",
                {
                  "text-disabled": activeIndex !== index,
                  "border-b-2": activeIndex === index,
                }
              )}
            >
              {tab}
              {hasIndicator && !openedTabs.includes(index) && (
                <Dot status="reserved" className="ml-2" />
              )}
            </button>
          );
        })}
      </div>
      {Children.map(children, (child, index) => {
        return activeIndex === index ? child : null;
      })}
    </div>
  );
};

export default TabBar;
