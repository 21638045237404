import React from "react";
import { TaskType } from "../../common/Types/DragAndDrop";
import Task from "./Task";

interface InnerListProps {
  tasks: TaskType[];
}

const InnerList: React.FC<InnerListProps> = (props) => {
  const { tasks } = props;

  return (
    <>
      {tasks.map((task, index) => (
        <Task key={task.id} task={task} index={index} />
      ))}
    </>
  );
};

export default InnerList;
