import { createContext, useContext, useEffect, useState } from "react";
import { Permission } from "../../common/Permission";
import { AuthResponse, LoginResponse } from "../../common/Responses";
import { User } from "../../common/Types";
import api from "../../services/PabloApiService";
import storage from "../../services/StorageService";

type AuthStatus =
  | "authenticating"
  | "loggedIn"
  | "loggedOut"
  | "expired"
  | "unauthorized";

type AuthContextType = {
  user: User | undefined;
  status: AuthStatus;
  can: (permissions: Permission[]) => boolean;
  role: () => string;
  onLogin: (response: LoginResponse) => void;
  onLogout: () => void;
  onUnauthorized: () => void;
};

const defaultContext: AuthContextType = {
  user: undefined,
  status: "authenticating",
  can: (permission: Permission[]) => false,
  role: () => "",
  onLogin: (response: LoginResponse) => {},
  onLogout: () => {},
  onUnauthorized: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultContext);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [status, setStatus] = useState<AuthStatus>("authenticating");
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const token = storage.getToken();
    token ? authenticate() : setStatus("loggedOut");
  }, []);

  const authenticate = async () => {
    const { data, success } = await api.post<AuthResponse>("/authenticate");

    if (success) {
      storage.setToken(data.token);
      setUser(data.user);
      setStatus("loggedIn");
    } else {
      setStatus("expired");
    }
  };

  const onLogin = (response: LoginResponse) => {
    setStatus("loggedIn");
    setUser(response.user);
    storage.setToken(response.token);
  };

  const onLogout = () => {
    setStatus("loggedOut");
    storage.clear();
  };

  const onUnauthorized = () => {
    setStatus("unauthorized");
  };

  const can = (permissions: Permission[]): boolean => {
    return (user?.permissions || []).some((permission) =>
      permissions.includes(permission)
    );
  };

  const role = (): string => {
    return user?.roles?.[0] ?? "";
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        status,
        can,
        role,
        onLogin,
        onLogout,
        onUnauthorized,
      }}
    >
      {status === "authenticating" ? (
        <div className="fixed col items-center justify-center overflow-auto z-50 bg-white left-0 right-0 top-0 bottom-0">
          <img
            src="/assets/ksk-logo.png"
            alt="ksk_logo"
            className="w-2/12 mb-4"
          />
          <div className="animate-spin h-8 w-8 rounded-full border-r-2 border-primary" />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
