/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Proposal } from "../../common/Types";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useArray } from "../../hooks/UseArray";
import { Validation } from "../../hooks/UseValidation";
import CustomerFullInfoAccordian from "../Accordion/CustomerFullInfoAccordian";
import { GroupExpandableCard } from "../Cards/ExpandableCard";
import RadioField from "../Fields/RadioField";
import { RadioGroup } from "../General/Radio";
import TabBar from "../Tabs/TabBar";
import CompanyInfoForm from "./CompanyInfoForm";
import BookingInfoAccordian from "../Accordion/BookingInfoAccordian";
import classNames from "classnames";
import { IFormSchema } from "../../common/Interfaces";
import AddItemButton from "../Buttons/AddItemButton";
import { useMultipleRefs } from "../../hooks/useMultipleRefs";
import { MAX_BOOKINGS, MAX_CUSTOMERS } from "../../common/Booking";

interface EditProposalDetailsFormProps<T extends IFormSchema> {
  proposal?: Proposal;
  validation: Validation<T>;
  setDirty: Function;
  hasBooking: boolean;
}

const EditProposalDetailsForm: React.FC<EditProposalDetailsFormProps<any>> = (
  props
) => {
  const { proposal, validation, setDirty, hasBooking } = props;
  const modal = useModalContext();

  const customerRefs: React.RefObject<any>[] = useMultipleRefs(MAX_CUSTOMERS);
  const bookingRefs: React.RefObject<any>[] = useMultipleRefs(MAX_BOOKINGS);

  const [buyerType, setBuyerType] = useState<string>(
    proposal?.customers
      ? proposal.customers.length > 0
        ? "individual"
        : "company"
      : "company"
  );
  const [customerIndexes, dispatchCustomerIndexes] = useArray<number>(
    proposal?.customers?.length
      ? proposal.customers.length > 0
        ? [...proposal.customers.keys()]
        : [0]
      : [0]
  );
  const [customerClearedList, dispatchCustomerClearedList] = useArray<number>(
    []
  );
  const [bookingInfoIndexes, dispatchBookingInfoIndexes] = useArray<number>(
    proposal?.booking?.paymentDetails?.length
      ? proposal?.booking?.paymentDetails?.length > 0
        ? [...proposal.booking.paymentDetails.keys()]
        : [0]
      : [0]
  );
  const [bookingClearedList, dispatchBookingClearedList] = useArray<number>([]);

  const onBuyerTypeChange = (value: any) => {
    if (buyerType !== value) {
      setDirty(true);
      validation.clearErrors();
      validation.setValue("buyerType", value);
      setBuyerType(value);
    }
  };

  const onDeleteCustomerInfoField = (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Delete Customer",
      description: "Are you sure you want to delete this customer?",
      onConfirm: {
        text: "Delete",
        action: () => {
          setDirty(true);
          customerRefs[realIndex].current.onClearAttachments();

          dispatchCustomerClearedList({ type: "add", value: index });
          dispatchCustomerIndexes({
            type: "remove",
            value: customerIndexes.find((v) => v === index) as number,
          });

          validation.resetField(`customerInfo.${index}`);
          validation.unregister(`customerInfo.${index}`);
        },
      },
    });
  };

  const onClearCustomerInfoField = async (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Clear Customer Information",
      description: "Are you sure you want to proceed with removing the values?",
      onConfirm: {
        text: "Clear",
        action: () => {
          setDirty(true);
          customerRefs[realIndex].current.onClearAttachments();

          dispatchCustomerClearedList({ type: "add", value: index });
          dispatchCustomerIndexes({
            type: "add",
            value: customerIndexes[customerIndexes.length - 1] + 1,
          });

          setTimeout(() => {
            dispatchCustomerIndexes({
              type: "remove",
              value: customerIndexes.find((v) => v === index) as number,
            });

            validation.resetField(`customerInfo.${index}`);
            validation.unregister(`customerInfo.${index}`);
          }, 1);
        },
      },
    });
  };

  const onDeleteBookingInfoField = (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Delete Booking Information",
      description: "Are you sure you want to delete this booking information?",
      onConfirm: {
        text: "Delete",
        action: () => {
          setDirty(true);
          bookingRefs[realIndex].current.onClearAttachments();

          dispatchBookingClearedList({ type: "add", value: index });
          dispatchBookingInfoIndexes({
            type: "remove",
            value: bookingInfoIndexes.find((v) => v === index) as number,
          });

          validation.resetField(`bookingInfo.${index}`);
          validation.unregister(`bookingInfo.${index}`);
        },
      },
    });
  };

  const onClearBookingInfoField = async (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Clear Booking Information",
      description: "Are you sure you want to proceed with removing the values?",
      onConfirm: {
        text: "Clear",
        action: () => {
          setDirty(true);
          bookingRefs[realIndex].current.onClearAttachments();

          dispatchBookingClearedList({ type: "add", value: index });
          dispatchBookingInfoIndexes({
            type: "add",
            value: bookingInfoIndexes[bookingInfoIndexes.length - 1] + 1,
          });

          setTimeout(() => {
            dispatchBookingInfoIndexes({
              type: "remove",
              value: bookingInfoIndexes.find((v) => v === index) as number,
            });

            validation.resetField(`bookingInfo.${index}`);
            validation.unregister(`bookingInfo.${index}`);
          }, 1);
        },
      },
    });
  };

  useEffect(() => {
    validation.setValue("buyerType", buyerType);
  }, []);

  return (
    <div className="border border-[#53565A33] rounded-sm mx-7">
      <div
        className={classNames("col bg-white shadow rounded-sm", {
          "py-8": hasBooking,
          "pb-4": !hasBooking,
        })}
      >
        {!hasBooking && proposal?.unit && (
          <TabBar
            tabs={[proposal.unit.number]}
            className="px-7 pt-4 pb-3"
            indexOnChanged={() => {}}
          />
        )}

        <div>
          <div className="col px-8 py-4 bg-white shadow rounded-sm mx-7 border border-[#53565A33]">
            <h2>Buyer Type</h2>
            <div className="flex-wrap flex items-center justify-start mt-2">
              <RadioGroup onChange={onBuyerTypeChange} defaultValue={buyerType}>
                <RadioField
                  key="individual"
                  label="Individual"
                  value="individual"
                  innerClassName="pl-0"
                />
                <RadioField
                  key="company"
                  label="Company"
                  value="company"
                  innerClassName="pl-10"
                />
              </RadioGroup>
            </div>
          </div>

          {buyerType === "individual" ? (
            <GroupExpandableCard
              defaultValue="customer-information-1"
              onExpand={() => {}}
            >
              {customerIndexes.map((customerInfoIndex, index) => (
                <CustomerFullInfoAccordian
                  ref={customerRefs[index]}
                  key={`customer-information-${customerInfoIndex}`}
                  value={`customer-information-${index + 1}`}
                  title={`Customer Information ${index + 1} ${
                    index === 0 ? "(Main Buyer)" : ""
                  }`}
                  index={customerInfoIndex}
                  defaultValues={
                    customerClearedList.includes(customerInfoIndex)
                      ? undefined
                      : proposal?.customers?.[customerInfoIndex]
                  }
                  showRequired={true}
                  validation={validation}
                  className="border border-[#53565A33]"
                  onDeleteAction={
                    customerIndexes.length > 1
                      ? () =>
                          onDeleteCustomerInfoField(customerInfoIndex, index)
                      : () => onClearCustomerInfoField(customerInfoIndex, index)
                  }
                  setDirty={setDirty}
                />
              ))}

              {customerIndexes.length < MAX_CUSTOMERS && (
                <AddItemButton
                  id="add-customer-info-button"
                  text="+ Add new customer information"
                  containerClassName="mx-7 my-4"
                  onClick={() =>
                    dispatchCustomerIndexes({
                      type: "add",
                      value: customerIndexes[customerIndexes.length - 1] + 1,
                    })
                  }
                />
              )}
            </GroupExpandableCard>
          ) : (
            <CompanyInfoForm
              validation={validation}
              className="border border-[#53565A33]"
              defaultValues={proposal?.companies?.[0]}
              setDirty={setDirty}
            />
          )}

          {hasBooking && (
            <div className="col px-8 pt-4 bg-white shadow rounded-sm mx-7 border border-[#53565A33]">
              <div className="col mb-8">
                <div className="row items-center">
                  <h2 className="mr-4">Booking Information</h2>
                </div>

                <GroupExpandableCard
                  defaultValue="booking-info-1"
                  onExpand={() => {}}
                >
                  {bookingInfoIndexes.map((bookingInfo, index) => (
                    <BookingInfoAccordian
                      ref={bookingRefs[index]}
                      key={`booking-info-${bookingInfo}`}
                      value={`booking-info-${index + 1}`}
                      title={`Booking Information ${index + 1}`}
                      index={bookingInfo}
                      defaultValues={
                        bookingClearedList.includes(bookingInfo)
                          ? undefined
                          : proposal?.booking?.paymentDetails?.[bookingInfo]
                      }
                      showRequired={true}
                      validation={validation}
                      className="shadow-none mx-0 mt-4 mb-0 border border-[#53565A33]"
                      onDeleteAction={
                        bookingInfoIndexes.length > 1
                          ? () => onDeleteBookingInfoField(bookingInfo, index)
                          : () => onClearBookingInfoField(bookingInfo, index)
                      }
                      setDirty={setDirty}
                    />
                  ))}

                  {bookingInfoIndexes.length < MAX_BOOKINGS && (
                    <AddItemButton
                      id="add-booking-info-button"
                      text="+ Add new booking information"
                      onClick={() =>
                        dispatchBookingInfoIndexes({
                          type: "add",
                          value:
                            bookingInfoIndexes[bookingInfoIndexes.length - 1] +
                            1,
                        })
                      }
                    />
                  )}
                </GroupExpandableCard>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProposalDetailsForm;
