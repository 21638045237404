import React from "react";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface CollapsibleItemProps {
  onHide: React.MouseEventHandler;
}

const CollapsibleItem: React.FC<CollapsibleItemProps> = (props) => {
  return (
    <div
      onClick={props.onHide}
      className={
        "row justify-end p-2 hover:bg-[#494949] rounded cursor-pointer mb-1 transition-all ease-in-out"
      }
    >
      <PabloIcon
        icon={Icon.collapseArrow}
        className={"transform rotate-0 transition-transform duration-300"}
      />
    </div>
  );
};

export default CollapsibleItem;
