/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ReviewPriceDistributionCoordinates } from "../../common/Types";
import { formatNumber, range } from "../../utils/CommonUtils";
import AreaChart from "../Charts/AreaChart";

interface ReviewUnsoldUnitPriceDistributionProps {
  tower?: string;
  reviewPriceDistribution?: ReviewPriceDistributionCoordinates;
}

const ReviewUnsoldUnitPriceDistribution: React.FC<
  ReviewUnsoldUnitPriceDistributionProps
> = (props) => {
  const { tower, reviewPriceDistribution } = props;

  const psfLabels = range(2500, 4500, 50);
  const absLabels = range(2, 5.6, 0.1);

  const data = {
    psf: [
      {
        fill: true,
        label: "Post Approval Value",
        data: reviewPriceDistribution?.unsoldUnitPricePsf.postApproval,
        borderColor: "rgb(31, 120, 180)",
        backgroundColor: "rgba(31, 120, 180, 0.6)",
        tension: 0.4,
      },
      {
        fill: true,
        label: "Current Value",
        data: reviewPriceDistribution?.unsoldUnitPricePsf.current,
        borderColor: "rgb(166, 206, 227)",
        backgroundColor: "rgba(166, 206, 227, 0.8)",
        tension: 0.4,
      },
    ],
    absolute: [
      {
        fill: true,
        label: "Post Approval Value",
        data: reviewPriceDistribution?.unsoldUnitPrice.postApproval,
        borderColor: "rgb(51, 160, 44)",
        backgroundColor: "rgba(51, 160, 44, 0.6)",
        tension: 0.4,
      },
      {
        fill: true,
        label: "Current Value",
        data: reviewPriceDistribution?.unsoldUnitPrice.current,
        borderColor: "rgb(178, 223, 138)",
        backgroundColor: "rgba(178, 223, 138, 0.8)",
        tension: 0.4,
      },
    ],
  };

  return (
    <>
      <h4 className="uppercase mb-4">
        Review Remaining Unsold Gross Unit Price Distribution
      </h4>

      <div className="laptop:row w-full">
        <div className="col desktop:w-1/2 laptop:w-2/3 w-full border border-[#53565A33] px-5 pt-2 pb-5">
          <h5 className="pb-3">
            Unit Gross Price (PSF) for Unsold Units in Tower {tower}
          </h5>
          <AreaChart
            xAxisName="Price Per Sqft (RM)"
            yAxisName="Number of Unsold Units"
            labels={psfLabels}
            datasets={data.psf}
          />
        </div>

        <div className="col desktop:w-[220px] laptop:w-1/3 laptop:text-right laptop:my-auto my-1 text-center w-full">
          Average Gross Price (PSF)
          <br />
          RM{" "}
          {formatNumber(
            reviewPriceDistribution?.averageUnsoldGrossPricePsf || 0
          )}
        </div>
      </div>

      <div className="laptop:row w-full laptop:mt-0 mt-6">
        <div className="col desktop:w-1/2 laptop:w-2/3 w-full border border-[#53565A33] mt-3 px-5 pt-2 pb-5">
          <h5 className="pb-3">
            Unit Gross Price (Absolute) for Unsold Units in Tower {tower}
          </h5>
          <AreaChart
            xAxisName="Price Per Unit (RM) (in Millions)"
            yAxisName="Number of Unsold Units"
            labels={absLabels}
            datasets={data.absolute}
          />
        </div>

        <div className="col desktop:w-[220px] laptop:w-1/3 laptop:text-right laptop:my-auto my-2 text-center w-full">
          Average Gross Price (Absolute)
          <br />
          RM{" "}
          {formatNumber(reviewPriceDistribution?.averageUnsoldGrossPrice || 0)}
        </div>
      </div>
    </>
  );
};

export default ReviewUnsoldUnitPriceDistribution;
