/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { object, SchemaOf } from "yup";
import { SoldDetail } from "../../common/Types";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useValidation } from "../../hooks/UseValidation";
import { baseString } from "../../utils/SchemaUtil";
import BottomBar from "../Navs/BottomBar";
import api from "../../services/PabloApiService";
import { GeneralMessageResponse } from "../../common/Responses";
import DatePickerField from "../Fields/DatePickerField";
import moment from "moment";

interface UpdateSPADate {
  revisedSpaSignedDate: string;
}

const schema: SchemaOf<UpdateSPADate> = object().shape({
  revisedSpaSignedDate: baseString().required().label("SPA signed date"),
});

interface UpdateSPADateFormProps {
  proposalId: number;
  detail?: SoldDetail;
  closeModal: Function;
  onCompleteCallback: () => void;
}

const UpdateSPADateForm: React.FC<UpdateSPADateFormProps> = (props) => {
  const now = moment().format("YYYY-MM-DD");
  const { proposalId, closeModal, detail, onCompleteCallback } = props;
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<UpdateSPADate>(schema);
  const { register, watch, handleSubmit, errors } = validation;
  const spaForm = watch();

  const submitForm = async () => {
    loadingPage.start();

    const body = {
      revisedSpaSignedAt: spaForm?.revisedSpaSignedDate,
    };

    const { success } = await api.patch<GeneralMessageResponse>(
      `/proposals/${proposalId}/update-revised-spa`,
      body
    );

    closeModal();

    await onCompleteCallback();

    loadingPage.end();

    return success;
  };

  return (
    <>
      <div className="overflow-auto pb-5 h-full">
        <DatePickerField
          id="revised-spa-signed-date"
          label="Revised SPA Signed Date"
          required={true}
          error={errors?.revisedSpaSignedDate?.message}
          max={now}
          defaultValue={detail?.revisedSpaSignedAt}
          {...register("revisedSpaSignedDate")}
        />
      </div>

      <BottomBar innerClassName="pr-0 gap-4">
        <button
          id="cancel-updated-spa-date-button"
          className="secondary-button"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          id="submit-updated-spa-date-button"
          className="primary-button"
          onClick={handleSubmit(submitForm)}
        >
          Submit
        </button>
      </BottomBar>
    </>
  );
};

export default UpdateSPADateForm;
