/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { boolean, number, object, SchemaOf } from "yup";
import Country from "../../common/Country";
import { agencyStatuses, agentStatuses } from "../../common/Enums";
import Path from "../../common/Path";
import { Agency, NewAgent, User } from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import DatePickerField from "../../components/Fields/DatePickerField";
import DropdownField from "../../components/Fields/DropdownField";
import TextField from "../../components/Fields/TextField";
import Line from "../../components/General/Line";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import BottomBar from "../../components/Navs/BottomBar";
import { useAgencyListContext } from "../../hooks/contexts/AgencyListContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import { listDropdownItems } from "../../utils/CommonUtils";
import { baseString } from "../../utils/SchemaUtil";

interface AgencyForm {
  name: string;
  country: string;
  loaStartDate: string;
  loaEndDate: string;
  tier: number;
  commissionRate: number;
  salesRepId: number;
  status: string;
  isAddAgent: boolean;
  agent?: NewAgent;
}

const AgencyFormPage: React.FC = () => {
  const { agencyId } = useParams();
  const { state } = useLocation();
  const agency: Agency | null = state?.agency;
  const salesReps: User[] | [] = state.salesReps;

  const schema: SchemaOf<AgencyForm> = object().shape({
    name: baseString().required().default(agency?.name).label("Agency name"),
    country: baseString().required().default(agency?.country),
    loaStartDate: baseString()
      .required()
      .default(agency?.loaStartDate)
      .label("LOA start date"),
    loaEndDate: baseString()
      .required()
      .default(agency?.loaEndDate)
      .label("LOA end date"),
    tier: number()
      .oneOf(Array.from([1, 2, 3]))
      .required()
      .default(agency?.tier),
    commissionRate: number()
      .typeError("Commission rate must contain a number.")
      .required()
      .min(0)
      .max(20)
      .integer()
      .default(agency?.commissionRate)
      .label("Commission rate"),
    salesRepId: number()
      .required()
      .min(0, "Sales PIC is required")
      .default(agency?.salesRep?.id)
      .label("Sales PIC"),
    status: baseString()
      .oneOf(Array.from(agencyStatuses))
      .required()
      .default(agency?.status)
      .label("Agency status"),
    isAddAgent: boolean()
      .required()
      .default(!agencyId ? true : false),
    agent: object().when("isAddAgent", {
      is: true,
      then: object().shape({
        fullName: baseString().required().label("Agent name"),
        email: baseString()
          .trim()
          .matches(
            /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
            "Email format is invalid"
          )
          .required()
          .label("Email"),
        phoneCode: baseString().required().label("Dial code"),
        phoneNumber: baseString()
          .trim()
          .matches(/^[0-9]*$/, "Contact number must be in numbers.")
          .required()
          .label("Contact number"),
        status: baseString()
          .oneOf(Array.from(agentStatuses))
          .required()
          .label("Agent status"),
      }),
    }),
  });

  const navigate = useNavigate();
  const agencyList = useAgencyListContext();
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<AgencyForm>(schema);
  const { scrollOnError } = useScrollToError<AgencyForm>();
  const { setDirty } = useUnload();

  const now = moment();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const submitForm = async (body: AgencyForm) => {
    let success = false;
    loadingPage.start();

    if (!agencyId) {
      success = await agencyList.store(body, ["agents"]);
    } else {
      success = await agencyList.update(parseInt(agencyId), body);
    }

    loadingPage.end();
    setShowSuccess(success);
  };

  useEffect(() => {
    validation.setValue("isAddAgent", !agencyId ? true : false);
  }, []);

  useEffect(() => {
    scrollOnError(validation.errors);
  }, [validation.errors]);

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton text="Back to manage agency" />

          <FormCard
            title={agencyId ? "Edit agency" : "Add new agency and agent"}
          >
            <div className="gap-3 col">
              <h3>Agency details</h3>
              <div className="col gap-5 mt-2">
                <div className="tablet:row w-full gap-4">
                  <TextField
                    id="agency-name"
                    label="Agency Name"
                    type="text"
                    defaultValue={agency?.name}
                    error={validation.errors.name?.message}
                    onInput={() => setDirty(true)}
                    {...validation.register("name", {
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <div className="mb-5" />

                  <DropdownField
                    id="agency-country"
                    label="Country"
                    error={validation.errors.country?.message}
                    placeholder="Select Country"
                    items={Country.countryData.map((country) => {
                      return {
                        text: country.name,
                        value: country.alpha2Code,
                      };
                    })}
                    searchable="true"
                    defaultValue={agency?.country}
                    {...validation.register("country", {
                      value: agency?.country || "",
                      onChange: () => setDirty(true),
                    })}
                  />
                </div>

                <div className="tablet:row w-full gap-4">
                  <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
                    <DatePickerField
                      id="agency-loa-start-date"
                      label="LOA Period (Start)"
                      defaultValue={agency?.loaStartDate}
                      dynamicWidth={true}
                      containerClassName="w-1/2"
                      error={validation.errors.loaStartDate?.message}
                      onInput={() => setDirty(true)}
                      min={now.clone().startOf("month").format("YYYY-MM-DD")}
                      {...validation.register("loaStartDate")}
                    />

                    <DatePickerField
                      id="agency-loa-end-date"
                      label="LOA Period (End)"
                      defaultValue={agency?.loaEndDate}
                      dynamicWidth={true}
                      containerClassName="w-1/2"
                      error={validation.errors.loaEndDate?.message}
                      onInput={() => setDirty(true)}
                      min={
                        agency?.loaStartDate
                          ? moment(agency?.loaStartDate)
                              .clone()
                              .format("YYYY-MM-DD")
                          : now.clone().format("YYYY-MM-DD")
                      }
                      {...validation.register("loaEndDate")}
                    />
                  </div>
                  <div className="mb-5" />

                  <DropdownField
                    id="agency-tier"
                    label="Tier"
                    error={validation.errors.tier?.message}
                    placeholder="Select Tier"
                    items={[...Array(3)].map((tier, index) => {
                      return {
                        text: (index + 1).toString(),
                        value: (index + 1).toString(),
                      };
                    })}
                    defaultValue={agency?.tier.toString()}
                    {...validation.register("tier", {
                      value: agency?.tier || 0,
                      onChange: () => setDirty(true),
                    })}
                  />
                </div>

                <div className="tablet:row w-full gap-4">
                  <TextField
                    id="agency-commission-rate"
                    label="Commission rate (%)"
                    type="number"
                    onKeyDown={(e) =>
                      /[+-.,]$/.test(e.key) && e.preventDefault()
                    }
                    defaultValue={agency?.commissionRate}
                    error={validation.errors.commissionRate?.message}
                    onInput={() => setDirty(true)}
                    {...validation.register("commissionRate")}
                  />
                  <div className="mb-5" />

                  <DropdownField
                    id="agency-sales-rep"
                    label="Sales PIC"
                    error={validation.errors.salesRepId?.message}
                    placeholder="Select Sales Rep"
                    items={salesReps.map((user) => {
                      return {
                        text: user.fullName,
                        value: user.id.toString(),
                      };
                    })}
                    searchable="true"
                    defaultValue={agency?.salesRep?.id.toString()}
                    {...validation.register("salesRepId", {
                      value: agency?.salesRep?.id || -1,
                      onChange: () => setDirty(true),
                    })}
                  />
                </div>

                <div className="tablet:row w-full gap-4">
                  <DropdownField
                    id="agency-status"
                    label="Status"
                    error={validation.errors.status?.message}
                    placeholder="Select Agency Status"
                    items={agencyStatuses.map((status) =>
                      listDropdownItems(status)
                    )}
                    defaultValue={agency?.status}
                    {...validation.register("status", {
                      value: agency?.status || "",
                      onChange: () => setDirty(true),
                    })}
                  />
                  <div className="mb-5" />
                  <div className="col mb-5 w-full tablet:w-[498px]" />
                </div>
              </div>
            </div>

            {!agencyId && (
              <>
                <Line />
                <div className="gap-3 col">
                  <h3>Agent details</h3>
                  <div className="col gap-5 mt-2">
                    <div className="tablet:row w-full gap-4">
                      <TextField
                        id="agent-name"
                        label="Agent Name"
                        type="text"
                        error={validation.errors.agent?.fullName?.message}
                        onInput={() => setDirty(true)}
                        {...validation.register("agent.fullName", {
                          setValueAs: (v) => v.trim(),
                        })}
                      />
                      <div className="mb-5" />

                      <TextField
                        id="agent-email"
                        label="Email"
                        type="text"
                        error={validation.errors.agent?.email?.message}
                        onInput={() => setDirty(true)}
                        {...validation.register("agent.email", {
                          setValueAs: (v) => v.trim().toLowerCase(),
                        })}
                      />
                    </div>

                    <div className="tablet:row w-full gap-4">
                      <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
                        <DropdownField
                          id="agent-phone-code"
                          label="Dial Code"
                          error={validation.errors.agent?.phoneCode?.message}
                          placeholder="Select Dial Code"
                          items={Country.countryData.map((country) => {
                            return {
                              text: `+${country.dialCode} - ${country.name}`,
                              value: country.dialCode,
                            };
                          })}
                          searchable="true"
                          dynamicWidth={true}
                          containerClassName="w-2/6"
                          listClassName="min-w-[420px]"
                          showValue={true}
                          {...validation.register("agent.phoneCode", {
                            value: "",
                            onChange: () => setDirty(true),
                          })}
                        />
                        <TextField
                          id="agent-contact-number"
                          label="Contact No."
                          type="tel"
                          dynamicWidth={true}
                          containerClassName="w-4/6"
                          error={validation.errors.agent?.phoneNumber?.message}
                          onInput={() => setDirty(true)}
                          {...validation.register("agent.phoneNumber", {
                            setValueAs: (v) => v.trim(),
                          })}
                        />
                      </div>
                      <div className="mb-5" />

                      <DropdownField
                        id="agent-status"
                        label="Status"
                        error={validation.errors.agent?.status?.message}
                        placeholder="Select Agent Status"
                        items={agentStatuses.map((status) =>
                          listDropdownItems(status)
                        )}
                        {...validation.register("agent.status", {
                          value: "",
                          onChange: () => setDirty(true),
                        })}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </FormCard>
        </div>

        <BottomBar>
          <button
            id="submit-agency-button"
            className="primary-button"
            onClick={validation.handleSubmit(() => {
              setDirty(false);
              setShowConfirmation(true);
            })}
          >
            {agencyId ? "Save" : "Add"}
          </button>
        </BottomBar>
      </div>

      {/* modal */}
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        type="warning"
        title={
          agencyId
            ? "Are you sure you want to update the agency details?"
            : "Are you sure you want to add this new agency?"
        }
        onConfirm={{
          text: agencyId ? "Update" : "Add",
          action: validation.handleSubmit(submitForm),
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        dismissible={false}
        onHide={() => setShowSuccess(false)}
        type="success"
        title={
          agencyId
            ? "Agency details has successfully been updated."
            : "You have successfully added this agency."
        }
        onConfirm={{
          text: "Manage agency",
          action: () => navigate(Path.agencies),
        }}
        onCancel={{
          text: "Back to main page",
          action: () => navigate(Path.main),
        }}
      />
    </>
  );
};

export default AgencyFormPage;
