/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { IFormSchema } from "../../common/Interfaces";
import { FlexibleInstalmentDetails } from "../../common/Types";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import { useArray } from "../../hooks/UseArray";
import { Validation } from "../../hooks/UseValidation";
import { forceDecimal, formatNumber } from "../../utils/CommonUtils";
import DatePickerField from "../Fields/DatePickerField";
import TextField from "../Fields/TextField";
import Line from "../General/Line";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import ErrorText from "../Texts/ErrorText";

interface FlexibleInstalmentPaymentStructureAccordianProps<
  T extends IFormSchema
> {
  validation: Validation<T>;
  grossSellingPrice?: number;
  setValidated: Function;
  detailsIndexes: number[];
  dispatchDetailsIndexes: Function;
  defaultValues?: FlexibleInstalmentDetails[];
}

const FlexibleInstalmentPaymentStructureAccordian: React.FC<
  FlexibleInstalmentPaymentStructureAccordianProps<any>
> = (props) => {
  const MAX_DETAILS = 30;
  const MATCH_GROSS_PRICE_ERROR =
    "Total value in Amount Payable does not match the Gross Selling price";
  const unitList = useUnitListContext();
  const { unitPrices } = unitList;
  const {
    validation,
    grossSellingPrice,
    setValidated,
    defaultValues,
    detailsIndexes,
    dispatchDetailsIndexes,
  } = props;
  const paymentStructureFields = validation.watch("paymentStructure");
  const error = validation?.errors?.paymentStructure?.flexibleInstalmentDetails;
  const totalPrice = grossSellingPrice || unitPrices?.totalGrossPrice || 0;

  const [detailsClearedList, dispatchDetailsClearedList] = useArray<number>([]);
  const [remainingAmount, setRemainingAmount] = useState<number>(totalPrice);
  const [isFocused, setFocused] = useState<boolean>(false);

  const onAddDetailsField = () => {
    dispatchDetailsIndexes({
      type: "add",
      value: detailsIndexes[detailsIndexes.length - 1] + 1,
    });
  };

  const onClearDetailsField = (index: number) => {
    validation.setValue(`paymentStructure.flexibleInstalmentDetails.${index}`, {
      item: "",
      value: undefined,
    });

    dispatchDetailsClearedList({ type: "add", value: index });
    dispatchDetailsIndexes({
      type: "add",
      value: detailsIndexes[detailsIndexes.length - 1] + 1,
    });

    setTimeout(() => {
      dispatchDetailsIndexes({
        type: "remove",
        value: detailsIndexes.find((v) => v === index) as number,
      });
      validation.resetField(
        `paymentStructure.flexibleInstalmentDetails.${index}`
      );
      validation.unregister(
        `paymentStructure.flexibleInstalmentDetails.${index}`
      );
    }, 1);
  };

  const onDeleteDetailsField = (index: number) => {
    dispatchDetailsClearedList({ type: "add", value: index });
    dispatchDetailsIndexes({
      type: "remove",
      value: detailsIndexes.find((v) => v === index) as number,
    });
    validation.resetField(
      `paymentStructure.flexibleInstalmentDetails.${index}`
    );
    validation.unregister(
      `paymentStructure.flexibleInstalmentDetails.${index}`
    );
  };

  const calculateAmountPercentage = (amount: number) => {
    return forceDecimal((amount / totalPrice) * 100) || "-";
  };

  const calculateRemainingAmount = () => {
    let sumOfAmounts = 0;

    paymentStructureFields?.flexibleInstalmentDetails?.forEach(
      (item: FlexibleInstalmentDetails) => {
        sumOfAmounts += item?.amountPayable || 0;
      }
    );

    const tempRemainingAmount = totalPrice - sumOfAmounts;

    setRemainingAmount(tempRemainingAmount);
  };

  useEffect(() => {
    calculateRemainingAmount();

    const isEqualToTotalPrice =
      paymentStructureFields?.flexibleInstalmentDetails
        ?.map((obj: any) => obj?.amountPayable || 0)
        ?.reduce((total: number, num: number) => total + num) === totalPrice;
    const hasEqualToTotalPriceError =
      error?.every(
        (obj: any) => obj?.amountPayable?.type === "priceNotFulfilled"
      ) || false;

    if (!isEqualToTotalPrice) {
      if (!hasEqualToTotalPriceError)
        detailsIndexes.forEach((i) => {
          validation.setError(
            `paymentStructure.flexibleInstalmentDetails.${i}.amountPayable`,
            {
              type: "priceNotFulfilled",
              message: MATCH_GROSS_PRICE_ERROR,
            }
          );
        });
    } else {
      if (hasEqualToTotalPriceError)
        detailsIndexes.forEach((i) => {
          validation.clearErrors(
            `paymentStructure.flexibleInstalmentDetails.${i}.amountPayable`
          );
        });
    }

    if (validation.errors?.paymentStructure) {
      setValidated("error");
    } else {
      setValidated("warning");
    }
  }, [validation.watch()]);

  return (
    <>
      {error?.some(
        (e: any) => e?.amountPayable?.type === "priceNotFulfilled"
      ) && <ErrorText text={MATCH_GROSS_PRICE_ERROR} className="mb-4" />}

      <div className="bg-section-background p-5">
        <div
          className={classNames(
            "col w-full bg-white flex justify-start gap-2 py-2",
            {
              "pb-4": detailsIndexes.length >= MAX_DETAILS,
            }
          )}
        >
          <div className="row flex justify-start gap-3 px-4 text-sm font-bold">
            <div className="w-[25%]">Item</div>
            <div className="w-[19%]">Date Payable</div>
            <div className="w-[19%]">Amount Payable (RM)</div>
            <div className="w-[10%]">Percentage (%)</div>
            <div className="w-[22%]">Remarks (Optional)</div>
            <div className="w-[5%]">&nbsp;</div>
          </div>

          {detailsIndexes.map((element) => (
            <div
              key={`details-${element}`}
              className="row flex justify-start gap-3 px-4"
            >
              <div className="w-[25%]">
                <TextField
                  id={`flexible-instalment-item-${element}`}
                  type="text"
                  error={error?.[element]?.item?.message}
                  innerClassName="w-full text-xs h-9 py-0 px-2"
                  dynamicWidth={true}
                  defaultValue={
                    detailsClearedList.includes(element)
                      ? undefined
                      : defaultValues?.[element]?.item
                  }
                  {...validation.register(
                    `paymentStructure.flexibleInstalmentDetails.${element}.item`,
                    { setValueAs: (v) => v?.trim() }
                  )}
                />
              </div>
              <div className="w-[19%]">
                <DatePickerField
                  id={`flexible-instalment-date-payable-${element}`}
                  innerClassName="w-full text-xs h-9 py-0 px-2"
                  required={true}
                  dynamicWidth={true}
                  error={error?.[element]?.datePayable?.message}
                  defaultValue={
                    detailsClearedList.includes(element)
                      ? undefined
                      : defaultValues?.[element]?.datePayable
                  }
                  {...validation.register(
                    `paymentStructure.flexibleInstalmentDetails.${element}.datePayable`
                  )}
                />
              </div>
              <div className="w-[19%]">
                <TextField
                  id={`flexible-instalment-amount-payable-${element}`}
                  type="text"
                  error={
                    error?.[element]?.amountPayable?.type !==
                    "priceNotFulfilled"
                      ? error?.[element]?.amountPayable?.message
                      : ""
                  }
                  innerClassName="w-full text-xs h-9 py-0 px-2"
                  inputClassName="text-right"
                  dynamicWidth={true}
                  defaultValue={
                    detailsClearedList.includes(element)
                      ? undefined
                      : defaultValues?.[element]?.amountPayable
                  }
                  onFocus={() => setFocused(true)}
                  {...validation.register(
                    `paymentStructure.flexibleInstalmentDetails.${element}.amountPayable`,
                    {
                      setValueAs: (v) => parseInt(v) || "",
                      onBlur: () => setFocused(false),
                    }
                  )}
                  value={
                    isFocused
                      ? paymentStructureFields?.flexibleInstalmentDetails?.[
                          element
                        ]?.amountPayable
                      : formatNumber(
                          paymentStructureFields?.flexibleInstalmentDetails?.[
                            element
                          ]?.amountPayable
                        )
                  }
                />
              </div>
              <div className="w-[10%] text-right">
                <TextField
                  id={`flexible-instalment-percentage-display-${element}`}
                  type="text"
                  innerClassName="w-full text-xs h-9 py-0 px-2"
                  inputClassName="text-right"
                  dynamicWidth={true}
                  disabled={true}
                  value={calculateAmountPercentage(
                    paymentStructureFields?.flexibleInstalmentDetails?.[element]
                      ?.amountPayable || 0
                  )}
                />
              </div>
              <div className="w-[22%]">
                <TextField
                  id={`flexible-instalment-remarks-${element}`}
                  type="text"
                  error={error?.[element]?.remarks?.message}
                  innerClassName="w-full text-xs h-9 py-0 px-2"
                  dynamicWidth={true}
                  defaultValue={
                    detailsClearedList.includes(element)
                      ? undefined
                      : defaultValues?.[element]?.remarks
                  }
                  {...validation.register(
                    `paymentStructure.flexibleInstalmentDetails.${element}.remarks`,
                    { setValueAs: (v) => v?.trim() }
                  )}
                />
              </div>

              <div
                className="w-[5%] my-auto cursor-pointer"
                onClick={() =>
                  detailsIndexes.length > 1
                    ? onDeleteDetailsField(element)
                    : onClearDetailsField(element)
                }
              >
                <PabloIcon
                  icon={Icon.remove}
                  className="w-[20px] h-[20px] gray-icon"
                />
              </div>
            </div>
          ))}

          {detailsIndexes.length < MAX_DETAILS && (
            <div className="row w-full px-4">
              <button
                id="add-flexible-instalment-details-button"
                className="text-xs text-[#0880e3] hover:text-[#084de3]"
                onClick={onAddDetailsField}
              >
                + Add Details
              </button>
            </div>
          )}

          <Line
            className={classNames("mx-4", {
              "mt-4": detailsIndexes.length >= MAX_DETAILS,
            })}
          />

          <div className="row flex justify-start gap-3 px-4 text-sm italic">
            <div className="w-[25%]" />
            <div className="w-[19%] text-right">Remaining Amount</div>
            <div className="w-[19%] text-right">
              {remainingAmount < 0 ? "< 0" : formatNumber(remainingAmount)}
            </div>
            <div className="w-[10%]" />
            <div className="w-[22%]" />
            <div className="w-[5%]" />
          </div>

          <div className="row flex justify-start gap-3 px-4 text-sm font-bold">
            <div className="w-[25%]" />
            <div className="w-[19%] uppercase text-right">Total</div>
            <div className="w-[19%] text-right">{formatNumber(totalPrice)}</div>
            <div className="w-[10%] text-right">100</div>
            <div className="w-[22%]" />
            <div className="w-[5%]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FlexibleInstalmentPaymentStructureAccordian;
