import { createContext, useContext, useState } from "react";
import { Building } from "../../common/Types";
import storage from "../../services/StorageService";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";

type PropertyContextType = {
  buildings: Building[];
  selectedBuilding: Building;
  onChanged: (building: Building) => void;
};

const buildings: Building[] = [
  { id: 1, name: "8 Conlay Tower A", value: "A", sectionId: 1, projectId: 1 },
  { id: 2, name: "8 Conlay Tower B", value: "B", sectionId: 2, projectId: 1 },
];

const defaultContext: PropertyContextType = {
  buildings: [],
  selectedBuilding: buildings[1],
  onChanged: (building: Building) => {},
};

const PropertyContext = createContext<PropertyContextType>(defaultContext);

export const usePropertyContext = () => useContext(PropertyContext);

export const PropertyContextProvider: React.FC = ({ children }) => {
  const unitList = useUnitListContext();
  const [selectedBuilding, setSelectedBuilding] = useState<Building>(
    storage.getSelectedBuilding() ?? buildings[1]
  );

  const onChanged = (building: Building) => {
    setSelectedBuilding(building);
    storage.setSelectedBuilding(building);
    unitList.handleOnClearFilteredUnits();
  };

  return (
    <PropertyContext.Provider
      value={{ buildings, selectedBuilding, onChanged }}
    >
      {children}
    </PropertyContext.Provider>
  );
};
