import classNames from "classnames";
import {
  grrRatesSelection,
  grrYearsSelection,
} from "../../common/SalesPackage";
import { GrrValue } from "../../common/Types";
import { Validation } from "../../hooks/UseValidation";
import { ProposalForm } from "../../schemas/ProposalSchema";
import DropdownField from "../Fields/DropdownField";

interface GrrCardProps {
  validation: Validation<ProposalForm>;
  defaultValues?: GrrValue;
}

const GrrCard: React.FC<GrrCardProps> = (props) => {
  const { validation, defaultValues } = props;
  const salesPackage = props.validation.watch("salesPackage");

  return (
    <>
      <div className="col w-full tablet:w-[300px] min-h-[160px]">
        <div className="mb-5">
          <label className="w-full uppercase">Enter GRR Rate</label>
        </div>
        <div
          className={classNames(
            "w-full bg-[#F0E7E780] px-4 py-2 flex gap-8 items-center justify-evenly text-sm h-full",
            {
              "min-h-[160px]":
                salesPackage?.rebateType === "nett-selling-price",
              "min-h-[140px]": salesPackage?.rebateType === "percentage",
            }
          )}
        >
          <div className="text-center">
            <div className="mb-5">GRR Rate</div>
            <DropdownField
              id="grr-rate"
              key="grr-rate"
              items={grrRatesSelection.map((rate) => {
                return {
                  text: `${rate}%`,
                  value: rate.toString(),
                };
              })}
              dynamicWidth={true}
              innerClassName="text-xs h-9"
              containerClassName="w-full"
              listContainerClassName="top-[27px]"
              defaultValue={defaultValues?.rate || "6"}
              disabled={!!defaultValues}
              {...validation.register("salesPackage.grrValues.rate", {
                valueAsNumber: true,
              })}
            />
          </div>
          <div className="text-center">
            <div className="mb-5">Number of years</div>
            <DropdownField
              id="grr-year"
              key="grr-year"
              items={grrYearsSelection.map((year) => {
                return {
                  text: `${year} Year${year > 1 ? "s" : ""}`,
                  value: year.toString(),
                };
              })}
              dynamicWidth={true}
              innerClassName="min-w-[120px] text-xs h-9"
              containerClassName="w-full"
              listContainerClassName="top-[27px]"
              defaultValue={defaultValues?.year || "2"}
              disabled={!!defaultValues}
              {...validation.register("salesPackage.grrValues.year", {
                valueAsNumber: true,
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GrrCard;
