/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { object, SchemaOf } from "yup";
import { Solicitor } from "../../common/Types";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useValidation } from "../../hooks/UseValidation";
import { baseString } from "../../utils/SchemaUtil";
import BottomBar from "../Navs/BottomBar";
import api from "../../services/PabloApiService";
import { GeneralMessageResponse } from "../../common/Responses";
import DropdownField from "../Fields/DropdownField";

interface UpdateSolicitor {
  solicitorId: string;
}

const schema: SchemaOf<UpdateSolicitor> = object().shape({
  solicitorId: baseString().required().label("Solicitor"),
});

interface UpdateSolicitorFormProps {
  proposalId: number;
  solicitorList?: Solicitor[];
  defaultSolicitorId?: number;
  closeModal: Function;
  onCompleteCallback: () => void;
}

const UpdateSolicitorForm: React.FC<UpdateSolicitorFormProps> = (props) => {
  const {
    proposalId,
    closeModal,
    solicitorList,
    defaultSolicitorId,
    onCompleteCallback,
  } = props;
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<UpdateSolicitor>(schema);
  const { register, watch, handleSubmit, errors } = validation;
  const solicitorForm = watch();

  const submitForm = async () => {
    loadingPage.start();

    const body = {
      solicitorId: solicitorForm?.solicitorId,
    };

    const { success } = await api.patch<GeneralMessageResponse>(
      `/proposals/${proposalId}/update-proposal-solicitor`,
      body
    );

    closeModal();

    await onCompleteCallback();

    loadingPage.end();

    return success;
  };

  return (
    <>
      <div className="overflow-auto pb-5 h-full">
        {solicitorList && solicitorList?.length > 0 ? (
          <DropdownField
            id="solicitorId"
            label="Solicitor"
            placeholder="Select a solicitor"
            error={errors?.solicitorId?.message}
            items={solicitorList.map((solicitor) => {
              return {
                text: solicitor.name,
                value: solicitor.id.toString(),
              };
            })}
            defaultValue={defaultSolicitorId?.toString()}
            {...register("solicitorId")}
          />
        ) : (
          <></>
        )}
      </div>

      <BottomBar innerClassName="pr-0 gap-4">
        <button
          id="cancel-updated-solicitor-button"
          className="secondary-button"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          id="submit-updated-solicitor-button"
          className="primary-button"
          onClick={handleSubmit(submitForm)}
        >
          Submit
        </button>
      </BottomBar>
    </>
  );
};

export default UpdateSolicitorForm;
