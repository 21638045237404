/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import { Proposal } from "../../common/Types";
import MoreButton from "../../components/Buttons/MoreButton";
import BaseCard from "../../components/Cards/BaseCard";
import ProposalStatusChip from "../../components/Chips/ProposalStatusChip";
import CancelProposalForm from "../../components/Forms/CancelProposalForm";
import Icon from "../../components/Icons/Icon";
import PopUpItem from "../../components/PopUp/PopUpItem";
import SortCaret from "../../components/Tables/SortCaret";
import TabBarTable from "../../components/Tables/TabBarTable";
import HeaderText from "../../components/Texts/HeaderText";
import Format from "../../helpers/Format";
import { useBookingApprovalListContext } from "../../hooks/contexts/BookingApprovalListContext";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useFilter } from "../../hooks/Filter";
import { formatNumber } from "../../utils/CommonUtils";
import UpdateSPADateForm from "../../components/Forms/UpdateSPADateForm";
import UpdateSolicitorForm from "../../components/Forms/UpdateSolicitorForm";
import UpdateSpecialRebateForm from "../../components/Forms/UpdateSpecialRebateForm";

const BookingApprovalListPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bookingApprovalList = useBookingApprovalListContext();
  const loadingBar = useLoadingBarContext();
  const modal = useModalContext();
  const { items, sort } = useFilter<Proposal>(bookingApprovalList.proposals);

  const approvedProposals = items.filter((item) =>
    ["booked", "sold"].includes(item.status)
  );
  const pendingProposals = items.filter((item) =>
    ["pending-booking", "pending-cancellation"].includes(item.status)
  );
  const declinedProposals = items.filter((item) =>
    ["declined-booking", "cancelled"].includes(item.status)
  );
  const otherProposals = items.filter((item) =>
    ["pending-approval", "resubmit-approval", "resubmit-booking"].includes(
      item.status
    )
  );

  const viewBookingApproval = (proposal: Proposal) => {
    navigate(Path.bookingApprovalDetails(proposal.id), { state: proposal });
  };

  const convertToSold = (proposal: Proposal) => {
    navigate(Path.sold(proposal.id), { state: proposal });
  };

  const generateLetters = (proposal: Proposal) => {
    navigate(Path.viewGenerateLetters(proposal.id, "ce"), { state: proposal });
  };

  const onCancelPreview = async (proposal: Proposal) => {
    modal.view({
      title: "",
      dismissible: false,
      containerClassName: "w-10/12 h-[90%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <CancelProposalForm
          proposalId={proposal.id}
          proposalStatus={proposal.status}
          detail={proposal?.cancelledDetail}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => bookingApprovalList.index()}
        />
      ),
    });
  };

  const onUpdateSPADatePreview = async (proposal: Proposal) => {
    modal.view({
      title: "Update SPA Signed Date",
      description: "Are you sure you want to update the SPA signed date?",
      dismissible: false,
      containerClassName: "w-[550px] h-[45%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <UpdateSPADateForm
          proposalId={proposal.id}
          detail={proposal?.soldDetail}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => bookingApprovalList.index()}
        />
      ),
    });
  };

  const onUpdateSolicitorPreview = async (proposal: Proposal) => {
    modal.view({
      title: "Update Solicitor",
      description: "Are you sure you want to update the solicitor?",
      dismissible: false,
      containerClassName: "w-[550px] h-[65%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <UpdateSolicitorForm
          proposalId={proposal.id}
          solicitorList={bookingApprovalList?.solicitors}
          defaultSolicitorId={proposal?.solicitor?.id}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => bookingApprovalList.index()}
        />
      ),
    });
  };

  const onUpdateSpecialRebatePreview = async (proposal: Proposal) => {
    modal.view({
      title: "Update Special Rebate",
      description: "Are you sure you want to update the special rebate?",
      dismissible: false,
      containerClassName: "w-[550px] h-[65%]",
      modalClassName: "w-full px-5 pt-5",
      content: (
        <UpdateSpecialRebateForm
          proposalId={proposal.id}
          detail={proposal?.salesPackage}
          closeModal={() => modal.setShow(false)}
          onCompleteCallback={() => bookingApprovalList.index()}
        />
      ),
    });
  };

  const listActions = (proposal: Proposal, index: number) => {
    const defaultActions = [
      <PopUpItem
        icon={Icon.eye}
        label={proposal.status === "pending-booking" ? "Give Approval" : "View"}
        onClick={() => viewBookingApproval(proposal)}
      />,
    ];
    let additionalActions: JSX.Element[] = [];

    switch (proposal.status) {
      case "booked":
        additionalActions = [
          <PopUpItem
            icon={Icon.proposalListing}
            label="Generate 7 Letters"
            onClick={() => generateLetters(proposal)}
          />,
          <PopUpItem
            icon={Icon.convert}
            label="Convert to Sold"
            imageClassName="w-[24px] h-[24px] opacity-70"
            onClick={() => convertToSold(proposal)}
          />,
          <PopUpItem
            icon={Icon.close}
            label="Cancel"
            onClick={() => onCancelPreview(proposal)}
          />,
        ];
        break;

      case "cancelled":
        additionalActions = [
          <PopUpItem
            icon={Icon.edit}
            label="Edit Cancellation"
            onClick={() => onCancelPreview(proposal)}
          />,
        ];
        break;

      case "pending-booking":
      case "pending-approval":
      case "resubmit-booking":
      case "resubmit-approval":
        additionalActions = [
          <PopUpItem
            icon={Icon.close}
            label={"Cancel"}
            onClick={() => onCancelPreview(proposal)}
          />,
        ];
        break;

      case "sold":
        additionalActions = [
          <PopUpItem
            icon={Icon.proposalListing}
            label="Generate 7 Letters"
            onClick={() => generateLetters(proposal)}
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit Ownership"
            onClick={() =>
              navigate(Path.editBuyers(proposal.id, "ce"), {
                state: proposal,
              })
            }
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit Solicitor"
            onClick={() => onUpdateSolicitorPreview(proposal)}
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit SPA Signed Date"
            onClick={() => onUpdateSPADatePreview(proposal)}
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit Special Rebate"
            onClick={() => onUpdateSpecialRebatePreview(proposal)}
          />,
          <PopUpItem
            icon={Icon.close}
            label={"Cancel"}
            onClick={() => onCancelPreview(proposal)}
          />,
        ];
        break;

      default:
        break;
    }

    return [...defaultActions, ...additionalActions];
  };

  useEffect(() => {
    bookingApprovalList.index();
    bookingApprovalList.getSolicitors();
  }, [location]);

  useEffect(() => {
    if (bookingApprovalList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [bookingApprovalList.isLoading]);

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="CE Approval Listing" />

        <BaseCard>
          <TabBarTable
            tabs={[
              "Pending Approval",
              "Approved",
              "Cancelled/Declined",
              "Others",
            ]}
            isLoading={bookingApprovalList.isLoading}
            onSearching={bookingApprovalList.search}
            searchHint="Search by customer, unit or status"
            headers={[
              <SortCaret
                key="buyer-name"
                header="Buyer name"
                onToggle={() => sort("customers[0].fullName,companies[0].name")}
              />,
              <SortCaret
                key="created-by"
                header="Created by"
                onToggle={() => sort("user.fullName")}
              />,
              <SortCaret
                key="unit"
                header="Unit"
                onToggle={() => sort("unit.number")}
              />,
              <SortCaret
                key="buildup"
                header="Build up"
                onToggle={() => sort("unit.type.buildUp")}
              />,
              <SortCaret
                key="type"
                header="Type"
                onToggle={() => sort("unit.type.name")}
              />,
              <SortCaret
                key="creation-date"
                header="Creation date"
                onToggle={() => sort("createdAt")}
              />,
              <SortCaret
                key="proposal-status"
                header="Proposal Status"
                onToggle={() => sort("status")}
              />,
            ]}
            bodies={[
              pendingProposals,
              approvedProposals,
              declinedProposals,
              otherProposals,
            ].map((filters) => {
              return filters.map((proposal, index) => {
                return (
                  <tr
                    key={`proposal-${proposal.id}-${index}`}
                    className={classNames("h-0 align-top", {
                      "bg-opacity-50 bg-ink-well": proposal.status === "sold",
                    })}
                  >
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal.customers?.length
                        ? proposal.customers?.map((customer, customerIndex) => (
                            <div key={`customer-${customerIndex}`}>
                              {customer.fullName}
                            </div>
                          ))
                        : proposal.companies?.map((company, companyIndex) => (
                            <div key={`company-${companyIndex}`}>
                              {company.name}
                            </div>
                          ))}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal.user?.fullName}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal?.unit?.number}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {formatNumber(proposal?.unit?.type?.buildUp || "-")}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal?.unit?.type?.name || "-"}
                    </td>
                    <td
                      className={classNames("py-3", {
                        "opacity-50": proposal.status === "sold",
                      })}
                    >
                      {proposal.createdAt
                        ? Format.date(proposal.createdAt)
                        : "-"}
                    </td>
                    <td className="py-2">
                      <ProposalStatusChip status={proposal.status} />
                    </td>
                    <td className="flex justify-end items-center h-11 pl-0">
                      <MoreButton popupClassName="w-max">
                        {listActions(proposal, index).map(
                          (action, actionIndex) => (
                            <Fragment key={`action-${actionIndex}`}>
                              {action}
                            </Fragment>
                          )
                        )}
                      </MoreButton>
                    </td>
                  </tr>
                );
              });
            })}
          />
        </BaseCard>
      </div>
    </>
  );
};

export default BookingApprovalListPage;
