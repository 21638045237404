import React, { createContext, useContext, useState } from "react";
import LoadingBar from "react-top-loading-bar";

type LoadingBarContextType = {
  start: () => void;
  end: () => void;
};

const defaultContext: LoadingBarContextType = {
  start: () => {},
  end: () => {},
};

const LoadingBarContext = createContext<LoadingBarContextType>(defaultContext);

export const useLoadingBarContext = () => useContext(LoadingBarContext);

export const LoadingBarContextProvider: React.FC = ({ children }) => {
  const [progress, setProgress] = useState<number>(0);

  const start = () => setProgress(Math.floor(Math.random() * 50) + 30);

  const end = () => setProgress(100);

  return (
    <LoadingBarContext.Provider value={{ start, end }}>
      <LoadingBar
        color="#B11116"
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {children}
    </LoadingBarContext.Provider>
  );
};
