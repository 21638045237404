export default class Format {
  static date(date: string, withTime = false): string {
    const d = new Date(date);
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const month =
      d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();

    if (withTime) {
      const seconds =
        d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
      const minutes =
        d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
      const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return `${day}/${month}/${year}`;
  }

  static withComma(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static bytes(bytes: number, decimals = 1): string {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
