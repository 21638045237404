/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface CheckboxProps {
  checked: any;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(props.checked ?? false);

  useEffect(() => {
    setChecked(props.checked ?? false);
  }, [props.checked]);

  const toggle = () => {
    if (props.onChange) props.onChange(!checked);
    setChecked(!checked);
  };

  return (
    <button
      className={classNames(
        "ring-[1px] w-[18px] h-[18px] cursor-pointer focus:outline-none row justify-center items-center",
        {
          "ring-disabled cursor-not-allowed": props.disabled,
          "ring-black": !props.disabled,
        }
      )}
      onClick={props.disabled ?? false ? () => {} : toggle}
    >
      <div
        className={classNames(
          " overflow-hidden transition-[height] duration-100 text-white text-lg flex justify-center items-center",
          {
            "bg-disabled": props.disabled,
            "bg-black": !props.disabled,
            "w-[18px] h-[18px]": checked,
            "w-0 h-0": !checked,
          }
        )}
      >
        <PabloIcon icon={Icon.success} className="p-[2px]" />
      </div>
    </button>
  );
};
