import { useReducer } from "react";

type ArrayReducer<T> = {
  type: "add" | "remove" | "clear" | "replace";
  value: T;
  arrValue?: T[];
};

export const useArray = <T>(arr: T[]) => {
  return useReducer(
    (array: T[], { type, value, arrValue = [] }: ArrayReducer<T>) => {
      switch (type) {
        case "add":
          return [...array, value];

        case "remove":
          return array.filter((val) => val !== value);

        case "clear":
          return [];

        // case "indexed":
        //   return array.filter((val) => val !== null);

        case "replace":
          return [...arrValue];

        default:
          return array;
      }
    },
    arr
  );
};
