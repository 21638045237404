import classNames from "classnames";
import React, { useState } from "react";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface SearchFieldProps {
  hint: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { hint, disabled } = props;

  const [value, setValue] = useState<string>("");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };

  const clearValue = () => {
    if (!disabled) {
      setValue("");
      props.onChange("");
    }
  };

  return (
    <label
      className={classNames(
        "bg-white border-[1px] border-[#1F1F1F4D] w-[428px] h-10 px-5 items-center gap-2 row justify-between",
        {
          "opacity-50 cursor-not-allowed": disabled,
        }
      )}
    >
      <input
        id="search-field"
        type="text"
        placeholder={hint}
        className={classNames("focus:outline-none w-full", {
          "cursor-default pointer-events-none bg-white": disabled,
        })}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />

      {value.length <= 0 ? (
        <PabloIcon icon={Icon.search} />
      ) : (
        <PabloIcon
          icon={Icon.close}
          onClick={clearValue}
          className={classNames({
            "cursor-not-allowed": disabled,
            "cursor-pointer": !disabled,
          })}
        />
      )}
    </label>
  );
};

export default SearchField;
