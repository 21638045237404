import classNames from "classnames";
import React from "react";
import PabloIcon from "../Icons/PabloIcon";

interface SquareButtonProps {
  icon: string;
  className?: string;
  onclick: () => void;
}

const SquareButton: React.FC<SquareButtonProps> = ({
  icon,
  className,
  onclick,
}) => {
  return (
    <button
      className={classNames(
        "h-9 w-9 col items-center justify-center hover:bg-ink-well rounded focus:outline-none",
        className
      )}
      onClick={onclick}
    >
      <PabloIcon icon={icon} />
    </button>
  );
};

export default SquareButton;
