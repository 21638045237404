/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { rounding } from "../../utils/CommonUtils";
import SearchField from "../Fields/SearchField";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface TableProps {
  isLoading: boolean;
  onSearching: (value: string) => void;
  searchHint: string;
  headers: ReactElement[];
  body: ReactElement[];
  limit?: number;
  searchable?: boolean;
  showPagination?: boolean;
  containerClassName?: string;
}

const Table: React.FC<TableProps> = ({
  isLoading,
  onSearching,
  searchHint,
  headers,
  body,
  limit = body.length < 20 ? body.length : 20,
  searchable = true,
  showPagination = true,
  containerClassName = "",
}) => {
  const [paginatedBody, setPaginatedBody] = useState<ReactElement[]>([]);
  const [page, setPage] = useState<number>(1);
  const maxPage = rounding(body.length / limit, "ceil");

  useEffect(() => {
    const min = page * limit - limit;
    setPaginatedBody(body.slice(min, page * limit));
  }, [page, body, limit]);

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [onSearching]);

  const previous = () => {
    if (page > 1) {
      const main = document.getElementById("main");
      main?.scrollTo(0, 0);
      setPage(page - 1);
    }
  };

  const next = () => {
    if (page < maxPage) {
      const main = document.getElementById("main");
      main?.scrollTo(0, 0);
      setPage(page + 1);
    }
  };

  return (
    <>
      <div
        className={classNames(
          "bg-section-background gap-5 col",
          {
            "p-5": !containerClassName,
          },
          containerClassName
        )}
      >
        {searchable && (
          <div className="row justify-between items-center">
            <SearchField hint={searchHint} onChange={onSearching} />
          </div>
        )}

        <div className="overflow-x-auto">
          <table>
            <thead>
              <tr>{headers}</tr>
            </thead>

            <tbody>{paginatedBody}</tbody>
          </table>

          {paginatedBody.length <= 0 && (
            <div className="row justify-center items-center bg-white h-20 border-b-[1px] border-t-[1px] border-section-background">
              {isLoading ? "Loading" : "No result"}
            </div>
          )}
        </div>
      </div>

      {showPagination && paginatedBody.length > 0 && (
        <div className="row text-sm justify-between mt-4 font-thin">
          <div>
            <span className="font-bold">
              {page * limit - limit + 1} - {""}
              {paginatedBody.length >= limit ? page * limit : body.length}
            </span>{" "}
            of {body.length}
          </div>

          <div className="row justify-center items-center">
            {page > 1 && (
              <button
                className="h-9 w-9 col items-center justify-center hover:bg-ink-well rounded"
                onClick={previous}
              >
                <PabloIcon icon={Icon.arrow} />
              </button>
            )}

            <span className="px-2">
              Page <span className="font-bold">{page}</span> of {maxPage}
            </span>

            <button
              disabled={page >= maxPage}
              className={classNames(
                "h-9 w-9 col items-center justify-center rounded disabled:cursor-not-allowed",
                {
                  "hover:bg-ink-well": page < maxPage,
                }
              )}
              onClick={next}
            >
              <PabloIcon
                icon={Icon.arrow}
                className={classNames("rotate-180", {
                  "gray-icon": page >= maxPage,
                })}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
