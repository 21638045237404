/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { useEffect } from "react";
import { MAX_PERSONS } from "../../common/Booking";
import Country from "../../common/Country";
import { Attachment, Company } from "../../common/Types";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useArray } from "../../hooks/UseArray";
import { useMultipleRefs } from "../../hooks/useMultipleRefs";
import { Validation } from "../../hooks/UseValidation";
import { BookingForm } from "../../schemas/BookingSchema";
import RequiredField from "../../views/generals/RequiredField";
import CompanyPersonalInfoAccordian from "../Accordion/CompanyPersonalInfoAccordian";
import AddItemButton from "../Buttons/AddItemButton";
import { GroupExpandableCard } from "../Cards/ExpandableCard";
import DropdownField from "../Fields/DropdownField";
import TextField from "../Fields/TextField";
import Line from "../General/Line";
import AddressForm from "./AddressForm";
import FileUploadForm from "./FileUploadForm";

interface CompanyInfoFormProps {
  validation: Validation<BookingForm>;
  className?: string;
  defaultValues?: Company;
  setDirty: Function;
}

const CompanyInfoForm: React.FC<CompanyInfoFormProps> = (props) => {
  const { validation, className, defaultValues, setDirty } = props;
  const modal = useModalContext();

  const personRefs: React.RefObject<any>[] = useMultipleRefs(MAX_PERSONS);

  const companyInfo = validation.watch(`companyInfo`);

  const [proofOfCompanySuperform, dispatchProofOfCompanySuperform] =
    useArray<Attachment>(
      defaultValues?.attachments?.map((attachment) => {
        return {
          id: attachment.id,
          name: attachment.name,
          blob: new Blob([attachment.path], {
            type: "pdf",
          }),
          blobPath: attachment.path,
        };
      }) || []
    );
  const [personalInfoIndexes, dispatchPersonalInfoIndexes] = useArray<number>(
    defaultValues?.customers
      ? defaultValues.customers.length > 0
        ? [...defaultValues?.customers?.keys()]
        : [0]
      : [0]
  );
  const [clearedList, dispatchClearedList] = useArray<number>([]);

  const onDeletePersonalInfoField = (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Delete Authorised Person Information",
      description:
        "Are you sure you want to delete this authorised person information?",
      onConfirm: {
        text: "Delete",
        action: () => {
          setDirty(true);
          personRefs[realIndex].current.onClearAttachments();

          dispatchClearedList({ type: "add", value: index });
          dispatchPersonalInfoIndexes({
            type: "remove",
            value: personalInfoIndexes.find((v) => v === index) as number,
          });

          validation.unregister(`companyInfo.personalInfo.${index}`);
        },
      },
    });
  };

  const onClearPersonalInfoField = async (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Clear Authorised Person Information",
      description: "Are you sure you want to proceed with removing the values?",
      onConfirm: {
        text: "Clear",
        action: () => {
          setDirty(true);
          personRefs[realIndex].current.onClearAttachments();

          dispatchClearedList({ type: "add", value: index });
          dispatchPersonalInfoIndexes({
            type: "add",
            value: personalInfoIndexes[personalInfoIndexes.length - 1] + 1,
          });

          setTimeout(() => {
            dispatchPersonalInfoIndexes({
              type: "remove",
              value: personalInfoIndexes.find((v) => v === index) as number,
            });

            validation.unregister(`companyInfo.personalInfo.${index}`);
          }, 1);
        },
      },
    });
  };

  useEffect(() => {
    validation.setValue(`companyInfo.id`, defaultValues?.id);
  }, []);

  return (
    <div
      className={classNames(
        "col px-8 pt-4 pb-6 bg-white shadow rounded-sm mx-7 my-4",
        className
      )}
    >
      <div className="col">
        <div className="mb-5 row items-center">
          <h2 className="mr-4">Company Information</h2>
          <RequiredField />
        </div>

        <div className="col gap-5">
          <h4 className="font-bold">Company Details</h4>
          <div className="row -mb-1 gap-4">
            <TextField
              id="company-name"
              label="Company Name"
              type="text"
              error={validation.errors.companyInfo?.companyName?.message}
              defaultValue={defaultValues?.name || ""}
              onInput={() => setDirty(true)}
              {...validation.register("companyInfo.companyName", {
                setValueAs: (v) => v.trim(),
              })}
            />
            <TextField
              id="company-registration-number"
              label="Company Registration Number"
              type="text"
              error={
                validation.errors.companyInfo?.companyRegistrationNumber
                  ?.message
              }
              defaultValue={defaultValues?.registrationNumber || ""}
              onInput={() => setDirty(true)}
              {...validation.register("companyInfo.companyRegistrationNumber", {
                setValueAs: (v) => v.trim(),
              })}
            />
          </div>

          <div className="row -mb-1 gap-4">
            <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
              <DropdownField
                id="company-dial-code"
                key="company-dial-code"
                label="Dial Code"
                error={validation.errors.companyInfo?.companyPhoneCode?.message}
                items={Country.countryData.map((country) => {
                  return {
                    text: `+${country.dialCode} - ${country.name}`,
                    value: country.dialCode,
                  };
                })}
                placeholder="Select Dial Code"
                searchable="true"
                dynamicWidth={true}
                containerClassName="w-2/6"
                listClassName="min-w-[420px]"
                showValue={true}
                defaultValue={
                  defaultValues?.phoneCode?.toString() ||
                  companyInfo?.companyPhoneCode ||
                  ""
                }
                {...validation.register("companyInfo.companyPhoneCode", {
                  value:
                    defaultValues?.phoneCode?.toString() ||
                    companyInfo?.companyPhoneCode ||
                    "",
                  onChange: () => setDirty(true),
                })}
              />
              <TextField
                id="company-contact-number"
                label="Contact No."
                type="tel"
                dynamicWidth={true}
                containerClassName="w-4/6"
                error={
                  validation.errors.companyInfo?.companyPhoneNumber?.message
                }
                defaultValue={defaultValues?.phoneNumber}
                onInput={() => setDirty(true)}
                {...validation.register("companyInfo.companyPhoneNumber", {
                  setValueAs: (v) => v.trim(),
                })}
              />
            </div>

            <div className="col w-full tablet:w-[498px]" />
          </div>

          <Line className="mt-4 mb-2" />

          <div className="col gap-5 mb-9">
            <AddressForm
              fieldPrefix={`companyInfo`}
              validation={validation}
              error={validation.errors.companyInfo}
              defaultValues={defaultValues?.address}
              setDirty={setDirty}
            />
          </div>
        </div>

        <Line className="mb-8" />

        <FileUploadForm
          id="proof-of-company-superform"
          files={proofOfCompanySuperform}
          title="Company Superform"
          error={validation.errors.companyInfo?.attachments}
          validation={validation}
          fieldPrefix="companyInfo"
          acceptedFileTypes={["pdf"]}
          dispatchFiles={dispatchProofOfCompanySuperform}
        />

        <GroupExpandableCard
          defaultValue="company-personal-details-1"
          onExpand={() => {}}
        >
          {personalInfoIndexes.map((personalInfo, index) => (
            <CompanyPersonalInfoAccordian
              ref={personRefs[index]}
              key={`company-personal-details-${personalInfo}`}
              value={`company-personal-details-${index + 1}`}
              title={`Authorised Person Information ${index + 1}`}
              index={personalInfo}
              defaultValues={
                clearedList.includes(personalInfo)
                  ? undefined
                  : defaultValues?.customers?.[personalInfo]
              }
              showRequired={true}
              validation={validation}
              className="shadow-none mx-0 mt-4 mb-0 border border-[#53565A33]"
              onDeleteAction={
                personalInfoIndexes.length > 1
                  ? () => onDeletePersonalInfoField(personalInfo, index)
                  : () => onClearPersonalInfoField(personalInfo, index)
              }
              setDirty={setDirty}
            />
          ))}

          {personalInfoIndexes.length < MAX_PERSONS && (
            <AddItemButton
              id="add-authorised-person-button"
              text="+ Add new authorised person information"
              onClick={() =>
                dispatchPersonalInfoIndexes({
                  type: "add",
                  value:
                    personalInfoIndexes[personalInfoIndexes.length - 1] + 1,
                })
              }
            />
          )}
        </GroupExpandableCard>
      </div>
    </div>
  );
};

export default CompanyInfoForm;
