/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Path from "../../common/Path";
import { IndexCheckReservedUnitResponse } from "../../common/Responses";
import { ReservedUnit, ReservedUnitUser } from "../../common/Types";
import FilterUnitButton from "../../components/Buttons/FilterUnitButton";
import BaseCard from "../../components/Cards/BaseCard";
import UnitStatusChip from "../../components/Chips/UnitStatusChip";
import Dot from "../../components/General/Dot";
import UnitGridTable from "../../components/Grid/UnitGridTable";
import Icon from "../../components/Icons/Icon";
import PabloIcon from "../../components/Icons/PabloIcon";
import Modal from "../../components/Modals/Modal";
import BottomBar from "../../components/Navs/BottomBar";
import HeaderText from "../../components/Texts/HeaderText";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { usePropertyContext } from "../../hooks/contexts/PropertyContext";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import pabloApi from "../../services/PabloApiService";

const UnitListPage: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const loadingBar = useLoadingBarContext();
  const loadingPage = useLoadingPageContext();
  const property = usePropertyContext();
  const unitList = useUnitListContext();

  const { selectedUnits } = unitList;
  const [reservedUnitUsers, setReservedUnitUsers] = useState<ReservedUnit>();

  const redirectToProposalCreationPage = () => {
    navigate(Path.newProposals, { state: selectedUnits });
  };

  const handleOnSubmit = () => {
    const reservedUnits = selectedUnits.find(
      (unit) => unit.status === "Reserved" || unit.status === "Available"
    );

    if (reservedUnits && !showModal) {
      getReservedUnitsDetails();
    } else {
      redirectToProposalCreationPage();
    }
  };

  const getReservedUnitsDetails = async () => {
    loadingPage.start();

    const { data, success } =
      await pabloApi.get<IndexCheckReservedUnitResponse>(
        `/check-reserved-units/${selectedUnits[0].projectId}/${selectedUnits[0].sectionId}/${selectedUnits[0].unitId}`
      );

    if (success) {
      if (data.reservedUnit.status === "available") {
        redirectToProposalCreationPage();
      } else {
        setReservedUnitUsers(data.reservedUnit);
        setShowModal(true);
      }
    }

    loadingPage.end();
  };

  useEffect(() => {
    if (unitList.loadingItems.length !== 0) loadingBar.start();
    else loadingBar.end();
  }, [unitList.loadingItems]);

  useEffect(() => {
    unitList.handleOnClearUnitSelection();
    refresh();
  }, [property.selectedBuilding]);

  const refresh = () => {
    unitList.index(
      property.selectedBuilding.projectId,
      property.selectedBuilding.sectionId,
      ["unit_type", "unit_theme", "section"]
    );
  };

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="Unit Price List" />

        <BaseCard>
          <div className="row justify-between items-center mb-8">
            <FilterUnitButton disabled={unitList.units.length === 0} />

            <div className="row gap-4">
              <button className="secondary-button" onClick={refresh}>
                Refresh
              </button>

              <button className="secondary-button group cursor-not-allowed opacity-50">
                <PabloIcon icon={Icon.download} className="secondary-icon" />
                Export Sales Report
              </button>
            </div>
          </div>

          <div className="p-5 bg-[#F0E7E780] gap-5 col">
            <div>
              <Dot status="Available" text="Available" />
              <Dot status="Reserved" text="Reserved" />
              <Dot status="Blocked" text="Blocked" />
              <Dot status="Booked" text="Booked" />
              <Dot status="Sold" text="Sold" />
            </div>

            <UnitGridTable />
          </div>
        </BaseCard>

        <BottomBar show={selectedUnits.length > 0}>
          <button
            className="secondary-button mr-4"
            onClick={unitList.handleOnClearUnitSelection}
          >
            Clear Selection
          </button>

          <div className="uppercase text-xs mr-auto">
            {selectedUnits.length} Unit(S) Selected
          </div>

          <button className="primary-button" onClick={handleOnSubmit}>
            Next
          </button>
        </BottomBar>
      </div>

      {/* modal */}
      <Modal
        show={showModal}
        type="warning"
        onHide={() => setShowModal(false)}
        dismissible={true}
      >
        <h2 className="mb-4">
          {`The unit(s) chosen has been 
          ${
            reservedUnitUsers?.status === "booked" ? " / booked" : "reserved"
          } by another
          catalyst`}
        </h2>

        <div className="flex-grow text-sm">
          {reservedUnitUsers ? (
            <span key={`reserved-unit-${reservedUnitUsers.number}`}>
              {reservedUnitUsers.number}
              {reservedUnitUsers.status === "booked" && (
                <span className="pl-3">
                  <UnitStatusChip status={reservedUnitUsers.status} />
                  <br />
                </span>
              )}
              <br />

              {reservedUnitUsers.users.length > 0 ? (
                reservedUnitUsers.users.map((user: ReservedUnitUser) => {
                  return (
                    <ul
                      key={`reserved-unit-user-${user.fullName}`}
                      className="list-disc mt-2 mb-5 ml-5"
                      style={{ columnCount: 2 }}
                    >
                      <li>{user.fullName}</li>
                    </ul>
                  );
                })
              ) : (
                <div className="mt-2 italic opacity-70">
                  No catalyst found
                  <br />
                  <br />
                </div>
              )}
            </span>
          ) : (
            "No catalyst found. Please discuss with your team leader on how to proceed."
          )}
        </div>

        {reservedUnitUsers?.status === "reserved" && (
          <button
            className="primary-button mt-5 h-[48px] w-[218px]"
            onClick={handleOnSubmit}
          >
            Continue
          </button>
        )}
      </Modal>
    </>
  );
};

export default UnitListPage;
