/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Path from "../../common/Path";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useDeviceContext } from "../../hooks/contexts/DeviceContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import api from "../../services/PabloApiService";

const SocialLoginCallbackPage: React.FC = () => {
  const auth = useAuthContext();
  const device = useDeviceContext();
  const navigate = useNavigate();
  const { provider } = useParams();
  const { search } = useLocation();
  const loadingPage = useLoadingPageContext();

  const socialLoginCallback = async () => {
    loadingPage.start();

    const { data, success } = await api.get<any>(
      `/social/${provider}/callback${search}`
    );

    if (success) {
      auth.onLogin(data);
      navigate(device.isPortrait ? Path.calculator : Path.main);
    } else {
      navigate(Path.login);
    }

    loadingPage.end();
  };

  useEffect(() => {
    if (!search) {
      navigate(
        auth.status !== "loggedIn"
          ? Path.login
          : device.isPortrait
          ? Path.calculator
          : Path.main
      );
    } else {
      socialLoginCallback();
    }
  }, []);

  return <></>;
};

export default SocialLoginCallbackPage;
