import React, { createContext, useContext, useState } from "react";
import api from "../../services/PricingEngineApiService";
import pabloApi from "../../services/PabloApiService";
import { TitanUnitsAppendCategory } from "../../services/UnitsApiService";
import { rearrangeJsonData } from "../../utils/CommonUtils";
import { PEUnit, RecommendedMaxRebate } from "../../common/Types";
import { useArray } from "../UseArray";
import {
  IndexMaxRecRebateResponse,
  IndexTitanUnitsResponse,
} from "../../common/Responses";

type UnitPrices = {
  totalGrossPrice: number;
};

type UnitListContextType = {
  filters: any;
  units: PEUnit[][];
  filteredUnits: PEUnit[][];
  selectedUnits: PEUnit[];
  unitPrices: UnitPrices;
  maxRecRebate: RecommendedMaxRebate;
  loadingItems: string[];
  index: (
    projectId: number,
    sectionId: number,
    append?: Array<TitanUnitsAppendCategory>,
    status?: string
  ) => void;
  getPrices: (price: number) => void;
  getMaxRecRebate: (body: any) => void;
  handleOnSelectUnit: (unit: PEUnit, isDisabled: boolean) => void;
  handleOnClearUnitSelection: () => void;
  handleOnFilter: (units: PEUnit[][]) => void;
  handleOnClearFilteredUnits: () => void;
  setFilters: React.Dispatch<any>;
  dispatch: Function;
};

const defaultContext: UnitListContextType = {
  filters: {},
  units: [],
  filteredUnits: [],
  selectedUnits: [],
  unitPrices: { totalGrossPrice: 0 },
  maxRecRebate: {
    maxRecDisc: 0,
    recAbsNettPrice: 0,
  },
  loadingItems: [],
  index: (
    projectId: number,
    sectionId: number,
    append?: Array<TitanUnitsAppendCategory>,
    status?: string
  ) => {},
  getPrices: (price: number) => {},
  getMaxRecRebate: (body: any) => {},
  handleOnSelectUnit: (unit: PEUnit, isDisabled: boolean) => {},
  handleOnClearUnitSelection: () => {},
  handleOnFilter: (units: PEUnit[][]) => {},
  handleOnClearFilteredUnits: () => {},
  setFilters: () => {},
  dispatch: () => {},
};

const UnitListContext = createContext<UnitListContextType>(defaultContext);

export const useUnitListContext = () => useContext(UnitListContext);

export const UnitListContextProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<any>({
    unitType: [],
    orientation: [],
    theme: [],
    wholeFloor: false,
    status: [],
    noOfBedrooms: [],
    floors: [],
  });
  const [units, setUnits] = useState<PEUnit[][]>([]);
  const [filteredUnits, setFilteredUnits] = useState<PEUnit[][]>([]);
  const [selectedUnits, setSelectedUnits] = useState<PEUnit[]>([]);
  const [unitPrices, setUnitPrices] = useState<UnitPrices>({
    totalGrossPrice: 0,
  });
  const [maxRecRebate, setMaxRecRebate] = useState<RecommendedMaxRebate>({
    maxRecDisc: 0,
    recAbsNettPrice: 0,
  });
  const [loadingItems, dispatch] = useArray<string>([]);

  const index = async (
    projectId: number,
    sectionId: number,
    append?: Array<TitanUnitsAppendCategory>,
    status?: string
  ) => {
    dispatch({ type: "add", value: "index" });

    const commaSeparatedText = append?.toString();
    const { data, success } = await pabloApi.get<IndexTitanUnitsResponse>(
      `/projects/${projectId}/sections/${sectionId}/units/?append=${
        commaSeparatedText ?? ""
      }&status=${status ?? ""}`
    );

    if (success) {
      const tempUnits = rearrangeJsonData(data);
      setUnits(tempUnits);
    }

    dispatch({ type: "remove", value: "index" });
  };

  const getPrices = async (price: number) => {
    setUnitPrices({ totalGrossPrice: price });
  };

  const getMaxRecRebate = async (body: any) => {
    dispatch({ type: "add", value: "/return_max_discount" });

    const { data, success } = await api.post<IndexMaxRecRebateResponse>(
      "/proposal-creation/max-discount",
      body
    );

    if (success) {
      setMaxRecRebate(data);
    }

    dispatch({ type: "remove", value: "/return_max_discount" });
  };

  const handleOnSelectUnit = (unit: PEUnit, isDisabled: boolean) => {
    if (
      selectedUnits.some(
        (selectedUnit: PEUnit) => selectedUnit.unitNo === unit.unitNo
      )
    ) {
      setSelectedUnits([]);
      return;
    }

    if (isDisabled) return;

    setSelectedUnits([unit]);
  };

  const handleOnFilter = (units: PEUnit[][]) => {
    setFilteredUnits(units);
    setSelectedUnits([]);
  };

  const handleOnClearUnitSelection = () => {
    setSelectedUnits([]);
  };

  const handleOnClearFilteredUnits = () => {
    filteredUnits.length !== 0 && setFilteredUnits([]);
  };

  return (
    <UnitListContext.Provider
      value={{
        filters,
        units,
        filteredUnits,
        selectedUnits,
        unitPrices,
        maxRecRebate,
        loadingItems,
        index,
        handleOnSelectUnit,
        handleOnClearUnitSelection,
        handleOnFilter,
        handleOnClearFilteredUnits,
        setFilters,
        getPrices,
        getMaxRecRebate,
        dispatch,
      }}
    >
      {children}
    </UnitListContext.Provider>
  );
};
