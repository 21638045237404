import { Country as CountryType } from "./Types";

class Country {
  static readonly countryData: CountryType[] = [
    {
      numCode: "458",
      alpha2Code: "MY",
      alpha3Code: "MYS",
      name: "Malaysia",
      nationality: "Malaysian",
      dialCode: "60",
    },
    {
      numCode: "702",
      alpha2Code: "SG",
      alpha3Code: "SGP",
      name: "Singapore",
      nationality: "Singaporean",
      dialCode: "65",
    },
    {
      numCode: "156",
      alpha2Code: "CN",
      alpha3Code: "CHN",
      name: "China",
      nationality: "Chinese",
      dialCode: "86",
    },
    {
      numCode: "344",
      alpha2Code: "HK",
      alpha3Code: "HKG",
      name: "Hong Kong, China",
      nationality: "Hong Kong, Hong Kongese",
      dialCode: "852",
    },
    {
      numCode: "392",
      alpha2Code: "JP",
      alpha3Code: "JPN",
      name: "Japan",
      nationality: "Japanese",
      dialCode: "81",
    },
    {
      numCode: "4",
      alpha2Code: "AF",
      alpha3Code: "AFG",
      name: "Afghanistan",
      nationality: "Afghan",
      dialCode: "93",
    },
    {
      numCode: "248",
      alpha2Code: "AX",
      alpha3Code: "ALA",
      name: "Åland Islands",
      nationality: "Åland Island",
      dialCode: "358",
    },
    {
      numCode: "8",
      alpha2Code: "AL",
      alpha3Code: "ALB",
      name: "Albania",
      nationality: "Albanian",
      dialCode: "355",
    },
    {
      numCode: "12",
      alpha2Code: "DZ",
      alpha3Code: "DZA",
      name: "Algeria",
      nationality: "Algerian",
      dialCode: "213",
    },
    {
      numCode: "16",
      alpha2Code: "AS",
      alpha3Code: "ASM",
      name: "American Samoa",
      nationality: "American Samoan",
      dialCode: "1684",
    },
    {
      numCode: "20",
      alpha2Code: "AD",
      alpha3Code: "AND",
      name: "Andorra",
      nationality: "Andorran",
      dialCode: "376",
    },
    {
      numCode: "24",
      alpha2Code: "AO",
      alpha3Code: "AGO",
      name: "Angola",
      nationality: "Angolan",
      dialCode: "244",
    },
    {
      numCode: "660",
      alpha2Code: "AI",
      alpha3Code: "AIA",
      name: "Anguilla",
      nationality: "Anguillan",
      dialCode: "1264",
    },
    {
      numCode: "10",
      alpha2Code: "AQ",
      alpha3Code: "ATA",
      name: "Antarctica",
      nationality: "Antarctic",
      dialCode: "672",
    },
    {
      numCode: "28",
      alpha2Code: "AG",
      alpha3Code: "ATG",
      name: "Antigua and Barbuda",
      nationality: "Antiguan or Barbudan",
      dialCode: "1268",
    },
    {
      numCode: "32",
      alpha2Code: "AR",
      alpha3Code: "ARG",
      name: "Argentina",
      nationality: "Argentine",
      dialCode: "54",
    },
    {
      numCode: "51",
      alpha2Code: "AM",
      alpha3Code: "ARM",
      name: "Armenia",
      nationality: "Armenian",
      dialCode: "374",
    },
    {
      numCode: "533",
      alpha2Code: "AW",
      alpha3Code: "ABW",
      name: "Aruba",
      nationality: "Aruban",
      dialCode: "297",
    },
    {
      numCode: "36",
      alpha2Code: "AU",
      alpha3Code: "AUS",
      name: "Australia",
      nationality: "Australian",
      dialCode: "61",
    },
    {
      numCode: "40",
      alpha2Code: "AT",
      alpha3Code: "AUT",
      name: "Austria",
      nationality: "Austrian",
      dialCode: "43",
    },
    {
      numCode: "31",
      alpha2Code: "AZ",
      alpha3Code: "AZE",
      name: "Azerbaijan",
      nationality: "Azerbaijani, Azeri",
      dialCode: "994",
    },
    {
      numCode: "44",
      alpha2Code: "BS",
      alpha3Code: "BHS",
      name: "Bahamas",
      nationality: "Bahamian",
      dialCode: "1242",
    },
    {
      numCode: "48",
      alpha2Code: "BH",
      alpha3Code: "BHR",
      name: "Bahrain",
      nationality: "Bahraini",
      dialCode: "973",
    },
    {
      numCode: "50",
      alpha2Code: "BD",
      alpha3Code: "BGD",
      name: "Bangladesh",
      nationality: "Bangladeshi",
      dialCode: "880",
    },
    {
      numCode: "52",
      alpha2Code: "BB",
      alpha3Code: "BRB",
      name: "Barbados",
      nationality: "Barbadian",
      dialCode: "1246",
    },
    {
      numCode: "112",
      alpha2Code: "BY",
      alpha3Code: "BLR",
      name: "Belarus",
      nationality: "Belarusian",
      dialCode: "375",
    },
    {
      numCode: "56",
      alpha2Code: "BE",
      alpha3Code: "BEL",
      name: "Belgium",
      nationality: "Belgian",
      dialCode: "32",
    },
    {
      numCode: "84",
      alpha2Code: "BZ",
      alpha3Code: "BLZ",
      name: "Belize",
      nationality: "Belizean",
      dialCode: "501",
    },
    {
      numCode: "204",
      alpha2Code: "BJ",
      alpha3Code: "BEN",
      name: "Benin",
      nationality: "Beninese, Beninois",
      dialCode: "229",
    },
    {
      numCode: "60",
      alpha2Code: "BM",
      alpha3Code: "BMU",
      name: "Bermuda",
      nationality: "Bermudian, Bermudan",
      dialCode: "1441",
    },
    {
      numCode: "64",
      alpha2Code: "BT",
      alpha3Code: "BTN",
      name: "Bhutan",
      nationality: "Bhutanese",
      dialCode: "975",
    },
    {
      numCode: "68",
      alpha2Code: "BO",
      alpha3Code: "BOL",
      name: "Bolivia (Plurinational State of)",
      nationality: "Bolivian",
      dialCode: "591",
    },
    {
      numCode: "70",
      alpha2Code: "BA",
      alpha3Code: "BIH",
      name: "Bosnia and Herzegovina",
      nationality: "Bosnian or Herzegovinian",
      dialCode: "387",
    },
    {
      numCode: "72",
      alpha2Code: "BW",
      alpha3Code: "BWA",
      name: "Botswana",
      nationality: "Motswana, Botswanan",
      dialCode: "267",
    },
    {
      numCode: "74",
      alpha2Code: "BV",
      alpha3Code: "BVT",
      name: "Bouvet Island",
      nationality: "Bouvet Island",
      dialCode: "47",
    },
    {
      numCode: "76",
      alpha2Code: "BR",
      alpha3Code: "BRA",
      name: "Brazil",
      nationality: "Brazilian",
      dialCode: "55",
    },
    {
      numCode: "86",
      alpha2Code: "IO",
      alpha3Code: "IOT",
      name: "British Indian Ocean Territory",
      nationality: "BIOT",
      dialCode: "246",
    },
    {
      numCode: "96",
      alpha2Code: "BN",
      alpha3Code: "BRN",
      name: "Brunei Darussalam",
      nationality: "Bruneian",
      dialCode: "673",
    },
    {
      numCode: "100",
      alpha2Code: "BG",
      alpha3Code: "BGR",
      name: "Bulgaria",
      nationality: "Bulgarian",
      dialCode: "359",
    },
    {
      numCode: "854",
      alpha2Code: "BF",
      alpha3Code: "BFA",
      name: "Burkina Faso",
      nationality: "Burkinabé",
      dialCode: "226",
    },
    {
      numCode: "108",
      alpha2Code: "BI",
      alpha3Code: "BDI",
      name: "Burundi",
      nationality: "Burundian",
      dialCode: "257",
    },
    {
      numCode: "132",
      alpha2Code: "CV",
      alpha3Code: "CPV",
      name: "Cabo Verde",
      nationality: "Cabo Verdean",
      dialCode: "238",
    },
    {
      numCode: "116",
      alpha2Code: "KH",
      alpha3Code: "KHM",
      name: "Cambodia",
      nationality: "Cambodian",
      dialCode: "855",
    },
    {
      numCode: "120",
      alpha2Code: "CM",
      alpha3Code: "CMR",
      name: "Cameroon",
      nationality: "Cameroonian",
      dialCode: "237",
    },
    {
      numCode: "124",
      alpha2Code: "CA",
      alpha3Code: "CAN",
      name: "Canada",
      nationality: "Canadian",
      dialCode: "1",
    },
    {
      numCode: "136",
      alpha2Code: "KY",
      alpha3Code: "CYM",
      name: "Cayman Islands",
      nationality: "Caymanian",
      dialCode: " 345",
    },
    {
      numCode: "140",
      alpha2Code: "CF",
      alpha3Code: "CAF",
      name: "Central African Republic",
      nationality: "Central African",
      dialCode: "236",
    },
    {
      numCode: "148",
      alpha2Code: "TD",
      alpha3Code: "TCD",
      name: "Chad",
      nationality: "Chadian",
      dialCode: "235",
    },
    {
      numCode: "152",
      alpha2Code: "CL",
      alpha3Code: "CHL",
      name: "Chile",
      nationality: "Chilean",
      dialCode: "56",
    },
    {
      numCode: "162",
      alpha2Code: "CX",
      alpha3Code: "CXR",
      name: "Christmas Island",
      nationality: "Christmas Island",
      dialCode: "61",
    },
    {
      numCode: "166",
      alpha2Code: "CC",
      alpha3Code: "CCK",
      name: "Cocos (Keeling) Islands",
      nationality: "Cocos Island",
      dialCode: "61",
    },
    {
      numCode: "170",
      alpha2Code: "CO",
      alpha3Code: "COL",
      name: "Colombia",
      nationality: "Colombian",
      dialCode: "57",
    },
    {
      numCode: "174",
      alpha2Code: "KM",
      alpha3Code: "COM",
      name: "Comoros",
      nationality: "Comoran, Comorian",
      dialCode: "269",
    },
    {
      numCode: "178",
      alpha2Code: "CG",
      alpha3Code: "COG",
      name: "Congo (Republic of the)",
      nationality: "Congolese",
      dialCode: "242",
    },
    {
      numCode: "180",
      alpha2Code: "CD",
      alpha3Code: "COD",
      name: "Congo (Democratic Republic of the)",
      nationality: "Congolese",
      dialCode: "243",
    },
    {
      numCode: "184",
      alpha2Code: "CK",
      alpha3Code: "COK",
      name: "Cook Islands",
      nationality: "Cook Island",
      dialCode: "682",
    },
    {
      numCode: "188",
      alpha2Code: "CR",
      alpha3Code: "CRI",
      name: "Costa Rica",
      nationality: "Costa Rican",
      dialCode: "506",
    },
    {
      numCode: "384",
      alpha2Code: "CI",
      alpha3Code: "CIV",
      name: "Côte d'Ivoire",
      nationality: "Ivorian",
      dialCode: "225",
    },
    {
      numCode: "191",
      alpha2Code: "HR",
      alpha3Code: "HRV",
      name: "Croatia",
      nationality: "Croatian",
      dialCode: "385",
    },
    {
      numCode: "192",
      alpha2Code: "CU",
      alpha3Code: "CUB",
      name: "Cuba",
      nationality: "Cuban",
      dialCode: "53",
    },
    {
      numCode: "196",
      alpha2Code: "CY",
      alpha3Code: "CYP",
      name: "Cyprus",
      nationality: "Cypriot",
      dialCode: "357",
    },
    {
      numCode: "203",
      alpha2Code: "CZ",
      alpha3Code: "CZE",
      name: "Czech Republic",
      nationality: "Czech",
      dialCode: "420",
    },
    {
      numCode: "208",
      alpha2Code: "DK",
      alpha3Code: "DNK",
      name: "Denmark",
      nationality: "Danish",
      dialCode: "45",
    },
    {
      numCode: "262",
      alpha2Code: "DJ",
      alpha3Code: "DJI",
      name: "Djibouti",
      nationality: "Djiboutian",
      dialCode: "253",
    },
    {
      numCode: "212",
      alpha2Code: "DM",
      alpha3Code: "DMA",
      name: "Dominica",
      nationality: "Dominican",
      dialCode: "1767",
    },
    {
      numCode: "214",
      alpha2Code: "DO",
      alpha3Code: "DOM",
      name: "Dominican Republic",
      nationality: "Dominican",
      dialCode: "1849",
    },
    {
      numCode: "218",
      alpha2Code: "EC",
      alpha3Code: "ECU",
      name: "Ecuador",
      nationality: "Ecuadorian",
      dialCode: "593",
    },
    {
      numCode: "818",
      alpha2Code: "EG",
      alpha3Code: "EGY",
      name: "Egypt",
      nationality: "Egyptian",
      dialCode: "20",
    },
    {
      numCode: "222",
      alpha2Code: "SV",
      alpha3Code: "SLV",
      name: "El Salvador",
      nationality: "Salvadoran",
      dialCode: "503",
    },
    {
      numCode: "226",
      alpha2Code: "GQ",
      alpha3Code: "GNQ",
      name: "Equatorial Guinea",
      nationality: "Equatorial Guinean, Equatoguinean",
      dialCode: "240",
    },
    {
      numCode: "232",
      alpha2Code: "ER",
      alpha3Code: "ERI",
      name: "Eritrea",
      nationality: "Eritrean",
      dialCode: "291",
    },
    {
      numCode: "233",
      alpha2Code: "EE",
      alpha3Code: "EST",
      name: "Estonia",
      nationality: "Estonian",
      dialCode: "372",
    },
    {
      numCode: "231",
      alpha2Code: "ET",
      alpha3Code: "ETH",
      name: "Ethiopia",
      nationality: "Ethiopian",
      dialCode: "251",
    },
    {
      numCode: "238",
      alpha2Code: "FK",
      alpha3Code: "FLK",
      name: "Falkland Islands (Malvinas)",
      nationality: "Falkland Island",
      dialCode: "500",
    },
    {
      numCode: "234",
      alpha2Code: "FO",
      alpha3Code: "FRO",
      name: "Faroe Islands",
      nationality: "Faroese",
      dialCode: "298",
    },
    {
      numCode: "242",
      alpha2Code: "FJ",
      alpha3Code: "FJI",
      name: "Fiji",
      nationality: "Fijian",
      dialCode: "679",
    },
    {
      numCode: "246",
      alpha2Code: "FI",
      alpha3Code: "FIN",
      name: "Finland",
      nationality: "Finnish",
      dialCode: "358",
    },
    {
      numCode: "250",
      alpha2Code: "FR",
      alpha3Code: "FRA",
      name: "France",
      nationality: "French",
      dialCode: "33",
    },
    {
      numCode: "254",
      alpha2Code: "GF",
      alpha3Code: "GUF",
      name: "French Guiana",
      nationality: "French Guianese",
      dialCode: "594",
    },
    {
      numCode: "258",
      alpha2Code: "PF",
      alpha3Code: "PYF",
      name: "French Polynesia",
      nationality: "French Polynesian",
      dialCode: "689",
    },
    {
      numCode: "260",
      alpha2Code: "TF",
      alpha3Code: "ATF",
      name: "French Southern Territories",
      nationality: "French Southern Territories",
      dialCode: "262",
    },
    {
      numCode: "266",
      alpha2Code: "GA",
      alpha3Code: "GAB",
      name: "Gabon",
      nationality: "Gabonese",
      dialCode: "241",
    },
    {
      numCode: "270",
      alpha2Code: "GM",
      alpha3Code: "GMB",
      name: "Gambia",
      nationality: "Gambian",
      dialCode: "220",
    },
    {
      numCode: "268",
      alpha2Code: "GE",
      alpha3Code: "GEO",
      name: "Georgia",
      nationality: "Georgian",
      dialCode: "995",
    },
    {
      numCode: "276",
      alpha2Code: "DE",
      alpha3Code: "DEU",
      name: "Germany",
      nationality: "German",
      dialCode: "49",
    },
    {
      numCode: "288",
      alpha2Code: "GH",
      alpha3Code: "GHA",
      name: "Ghana",
      nationality: "Ghanaian",
      dialCode: "233",
    },
    {
      numCode: "292",
      alpha2Code: "GI",
      alpha3Code: "GIB",
      name: "Gibraltar",
      nationality: "Gibraltar",
      dialCode: "350",
    },
    {
      numCode: "300",
      alpha2Code: "GR",
      alpha3Code: "GRC",
      name: "Greece",
      nationality: "Greek, Hellenic",
      dialCode: "30",
    },
    {
      numCode: "304",
      alpha2Code: "GL",
      alpha3Code: "GRL",
      name: "Greenland",
      nationality: "Greenlandic",
      dialCode: "299",
    },
    {
      numCode: "308",
      alpha2Code: "GD",
      alpha3Code: "GRD",
      name: "Grenada",
      nationality: "Grenadian",
      dialCode: "1473",
    },
    {
      numCode: "312",
      alpha2Code: "GP",
      alpha3Code: "GLP",
      name: "Guadeloupe",
      nationality: "Guadeloupe",
      dialCode: "590",
    },
    {
      numCode: "316",
      alpha2Code: "GU",
      alpha3Code: "GUM",
      name: "Guam",
      nationality: "Guamanian, Guambat",
      dialCode: "1671",
    },
    {
      numCode: "320",
      alpha2Code: "GT",
      alpha3Code: "GTM",
      name: "Guatemala",
      nationality: "Guatemalan",
      dialCode: "502",
    },
    {
      numCode: "831",
      alpha2Code: "GG",
      alpha3Code: "GGY",
      name: "Guernsey",
      nationality: "Channel Island",
      dialCode: "44",
    },
    {
      numCode: "324",
      alpha2Code: "GN",
      alpha3Code: "GIN",
      name: "Guinea",
      nationality: "Guinean",
      dialCode: "224",
    },
    {
      numCode: "624",
      alpha2Code: "GW",
      alpha3Code: "GNB",
      name: "Guinea-Bissau",
      nationality: "Bissau-Guinean",
      dialCode: "245",
    },
    {
      numCode: "328",
      alpha2Code: "GY",
      alpha3Code: "GUY",
      name: "Guyana",
      nationality: "Guyanese",
      dialCode: "592",
    },
    {
      numCode: "332",
      alpha2Code: "HT",
      alpha3Code: "HTI",
      name: "Haiti",
      nationality: "Haitian",
      dialCode: "509",
    },
    {
      numCode: "334",
      alpha2Code: "HM",
      alpha3Code: "HMD",
      name: "Heard Island and McDonald Islands",
      nationality: "Heard Island or McDonald Islands",
      dialCode: "0",
    },
    {
      numCode: "336",
      alpha2Code: "VA",
      alpha3Code: "VAT",
      name: "Vatican City State",
      nationality: "Vatican",
      dialCode: "379",
    },
    {
      numCode: "340",
      alpha2Code: "HN",
      alpha3Code: "HND",
      name: "Honduras",
      nationality: "Honduran",
      dialCode: "504",
    },
    {
      numCode: "348",
      alpha2Code: "HU",
      alpha3Code: "HUN",
      name: "Hungary",
      nationality: "Hungarian, Magyar",
      dialCode: "36",
    },
    {
      numCode: "352",
      alpha2Code: "IS",
      alpha3Code: "ISL",
      name: "Iceland",
      nationality: "Icelandic",
      dialCode: "354",
    },
    {
      numCode: "356",
      alpha2Code: "IN",
      alpha3Code: "IND",
      name: "India",
      nationality: "Indian",
      dialCode: "91",
    },
    {
      numCode: "360",
      alpha2Code: "ID",
      alpha3Code: "IDN",
      name: "Indonesia",
      nationality: "Indonesian",
      dialCode: "62",
    },
    {
      numCode: "364",
      alpha2Code: "IR",
      alpha3Code: "IRN",
      name: "Iran",
      nationality: "Iranian, Persian",
      dialCode: "98",
    },
    {
      numCode: "368",
      alpha2Code: "IQ",
      alpha3Code: "IRQ",
      name: "Iraq",
      nationality: "Iraqi",
      dialCode: "964",
    },
    {
      numCode: "372",
      alpha2Code: "IE",
      alpha3Code: "IRL",
      name: "Ireland",
      nationality: "Irish",
      dialCode: "353",
    },
    {
      numCode: "833",
      alpha2Code: "IM",
      alpha3Code: "IMN",
      name: "Isle of Man",
      nationality: "Manx",
      dialCode: "44",
    },
    {
      numCode: "376",
      alpha2Code: "IL",
      alpha3Code: "ISR",
      name: "Israel",
      nationality: "Israeli",
      dialCode: "972",
    },
    {
      numCode: "380",
      alpha2Code: "IT",
      alpha3Code: "ITA",
      name: "Italy",
      nationality: "Italian",
      dialCode: "39",
    },
    {
      numCode: "388",
      alpha2Code: "JM",
      alpha3Code: "JAM",
      name: "Jamaica",
      nationality: "Jamaican",
      dialCode: "1876",
    },
    {
      numCode: "832",
      alpha2Code: "JE",
      alpha3Code: "JEY",
      name: "Jersey",
      nationality: "Channel Island",
      dialCode: "44",
    },
    {
      numCode: "400",
      alpha2Code: "JO",
      alpha3Code: "JOR",
      name: "Jordan",
      nationality: "Jordanian",
      dialCode: "962",
    },
    {
      numCode: "398",
      alpha2Code: "KZ",
      alpha3Code: "KAZ",
      name: "Kazakhstan",
      nationality: "Kazakhstani, Kazakh",
      dialCode: "7",
    },
    {
      numCode: "404",
      alpha2Code: "KE",
      alpha3Code: "KEN",
      name: "Kenya",
      nationality: "Kenyan",
      dialCode: "254",
    },
    {
      numCode: "296",
      alpha2Code: "KI",
      alpha3Code: "KIR",
      name: "Kiribati",
      nationality: "I-Kiribati",
      dialCode: "686",
    },
    {
      numCode: "408",
      alpha2Code: "KP",
      alpha3Code: "PRK",
      name: "Korea (Democratic People's Republic of)",
      nationality: "North Korean",
      dialCode: "850",
    },
    {
      numCode: "410",
      alpha2Code: "KR",
      alpha3Code: "KOR",
      name: "Korea (Republic of)",
      nationality: "South Korean",
      dialCode: "82",
    },
    {
      numCode: "414",
      alpha2Code: "KW",
      alpha3Code: "KWT",
      name: "Kuwait",
      nationality: "Kuwaiti",
      dialCode: "965",
    },
    {
      numCode: "417",
      alpha2Code: "KG",
      alpha3Code: "KGZ",
      name: "Kyrgyzstan",
      nationality: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
      dialCode: "996",
    },
    {
      numCode: "418",
      alpha2Code: "LA",
      alpha3Code: "LAO",
      name: "Lao People's Democratic Republic",
      nationality: "Lao, Laotian",
      dialCode: "856",
    },
    {
      numCode: "428",
      alpha2Code: "LV",
      alpha3Code: "LVA",
      name: "Latvia",
      nationality: "Latvian",
      dialCode: "371",
    },
    {
      numCode: "422",
      alpha2Code: "LB",
      alpha3Code: "LBN",
      name: "Lebanon",
      nationality: "Lebanese",
      dialCode: "961",
    },
    {
      numCode: "426",
      alpha2Code: "LS",
      alpha3Code: "LSO",
      name: "Lesotho",
      nationality: "Basotho",
      dialCode: "266",
    },
    {
      numCode: "430",
      alpha2Code: "LR",
      alpha3Code: "LBR",
      name: "Liberia",
      nationality: "Liberian",
      dialCode: "231",
    },
    {
      numCode: "434",
      alpha2Code: "LY",
      alpha3Code: "LBY",
      name: "Libya",
      nationality: "Libyan",
      dialCode: "218",
    },
    {
      numCode: "438",
      alpha2Code: "LI",
      alpha3Code: "LIE",
      name: "Liechtenstein",
      nationality: "Liechtenstein",
      dialCode: "423",
    },
    {
      numCode: "440",
      alpha2Code: "LT",
      alpha3Code: "LTU",
      name: "Lithuania",
      nationality: "Lithuanian",
      dialCode: "370",
    },
    {
      numCode: "442",
      alpha2Code: "LU",
      alpha3Code: "LUX",
      name: "Luxembourg",
      nationality: "Luxembourg, Luxembourgish",
      dialCode: "352",
    },
    {
      numCode: "446",
      alpha2Code: "MO",
      alpha3Code: "MAC",
      name: "Macao, China",
      nationality: "Macanese, Chinese",
      dialCode: "853",
    },
    {
      numCode: "807",
      alpha2Code: "MK",
      alpha3Code: "MKD",
      name: "Macedonia (the former Yugoslav Republic of)",
      nationality: "Macedonian",
      dialCode: "389",
    },
    {
      numCode: "450",
      alpha2Code: "MG",
      alpha3Code: "MDG",
      name: "Madagascar",
      nationality: "Malagasy",
      dialCode: "261",
    },
    {
      numCode: "454",
      alpha2Code: "MW",
      alpha3Code: "MWI",
      name: "Malawi",
      nationality: "Malawian",
      dialCode: "265",
    },
    {
      numCode: "462",
      alpha2Code: "MV",
      alpha3Code: "MDV",
      name: "Maldives",
      nationality: "Maldivian",
      dialCode: "960",
    },
    {
      numCode: "466",
      alpha2Code: "ML",
      alpha3Code: "MLI",
      name: "Mali",
      nationality: "Malian, Malinese",
      dialCode: "223",
    },
    {
      numCode: "470",
      alpha2Code: "MT",
      alpha3Code: "MLT",
      name: "Malta",
      nationality: "Maltese",
      dialCode: "356",
    },
    {
      numCode: "584",
      alpha2Code: "MH",
      alpha3Code: "MHL",
      name: "Marshall Islands",
      nationality: "Marshallese",
      dialCode: "692",
    },
    {
      numCode: "474",
      alpha2Code: "MQ",
      alpha3Code: "MTQ",
      name: "Martinique",
      nationality: "Martiniquais, Martinican",
      dialCode: "596",
    },
    {
      numCode: "478",
      alpha2Code: "MR",
      alpha3Code: "MRT",
      name: "Mauritania",
      nationality: "Mauritanian",
      dialCode: "222",
    },
    {
      numCode: "480",
      alpha2Code: "MU",
      alpha3Code: "MUS",
      name: "Mauritius",
      nationality: "Mauritian",
      dialCode: "230",
    },
    {
      numCode: "175",
      alpha2Code: "YT",
      alpha3Code: "MYT",
      name: "Mayotte",
      nationality: "Mahoran",
      dialCode: "262",
    },
    {
      numCode: "484",
      alpha2Code: "MX",
      alpha3Code: "MEX",
      name: "Mexico",
      nationality: "Mexican",
      dialCode: "52",
    },
    {
      numCode: "583",
      alpha2Code: "FM",
      alpha3Code: "FSM",
      name: "Micronesia (Federated States of)",
      nationality: "Micronesian",
      dialCode: "691",
    },
    {
      numCode: "498",
      alpha2Code: "MD",
      alpha3Code: "MDA",
      name: "Moldova (Republic of)",
      nationality: "Moldovan",
      dialCode: "373",
    },
    {
      numCode: "492",
      alpha2Code: "MC",
      alpha3Code: "MCO",
      name: "Monaco",
      nationality: "Monégasque, Monacan",
      dialCode: "377",
    },
    {
      numCode: "496",
      alpha2Code: "MN",
      alpha3Code: "MNG",
      name: "Mongolia",
      nationality: "Mongolian",
      dialCode: "976",
    },
    {
      numCode: "499",
      alpha2Code: "ME",
      alpha3Code: "MNE",
      name: "Montenegro",
      nationality: "Montenegrin",
      dialCode: "382",
    },
    {
      numCode: "500",
      alpha2Code: "MS",
      alpha3Code: "MSR",
      name: "Montserrat",
      nationality: "Montserratian",
      dialCode: "1664",
    },
    {
      numCode: "504",
      alpha2Code: "MA",
      alpha3Code: "MAR",
      name: "Morocco",
      nationality: "Moroccan",
      dialCode: "212",
    },
    {
      numCode: "508",
      alpha2Code: "MZ",
      alpha3Code: "MOZ",
      name: "Mozambique",
      nationality: "Mozambican",
      dialCode: "258",
    },
    {
      numCode: "104",
      alpha2Code: "MM",
      alpha3Code: "MMR",
      name: "Myanmar",
      nationality: "Burmese",
      dialCode: "95",
    },
    {
      numCode: "516",
      alpha2Code: "NA",
      alpha3Code: "NAM",
      name: "Namibia",
      nationality: "Namibian",
      dialCode: "264",
    },
    {
      numCode: "520",
      alpha2Code: "NR",
      alpha3Code: "NRU",
      name: "Nauru",
      nationality: "Nauruan",
      dialCode: "674",
    },
    {
      numCode: "524",
      alpha2Code: "NP",
      alpha3Code: "NPL",
      name: "Nepal",
      nationality: "Nepali, Nepalese",
      dialCode: "977",
    },
    {
      numCode: "528",
      alpha2Code: "NL",
      alpha3Code: "NLD",
      name: "Netherlands",
      nationality: "Dutch, Netherlandic",
      dialCode: "31",
    },
    {
      numCode: "540",
      alpha2Code: "NC",
      alpha3Code: "NCL",
      name: "New Caledonia",
      nationality: "New Caledonian",
      dialCode: "687",
    },
    {
      numCode: "554",
      alpha2Code: "NZ",
      alpha3Code: "NZL",
      name: "New Zealand",
      nationality: "New Zealand, NZ",
      dialCode: "64",
    },
    {
      numCode: "558",
      alpha2Code: "NI",
      alpha3Code: "NIC",
      name: "Nicaragua",
      nationality: "Nicaraguan",
      dialCode: "505",
    },
    {
      numCode: "562",
      alpha2Code: "NE",
      alpha3Code: "NER",
      name: "Niger",
      nationality: "Nigerien",
      dialCode: "227",
    },
    {
      numCode: "566",
      alpha2Code: "NG",
      alpha3Code: "NGA",
      name: "Nigeria",
      nationality: "Nigerian",
      dialCode: "234",
    },
    {
      numCode: "570",
      alpha2Code: "NU",
      alpha3Code: "NIU",
      name: "Niue",
      nationality: "Niuean",
      dialCode: "683",
    },
    {
      numCode: "574",
      alpha2Code: "NF",
      alpha3Code: "NFK",
      name: "Norfolk Island",
      nationality: "Norfolk Island",
      dialCode: "672",
    },
    {
      numCode: "580",
      alpha2Code: "MP",
      alpha3Code: "MNP",
      name: "Northern Mariana Islands",
      nationality: "Northern Marianan",
      dialCode: "1670",
    },
    {
      numCode: "578",
      alpha2Code: "NO",
      alpha3Code: "NOR",
      name: "Norway",
      nationality: "Norwegian",
      dialCode: "47",
    },
    {
      numCode: "512",
      alpha2Code: "OM",
      alpha3Code: "OMN",
      name: "Oman",
      nationality: "Omani",
      dialCode: "968",
    },
    {
      numCode: "586",
      alpha2Code: "PK",
      alpha3Code: "PAK",
      name: "Pakistan",
      nationality: "Pakistani",
      dialCode: "92",
    },
    {
      numCode: "585",
      alpha2Code: "PW",
      alpha3Code: "PLW",
      name: "Palau",
      nationality: "Palauan",
      dialCode: "680",
    },
    {
      numCode: "275",
      alpha2Code: "PS",
      alpha3Code: "PSE",
      name: "Palestine, State of",
      nationality: "Palestinian",
      dialCode: "970",
    },
    {
      numCode: "591",
      alpha2Code: "PA",
      alpha3Code: "PAN",
      name: "Panama",
      nationality: "Panamanian",
      dialCode: "507",
    },
    {
      numCode: "598",
      alpha2Code: "PG",
      alpha3Code: "PNG",
      name: "Papua New Guinea",
      nationality: "Papua New Guinean, Papuan",
      dialCode: "675",
    },
    {
      numCode: "600",
      alpha2Code: "PY",
      alpha3Code: "PRY",
      name: "Paraguay",
      nationality: "Paraguayan",
      dialCode: "595",
    },
    {
      numCode: "604",
      alpha2Code: "PE",
      alpha3Code: "PER",
      name: "Peru",
      nationality: "Peruvian",
      dialCode: "51",
    },
    {
      numCode: "608",
      alpha2Code: "PH",
      alpha3Code: "PHL",
      name: "Philippines",
      nationality: "Philippine, Filipino",
      dialCode: "63",
    },
    {
      numCode: "612",
      alpha2Code: "PN",
      alpha3Code: "PCN",
      name: "Pitcairn",
      nationality: "Pitcairn Island",
      dialCode: "64",
    },
    {
      numCode: "616",
      alpha2Code: "PL",
      alpha3Code: "POL",
      name: "Poland",
      nationality: "Polish",
      dialCode: "48",
    },
    {
      numCode: "620",
      alpha2Code: "PT",
      alpha3Code: "PRT",
      name: "Portugal",
      nationality: "Portuguese",
      dialCode: "351",
    },
    {
      numCode: "630",
      alpha2Code: "PR",
      alpha3Code: "PRI",
      name: "Puerto Rico",
      nationality: "Puerto Rican",
      dialCode: "1939",
    },
    {
      numCode: "634",
      alpha2Code: "QA",
      alpha3Code: "QAT",
      name: "Qatar",
      nationality: "Qatari",
      dialCode: "974",
    },
    {
      numCode: "638",
      alpha2Code: "RE",
      alpha3Code: "REU",
      name: "Réunion",
      nationality: "Réunionese, Réunionnais",
      dialCode: "262",
    },
    {
      numCode: "642",
      alpha2Code: "RO",
      alpha3Code: "ROU",
      name: "Romania",
      nationality: "Romanian",
      dialCode: "40",
    },
    {
      numCode: "643",
      alpha2Code: "RU",
      alpha3Code: "RUS",
      name: "Russian Federation",
      nationality: "Russian",
      dialCode: "7",
    },
    {
      numCode: "646",
      alpha2Code: "RW",
      alpha3Code: "RWA",
      name: "Rwanda",
      nationality: "Rwandan",
      dialCode: "250",
    },
    {
      numCode: "652",
      alpha2Code: "BL",
      alpha3Code: "BLM",
      name: "Saint Barthélemy",
      nationality: "Barthélemois",
      dialCode: "590",
    },
    {
      numCode: "654",
      alpha2Code: "SH",
      alpha3Code: "SHN",
      name: "Saint Helena, Ascension and Tristan da Cunha",
      nationality: "Saint Helenian",
      dialCode: "290",
    },
    {
      numCode: "659",
      alpha2Code: "KN",
      alpha3Code: "KNA",
      name: "Saint Kitts and Nevis",
      nationality: "Kittitian or Nevisian",
      dialCode: "1869",
    },
    {
      numCode: "662",
      alpha2Code: "LC",
      alpha3Code: "LCA",
      name: "Saint Lucia",
      nationality: "Saint Lucian",
      dialCode: "1758",
    },
    {
      numCode: "663",
      alpha2Code: "MF",
      alpha3Code: "MAF",
      name: "Saint Martin (French part)",
      nationality: "Saint-Martinoise",
      dialCode: "590",
    },
    {
      numCode: "666",
      alpha2Code: "PM",
      alpha3Code: "SPM",
      name: "Saint Pierre and Miquelon",
      nationality: "Saint-Pierrais or Miquelonnais",
      dialCode: "508",
    },
    {
      numCode: "670",
      alpha2Code: "VC",
      alpha3Code: "VCT",
      name: "Saint Vincent and the Grenadines",
      nationality: "Saint Vincentian, Vincentian",
      dialCode: "1784",
    },
    {
      numCode: "882",
      alpha2Code: "WS",
      alpha3Code: "WSM",
      name: "Samoa",
      nationality: "Samoan",
      dialCode: "685",
    },
    {
      numCode: "674",
      alpha2Code: "SM",
      alpha3Code: "SMR",
      name: "San Marino",
      nationality: "Sammarinese",
      dialCode: "378",
    },
    {
      numCode: "678",
      alpha2Code: "ST",
      alpha3Code: "STP",
      name: "Sao Tome and Principe",
      nationality: "São Toméan",
      dialCode: "239",
    },
    {
      numCode: "682",
      alpha2Code: "SA",
      alpha3Code: "SAU",
      name: "Saudi Arabia",
      nationality: "Saudi, Saudi Arabian",
      dialCode: "966",
    },
    {
      numCode: "686",
      alpha2Code: "SN",
      alpha3Code: "SEN",
      name: "Senegal",
      nationality: "Senegalese",
      dialCode: "221",
    },
    {
      numCode: "688",
      alpha2Code: "RS",
      alpha3Code: "SRB",
      name: "Serbia",
      nationality: "Serbian",
      dialCode: "381",
    },
    {
      numCode: "690",
      alpha2Code: "SC",
      alpha3Code: "SYC",
      name: "Seychelles",
      nationality: "Seychellois",
      dialCode: "248",
    },
    {
      numCode: "694",
      alpha2Code: "SL",
      alpha3Code: "SLE",
      name: "Sierra Leone",
      nationality: "Sierra Leonean",
      dialCode: "232",
    },
    {
      numCode: "703",
      alpha2Code: "SK",
      alpha3Code: "SVK",
      name: "Slovakia",
      nationality: "Slovak",
      dialCode: "421",
    },
    {
      numCode: "705",
      alpha2Code: "SI",
      alpha3Code: "SVN",
      name: "Slovenia",
      nationality: "Slovenian, Slovene",
      dialCode: "386",
    },
    {
      numCode: "90",
      alpha2Code: "SB",
      alpha3Code: "SLB",
      name: "Solomon Islands",
      nationality: "Solomon Island",
      dialCode: "677",
    },
    {
      numCode: "706",
      alpha2Code: "SO",
      alpha3Code: "SOM",
      name: "Somalia",
      nationality: "Somali, Somalian",
      dialCode: "252",
    },
    {
      numCode: "710",
      alpha2Code: "ZA",
      alpha3Code: "ZAF",
      name: "South Africa",
      nationality: "South African",
      dialCode: "27",
    },
    {
      numCode: "239",
      alpha2Code: "GS",
      alpha3Code: "SGS",
      name: "South Georgia and the South Sandwich Islands",
      nationality: "South Georgia or South Sandwich Islands",
      dialCode: "500",
    },
    {
      numCode: "728",
      alpha2Code: "SS",
      alpha3Code: "SSD",
      name: "South Sudan",
      nationality: "South Sudanese",
      dialCode: "211",
    },
    {
      numCode: "724",
      alpha2Code: "ES",
      alpha3Code: "ESP",
      name: "Spain",
      nationality: "Spanish",
      dialCode: "34",
    },
    {
      numCode: "144",
      alpha2Code: "LK",
      alpha3Code: "LKA",
      name: "Sri Lanka",
      nationality: "Sri Lankan",
      dialCode: "94",
    },
    {
      numCode: "729",
      alpha2Code: "SD",
      alpha3Code: "SDN",
      name: "Sudan",
      nationality: "Sudanese",
      dialCode: "249",
    },
    {
      numCode: "740",
      alpha2Code: "SR",
      alpha3Code: "SUR",
      name: "Suriname",
      nationality: "Surinamese",
      dialCode: "597",
    },
    {
      numCode: "744",
      alpha2Code: "SJ",
      alpha3Code: "SJM",
      name: "Svalbard and Jan Mayen",
      nationality: "Svalbard",
      dialCode: "47",
    },
    {
      numCode: "748",
      alpha2Code: "SZ",
      alpha3Code: "SWZ",
      name: "Swaziland",
      nationality: "Swazi",
      dialCode: "268",
    },
    {
      numCode: "752",
      alpha2Code: "SE",
      alpha3Code: "SWE",
      name: "Sweden",
      nationality: "Swedish",
      dialCode: "46",
    },
    {
      numCode: "756",
      alpha2Code: "CH",
      alpha3Code: "CHE",
      name: "Switzerland",
      nationality: "Swiss",
      dialCode: "41",
    },
    {
      numCode: "760",
      alpha2Code: "SY",
      alpha3Code: "SYR",
      name: "Syrian Arab Republic",
      nationality: "Syrian",
      dialCode: "963",
    },
    {
      numCode: "158",
      alpha2Code: "TW",
      alpha3Code: "TWN",
      name: "Taiwan, China",
      nationality: "Chinese, Taiwanese",
      dialCode: "886",
    },
    {
      numCode: "762",
      alpha2Code: "TJ",
      alpha3Code: "TJK",
      name: "Tajikistan",
      nationality: "Tajikistani",
      dialCode: "992",
    },
    {
      numCode: "834",
      alpha2Code: "TZ",
      alpha3Code: "TZA",
      name: "Tanzania, United Republic of",
      nationality: "Tanzanian",
      dialCode: "255",
    },
    {
      numCode: "764",
      alpha2Code: "TH",
      alpha3Code: "THA",
      name: "Thailand",
      nationality: "Thai",
      dialCode: "66",
    },
    {
      numCode: "626",
      alpha2Code: "TL",
      alpha3Code: "TLS",
      name: "Timor-Leste",
      nationality: "Timorese",
      dialCode: "670",
    },
    {
      numCode: "768",
      alpha2Code: "TG",
      alpha3Code: "TGO",
      name: "Togo",
      nationality: "Togolese",
      dialCode: "228",
    },
    {
      numCode: "772",
      alpha2Code: "TK",
      alpha3Code: "TKL",
      name: "Tokelau",
      nationality: "Tokelauan",
      dialCode: "690",
    },
    {
      numCode: "776",
      alpha2Code: "TO",
      alpha3Code: "TON",
      name: "Tonga",
      nationality: "Tongan",
      dialCode: "676",
    },
    {
      numCode: "780",
      alpha2Code: "TT",
      alpha3Code: "TTO",
      name: "Trinidad and Tobago",
      nationality: "Trinidadian or Tobagonian",
      dialCode: "1868",
    },
    {
      numCode: "788",
      alpha2Code: "TN",
      alpha3Code: "TUN",
      name: "Tunisia",
      nationality: "Tunisian",
      dialCode: "216",
    },
    {
      numCode: "792",
      alpha2Code: "TR",
      alpha3Code: "TUR",
      name: "Turkey",
      nationality: "Turkish",
      dialCode: "90",
    },
    {
      numCode: "795",
      alpha2Code: "TM",
      alpha3Code: "TKM",
      name: "Turkmenistan",
      nationality: "Turkmen",
      dialCode: "993",
    },
    {
      numCode: "796",
      alpha2Code: "TC",
      alpha3Code: "TCA",
      name: "Turks and Caicos Islands",
      nationality: "Turks and Caicos Island",
      dialCode: "1649",
    },
    {
      numCode: "798",
      alpha2Code: "TV",
      alpha3Code: "TUV",
      name: "Tuvalu",
      nationality: "Tuvaluan",
      dialCode: "688",
    },
    {
      numCode: "800",
      alpha2Code: "UG",
      alpha3Code: "UGA",
      name: "Uganda",
      nationality: "Ugandan",
      dialCode: "256",
    },
    {
      numCode: "804",
      alpha2Code: "UA",
      alpha3Code: "UKR",
      name: "Ukraine",
      nationality: "Ukrainian",
      dialCode: "380",
    },
    {
      numCode: "784",
      alpha2Code: "AE",
      alpha3Code: "ARE",
      name: "United Arab Emirates",
      nationality: "Emirati, Emirian, Emiri",
      dialCode: "971",
    },
    {
      numCode: "826",
      alpha2Code: "GB",
      alpha3Code: "GBR",
      name: "United Kingdom of Great Britain and Northern Ireland",
      nationality: "British, UK",
      dialCode: "44",
    },
    {
      numCode: "840",
      alpha2Code: "US",
      alpha3Code: "USA",
      name: "United States of America",
      nationality: "American",
      dialCode: "1",
    },
    {
      numCode: "858",
      alpha2Code: "UY",
      alpha3Code: "URY",
      name: "Uruguay",
      nationality: "Uruguayan",
      dialCode: "598",
    },
    {
      numCode: "860",
      alpha2Code: "UZ",
      alpha3Code: "UZB",
      name: "Uzbekistan",
      nationality: "Uzbekistani, Uzbek",
      dialCode: "998",
    },
    {
      numCode: "548",
      alpha2Code: "VU",
      alpha3Code: "VUT",
      name: "Vanuatu",
      nationality: "Ni-Vanuatu, Vanuatuan",
      dialCode: "678",
    },
    {
      numCode: "862",
      alpha2Code: "VE",
      alpha3Code: "VEN",
      name: "Venezuela (Bolivarian Republic of)",
      nationality: "Venezuelan",
      dialCode: "58",
    },
    {
      numCode: "704",
      alpha2Code: "VN",
      alpha3Code: "VNM",
      name: "Vietnam",
      nationality: "Vietnamese",
      dialCode: "84",
    },
    {
      numCode: "92",
      alpha2Code: "VG",
      alpha3Code: "VGB",
      name: "Virgin Islands (British)",
      nationality: "British Virgin Island",
      dialCode: "1284",
    },
    {
      numCode: "850",
      alpha2Code: "VI",
      alpha3Code: "VIR",
      name: "Virgin Islands (U.S.)",
      nationality: "U.S. Virgin Island",
      dialCode: "1340",
    },
    {
      numCode: "876",
      alpha2Code: "WF",
      alpha3Code: "WLF",
      name: "Wallis and Futuna",
      nationality: "Wallis and Futuna, Wallisian or Futunan",
      dialCode: "681",
    },
    {
      numCode: "887",
      alpha2Code: "YE",
      alpha3Code: "YEM",
      name: "Yemen",
      nationality: "Yemeni",
      dialCode: "967",
    },
    {
      numCode: "894",
      alpha2Code: "ZM",
      alpha3Code: "ZMB",
      name: "Zambia",
      nationality: "Zambian",
      dialCode: "260",
    },
    {
      numCode: "716",
      alpha2Code: "ZW",
      alpha3Code: "ZWE",
      name: "Zimbabwe",
      nationality: "Zimbabwean",
      dialCode: "263",
    },
  ];

  static countryName = (alpha2Code: string) => {
    const tempCountry = Country.countryData.find(
      (country) => country.alpha2Code === alpha2Code
    );

    return tempCountry?.name;
  };
}

export default Country;
