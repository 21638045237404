/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import Line from "../General/Line";
import Textarea from "../General/Textarea";
import Avatar from "../Images/Avatar";
import api from "../../services/PabloApiService";
import {
  GeneralMessageResponse,
  IndexGeneralDataResponse,
} from "../../common/Responses";
import { toast } from "react-toastify";
import { Comment } from "../../common/Types";
import moment from "moment";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import classNames from "classnames";

interface CommentsFormProps {
  customerId: string;
}

const CommentsForm: React.FC<CommentsFormProps> = ({ customerId }) => {
  const auth = useAuthContext();
  const loadingBar = useLoadingBarContext();

  const [isEditing, setEditing] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState<string>("");

  const getComments = async () => {
    const commentsResponse = await api.get<IndexGeneralDataResponse<Comment>>(
      `/customer/${customerId}/comment`
    );

    if (commentsResponse.success) setComments(commentsResponse.data.data);
  };

  const submitComment = async () => {
    if (newComment) {
      loadingBar.start();
      setLoading(true);

      const commentBody = {
        statement: newComment,
      };

      const result = await api.post<GeneralMessageResponse>(
        `/customer/${customerId}/comment`,
        commentBody
      );

      if (result) {
        toast.success("Comment has been added successfully");
        await getComments();
        setEditing(false);
        setNewComment("");
      } else toast.error("Comment failed to be submitted");

      loadingBar.end();
      setLoading(false);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <>
      <Line />

      <div
        className={classNames({
          "opacity-50 select-none pointer-events-none": isLoading,
        })}
      >
        <h5 className="mb-4 font-bold">Comments</h5>
        <div className="w-full flex justify-between gap-4">
          <div className="flex-shrink">
            <Avatar user={auth.user} size="medium" />
          </div>

          <div className="flex-grow">
            <div className="w-full">
              <Textarea
                rows={3}
                dynamicWidth={true}
                containerClassName="w-full"
                innerClassName="p-4"
                error=""
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNewComment(e.currentTarget.value)
                }
                onFocus={() => setEditing(true)}
              />
            </div>

            {isEditing && (
              <div className="w-full flex justify-start gap-4 mt-3">
                <button
                  id="cancel-comment-button"
                  className="secondary-button"
                  onClick={() => {
                    setEditing(false);
                    setNewComment("");
                  }}
                >
                  Cancel
                </button>
                <button
                  id="save-comment-button"
                  className="primary-button"
                  disabled={!newComment}
                  onClick={submitComment}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>

        {comments?.map((comment, index) => {
          const momentDateTime = moment(comment.createdAt);
          const date = momentDateTime.format("DD/MM/YYYY");
          const time = momentDateTime.format("HH:mm");

          return (
            <div
              key={`comment-${index}`}
              className="w-full mt-8 flex justify-between gap-4"
            >
              <div className="flex-shrink">
                <Avatar user={comment.creator} size="medium" />
              </div>

              <div className="flex-grow text-sm">
                <div className="w-full">
                  <span className="font-bold">{comment.creator.fullName}</span>{" "}
                  <span className="pl-4 text-gray">
                    {date} at {time}
                  </span>
                </div>

                <div className="w-full mt-2">{comment.statement}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CommentsForm;
