/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "../Tables/Table";
import { formatNumber, forceDecimal } from "../../utils/CommonUtils";
import { stages } from "../../common/PaymentStructure";
import TextField from "../Fields/TextField";
import { Rebate } from "../../common/Types";
import { useDeviceContext } from "../../hooks/contexts/DeviceContext";

interface ScheduleHTableProps {
  totalPrice: number;
  totalRebate: number;
  nettSellingPrice: number;
  progressRebates: Rebate[];
  remainingRebate?: number;
  editable: boolean;
  hasDefaultValues?: boolean;
  isModal?: boolean;
  setProgressRebates: Function;
  setRemainingRebate: (rebate: number) => void;
  rebateType: string;
}

const ScheduleHTable: React.FC<ScheduleHTableProps> = (props) => {
  const {
    totalPrice,
    totalRebate,
    nettSellingPrice,
    progressRebates,
    remainingRebate,
    editable,
    hasDefaultValues,
    isModal,
    setProgressRebates,
    setRemainingRebate,
    rebateType,
  } = props;

  const device = useDeviceContext();
  const [totalInputRebate, setTotalInputRebate] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOnFirstLoad, setOnFirstLoad] = useState<boolean>(
    hasDefaultValues ? true : false
  );

  const isPercentage = rebateType === "percentage";

  const calculatePrice = (percentage: number, rebate: number) => {
    if (!totalPrice) return 0;
    rebate = rebate ?? 0;

    const stagePrice = totalPrice * (percentage / 100);
    const discountedPrice = totalPrice * (rebate / 100);
    const finalPrice = stagePrice - discountedPrice;

    return forceDecimal(finalPrice);
  };

  const calculateAbsoluteAmountPayable = (
    rebate: number,
    progressPayment: number
  ) => {
    return forceDecimal(progressPayment - rebate);
  };

  const calculateTotalPrice = () => {
    const rebate = editable ? totalInputRebate : totalRebate;
    const finalTotalPrice = totalPrice
      ? isPercentage
        ? totalPrice * (1 - rebate / 100)
        : totalPrice - rebate
      : 0;

    return formatNumber(forceDecimal(finalTotalPrice).toFixed(2));
  };

  const toggleEditing = () => {
    setOnFirstLoad(false);
    setIsEditing(!isEditing);
  };

  const updateRebateText = (index: number, value: number) => {
    toggleEditing();
    setProgressRebates((prevValues: Rebate[]) => {
      prevValues[index].stringValue = value === 0 ? "" : value.toString();
      return [...prevValues];
    });
  };

  const handleChange = (index: number, value: string) => {
    setOnFirstLoad(false);
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value)) {
      setProgressRebates((prevValues: Rebate[]) => {
        const tempValue = parseFloat(value);
        let tempRebate = 0;

        if (isPercentage) {
          tempRebate =
            tempValue < 0 || !tempValue
              ? 0
              : tempValue > stages[index].percentage
              ? stages[index].percentage
              : tempValue;
        } else {
          let progressPayment = calculatePrice(stages[index].percentage, 0);

          tempRebate =
            tempValue < 0 || !tempValue
              ? 0
              : tempValue > progressPayment
              ? progressPayment
              : tempValue;
        }

        prevValues[index].value = forceDecimal(tempRebate);
        prevValues[index].stringValue = value;

        return [...prevValues];
      });
    }
  };

  const generateHeader = () => {
    const absoluteColumn = isPercentage
      ? []
      : [
          <th key={"progress-payment"} className="w-3/12 h-14 uppercase">
            Progressive Payment (RM)
          </th>,
        ];

    const tempHeaders = [
      <th key={"progress-stage"} className="w-2/12 h-14 uppercase">
        Progress Stage
      </th>,
      <th key={"percentage"} className="w-2/12 h-14 uppercase">
        {isPercentage ? "Percentage (%)" : "Progressive Payment (%)"}
      </th>,
      ...absoluteColumn,
      <th key={"rebate"} className="w-2/12 h-14 uppercase">
        Rebate ({isPercentage ? "%" : "RM"})
      </th>,
      <th key={"amount-payable"} className="w-3/12 h-14 uppercase text-right">
        Amount Payable (RM)
      </th>,
    ];

    const throwawayColumn = [
      <th key={"throwaway-col-2"} className="w-1/12 h-14">
        &nbsp;
      </th>,
    ];

    return device.isDesktop ? tempHeaders.concat(throwawayColumn) : tempHeaders;
  };

  const generateBody = () => {
    const remainingRebateRow = editable
      ? [
          <tr key={`remaining-rebate`} className="m-5">
            <td className="bg-[#F7F7F7]" colSpan={!isPercentage ? 3 : 2} />
            <td className="font-light italic bg-[#F7F7F7]" colSpan={3}>
              Remaining Rebate: {!isPercentage && <br />}
              {isPercentage
                ? ` ${remainingRebate}%`
                : `${remainingRebate && formatNumber(remainingRebate)}`}
            </td>
          </tr>,
        ]
      : [];

    const tempStages = stages.map((stage, index) => {
      const displayRebate = progressRebates?.find((currentValue) =>
        stage.name === currentValue.stage ? currentValue : null
      );

      return (
        <tr key={`stage-${index}`} className="m-5">
          <td className="font-normal">{stage.name}</td>
          <td className="font-normal">{stage.percentage}</td>
          {!isPercentage && (
            <td className="font-normal">
              {formatNumber(calculatePrice(stage.percentage, 0).toFixed(2))}
            </td>
          )}
          <td>
            {editable ? (
              isEditing ? (
                <TextField
                  id={`stage-${stage.name}-field`}
                  type="text"
                  error=""
                  innerClassName="max-w-[158px] text-xs h-7 py-0 px-2"
                  value={progressRebates[index].stringValue}
                  onChange={(e) => handleChange(index, e.currentTarget.value)}
                  onBlur={() =>
                    updateRebateText(
                      index,
                      forceDecimal(progressRebates[index].value)
                    )
                  }
                  dynamicWidth={true}
                />
              ) : (
                <TextField
                  id={`stage-${stage.name}-display`}
                  type="text"
                  error=""
                  innerClassName="max-w-[158px] text-xs h-7 py-0 px-2"
                  value={
                    progressRebates[index].value === 0
                      ? ""
                      : forceDecimal(progressRebates[index].value)
                  }
                  readOnly
                  onFocus={toggleEditing}
                  dynamicWidth={true}
                />
              )
            ) : (
              <span>
                {stage.name === displayRebate?.stage
                  ? displayRebate?.value
                    ? formatNumber(displayRebate.value)
                    : "-"
                  : "-"}
              </span>
            )}
          </td>
          <td className="font-normal text-right">
            {editable
              ? formatNumber(
                  isPercentage
                    ? calculatePrice(
                        stage.percentage,
                        progressRebates[index]?.value
                      ).toFixed(2)
                    : calculateAbsoluteAmountPayable(
                        progressRebates[index]?.value,
                        calculatePrice(stage.percentage, 0)
                      ).toFixed(2)
                )
              : formatNumber(
                  isPercentage
                    ? calculatePrice(
                        stage.percentage,
                        displayRebate?.value ?? 0
                      ).toFixed(2)
                    : calculateAbsoluteAmountPayable(
                        displayRebate?.value ?? 0,
                        calculatePrice(stage.percentage, 0)
                      ).toFixed(2)
                )}
          </td>
        </tr>
      );
    });

    return [
      ...remainingRebateRow,
      ...tempStages,
      <tr key={`key-total`} className="h-12 m-5">
        <td
          className="uppercase font-extrabold text-xs"
          colSpan={!isPercentage ? 3 : 2}
        >
          Total
        </td>
        <td className="font-extrabold">
          {editable
            ? isPercentage
              ? totalInputRebate ?? 0
              : `${formatNumber(totalInputRebate)}`
            : isPercentage
            ? totalRebate
            : `${formatNumber(totalRebate)}`}
        </td>
        <td className="font-extrabold text-right">{calculateTotalPrice()}</td>
      </tr>,
    ];
  };

  useEffect(() => {
    if (rebateType) {
      setOnFirstLoad(false);

      !isOnFirstLoad &&
        editable &&
        setProgressRebates((prevValues: Rebate[]) => {
          prevValues.forEach((i) => {
            i.value = 0;
            i.stringValue = "";
          });

          return [...prevValues];
        });
    }
  }, [totalRebate, nettSellingPrice, rebateType]);

  useEffect(() => {
    if (editable) {
      let tempTotalRebate = progressRebates.reduce(
        (inputRebate: number, progressRebate: Rebate) => {
          return inputRebate + progressRebate.value;
        },
        0
      );

      setTotalInputRebate(forceDecimal(tempTotalRebate));
      setRemainingRebate(forceDecimal(totalRebate - tempTotalRebate));
    }
  }, [progressRebates, isOnFirstLoad]);

  return (
    <Table
      isLoading={false}
      onSearching={() => {}}
      searchable={false}
      searchHint=""
      showPagination={false}
      containerClassName={isModal ? "py-5" : ""}
      headers={generateHeader()}
      body={generateBody()}
    />
  );
};

export default ScheduleHTable;
