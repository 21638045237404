import classNames from "classnames";
import React, { forwardRef } from "react";
import { formatErrorMessage } from "../../utils/CommonUtils";

interface TextFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  required?: boolean;
  error?: string;
  hasError?: boolean;
  innerClassName?: string;
  containerClassName?: string;
  inputClassName?: string;
  backLabel?: string;
  frontLabel?: string;
  dynamicWidth?: boolean;
  disabled?: boolean;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    innerClassName,
    containerClassName,
    inputClassName,
    backLabel,
    frontLabel,
    dynamicWidth,
    disabled,
    hasError,
    ...otherProps
  } = props;

  return (
    <div
      className={classNames(
        "col",
        {
          "w-full tablet:w-[498px]": !dynamicWidth,
          "opacity-50": disabled,
        },
        containerClassName
      )}
    >
      <label
        className={classNames(
          "input-border w-full",
          {
            "border-primary": props.hasError || props.error,
            "relative box-border": frontLabel || backLabel,
            "pr-6": backLabel,
            "cursor-not-allowed": disabled,
          },
          innerClassName
        )}
      >
        {props.label && (
          <div className="row gap-1">
            <label className="uppercase block mb-1">{props.label}</label>
            {(props.required ?? true) && (
              <span className="text-dark-red">*</span>
            )}
          </div>
        )}

        <input
          ref={ref}
          autoComplete="off"
          className={classNames(
            "w-full font-semibold focus:outline-none text-base mobile:text-sm",
            {
              "pl-6": frontLabel,
              "cursor-default pointer-events-none bg-white": disabled,
            },
            inputClassName
          )}
          disabled={disabled}
          maxLength={255}
          {...otherProps}
        />

        {frontLabel && (
          <div className="absolute left-5 bottom-2.5 text-base mobile:text-sm font-bold box-border">
            {frontLabel}
          </div>
        )}

        {backLabel && (
          <div className="absolute right-0 text-base mobile:text-sm font-bold pr-2 box-border">
            {backLabel}
          </div>
        )}
      </label>

      <span
        hidden={!props.error}
        className={classNames(
          "font-light text-primary pt-2 transition-all duration-200",
          {
            "text-sm": props.label,
            "text-xs": !props.label,
          }
        )}
      >
        {formatErrorMessage(props.error)}
      </span>
    </div>
  );
});

export default TextField;
