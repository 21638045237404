/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { object, SchemaOf } from "yup";
import Path from "../../common/Path";
import { Proposal } from "../../common/Types";
import MoreButton from "../../components/Buttons/MoreButton";
import BaseCard from "../../components/Cards/BaseCard";
import ProposalStatusChip from "../../components/Chips/ProposalStatusChip";
import Textarea from "../../components/General/Textarea";
import Icon from "../../components/Icons/Icon";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import PopUpItem from "../../components/PopUp/PopUpItem";
import SortCaret from "../../components/Tables/SortCaret";
import Table from "../../components/Tables/Table";
import HeaderText from "../../components/Texts/HeaderText";
import Format from "../../helpers/Format";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useMyProposalListContext } from "../../hooks/contexts/MyProposalListContext";
import { useFilter } from "../../hooks/Filter";
import { useValidation } from "../../hooks/UseValidation";
import { formatNumber } from "../../utils/CommonUtils";
import { baseString } from "../../utils/SchemaUtil";
import { toast } from "react-toastify";

interface WithdrawProposalForm {
  reason: string;
}

const schema: SchemaOf<WithdrawProposalForm> = object().shape({
  reason: baseString().required().label("Reason of withdrawal").max(500),
});

const MyProposalListPage: React.FC = () => {
  const navigate = useNavigate();
  const myProposalList = useMyProposalListContext();
  const loadingBar = useLoadingBarContext();
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<WithdrawProposalForm>(schema);
  const withdrawalForm = validation.watch();
  const { items, sort } = useFilter<Proposal>(myProposalList.proposals);
  const [showConfirmation, setShowConfirmation] = useState<{
    id?: number;
    status: boolean;
  }>({ status: false });

  const view = (proposal: Proposal) => {
    navigate(Path.viewMyProposal(proposal.id), { state: proposal });
  };

  const withdraw = async (id: number, fields: WithdrawProposalForm) => {
    const result = await validation.trigger();

    if (result) {
      loadingPage.start();

      const body = { reason: fields.reason };

      const success: boolean = await myProposalList.withdraw(id, body);

      if (success) {
        setShowConfirmation({ status: false });
        toast.success("Proposal withdrew successfully.");
        myProposalList.index(["customers", "units"]);
      }

      loadingPage.end();
    }
  };

  const listActions = (proposal: Proposal, index: number) => {
    const defaultActions = [
      <PopUpItem icon={Icon.eye} label="View" onClick={() => view(proposal)} />,
    ];
    const withdrawalAction = proposal?.booking
      ? []
      : [
          <PopUpItem
            icon={Icon.withdraw}
            label="Withdraw"
            onClick={() =>
              setShowConfirmation({
                id: myProposalList.proposals[index].id,
                status: true,
              })
            }
          />,
        ];
    let additionalActions: JSX.Element[] = [];

    switch (proposal.status) {
      case "approved":
        additionalActions = [
          <PopUpItem
            icon={Icon.extend}
            label="Book"
            onClick={() =>
              navigate(Path.newBooking, {
                state: myProposalList.proposals[index],
              })
            }
          />,
          ...withdrawalAction,
        ];
        break;

      case "booked":
        additionalActions = [
          <PopUpItem
            icon={Icon.proposalListing}
            label="Generate 7 Letters"
            onClick={() =>
              navigate(Path.viewGenerateLetters(proposal.id), {
                state: myProposalList.proposals[index],
              })
            }
          />,
          <PopUpItem
            icon={Icon.edit}
            label="Edit Buyers"
            onClick={() =>
              navigate(Path.editBuyers(proposal.id), {
                state: myProposalList.proposals[index],
              })
            }
          />,
        ];
        break;

      case "sold":
        additionalActions = [
          <PopUpItem
            icon={Icon.proposalListing}
            label="Generate 7 Letters"
            onClick={() =>
              navigate(Path.viewGenerateLetters(proposal.id), {
                state: myProposalList.proposals[index],
              })
            }
          />,
        ];
        break;

      case "resubmit-booking":
        additionalActions = [
          <PopUpItem
            icon={Icon.edit}
            label="Edit"
            onClick={() =>
              proposal?.booking
                ? navigate(Path.editBooking(proposal.id), {
                    state: myProposalList.proposals[index],
                  })
                : navigate(Path.editProposal(proposal.id), {
                    state: myProposalList.proposals[index],
                  })
            }
          />,
        ];
        break;

      case "resubmit-approval":
        additionalActions = [
          <PopUpItem
            icon={Icon.edit}
            label="Edit"
            onClick={() =>
              proposal?.booking
                ? navigate(Path.editBooking(proposal.id), {
                    state: myProposalList.proposals[index],
                  })
                : navigate(Path.editProposal(proposal.id), {
                    state: myProposalList.proposals[index],
                  })
            }
          />,
          ...withdrawalAction,
        ];
        break;

      case "pending-approval":
        additionalActions = [...withdrawalAction];
        break;

      default:
        break;
    }

    return [...defaultActions, ...additionalActions];
  };

  useEffect(() => {
    myProposalList.index(["customers", "units"]);
  }, []);

  useEffect(() => {
    if (myProposalList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [myProposalList.isLoading]);

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="My Proposals" />

        <BaseCard>
          <Table
            isLoading={myProposalList.isLoading}
            onSearching={myProposalList.search}
            searchHint="Search by customer, unit or status"
            limit={10}
            headers={[
              <SortCaret
                key="buyer-name"
                header="Buyer name"
                onToggle={() => sort("customers[0].fullName,companies[0].name")}
              />,
              <SortCaret
                key="unit"
                header="Unit"
                onToggle={() => sort("unit.number")}
              />,
              <SortCaret
                key="buildup"
                header="Build up"
                onToggle={() => sort("unit.type.buildUp")}
              />,
              <SortCaret
                key="type"
                header="Type"
                onToggle={() => sort("unit.type.name")}
              />,
              <SortCaret
                key="creation-date"
                header="Creation date"
                onToggle={() => {
                  sort("createdAt");
                }}
              />,
              <SortCaret
                key="proposal-status"
                header="Proposal Status"
                onToggle={() => sort("status")}
              />,
            ]}
            body={items.map((proposal, index) => (
              <tr
                key={`proposal-${proposal.id}-${index}`}
                className={classNames("h-0 align-top", {
                  "bg-opacity-50 bg-ink-well": proposal.status === "sold",
                })}
              >
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal.customers?.length
                    ? proposal.customers?.map((customer, customerIndex) => (
                        <div key={`customer-${customerIndex}`}>
                          {customer.fullName}
                        </div>
                      ))
                    : proposal.companies?.map((company, companyIndex) => (
                        <div key={`company-${companyIndex}`}>
                          {company.name}
                        </div>
                      ))}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal?.unit?.number}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {formatNumber(proposal?.unit?.type?.buildUp || "-")}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal?.unit?.type?.name || "-"}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal.createdAt ? Format.date(proposal.createdAt) : "-"}
                </td>
                <td className="py-2">
                  <ProposalStatusChip status={proposal.status} />
                </td>
                <td className="flex justify-end items-center h-11 pl-0">
                  <MoreButton popupClassName="w-max">
                    {listActions(proposal, index).map((action, actionIndex) => (
                      <Fragment key={`action-${actionIndex}`}>
                        {action}
                      </Fragment>
                    ))}
                  </MoreButton>
                </td>
              </tr>
            ))}
          />
        </BaseCard>
      </div>

      <ConfirmationModal
        show={showConfirmation.status}
        dismissible={false}
        onHide={() => {}}
        type="warning"
        title="Withdraw Proposal"
        description="Are you sure you want to withdraw this proposal?"
        content={
          <div className="mb-6">
            <Textarea
              id="reason-of-withdrawal"
              rows={3}
              label="Reason of Withdrawal"
              dynamicWidth={true}
              containerClassName="w-[498px]"
              error={validation.errors.reason?.message}
              required={true}
              maxLength={500}
              placeholder="Please provide reasons"
              {...validation.register("reason")}
            />
          </div>
        }
        onConfirm={{
          text: "Confirm",
          action: () =>
            showConfirmation?.id
              ? withdraw(showConfirmation.id, withdrawalForm)
              : {},
        }}
        onCancel={{
          text: "Cancel",
          action: () => {
            validation.clearErrors();
            validation.setValue("reason", "");
            setShowConfirmation({ status: false });
          },
        }}
      />
    </>
  );
};

export default MyProposalListPage;
