import { paymentMethodName } from "../../common/Booking";
import { PaymentDetail } from "../../common/Types";
import { formatNumber } from "../../utils/CommonUtils";
import { ExpandableCard, GroupExpandableCard } from "../Cards/ExpandableCard";
import Line from "../General/Line";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface PaymentInfoSummaryProps {
  name: string;
  paymentDetails?: PaymentDetail[];
  viewImage: (blobPath: string | null) => void;
}

const PaymentInfoSummary: React.FC<PaymentInfoSummaryProps> = (props) => {
  const { name, paymentDetails, viewImage } = props;

  return (
    <GroupExpandableCard defaultValue={`${name}-1`} onExpand={() => {}}>
      {paymentDetails?.map((detail, index) => (
        <ExpandableCard
          key={`${name}-${index}`}
          value={`${name}-${index + 1}`}
          title={`Payment Information ${index + 1}`}
          showRequired={false}
          className="shadow-none mx-0 mt-4 mb-0 border border-[#53565A33]"
          background="bg-section-background"
          autoScroll={false}
          validated="empty"
        >
          <table className="table-fixed w-full bg-transparent">
            <thead>
              <tr className="h-6 border-none">
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Currency
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Amount
                </th>
                <th className="min-w-[250px] desktop:w-[250px]" />
                <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                  Payment Method
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="h-6 border-none">
                <td className="text-sm font-bold px-0">
                  {detail?.currencyCode}
                </td>
                <td />
                <td className="text-sm font-bold px-0">
                  {detail?.amount && formatNumber(detail.amount)}
                </td>
                <td />
                <td className="text-sm font-bold px-0">
                  {detail?.paymentMethod &&
                    paymentMethodName(detail.paymentMethod)}
                </td>
              </tr>
            </tbody>
          </table>

          {detail?.remarks && (
            <div className="row flex justify-start items-center gap-11 mt-6">
              <div className="w-full">
                <p className="text-sm uppercase mb-1">Remarks</p>
                <span className="text-sm font-bold">{detail.remarks}</span>
              </div>
            </div>
          )}

          {(detail?.attachments ?? []).length > 0 && (
            <>
              <Line className="mt-6 mb-4" />

              <div className="font-normal uppercase">
                <h4 className="uppercase mb-4">Proof of Payment</h4>
                {detail?.attachments?.map((attachment, attachmentIndex) =>
                  attachment.name.includes(".pdf") ? (
                    <div
                      key={`booking-info-doc-${attachmentIndex}`}
                      className="row flex justify-between items-center h-[100px] my-3 text-xs "
                    >
                      <div className="row flex items-center">
                        <div className="border border-[#A9695B33] w-[100px] h-[100px]">
                          <PabloIcon
                            icon={Icon.myProposal}
                            className="gray-icon w-[50px] h-full m-auto object-contain"
                          />
                        </div>

                        <a
                          className="cursor-pointer pl-16"
                          href={attachment.path}
                          rel="noreferrer"
                          target="_blank"
                          download
                        >
                          <PabloIcon
                            icon={Icon.download}
                            className="gray-icon"
                          />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={`booking-info-image-${attachmentIndex}`}
                      className="row flex justify-between items-center h-[100px] my-3 text-xs "
                    >
                      <div className="row flex items-center">
                        <img
                          src={attachment.path}
                          onClick={() => viewImage(attachment.path)}
                          className="border border-[#A9695B33] object-contain w-[100px] h-[100px] cursor-pointer"
                          alt={`booking-${index}-attachments`}
                        />

                        <a
                          className="cursor-pointer pl-16"
                          href={attachment.path}
                          rel="noreferrer"
                          target="_blank"
                          download
                        >
                          <PabloIcon
                            icon={Icon.download}
                            className="gray-icon"
                          />
                        </a>
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </ExpandableCard>
      ))}
    </GroupExpandableCard>
  );
};

export default PaymentInfoSummary;
