/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import TextField from "../Fields/TextField";
import { forceDecimal, formatNumber } from "../../utils/CommonUtils";
import PabloIcon from "../Icons/PabloIcon";
import Icon from "../Icons/Icon";
import "../../styles/sales-package.css";
import { IFormSchema } from "../../common/Interfaces";
import { Validation } from "../../hooks/UseValidation";

interface NettSellingPriceRebateCardProps<T extends IFormSchema> {
  validation: Validation<T>;
  sellingPrice: number;
  psfPrice: number;
  recommendedSellingPrice: number;
  totalGrossPrice: number;
  selectedSalesPackage: string;
  onChangeNettSellingPrice: (rebate: number) => void;
}

const NettSellingPriceRebateCard: React.FC<
  NettSellingPriceRebateCardProps<any>
> = (props) => {
  const {
    validation,
    sellingPrice,
    psfPrice,
    recommendedSellingPrice,
    totalGrossPrice,
    selectedSalesPackage,
    onChangeNettSellingPrice,
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const error = validation?.errors?.salesPackage;

  const calculateRebateInPercentage = (price: number, unitPrice: number) => {
    const rebateInPercentage =
      price < 0 || price > unitPrice || !price
        ? 0
        : ((unitPrice - price) / unitPrice) * 100;

    validation.setValue(
      `salesPackage.rebate`,
      forceDecimal(rebateInPercentage)
    );
  };

  return (
    <div className="col w-full tablet:w-[498px]">
      {selectedSalesPackage === "grr" && (
        <div className="mb-5">
          <label className="w-full uppercase">Enter Nett Selling Price</label>
        </div>
      )}

      <div className="w-full min-h-[160px] bg-[#F0E7E780] px-4 py-2">
        <div className="mb-1.5">
          <span className="text-sm">
            Recommended Nett Selling Price ={" "}
            <strong>
              RM{formatNumber(recommendedSellingPrice.toString())}
            </strong>
          </span>
        </div>

        <TextField
          id="nett-selling-price-value"
          label="Nett Selling Price"
          type="text"
          required={true}
          error={error?.nettSellingPrice?.message || error?.rebate?.message}
          onChange={(e) => {
            const parsedValue = parseInt(
              e.currentTarget.value.replace(/,/g, "")
            );
            totalGrossPrice &&
              calculateRebateInPercentage(parsedValue, totalGrossPrice);
            onChangeNettSellingPrice(parsedValue);
          }}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          value={isFocused ? sellingPrice : formatNumber(sellingPrice)}
          placeholder="Enter Value"
          frontLabel="RM"
          dynamicWidth={true}
        />

        <div className="mt-3.5">
          <span className="text-sm">
            Nett Selling Price PSF ={" "}
            <strong>RM{formatNumber(psfPrice.toString())}</strong>
          </span>
        </div>
      </div>

      {sellingPrice > totalGrossPrice && (
        <div className="mt-3 flex items-center tablet:items-end justify-start gap-2 w-full">
          <div className="w-[20px] tablet:pb-2.5">
            <PabloIcon
              icon={Icon.info}
              className="w-[20px] h-[20px] primary-icon"
            />
          </div>
          <div className="w-full text-sm text-[#B11116]">
            The value you entered in Nett Selling Price exceeds the Unit Gross
            Selling Price.
          </div>
        </div>
      )}
    </div>
  );
};

export default NettSellingPriceRebateCard;
