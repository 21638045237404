/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useEffect } from "react";

export const useOnOutsideClick = (
  ref: RefObject<HTMLElement>,
  closeAction: () => void
) => {
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node))
      closeAction();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });
};
