import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { Response } from "../common/Types";

interface ApiClientOptions {
  baseUrl: string | undefined;
  headers?: {};
  params?: {};
}

export class ApiClient {
  private client: AxiosInstance;

  constructor({ baseUrl, headers, params }: ApiClientOptions) {
    this.client = axios.create({
      baseURL: baseUrl ?? "",
      timeout: 60 * 1000,
      params: params,
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        ...headers,
      },
    });

    this.client.interceptors.request.use(this.onRequest, this.onErrorRequest);
    this.client.interceptors.response.use(
      this.onResponse,
      this.onErrorResponse
    );
  }

  protected onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    const newConfig = { ...config };

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params);
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
  }

  protected onResponse<T>(response: AxiosResponse): Response<T> {
    if (
      response?.data &&
      response?.headers["content-type"] === "application/json"
    ) {
      response.data = camelizeKeys(response.data);
    }

    return { data: response.data, success: true };
  }

  protected onErrorRequest<T>(error: AxiosError): Response<T> {
    console.group(`Error request from ${error.config?.url}`);
    console.info(
      "status:",
      `${error.request?.status} ${error.request?.statusText}`
    );
    console.info("data:", error.request?.data);
    console.groupEnd();

    return { data: error.request, success: false };
  }

  protected onErrorResponse<T>(error: AxiosError): Response<T> {
    console.group(`Error response from ${error.config?.url}`);
    console.info(
      "status:",
      `${error.response?.status} ${error.response?.statusText}`
    );
    console.info("data:", error.response?.data);
    console.groupEnd();

    return { data: error.response?.data, success: false };
  }

  protected onFormDataResponse<T>(response: AxiosResponse): Response<T> {
    return { data: response.data, success: true };
  }

  async get<T>(path: string): Promise<Response<T>> {
    return await this.client.get(path);
  }

  async post<T>(path: string, body?: {}): Promise<Response<T>> {
    return await this.client.post(path, body);
  }

  async patch<T>(path: string, body?: {}): Promise<Response<T>> {
    return await this.client.patch(path, body);
  }

  async delete<T = void>(path: string): Promise<Response<T>> {
    return await this.client.delete(path);
  }
}
