import React from "react";
import classNames from "classnames";

interface AccordionProps {
  name: string;
  title: string;
  toggle: (name: string) => void;
  open: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ name, title, toggle, open, children }) => {
  return (
    <>
      <div
        key={name}
        className="h-10 bg-white hover:bg-ink-well ring-1 ring-input-border cursor-pointer"
        onClick={() => toggle(name)}
      >
        <div className="row h-10 items-center justify-start text-black font-bold">
          <span className="w-7 text-center text-lg mb-[2px]">{open ? "-" : "+"}</span>
          <span className="uppercase text-xs">{title}</span>
        </div>
      </div>

      <div className={
        classNames("bg-background transition-[height] duration-300 overflow-x-auto", {
          "h-auto py-3 px-5 ring-1 ring-input-border": open,
          "h-0": !open,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default Accordion;
