import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface MoreButtonProps {
  children: ReactNode;
  popupClassName?: string;
}

const MoreButton: React.FC<MoreButtonProps> = (props) => {
  const { popupClassName, children } = props;

  const [expanded, setExpanded] = useState(false);

  const close = () => {
    setExpanded(false);
  };

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      tabIndex={-1}
      onBlur={close}
      className="cursor-pointer hover:bg-ink-well rounded focus:outline-none select-none"
    >
      <button
        className="h-9 w-9 col items-center justify-center"
        onClick={toggle}
      >
        <PabloIcon icon={Icon.fillMore} />
      </button>

      <div
        hidden={!expanded}
        className={classNames(
          "z-10 absolute right-24 mt-2 w-48 border-[1px] border-secondary  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
          popupClassName
        )}
      >
        <div
          className="col"
          role="none"
          onMouseDown={(e) => e.preventDefault()}
          onClick={close}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MoreButton;
