/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FormErrors, Rebate } from "../../common/Types";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import ScheduleHTable from "../Tables/ScheduleHTable";
import ErrorText from "../Texts/ErrorText";

interface ScheduleHPaymentStructureAccordianProps {
  formErrors: FormErrors;
  grossSellingPrice?: number;
  nettSellingPrice: number;
  totalRebate: number;
  progressRebates: Rebate[];
  remainingRebate: number;
  hasPaymentStructureOpened: boolean;
  setFormErrors: Function;
  setProgressRebates: Function;
  setRemainingRebate: (rebate: number) => void;
  className?: string;
  hasDefaultValues?: boolean;
  rebateType: string;
}

const ScheduleHPaymentStructureAccordian: React.FC<
  ScheduleHPaymentStructureAccordianProps
> = (props) => {
  const unitList = useUnitListContext();
  const { unitPrices } = unitList;
  const {
    formErrors,
    grossSellingPrice,
    nettSellingPrice,
    totalRebate,
    progressRebates,
    remainingRebate,
    setProgressRebates,
    setRemainingRebate,
    hasDefaultValues,
    rebateType,
  } = props;
  const totalPrice = grossSellingPrice ?? unitPrices?.totalGrossPrice ?? 0;

  return (
    <>
      {formErrors.scheduleH && (
        <ErrorText text={formErrors.scheduleH} className="mb-4 mt-3" />
      )}

      <ScheduleHTable
        totalPrice={totalPrice}
        nettSellingPrice={nettSellingPrice}
        totalRebate={totalRebate}
        progressRebates={progressRebates}
        remainingRebate={remainingRebate}
        editable={true}
        hasDefaultValues={hasDefaultValues}
        setProgressRebates={setProgressRebates}
        setRemainingRebate={setRemainingRebate}
        rebateType={rebateType}
      />
    </>
  );
};

export default ScheduleHPaymentStructureAccordian;
