import classNames from "classnames";
import { Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import { TaskType } from "../../common/Types/DragAndDrop";
import Line from "../General/Line";
import InnerList from "./InnerList";

interface ColumnProps {
  id: string;
  title: string;
  tasks: TaskType[];
  hasCreateButton?: boolean;
}

const Column: React.FC<ColumnProps> = (props) => {
  const { id, title, tasks, hasCreateButton } = props;
  const navigate = useNavigate();

  return (
    <div className="w-[310px] min-w-[310px] mr-4 bg-white flex flex-col flex-grow">
      <div className="pt-3 pb-6 px-3 flex justify-between">
        <div className="py-2 px-4 bg-[#3488DB1A] rounded-full">
          <h3 className="font-normal text-[#416FB8] text-xs uppercase">
            {title}
          </h3>
        </div>
        <div className="py-2 px-4 text-xs font-bold flex-grow items-center">
          {tasks.length}
        </div>
      </div>

      <Line className="m-0" />

      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div
            className={classNames(
              "px-2 pt-2 transition-all duration-100 flex-grow",
              {
                "bg-input-border": snapshot.isDraggingOver,
              }
            )}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <InnerList tasks={tasks} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {hasCreateButton && (
        <button
          className="w-full secondary-button border-0"
          onClick={() => navigate(Path.newLeadDetails)}
        >
          + Create
        </button>
      )}
    </div>
  );
};

export default Column;
