export const proposalStages = ["creation", "booking"] as const;

export type ProposalStage = typeof proposalStages[number];

export const stageName = (stage: ProposalStage): string => {
  const stageNames = {
    creation: "Proposal Creation",
    booking: "Proposal Booking",
  };

  return stageNames[stage];
};
