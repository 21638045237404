import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import Path from "../common/Path";
import { Response } from "../common/Types";
import { ApiClient } from "./ApiClient";
import storage from "./StorageService";

class PabloApiService extends ApiClient {
  protected onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    const token = storage.getToken();
    const newConfig = { ...config };

    if (token) {
      newConfig.headers!["Authorization"] = `Bearer ${token}`;
    }

    return super.onRequest(newConfig);
  }

  protected onErrorResponse<T>(error: AxiosError): Response<T> {
    const listOfDisableToastErrors = ["No query results", "does not exist"];

    switch (error.response?.status) {
      case 401:
        window.location.href = `${Path.login}?expired=true`;
        storage.clear();
        break;

      default:
        const detailedErrors = Object.values(
          error.response?.data?.errors ?? {}
        );

        const message =
          error.response?.data?.message ??
          "Something went wrong on Pablo side.";

        const isDisabled = listOfDisableToastErrors.some((str) =>
          message.includes(str)
        );

        !isDisabled &&
          toast.error(
            <>
              {Object.keys(detailedErrors).length <= 0
                ? message
                : detailedErrors.map((e) => {
                    return (
                      <>
                        • {e} <br />
                      </>
                    );
                  })}
            </>
          );
        break;
    }

    return super.onErrorResponse(error);
  }

  async postFormData<T>(path: string, body?: FormData): Promise<Response<T>> {
    const token = storage.getToken();

    axios.interceptors.response.use(
      this.onFormDataResponse,
      this.onErrorResponse
    );

    return await axios.post(path, body, {
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        accept: "application/json",
        Authorization: token ? `Bearer ${storage.getToken()}` : "",
        "Content-Type": `multipart/form-data;`,
      },
    });
  }
}

const api = new PabloApiService({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
});

export default api;
