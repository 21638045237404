import classNames from "classnames";
import { Source } from "../../common/OpportunitySource";
import { formatNumber } from "../../utils/CommonUtils";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import TextField from "./TextField";

interface EditableFieldProps {
  id: string;
  title: string;
  isEditing: boolean;
  value?: number;
  channel: Source;
  fieldContainerClassName?: string;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueConfirm: () => void;
  onEditClick: () => void;
}

const EditableField: React.FC<EditableFieldProps> = (props) => {
  const {
    id,
    title,
    isEditing,
    value,
    channel,
    fieldContainerClassName,
    onValueChange,
    onValueConfirm,
    onEditClick,
  } = props;
  const fullTextFieldChannels = [
    "digital",
    "bgb",
    "existing-buyer",
    "business-associate",
    "brand-collaboration",
  ];

  return (
    <>
      {isEditing ? (
        <div className="flex justify-start">
          <div className={classNames("items-center", fieldContainerClassName)}>
            <TextField
              id={id}
              type="number"
              inputMode="numeric"
              error=""
              label={fullTextFieldChannels.includes(channel) ? title : ""}
              innerClassName={
                fullTextFieldChannels.includes(channel)
                  ? ""
                  : "max-w-[80px] text-xs h-9 py-0 px-2"
              }
              backLabel={fullTextFieldChannels.includes(channel) ? "" : "%"}
              defaultValue={value || 0}
              dynamicWidth={true}
              onChange={onValueChange}
            />
          </div>

          <div className="items-center">
            <button
              className={classNames(
                "ml-2 cursor-pointer text-xs text-[#0880e3] underline",
                {
                  "mt-2.5": !fullTextFieldChannels.includes(channel),
                  "mt-7": fullTextFieldChannels.includes(channel),
                }
              )}
              onClick={onValueConfirm}
            >
              Done
            </button>
          </div>
        </div>
      ) : (
        <>
          {fullTextFieldChannels.includes(channel) ? (
            <div className="min-w-[200px] px-5 pt-2">
              <p className="text-sm font-normal mb-2 uppercase">{title}</p>
              <div className="h-8 flex justify-start">
                <div className="font-bold text-base mobile:text-sm items-center">
                  {`${channel === "digital" ? "RM " : ""}${formatNumber(
                    value || 0
                  )}${channel === "digital" ? "" : "%"}`}
                </div>
                <div
                  className="items-center mt-0.5 ml-2 cursor-pointer"
                  onClick={onEditClick}
                >
                  <PabloIcon
                    className="w-4 h-4 blue-icon border-b border-b-[#0880e3] mt-[2px] mobile:mt-0"
                    icon={Icon.edit}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="h-8 pt-1 flex justify-start">
              <div className="font-bold text-base mobile:text-sm items-center">
                {value || 0}%
              </div>

              <div
                className="items-center mt-0.5 ml-2 cursor-pointer"
                onClick={onEditClick}
              >
                <PabloIcon
                  className="w-4 h-4 blue-icon border-b border-b-[#0880e3] mt-[2px] mobile:mt-0"
                  icon={Icon.edit}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditableField;
