import { createContext, useContext, useState } from "react";
import api from "../../services/PabloApiService";
import { Agent } from "../../common/Types";
import {
  IndexAgentResponse,
  StoreAgentResponse,
  UpdateAgentResponse,
} from "../../common/Responses";

type AgentListContextType = {
  agents: Agent[];
  isLoading: boolean;
  index: (param?: string) => Promise<boolean>;
  search: (keyword: string) => void;
  store: (body: any) => Promise<boolean>;
  update: (id: number, body: any) => Promise<boolean>;
  destroy: (id: number) => Promise<boolean>;
};

const defaultContext: AgentListContextType = {
  agents: [],
  isLoading: true,
  index: async (param?: string) => false,
  search: (keyword: string) => {},
  store: async (body: any) => false,
  update: async (id: number, body: any) => false,
  destroy: async (id: number) => false,
};

const AgentListContext = createContext<AgentListContextType>(defaultContext);

export const useAgentListContext = () => useContext(AgentListContext);

export const AgentListContextProvider: React.FC = ({ children }) => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [tempAgents, setTempAgents] = useState<Agent[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const index = async (param?: string): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.get<IndexAgentResponse>(
      `/agents?status=${param ?? ""}`
    );

    if (success) {
      setAgents(data.agents);
      setTempAgents(data.agents);
    }

    setLoading(false);

    return success;
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const results = tempAgents.filter((agent) => {
        const sameAgency = agent.agency.name
          .toLowerCase()
          .includes(formattedKeyword);
        const sameName = agent.fullName
          .toLowerCase()
          .includes(formattedKeyword);
        const sameEmail = agent.email.toLowerCase().includes(formattedKeyword);
        return sameAgency || sameName || sameEmail;
      });

      setAgents(results);
    } else {
      setAgents(tempAgents);
    }
  };

  const store = async (body: any): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.post<StoreAgentResponse>(
      "/agents",
      body
    );

    if (success) {
      let temp = agents.slice();
      temp.push(data.agent);
      setAgents(temp);
    }

    setLoading(false);

    return success;
  };

  const update = async (id: number, body: any): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.patch<UpdateAgentResponse>(
      `/agents/${id}`,
      body
    );

    if (success) {
      let temp = agents.slice();
      const updatingAgent = temp.filter((agent) => agent.id === id)[0];
      const agentIndex = temp.indexOf(updatingAgent);
      temp[agentIndex] = data.agent;
      setAgents(temp);
    }

    setLoading(false);

    return success;
  };

  const destroy = async (id: number): Promise<boolean> => {
    setLoading(true);

    const { success } = await api.delete(`/agents/${id}`);

    if (success) {
      const temp = agents.slice();
      const updatedAgents = temp.filter((item: Agent) => item.id !== id);
      setAgents(updatedAgents);
      setTempAgents(updatedAgents);
    }

    setLoading(false);

    return success;
  };

  return (
    <AgentListContext.Provider
      value={{ agents, isLoading, index, search, store, update, destroy }}
    >
      {children}
    </AgentListContext.Provider>
  );
};
