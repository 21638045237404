/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgentStatus } from "../../common/Enums";
import Path from "../../common/Path";
import { Agent } from "../../common/Types";
import MoreButton from "../../components/Buttons/MoreButton";
import BaseCard from "../../components/Cards/BaseCard";
import AgencyStatusChip from "../../components/Chips/AgencyStatusChip";
import Icon from "../../components/Icons/Icon";
import PabloIcon from "../../components/Icons/PabloIcon";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import PopUpItem from "../../components/PopUp/PopUpItem";
import SortCaret from "../../components/Tables/SortCaret";
import Table from "../../components/Tables/Table";
import HeaderText from "../../components/Texts/HeaderText";
import { useAgencyListContext } from "../../hooks/contexts/AgencyListContext";
import { useAgentListContext } from "../../hooks/contexts/AgentListContext";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useFilter } from "../../hooks/Filter";

const AgentListPage: React.FC = () => {
  const navigate = useNavigate();
  const agentList = useAgentListContext();
  const agencyList = useAgencyListContext();
  const loadingBar = useLoadingBarContext();
  const [deletingAgent, setDeletingAgent] = useState<Agent | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const loadingPage = useLoadingPageContext();
  const { items, sort } = useFilter<Agent>(agentList.agents);

  useEffect(() => {
    agentList.index();
    agencyList.index();
  }, []);

  useEffect(() => {
    if (agentList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [agentList.isLoading]);

  const viewAgent = (agent: Agent) => {
    navigate(Path.editAgent(agent.id), {
      state: { agent: agent, agencies: agencyList.agencies },
    });
  };

  const addNewAgent = () => {
    navigate(Path.newAgent, { state: { agencies: agencyList.agencies } });
  };

  const deleteAgent = async () => {
    loadingPage.start();
    const success = await agentList.destroy(deletingAgent!.id);
    setShowSuccess(success);
    loadingPage.end();
    agentList.index();
    agencyList.index();
  };

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="Manage agent" />

        <BaseCard>
          <div className="row justify-end items-center mb-8">
            <button className="secondary-button group" onClick={addNewAgent}>
              <PabloIcon icon={Icon.plus} className="secondary-icon" />
              Add new agent
            </button>
          </div>

          <Table
            isLoading={agentList.isLoading}
            onSearching={agentList.search}
            searchHint="Search by Agent Name, Agency or Email"
            headers={[
              <SortCaret
                key={"agent-name"}
                header="Agent Name"
                onToggle={() => sort("fullName")}
              />,
              <SortCaret
                key={"agent-agency"}
                header="Agency"
                onToggle={() => sort("agency.name")}
              />,
              <SortCaret
                key={"agent-email"}
                header="Email"
                onToggle={() => sort("email")}
              />,
              <SortCaret
                key={"agent-phone"}
                header="Contact No."
                onToggle={() => sort("phoneCode")}
              />,
              <SortCaret
                key={"agent-status"}
                header="Status"
                onToggle={() => sort("status")}
              />,
            ]}
            body={items.map((agent, index) => {
              return (
                <tr key={`${agent.id}-${index}`} className="m-5">
                  <td
                    className="hover:cursor-pointer hover:text-primary"
                    onClick={() => viewAgent(agent)}
                  >
                    <div className="row items-center overflow-ellipsis gap-2">
                      <span className="truncate">{agent.fullName}</span>
                    </div>
                  </td>
                  <td>{agent.agency.name}</td>
                  <td>{agent.email}</td>
                  <td>{`+${agent.phoneCode}${agent.phoneNumber}`}</td>
                  <td>
                    <AgencyStatusChip status={agent.status as AgentStatus} />
                  </td>

                  <td className="flex justify-end items-center h-11">
                    <MoreButton>
                      <PopUpItem
                        icon={Icon.edit}
                        label="Edit"
                        onClick={() => viewAgent(agent)}
                      />
                      <PopUpItem
                        icon={Icon.remove}
                        label="Delete"
                        onClick={() => setDeletingAgent(agent)}
                      />
                    </MoreButton>
                  </td>
                </tr>
              );
            })}
          />
        </BaseCard>
      </div>

      {/* modal */}
      <ConfirmationModal
        show={deletingAgent ? true : false}
        title="Are you sure you want to delete this agent?"
        type="warning"
        onHide={() => setDeletingAgent(null)}
        onConfirm={{
          text: "Delete",
          action: deleteAgent,
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        title="You have successfully deleted this agent."
        type="success"
        onHide={() => setShowSuccess(false)}
        onConfirm={{
          text: "Manage agent",
          action: () => navigate(Path.agents),
        }}
        onCancel={{
          text: "Back to main page",
          action: () => navigate(Path.main),
        }}
      />
    </>
  );
};

export default AgentListPage;
