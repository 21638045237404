import classNames from "classnames";
import { Fragment } from "react";
import { CeActionsHistory } from "../../common/Types";
import Format from "../../helpers/Format";

interface CEActionsHistorySummaryProps {
  ceActions: CeActionsHistory[];
}

const CEActionsHistorySummary: React.FC<CEActionsHistorySummaryProps> = (
  props
) => {
  const { ceActions } = props;

  const spaHistory = {
    category: "spa",
    title: "SPA Date",
    actions: ceActions?.filter((action) => action.category === "spa"),
  };
  const solicitorHistory = {
    category: "solicitor",
    title: "Solicitor Assigned",
    actions: ceActions?.filter((action) => action.category === "solicitor"),
  };
  const ownerHistory = {
    category: "owner",
    title: "Ownership Details",
    actions: ceActions?.filter((action) => action.category === "owner"),
  };
  const specialRebateHistory = {
    category: "special-rebate",
    title: "Special Rebate",
    actions: ceActions?.filter(
      (action) => action.category === "special-rebate"
    ),
  };

  const handleCEActionsHistory = () => {
    const categoriesHistory = [
      spaHistory,
      solicitorHistory,
      ownerHistory,
      specialRebateHistory,
    ];

    return (
      <>
        {categoriesHistory.map((category, categoryIndex) => (
          <Fragment key={`category-${categoryIndex}`}>
            {category.actions.length > 0 && (
              <table className="table-fixed w-full bg-transparent">
                <thead>
                  <tr className="h-6 border-none">
                    <th
                      className={classNames(
                        "text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase",
                        { "pt-3": categoryIndex > 0 }
                      )}
                      colSpan={2}
                    >
                      {category.title} Edited By
                    </th>
                    <th
                      className={classNames(
                        "text-sm font-normal p-0 min-w-[250px] desktop:w-[250px] uppercase",
                        { "pt-3": categoryIndex > 0 }
                      )}
                      colSpan={3}
                    >
                      {category.title} Edited At
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {category.actions.map((action, index) => (
                    <tr key={`action-${index}`} className="h-6 border-none">
                      <td className="text-sm font-bold px-0" colSpan={2}>
                        {action.editedBy.fullName}
                      </td>
                      <td className="text-sm font-bold px-0" colSpan={3}>
                        {Format.date(action.createdAt, true)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="px-5 pb-5 pt-3 mb-2 bg-section-background">
        <h4 className="uppercase mb-4">CE Actions History Information</h4>

        {handleCEActionsHistory()}
      </div>
    </>
  );
};

export default CEActionsHistorySummary;
