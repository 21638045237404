/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { rounding } from "../../utils/CommonUtils";
import SearchField from "../Fields/SearchField";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import TabBar from "../Tabs/TabBar";

interface TabBarTableProps {
  isLoading: boolean;
  onSearching: (value: string) => void;
  searchHint: string;
  headers: ReactElement[];
  bodies: ReactElement[][];
  limits?: number[];
  searchable?: boolean;
  showPagination?: boolean;
  containerClassName?: string;
  tabs: string[];
}

const TabBarTable: React.FC<TabBarTableProps> = ({
  isLoading,
  onSearching,
  searchHint,
  headers,
  bodies,
  limits = bodies.map((body) => (body.length < 20 ? body.length : 20)),
  searchable = true,
  showPagination = true,
  containerClassName = "",
  tabs,
}) => {
  const [paginatedBodies, setPaginatedBodies] = useState<ReactElement[][]>([
    [],
  ]);
  const [pages, setPages] = useState<number[]>(bodies.map(() => 1));
  const [index, setIndex] = useState<number>(0);
  const maxPage = rounding(bodies[index].length / limits[index], "ceil");

  useEffect(() => {
    const min = pages[index] * limits[index] - limits[index];
    let tempBodies = [...bodies];
    tempBodies[index] = bodies[index].slice(min, pages[index] * limits[index]);
    setPaginatedBodies(tempBodies);
  }, [pages, bodies]);

  useEffect(() => {
    if (pages[index] !== 1)
      setPages((prevPages) => {
        const tempArr = [...prevPages];
        tempArr[index] = 1;
        return tempArr;
      });
  }, [onSearching]);

  const previous = () => {
    if (pages[index] > 1) {
      const main = document.getElementById("main");
      main?.scrollTo(0, 0);
      let tempPages = [...pages];
      tempPages[index] = pages[index] - 1;
      setPages(tempPages);
    }
  };

  const next = () => {
    if (pages[index] < maxPage) {
      const main = document.getElementById("main");
      main?.scrollTo(0, 0);
      let tempPages = [...pages];
      tempPages[index] = pages[index] + 1;
      setPages(tempPages);
    }
  };

  return (
    <>
      <div
        className={classNames(
          "bg-section-background gap-5 col",
          {
            "p-5": !containerClassName,
          },
          containerClassName
        )}
      >
        {searchable && (
          <div className="row justify-between items-center">
            <SearchField hint={searchHint} onChange={onSearching} />
          </div>
        )}

        <TabBar
          tabs={tabs}
          className="px-4 pt-4 pb-3 bg-white"
          indexOnChanged={setIndex}
        >
          {paginatedBodies.map((body, pageIndex) => {
            return (
              <div
                key={`tab-bar-table-${pageIndex}`}
                className="overflow-x-auto"
              >
                <table>
                  <thead>
                    <tr>{headers}</tr>
                  </thead>

                  <tbody>{body}</tbody>
                </table>
                {body.length <= 0 && (
                  <div className="row justify-center items-center bg-white h-20 border-b-[1px] border-t-[1px] border-section-background">
                    {isLoading ? "Loading" : "No result"}
                  </div>
                )}
              </div>
            );
          })}
        </TabBar>
      </div>

      {showPagination && paginatedBodies[index].length > 0 && (
        <div className="row text-sm justify-between mt-4 font-thin">
          <div>
            <span className="font-bold">
              {pages[index] * limits[index] - limits[index] + 1} - {""}
              {paginatedBodies[index].length >= limits[index]
                ? pages[index] * limits[index]
                : bodies[index].length}
            </span>{" "}
            of {bodies[index].length}
          </div>

          <div className="row justify-center items-center">
            {pages[index] > 1 && (
              <button
                className="h-9 w-9 col items-center justify-center hover:bg-ink-well rounded"
                onClick={previous}
              >
                <PabloIcon icon={Icon.arrow} />
              </button>
            )}

            <span className="px-2">
              Page <span className="font-bold">{pages[index]}</span> of{" "}
              {maxPage}
            </span>

            <button
              disabled={pages[index] >= maxPage}
              className={classNames(
                "h-9 w-9 col items-center justify-center rounded disabled:cursor-not-allowed",
                {
                  "hover:bg-ink-well": pages[index] < maxPage,
                }
              )}
              onClick={next}
            >
              <PabloIcon
                icon={Icon.arrow}
                className={classNames("rotate-180", {
                  "gray-icon": pages[index] >= maxPage,
                })}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TabBarTable;
