/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Country from "../../common/Country";
import { defaultRaces, Race, salutations } from "../../common/Enums";
import { Attachment, Customer } from "../../common/Types";
import { useArray } from "../../hooks/UseArray";
import { Validation } from "../../hooks/UseValidation";
import { BookingForm } from "../../schemas/BookingSchema";
import { capitalize, listDropdownPhoneNumbers } from "../../utils/CommonUtils";
import { clearAttachments } from "../../utils/SchemaUtil";
import {
  ExpandableCard,
  ExpandableCardValidated,
} from "../Cards/ExpandableCard";
import DatePickerField from "../Fields/DatePickerField";
import DropdownField from "../Fields/DropdownField";
import RadioField from "../Fields/RadioField";
import TextField from "../Fields/TextField";
import AddressForm from "../Forms/AddressForm";
import FileUploadForm from "../Forms/FileUploadForm";
import Line from "../General/Line";
import { RadioGroup } from "../General/Radio";

interface CustomerFullInfoAccordianProps {
  value: string;
  title: string;
  index: number;
  defaultValues?: Customer;
  showRequired?: boolean;
  validation: Validation<BookingForm>;
  className?: string;
  onDeleteAction?: () => void;
  setDirty: Function;
}

const CustomerFullInfoAccordian = forwardRef<
  any,
  CustomerFullInfoAccordianProps
>((props, ref) => {
  const now = moment().format("YYYY-MM-DD");
  const {
    index,
    validation,
    value,
    title,
    defaultValues,
    showRequired,
    className,
    onDeleteAction,
    setDirty,
  } = props;
  const register = validation.register;
  const customerInfo = validation.watch(`customerInfo.${index}`);
  const error = validation?.errors?.customerInfo?.[index];

  const [proofOfIdentities, dispatchProofOfIdentities] = useArray<Attachment>(
    defaultValues?.attachments?.map((attachment) => {
      return {
        id: attachment.id,
        name: attachment.name,
        blob: new Blob([attachment.path], {
          type: "image",
        }),
        blobPath: attachment.path,
      };
    }) || []
  );
  const [validated, setValidated] = useState<ExpandableCardValidated>("empty");
  const [bumiStatus, setBumiStatus] = useState<string>(
    defaultValues?.bumiputera ? "yes" : "no"
  );

  const onBumiStatusChanged = (status: string) => {
    setBumiStatus(status);
    validation.setValue(`customerInfo.${index}.bumiputera`, status);
  };

  const generateOtherRaceField = () => {
    if (
      !!defaultValues?.race &&
      !defaultRaces.includes(defaultValues?.race as Race)
    ) {
      return !defaultRaces.includes(customerInfo?.race as Race) &&
        !defaultRaces.includes(defaultValues.race as Race) ? (
        <TextField
          id={`customer-other-race-${index}`}
          label="Race (Other)"
          type="text"
          required={true}
          error={error?.otherRace?.message}
          defaultValue={
            defaultRaces.includes(defaultValues.race as Race)
              ? ""
              : defaultValues.race
          }
          onInput={() => setDirty(true)}
          {...register(`customerInfo.${index}.otherRace`, {
            setValueAs: (v) => v.trim().toLowerCase(),
          })}
        />
      ) : (
        <div className="col mb-5 w-full tablet:w-[498px]" />
      );
    }

    return !!customerInfo?.race &&
      !defaultRaces.includes(customerInfo?.race as Race) ? (
      <TextField
        id={`customer-other-race-${index}`}
        label="Race (Other)"
        type="text"
        required={true}
        error={error?.otherRace?.message}
        onInput={() => setDirty(true)}
        {...register(`customerInfo.${index}.otherRace`, {
          setValueAs: (v) => v.trim().toLowerCase(),
        })}
      />
    ) : (
      <div className="col mb-5 w-full tablet:w-[498px]" />
    );
  };

  useImperativeHandle(ref, () => ({
    onClearAttachments: () =>
      clearAttachments(
        proofOfIdentities,
        dispatchProofOfIdentities,
        `customerInfo.${index}.attachments`,
        validation
      ),
  }));

  useEffect(() => {
    validation.setValue(`customerInfo.${index}.bumiputera`, bumiStatus);
    validation.setValue(`customerInfo.${index}.id`, defaultValues?.id);
  }, []);

  useEffect(() => {
    if (
      customerInfo?.addressInfo?.addressLine1 &&
      customerInfo?.addressInfo?.postCode &&
      customerInfo?.addressInfo?.city &&
      customerInfo?.addressInfo?.state &&
      customerInfo?.addressInfo?.country &&
      customerInfo?.salutation &&
      customerInfo?.fullName &&
      customerInfo?.phoneCode &&
      customerInfo?.phoneNumber &&
      customerInfo?.countryOfOrigin &&
      customerInfo?.birthDate &&
      customerInfo?.bumiputera &&
      customerInfo?.email &&
      customerInfo?.gender &&
      customerInfo?.identificationNumber &&
      customerInfo?.attachments?.length &&
      customerInfo?.race &&
      ((customerInfo?.race === "other" && customerInfo?.otherRace) ||
        customerInfo?.race !== "other")
    ) {
      setValidated("success");
    } else {
      setValidated("empty");
    }

    if (error) setValidated("error");
  }, [error, validation.watch()]);

  return (
    <ExpandableCard
      value={value}
      title={title}
      showRequired={showRequired}
      onDeleteAction={onDeleteAction}
      className={className}
      validated={validated}
    >
      <div className="col gap-5 mb-9">
        <h4 className="font-bold">Personal Details</h4>
        <div className="row -mb-1 gap-4">
          <DropdownField
            id={`customer-salutation-${index}`}
            key={`customer-salutation-${index}`}
            label="Salutation"
            placeholder="Select Salutation"
            error={error?.salutation?.message}
            items={salutations.map((salutation) => {
              return { text: salutation, value: salutation };
            })}
            searchable="true"
            defaultValue={
              defaultValues?.salutation || customerInfo?.salutation || ""
            }
            {...register(`customerInfo.${index}.salutation`, {
              value:
                defaultValues?.salutation || customerInfo?.salutation || "",
              onChange: () => setDirty(true),
            })}
          />
          <TextField
            id={`customer-full-name-${index}`}
            label="Full Name (as per IC/Passport)"
            type="text"
            error={error?.fullName?.message}
            defaultValue={defaultValues?.fullName}
            onInput={() => setDirty(true)}
            {...register(`customerInfo.${index}.fullName`, {
              setValueAs: (v) => v.trim(),
            })}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
            <DropdownField
              id={`customer-mobile-dial-code-${index}`}
              key={`customer-mobile-dial-code-${index}`}
              label="Dial Code"
              error={error?.phoneCode?.message}
              items={Country.countryData.map((country) =>
                listDropdownPhoneNumbers(country)
              )}
              placeholder="Select Mobile Dial Code"
              searchable="true"
              dynamicWidth={true}
              containerClassName="w-2/6"
              listClassName="min-w-[420px]"
              showValue={true}
              defaultValue={
                defaultValues?.phoneCode?.toString() ||
                customerInfo?.phoneCode ||
                ""
              }
              {...register(`customerInfo.${index}.phoneCode`, {
                value:
                  defaultValues?.phoneCode?.toString() ||
                  customerInfo?.phoneCode ||
                  "",
                onChange: () => setDirty(true),
              })}
            />
            <TextField
              id={`customer-mobile-contact-number-${index}`}
              label="Contact No. (Mobile)"
              type="tel"
              dynamicWidth={true}
              containerClassName="w-4/6"
              error={error?.phoneNumber?.message}
              defaultValue={defaultValues?.phoneNumber}
              onInput={() => setDirty(true)}
              {...register(`customerInfo.${index}.phoneNumber`, {
                setValueAs: (v) => v.trim(),
              })}
            />
          </div>

          <TextField
            id={`customer-nric-number-${index}`}
            label="IC No./Passport No"
            type="text"
            required={true}
            error={error?.identificationNumber?.message}
            defaultValue={defaultValues?.identificationNumber}
            onInput={() => setDirty(true)}
            {...register(`customerInfo.${index}.identificationNumber`, {
              setValueAs: (v) => v.trim(),
            })}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
            <DropdownField
              id={`customer-office-dial-code-${index}`}
              key={`customer-office-dial-code-${index}`}
              label="Dial Code"
              required={false}
              error={error?.officePhoneCode?.message}
              items={Country.countryData.map((country) =>
                listDropdownPhoneNumbers(country)
              )}
              placeholder="Select Office Dial Code"
              searchable="true"
              dynamicWidth={true}
              containerClassName="w-2/6"
              listClassName="min-w-[420px]"
              showValue={true}
              defaultValue={
                defaultValues?.officePhoneCode?.toString() ||
                customerInfo?.officePhoneCode ||
                ""
              }
              {...register(`customerInfo.${index}.officePhoneCode`, {
                value:
                  defaultValues?.officePhoneCode?.toString() ||
                  customerInfo?.officePhoneCode ||
                  "",
                onChange: () => setDirty(true),
              })}
            />
            <TextField
              id={`customer-office-contact-number-${index}`}
              label="Contact No. (Office)"
              type="tel"
              required={false}
              dynamicWidth={true}
              containerClassName="w-4/6"
              error={error?.officePhoneNumber?.message}
              defaultValue={defaultValues?.officePhoneNumber}
              onInput={() => setDirty(true)}
              {...register(`customerInfo.${index}.officePhoneNumber`, {
                setValueAs: (v) => v.trim(),
              })}
            />
          </div>

          <TextField
            id={`customer-email-${index}`}
            label="Email"
            type="email"
            required={true}
            error={error?.email?.message}
            defaultValue={defaultValues?.email}
            onInput={() => setDirty(true)}
            {...register(`customerInfo.${index}.email`, {
              setValueAs: (v) => v.trim().toLowerCase(),
            })}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <DropdownField
            id={`customer-gender-${index}`}
            key={`customer-gender-${index}`}
            label="Gender"
            placeholder="Select Gender"
            error={error?.gender?.message}
            items={["male", "female"].map((gender) => {
              return { text: capitalize(gender), value: gender };
            })}
            defaultValue={defaultValues?.gender || customerInfo?.gender || ""}
            {...register(`customerInfo.${index}.gender`, {
              value: defaultValues?.gender || customerInfo?.gender || "",
              onChange: () => setDirty(true),
            })}
          />
          <DatePickerField
            id={`customer-date-of-birth-${index}`}
            label="Date of Birth"
            required={true}
            error={error?.birthDate?.message}
            max={now}
            defaultValue={defaultValues?.birthDate}
            onInput={() => setDirty(true)}
            {...register(`customerInfo.${index}.birthDate`)}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <RadioGroup
            onChange={(status) => {
              setDirty(true);
              onBumiStatusChanged(status as string);
            }}
            defaultValue={bumiStatus}
            label="Bumiputera Status"
            required={true}
          >
            <RadioField
              id={`customer-is-bumiputera-${index}`}
              key={`customer-is-bumiputera-${index}`}
              label="Yes"
              value="yes"
              innerClassName="pl-0 py-0"
            />
            <RadioField
              id={`customer-non-bumiputera-${index}`}
              key={`customer-non-bumiputera-${index}`}
              label="No"
              value="no"
              innerClassName="pl-10 py-0"
            />
          </RadioGroup>

          <DropdownField
            id={`customer-origin-country-${index}`}
            key={`customer-origin-country-${index}`}
            label="Country of Origin"
            error={error?.countryOfOrigin?.message}
            placeholder="Select Country of Origin"
            items={Country.countryData.map((country) => {
              return {
                text: country.name,
                value: country.alpha2Code,
              };
            })}
            searchable="true"
            defaultValue={
              defaultValues?.country || customerInfo?.countryOfOrigin || ""
            }
            {...register(`customerInfo.${index}.countryOfOrigin`, {
              value:
                defaultValues?.country || customerInfo?.countryOfOrigin || "",
              onChange: () => setDirty(true),
            })}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <DropdownField
            id={`customer-race-${index}`}
            key={`customer-race-${index}`}
            label="Race"
            placeholder="Select Race"
            error={error?.race?.message}
            items={["malay", "chinese", "indian", "other"].map((race) => {
              return { text: capitalize(race), value: race };
            })}
            defaultValue={
              defaultValues?.race
                ? !defaultRaces.includes(defaultValues.race as Race)
                  ? "other"
                  : defaultValues.race
                : customerInfo?.race || ""
            }
            {...register(`customerInfo.${index}.race`, {
              value: defaultValues?.race
                ? !defaultRaces.includes(defaultValues.race as Race)
                  ? "other"
                  : defaultValues.race
                : customerInfo?.race || "",
              onChange: () => setDirty(true),
            })}
          />

          {generateOtherRaceField()}
        </div>
      </div>

      <Line className="mb-8" />

      <div className="col gap-5 mb-9">
        <AddressForm
          index={index}
          fieldPrefix={`customerInfo.${index}`}
          validation={validation}
          error={error}
          defaultValues={defaultValues?.address}
          setDirty={setDirty}
        />
      </div>

      <Line className="mb-8" />

      <div className="col">
        <FileUploadForm
          id={`${value}-uploader`}
          files={proofOfIdentities}
          title="Proof of Identity"
          validation={validation}
          error={error?.attachments}
          fieldPrefix={`customerInfo.${index}`}
          dispatchFiles={dispatchProofOfIdentities}
        />
      </div>
    </ExpandableCard>
  );
});

export default CustomerFullInfoAccordian;
