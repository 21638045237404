class Building {
  static readonly allUnitTypes: any = {
    A: [
      { name: "A / 1 Bedroom / 700 SF", value: "A", type: "A" },
      { name: "B / 1 + 1 Bedrooms / 756 SF", value: "B", type: "B" },
      { name: "B1 / 1 + 1 Bedrooms / 771 SF", value: "B1", type: "B" },
      { name: "B2 / 1 + 1 Bedrooms / 742 SF", value: "B2", type: "B" },
      { name: "C / 2 Bedrooms / 934 SF", value: "C", type: "C" },
      { name: "C1 / 2 Bedrooms / 943 SF", value: "C1", type: "C" },
      { name: "C2 / 2 Bedrooms / 946 SF", value: "C2", type: "C" },
      { name: "C3 / 2 Bedrooms / 1086 SF", value: "C3", type: "C" },
      { name: "D / 2 + 1 Bedrooms / 1073 SF", value: "D", type: "D" },
      { name: "D1 / 2 + 1 Bedrooms / 1150 SF", value: "D1", type: "D" },
      { name: "E / 3 Bedrooms / 1308 SF", value: "E", type: "E" },
    ],
    B: [
      { name: "A1 / 1 Bedroom / 705 SF", value: "A1", type: "A" },
      { name: "B3 / 1 + 1 Bedrooms / 747 SF", value: "B3", type: "B" },
      { name: "B4 / 1 + 1 Bedrooms / 751 SF", value: "B4", type: "B" },
      { name: "B5 / 1 + 1 Bedrooms / 776 SF", value: "B5", type: "B" },
      { name: "C4 / 2 Bedrooms / 945 SF", value: "C4", type: "C" },
      { name: "C5 / 2 Bedrooms / 949 SF", value: "C5", type: "C" },
      { name: "C6 / 2 Bedrooms / 960 SF", value: "C6", type: "C" },
      { name: "C7 / 2 Bedrooms / 1098 SF", value: "C7", type: "C" },
      { name: "D2 / 2 + 1 Bedrooms / 1095 SF", value: "D2", type: "D" },
      { name: "D3 / 2 + 1 Bedrooms / 1140 SF", value: "D3", type: "D" },
      { name: "D4 / 2 + 1 Bedrooms / 1147 SF", value: "D4", type: "D" },
      { name: "D5 / 2 + 1 Bedrooms / 1158 SF", value: "D5", type: "D" },
      { name: "D6 / 2 + 1 Bedrooms / 1155 SF", value: "D6", type: "D" },
      { name: "E1 / 3 Bedrooms / 1328 SF", value: "E1", type: "E" },
    ],
  };

  static readonly allOrientations: any = {
    A: [
      { name: "South East (RSGC View)", value: "SE" },
      { name: "North West (Pool & KLCC View)", value: "NW" },
      { name: "West (KL Tower View)", value: "W" },
    ],
    B: [
      { name: "South East (Pool, Landscape & RSGC View)", value: "SE" },
      { name: "North West (Pool & KLCC View)", value: "NW" },
      { name: "East (RSGC)", value: "E" },
    ],
  };

  static readonly allThemes: any = {
    A: ["Water", "Wood"],
    B: ["Urban", "Spring"],
  };

  static readonly allStatuses: string[] = [
    "Available",
    "Reserved",
    "Blocked",
    "Booked",
    "Sold",
  ];

  static readonly allNoOfBedrooms: any = [
    { name: "1 Bedroom", value: 1 },
    { name: "2 Bedrooms", value: 2 },
    { name: "3 Bedrooms ", value: 3 },
  ];

  static readonly allFloorLevels: any = [
    { name: "Low (Between 10th to 24th floors)", value: "low" },
    { name: "Mid (Between 27th to 42nd floors)", value: "mid" },
    { name: "High (45th floor and above)", value: "high" },
  ];
}

export const oneBedroomUnits = ["A", "A1", "B", "B1", "B2", "B3", "B4", "B5"];
export const twoBedroomsUnits = [
  "C",
  "C1",
  "C2",
  "C3",
  "C4",
  "C5",
  "C6",
  "C7",
  "D",
  "D1",
  "D2",
  "D3",
  "D4",
  "D5",
  "D6",
];
export const threeBedroomsUnits = ["E", "E1"];

export default Building;
