import classNames from "classnames";
import React from "react";

interface BottomBarProps {
  show?: boolean;
  innerClassName?: string;
}

const BottomBar: React.FC<BottomBarProps> = ({
  show = true,
  innerClassName = "",
  children,
}) => {
  return (
    <div
      className={classNames(
        "z-40 bottom-0 bg-white h-14 w-full sticky row items-center justify-end px-7 py-3 border-t-[1px] border-[#53565A33]",
        {
          hidden: !show,
        },
        innerClassName
      )}
    >
      {children}
    </div>
  );
};

export default BottomBar;
