export const paymentMethods = ["loan", "cash", "ksk-credit"] as const;

export const salesPackages = ["higher_rebate", "grr", "capp30"] as const;

export const rebateTypes = ["percentage", "nett-selling-price"] as const;

export const grrRatesSelection = [4, 5, 6, 7] as const;

export const grrYearsSelection = [1, 2, 3] as const;

export type PaymentMethodType = typeof paymentMethods[number];

export const paymentMethodTypeName = (paymentMethodType: PaymentMethodType) => {
  const paymentMethodTypeNames = {
    loan: "Loan",
    cash: "Cash",
    "ksk-credit": "KSK Credit",
  };

  return paymentMethodTypeNames[paymentMethodType];
};

export type SalesPackageType = typeof salesPackages[number];

export const salesPackageName = (salesPackage: SalesPackageType) => {
  const salesPackageNames = {
    higher_rebate: "Higher Rebate",
    grr: "Guaranteed Rental Return (GRR)",
    capp30: "CAPP30",
  };

  return salesPackageNames[salesPackage];
};

export type RebateType = typeof rebateTypes[number];

export const rebateTypeName = (rebateType: RebateType) => {
  const rebateTypeNames = {
    percentage: "Percentage",
    "nett-selling-price": "Nett Selling Price",
  };

  return rebateTypeNames[rebateType];
};

export type PackageableType =
  | "App\\Models\\HigherRebate"
  | "App\\Models\\GuaranteedRentalReturn"
  | "App\\Models\\Capp30";

export const packageableType = (type: PackageableType) => {
  const packageableTypes = {
    "App\\Models\\HigherRebate": "higher_rebate",
    "App\\Models\\GuaranteedRentalReturn": "grr",
    "App\\Models\\Capp30": "capp30",
  };

  return packageableTypes[type] as SalesPackageType;
};
