import { createContext, useContext, useState } from "react";
import api from "../../services/PabloApiService";
import { User } from "../../common/Types";
import {
  IndexUserResponse,
  StoreUserResponse,
  UpdateUserResponse,
} from "../../common/Responses";
import { roleName } from "../../common/Permission";

type UserListContextType = {
  users: User[];
  isLoading: boolean;
  index: () => Promise<boolean>;
  search: (keyword: string) => void;
  store: (body: any) => Promise<boolean>;
  update: (id: number, body: any) => Promise<boolean>;
  destroy: (id: number) => Promise<boolean>;
};

const defaultContext: UserListContextType = {
  users: [],
  isLoading: true,
  index: async () => false,
  search: (keyword: string) => {},
  store: async (body: any) => false,
  update: async (id: number, body: any) => false,
  destroy: async (id: number) => false,
};

const UserListContext = createContext<UserListContextType>(defaultContext);

export const useUserListContext = () => useContext(UserListContext);

export const UserListContextProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [tempUsers, setTempUsers] = useState<User[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const index = async (): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.get<IndexUserResponse>("/users");

    if (success) {
      setUsers(data.users);
      setTempUsers(data.users);
    }

    setLoading(false);

    return success;
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const results = tempUsers.filter((user) => {
        const sameName = user.fullName.toLowerCase().includes(formattedKeyword);
        const sameEmail = user.email.toLowerCase().includes(formattedKeyword);
        const sameRole = roleName(user?.roles?.[0] || "")
          .toLowerCase()
          .includes(formattedKeyword);
        return sameRole || sameName || sameEmail;
      });

      setUsers(results);
    } else {
      setUsers(tempUsers);
    }
  };

  const store = async (body: any): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.post<StoreUserResponse>("/users", body);

    if (success) {
      let temp = users.slice();
      temp.push(data.user);
      setUsers(temp);
    }

    setLoading(false);

    return success;
  };

  const update = async (id: number, body: any): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.patch<UpdateUserResponse>(
      `/users/${id}`,
      body
    );

    if (success) {
      let temp = users.slice();
      const updatingUser = temp.filter((user) => user.id === id)[0];
      const userIndex = temp.indexOf(updatingUser);
      temp[userIndex] = data.user;
      setUsers(temp);
    }

    setLoading(false);

    return success;
  };

  const destroy = async (id: number): Promise<boolean> => {
    setLoading(true);

    const { success } = await api.delete(`/users/${id}`);

    if (success) {
      const temp = users.slice();
      const updatedUsers = temp.filter((item: User) => item.id !== id);
      setUsers(updatedUsers);
      setTempUsers(updatedUsers);
    }

    setLoading(false);

    return success;
  };

  return (
    <UserListContext.Provider
      value={{ users, isLoading, index, search, store, update, destroy }}
    >
      {children}
    </UserListContext.Provider>
  );
};
