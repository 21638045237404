import classNames from "classnames";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import { TaskType } from "../../common/Types/DragAndDrop";
import { today } from "../../utils/TimeDateUtils";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import Avatar from "../Images/Avatar";

interface TaskProps {
  task: TaskType;
  index: number;
}

const Task: React.FC<TaskProps> = (props) => {
  const { task, index } = props;
  const navigate = useNavigate();

  const isDue = moment(task.content.dueDate).isSameOrBefore(today);

  const handlePriorityIcon = (priority: string): string => {
    let icon = Icon.priorityMedium;

    switch (priority) {
      case "lowest":
        icon = Icon.priorityLowest;
        break;

      case "low":
        icon = Icon.priorityLow;
        break;

      case "medium":
        icon = Icon.priorityMedium;
        break;

      case "high":
        icon = Icon.priorityHigh;
        break;

      case "highest":
        icon = Icon.priorityHighest;
        break;

      default:
        break;
    }

    return icon;
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={classNames(
            "col p-2 mb-2  hover:bg-ink-well transition-all duration-200 text-xs h-24 select-none",
            {
              "bg-[#F9F9F9]": !snapshot.isDragging,
              "bg-ink-well": snapshot.isDragging,
            }
          )}
          onClick={() => navigate(Path.editLeadDetails(task.id))}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="row">{task.content.name}</div>
          <div className="row mt-3">
            <div
              className={classNames("py-[2px] px-[10px]", {
                "font-normal bg-white rounded-full text-[0.625rem]":
                  task.content.country,
                "h-5": !task.content.country,
              })}
            >
              {task.content.country}
            </div>
          </div>
          <div className="row flex justify-between gap-2 mt-2">
            <div className={classNames("flex flex-grow")}>
              <div
                className={classNames(
                  "py-[2px] px-[10px] flex justify-between items-center gap-1",
                  {
                    "font-normal rounded-full text-[0.625rem] uppercase":
                      task?.content?.dueDate,
                    "bg-white": task?.content?.dueDate && !isDue,
                    "bg-light-red text-primary":
                      task?.content?.dueDate && isDue,
                  }
                )}
              >
                {task?.content?.dueDate && (
                  <>
                    <div className="">
                      <PabloIcon
                        icon={isDue ? Icon.clockDue : Icon.clock}
                        className="w-[12px] h-[12px]"
                      />
                    </div>
                    <div>
                      {moment(task.content.dueDate).format("DD MMM YYYY")}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-shrink">
              <PabloIcon
                icon={handlePriorityIcon(task.content.priority)}
                className="w-[20px] h-[20px]"
              />
            </div>
            <div className="flex flex-shrink">
              <Avatar user={task.content.salesPic} size="extra-small" />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Task;
