import { array, number, object, SchemaOf } from "yup";
import { salutations } from "../common/Enums";
import { IFormSchema } from "../common/Interfaces";
import { paymentStructures } from "../common/PaymentStructure";
import {
  grrRatesSelection,
  grrYearsSelection,
  rebateTypes,
  salesPackages,
} from "../common/SalesPackage";
import {
  FlexibleInstalmentDetails,
  GrrValue,
  Hook,
  InstalmentFormDetails,
} from "../common/Types";
import { baseString } from "../utils/SchemaUtil";

export interface BookingForm extends IFormSchema {
  buyerType: string;
  customerInfo?: {
    id?: number;
    salutation?: string;
    fullName?: string;
    phoneCode?: string;
    phoneNumber?: string;
    officePhoneCode?: string;
    officePhoneNumber?: string;
    email?: string;
    birthDate?: string;
    identificationNumber?: string;
    countryOfOrigin?: string;
    gender?: string;
    bumiputera?: string;
    race?: string;
    otherRace?: string;
    addressInfo?: {
      addressLine1?: string;
      addressLine2?: string;
      postCode?: string;
      city?: string;
      state?: string;
      country?: string;
    };
    attachments?: any[];
  }[];
  companyInfo?: {
    id?: number;
    companyName?: string;
    companyRegistrationNumber?: string;
    companyPhoneCode?: string;
    companyPhoneNumber?: string;
    addressInfo?: {
      addressLine1?: string;
      addressLine2?: string;
      postCode?: string;
      city?: string;
      state?: string;
      country?: string;
    };
    attachments?: any[];
    personalInfo?: {
      id?: number;
      salutation?: string;
      fullName?: string;
      phoneCode?: string;
      phoneNumber?: string;
      email?: string;
      identificationNumber?: string;
      title?: string;
      attachments?: any[];
    }[];
  };
  bookingInfo: {
    bookingReceived: number;
    paymentMethod: string;
    currencyCode: string;
    attachments: any[];
    remarks?: string;
  }[];
}

export const schema: SchemaOf<BookingForm> = object().shape({
  buyerType: baseString().required().label("Buyer type").default("individual"),
  customerInfo: array().when("buyerType", {
    is: "individual",
    then: array()
      .of(
        object().shape({
          id: number(),
          salutation: baseString()
            .oneOf(Array.from(salutations))
            .required()
            .label("Salutation"),
          fullName: baseString().required().label("Customer name"),
          phoneCode: baseString().required().label("Dial code"),
          phoneNumber: baseString()
            .trim()
            .matches(/^[0-9]*$/, "Contact number must be in numbers.")
            .required()
            .label("Contact number"),
          officePhoneCode: baseString().label("Office dial code"),
          officePhoneNumber: baseString()
            .trim()
            .matches(/^[0-9]*$/, "Contact number must be in numbers.")
            .label("Contact number"),
          email: baseString()
            .trim()
            .matches(
              /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
              "Email format is invalid"
            )
            .required()
            .label("Email"),
          birthDate: baseString().required().label("Birth date"),
          identificationNumber: baseString()
            .required()
            .label("Identification number"),
          countryOfOrigin: baseString().required().label("Country of origin"),
          gender: baseString()
            .oneOf(Array.from(["male", "female"]))
            .required()
            .label("Gender"),
          bumiputera: baseString()
            .oneOf(Array.from(["yes", "no"]))
            .required()
            .label("Bumiputera status")
            .default("no"),
          race: baseString()
            .oneOf(Array.from(["malay", "chinese", "indian", "other"]))
            .required()
            .label("Race"),
          otherRace: baseString().when("race", {
            is: "other",
            then: baseString().required().label("Race"),
          }),
          addressInfo: object().shape({
            addressLine1: baseString()
              .required()
              .label("Address line 1")
              .max(255),
            addressLine2: baseString().label("Address line 2").max(255),
            postCode: baseString().required().label("Postal code").max(32),
            city: baseString().required().label("City").max(255),
            state: baseString().required().label("State").max(255),
            country: baseString().required().label("Country"),
          }),
          attachments: array().min(1).required().label("Proof of identity"),
        })
      )
      .default([0])
      .ensure(),
  }),
  companyInfo: object().when("buyerType", {
    is: "company",
    then: object().shape({
      id: number(),
      companyName: baseString()
        .trim()
        .max(255)
        .required()
        .label("Company name"),
      companyRegistrationNumber: baseString()
        .required()
        .label("Company registration number"),
      companyPhoneCode: baseString().required().label("Company dial code"),
      companyPhoneNumber: baseString()
        .trim()
        .matches(/^[0-9]*$/, "Company contact number must be in numbers.")
        .required()
        .label("Company contact number"),
      addressInfo: object().shape({
        addressLine1: baseString().required().label("Address line 1").max(255),
        addressLine2: baseString().label("Address line 2").max(255),
        postCode: baseString().required().label("Postal code").max(32),
        city: baseString().required().label("City").max(255),
        state: baseString().required().label("State").max(255),
        country: baseString().required().label("Country"),
      }),
      attachments: array().min(1).required().label("Company superform"),
      personalInfo: array()
        .of(
          object().shape({
            id: number(),
            salutation: baseString()
              .oneOf(Array.from(salutations))
              .required()
              .label("Salutation"),
            fullName: baseString().required().label("Customer name"),
            phoneCode: baseString().required().label("Dial code"),
            phoneNumber: baseString()
              .trim()
              .matches(/^[0-9]*$/, "Contact number must be in numbers.")
              .required()
              .label("Contact number"),
            email: baseString()
              .trim()
              .matches(
                /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
                "Email format is invalid"
              )
              .required()
              .label("Email"),
            identificationNumber: baseString()
              .required()
              .label("Identification number"),
            title: baseString().required().label("Job title"),
            attachments: array().min(1).required().label("Proof of identity"),
          })
        )
        .default([0])
        .ensure(),
    }),
  }),
  bookingInfo: array()
    .of(
      object().shape({
        bookingReceived: number()
          .typeError("Booking amount received must contain a number.")
          .test(
            "is-two-decimal",
            "Booking amount must contain 2 decimal places only",
            (val: any) => {
              const regex = /^(?:[1-9]\d+|\d)(?:\.(\d\d|\d))?$/;

              if (val) {
                return regex.test(val);
              }

              return true;
            }
          )
          .min(0)
          .max(2000000000)
          .required()
          .label("Booking amount received"),
        paymentMethod: baseString().required().label("Payment method"),
        currencyCode: baseString().required().label("Currency"),
        attachments: array().min(1).required().label("Proof of payment"),
        remarks: baseString().label("Remarks").max(500),
      })
    )
    .default([0])
    .ensure(),
});

export interface BookingFormSalesPackage extends IFormSchema {
  salesPackage: {
    selectedSalesPackage: string;
    paymentMethod: string;
    rebateType: string;
    rebate: number;
    nettSellingPrice: number;
    grrValues?: GrrValue;
    hooks?: Hook[];
    remarks?: string;
  };
  paymentStructure: {
    type: string;
    fixedInstalmentDetails?: InstalmentFormDetails;
    flexibleInstalmentDetails?: FlexibleInstalmentDetails[];
  };
}

export const salesPackageSchema: SchemaOf<BookingFormSalesPackage> =
  object().shape({
    salesPackage: object().shape({
      paymentMethod: baseString().required(),
      selectedSalesPackage: baseString()
        .oneOf(Array.from(salesPackages))
        .required(),
      rebateType: baseString().oneOf(Array.from(rebateTypes)).required(),
      rebate: number()
        .required()
        .default(0)
        .min(0)
        .max(50, "Nett selling price cannot be 50% lower than the gross price"),
      nettSellingPrice: number()
        .required()
        .min(1, "Nett selling price cannot be 0")
        .label("Nett selling price"),
      grrValues: object().when("selectedSalesPackage", {
        is: "grr",
        then: object().shape({
          rate: number().oneOf(Array.from(grrRatesSelection)).required(),
          year: number().oneOf(Array.from(grrYearsSelection)).required(),
        }),
      }),
      hooks: array()
        .of(
          object().shape({
            item: baseString().label("Item"),
            value: number().when("item", {
              is: (value: string) => !!value,
              then: number()
                .min(1)
                .label("Value")
                .required()
                .transform((currentValue, originalValue) =>
                  originalValue === "" ? null : currentValue
                )
                .typeError("Must be a number"),
              otherwise: number()
                .min(1)
                .label("Value")
                .transform((currentValue, originalValue) =>
                  originalValue === "" ? null : currentValue
                )
                .nullable()
                .typeError("Must be a number"),
            }),
          })
        )
        .default([0])
        .ensure(),
      remarks: baseString().label("Remarks").max(500),
    }),
    paymentStructure: object().shape({
      type: baseString()
        .oneOf(Array.from(paymentStructures))
        .required()
        .label("Payment structure type")
        .default(paymentStructures[0]),
      fixedInstalmentDetails: object().when("type", {
        is: "instalment",
        then: object().shape({
          initialPayment: number()
            .required()
            .typeError("Initial amount must be a number.")
            .positive()
            .integer()
            .min(1)
            .label("Initial amount"),
          instalmentPayment: number()
            .typeError("Instalment amount must be a number")
            .default(0)
            .transform((currentValue, originalValue) =>
              originalValue === "" ? 0 : currentValue
            )
            .nullable()
            .label("Instalment amount"),
          outstandingPayment: number()
            .typeError("Outstanding amount must be a number.")
            .default(0)
            .transform((currentValue, originalValue) =>
              originalValue === "" ? 0 : currentValue
            )
            .nullable()
            .label("Outstanding amount"),
        }),
      }),
      flexibleInstalmentDetails: array().when("type", {
        is: "flexible-instalment",
        then: array().of(
          object().shape({
            item: baseString().required().label("Item"),
            datePayable: baseString().required().label("Date payable"),
            amountPayable: number()
              .required()
              .label("Amount payable")
              .typeError("Amount payable must be a number."),
            remarks: baseString().nullable().label("Remarks"),
          })
        ),
      }),
    }),
  });

export interface GenerateLettersForm extends IFormSchema {
  buyerType: string;
  customerInfo?: {
    id?: number;
    salutation?: string;
    fullName?: string;
    phoneCode?: string;
    phoneNumber?: string;
    officePhoneCode?: string;
    officePhoneNumber?: string;
    email?: string;
    birthDate?: string;
    identificationNumber?: string;
    countryOfOrigin?: string;
    gender?: string;
    bumiputera?: string;
    race?: string;
    otherRace?: string;
    addressInfo?: {
      addressLine1?: string;
      addressLine2?: string;
      postCode?: string;
      city?: string;
      state?: string;
      country?: string;
    };
    attachments?: any[];
  }[];
  companyInfo?: {
    id?: number;
    companyName?: string;
    companyRegistrationNumber?: string;
    companyPhoneCode?: string;
    companyPhoneNumber?: string;
    addressInfo?: {
      addressLine1?: string;
      addressLine2?: string;
      postCode?: string;
      city?: string;
      state?: string;
      country?: string;
    };
    attachments?: any[];
    personalInfo?: {
      id?: number;
      salutation?: string;
      fullName?: string;
      phoneCode?: string;
      phoneNumber?: string;
      email?: string;
      identificationNumber?: string;
      title?: string;
      attachments?: any[];
    }[];
  };
}

export const generateLettersSchema: SchemaOf<GenerateLettersForm> =
  object().shape({
    buyerType: baseString()
      .required()
      .label("Buyer type")
      .default("individual"),
    customerInfo: array().when("buyerType", {
      is: "individual",
      then: array()
        .of(
          object().shape({
            id: number(),
            salutation: baseString()
              .oneOf(Array.from(salutations))
              .required()
              .label("Salutation"),
            fullName: baseString().required().label("Customer name"),
            phoneCode: baseString().required().label("Dial code"),
            phoneNumber: baseString()
              .trim()
              .matches(/^[0-9]*$/, "Contact number must be in numbers.")
              .required()
              .label("Contact number"),
            officePhoneCode: baseString().label("Office dial code"),
            officePhoneNumber: baseString()
              .trim()
              .matches(/^[0-9]*$/, "Contact number must be in numbers.")
              .label("Contact number"),
            email: baseString()
              .trim()
              .matches(
                /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
                "Email format is invalid"
              )
              .required()
              .label("Email"),
            birthDate: baseString().required().label("Birth date"),
            identificationNumber: baseString()
              .required()
              .label("Identification number"),
            countryOfOrigin: baseString().required().label("Country of origin"),
            gender: baseString()
              .oneOf(Array.from(["male", "female"]))
              .required()
              .label("Gender"),
            bumiputera: baseString()
              .oneOf(Array.from(["yes", "no"]))
              .required()
              .label("Bumiputera status")
              .default("no"),
            race: baseString()
              .oneOf(Array.from(["malay", "chinese", "indian", "other"]))
              .required()
              .label("Race"),
            otherRace: baseString().when("race", {
              is: "other",
              then: baseString().required().label("Race"),
            }),
            addressInfo: object().shape({
              addressLine1: baseString()
                .required()
                .label("Address line 1")
                .max(255),
              addressLine2: baseString().label("Address line 2").max(255),
              postCode: baseString().required().label("Postal code").max(32),
              city: baseString().required().label("City").max(255),
              state: baseString().required().label("State").max(255),
              country: baseString().required().label("Country"),
            }),
            attachments: array().min(1).required().label("Proof of identity"),
          })
        )
        .default([0])
        .ensure(),
    }),
    companyInfo: object().when("buyerType", {
      is: "company",
      then: object().shape({
        id: number(),
        companyName: baseString()
          .trim()
          .max(255)
          .required()
          .label("Company name"),
        companyRegistrationNumber: baseString()
          .required()
          .label("Company registration number"),
        companyPhoneCode: baseString().required().label("Company dial code"),
        companyPhoneNumber: baseString()
          .trim()
          .matches(/^[0-9]*$/, "Company contact number must be in numbers.")
          .required()
          .label("Company contact number"),
        addressInfo: object().shape({
          addressLine1: baseString()
            .required()
            .label("Address line 1")
            .max(255),
          addressLine2: baseString().label("Address line 2").max(255),
          postCode: baseString().required().label("Postal code").max(32),
          city: baseString().required().label("City").max(255),
          state: baseString().required().label("State").max(255),
          country: baseString().required().label("Country"),
        }),
        attachments: array().min(1).required().label("Company superform"),
        personalInfo: array()
          .of(
            object().shape({
              id: number(),
              salutation: baseString()
                .oneOf(Array.from(salutations))
                .required()
                .label("Salutation"),
              fullName: baseString().required().label("Customer name"),
              phoneCode: baseString().required().label("Dial code"),
              phoneNumber: baseString()
                .trim()
                .matches(/^[0-9]*$/, "Contact number must be in numbers.")
                .required()
                .label("Contact number"),
              email: baseString()
                .trim()
                .matches(
                  /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
                  "Email format is invalid"
                )
                .required()
                .label("Email"),
              identificationNumber: baseString()
                .required()
                .label("Identification number"),
              title: baseString().required().label("Title"),
              attachments: array().min(1).required().label("Proof of identity"),
            })
          )
          .default([0])
          .ensure(),
      }),
    }),
  });
