import React, { Fragment } from "react";
import { ApprovalCategory } from "../../common/Approval";
import {
  Approval,
  ApprovalHistory,
  OpportunitySource,
  SalesPackage,
  Record,
} from "../../common/Types";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import SquareButton from "../Buttons/SquareButton";
import Icon from "../Icons/Icon";
import ManagementApprovalTabs from "./ManagementApprovalTabs";

interface ManagementApprovalHistoryProps {
  approvals?: Approval[];
  tower?: string;
  opportunitySource?: OpportunitySource;
  approvalHistory?: ApprovalHistory;
}

const ManagementApprovalHistory: React.FC<ManagementApprovalHistoryProps> = (
  props
) => {
  const { approvals, tower, opportunitySource, approvalHistory } = props;

  const modal = useModalContext();
  const status = approvals?.some((approval) => approval?.status === "approved")
    ? "approved"
    : approvals?.some((approval) => approval?.status === "declined")
    ? "declined"
    : "";

  const hasValidApproval = (approval: Approval) => {
    return !!(approval?.status && approval?.givenBy && approval?.givenAt);
  };

  const handleHistoryModal = (
    categories: ApprovalCategory[],
    salesPackage?: SalesPackage,
    record?: Record
  ) => {
    modal.view({
      title: "Approval Summary",
      containerClassName: "w-10/12 h-[90%]",
      modalClassName: "w-full p-5",
      content: (
        <ManagementApprovalTabs
          tower={tower}
          salesPackage={salesPackage}
          opportunitySource={opportunitySource}
          approvalCategories={categories}
          unitDiscountChart={record?.recordable?.unitDiscount}
          reviewDevelopmentImpact={record?.recordable?.developmentImpact}
          reviewPriceDistribution={{
            averageUnsoldGrossPricePsf: record?.recordable?.avgGrossPsf || 0,
            averageUnsoldGrossPrice: record?.recordable?.avgGrossAbs || 0,
            unsoldUnitPricePsf: record?.recordable
              ?.unsoldPricePsfCoordinates || {
              current: [],
              postApproval: [],
            },
            unsoldUnitPrice: record?.recordable?.unsoldPriceAbsCoordinates || {
              current: [],
              postApproval: [],
            },
          }}
        />
      ),
    });
  };

  return (
    <>
      {approvals?.map((approval, approvalIndex) => {
        return (
          <Fragment key={`history-item-${approvalIndex}`}>
            {approvalIndex === 0 && status && (
              <tr className="border-none">
                <th className="uppercase text-sm font-normal p-0 w-1/4">
                  {`${status} By`}
                </th>
                <th className="uppercase text-sm font-normal p-0 w-1/4">
                  {`${status} At`}
                </th>
                <th className="text-sm font-normal p-0">
                  {approval.status === "declined" && "Remarks"}
                </th>
                <th className="p-0 w-[20px]" />
              </tr>
            )}

            {hasValidApproval(approval) &&
              (approval?.status === "approved" ||
                (approvalIndex === 0 && approval?.status === "declined")) && (
                <tr className="h-6 border-none">
                  <td className="text-sm font-bold px-0">
                    {approval?.givenBy?.fullName}
                  </td>
                  <td className="text-sm font-bold px-0">
                    {approval?.givenAt}
                  </td>
                  <td className="text-sm font-bold px-0 whitespace-normal">
                    {approval.status === "declined" &&
                      (approval?.remarks || "--")}
                  </td>
                  <td className="text-sm font-bold px-0">
                    {approvalHistory && (
                      <SquareButton
                        onclick={() =>
                          handleHistoryModal(
                            approval?.categories,
                            approvalHistory?.historicalSalesPackage,
                            approval?.record
                          )
                        }
                        className="float-right"
                        icon={Icon.eye}
                      />
                    )}
                  </td>
                </tr>
              )}
          </Fragment>
        );
      })}
    </>
  );
};

export default ManagementApprovalHistory;
