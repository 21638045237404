/* eslint-disable react-hooks/exhaustive-deps */
import {
  bookingInfoPaymentMethods,
  paymentMethodName,
  supportedCurrencies,
} from "../../common/Booking";
import { Attachment, PaymentDetail } from "../../common/Types";
import { useArray } from "../../hooks/UseArray";
import { Validation } from "../../hooks/UseValidation";
import DropdownField from "../Fields/DropdownField";
import TextField from "../Fields/TextField";
import Line from "../General/Line";
import Textarea from "../General/Textarea";
import FileUploadForm from "../Forms/FileUploadForm";
import {
  ExpandableCard,
  ExpandableCardValidated,
} from "../Cards/ExpandableCard";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { clearAttachments } from "../../utils/SchemaUtil";
import { IFormSchema } from "../../common/Interfaces";

interface BookingInfoAccordianProps<T extends IFormSchema> {
  value: string;
  title: string;
  index: number;
  defaultValues?: PaymentDetail;
  showRequired?: boolean;
  onDeleteAction?: () => void;
  validation: Validation<T>;
  className?: string;
  setDirty: Function;
}

const BookingInfoAccordian = forwardRef<any, BookingInfoAccordianProps<any>>(
  (props, ref) => {
    const {
      value,
      title,
      index,
      defaultValues,
      showRequired,
      validation,
      className,
      setDirty,
      onDeleteAction,
    } = props;
    const bookingInfo = validation.watch(`bookingInfo.${index}`);
    const error = validation?.errors?.bookingInfo?.[index];

    const [proofOfPayment, dispatchProofOfPayment] = useArray<Attachment>(
      defaultValues?.attachments?.map((attachment) => {
        return {
          id: attachment.id,
          name: attachment.name,
          blob: new Blob([attachment.path], {
            type: attachment.path.includes(".pdf") ? "pdf" : "image",
          }),
          blobPath: attachment.path,
        };
      }) || []
    );
    const [validated, setValidated] =
      useState<ExpandableCardValidated>("empty");

    useImperativeHandle(ref, () => ({
      onClearAttachments: () =>
        clearAttachments(
          proofOfPayment,
          dispatchProofOfPayment,
          `bookingInfo.${index}.attachments`,
          validation
        ),
    }));

    useEffect(() => {
      if (
        bookingInfo?.bookingReceived &&
        bookingInfo?.paymentMethod &&
        bookingInfo?.attachments?.length
      ) {
        setValidated("success");
      } else {
        setValidated("empty");
      }

      if (error) setValidated("error");
    }, [error, validation.watch()]);

    return (
      <ExpandableCard
        value={value}
        title={title}
        showRequired={showRequired}
        className={className}
        onDeleteAction={onDeleteAction}
        validated={validated}
        autoScroll={false}
      >
        <div className="col gap-5 mb-8">
          <div className="row -mb-1 gap-4">
            <div className="w-full tablet:w-[498px] flex justify-start gap-4">
              <DropdownField
                id={`booking-currency-${index}`}
                key={`booking-currency-${index}`}
                label="Currency"
                error={error?.currencyCode?.message}
                defaultValue={
                  defaultValues?.currencyCode || bookingInfo?.currencyCode || ""
                }
                dynamicWidth={true}
                containerClassName="w-2/6"
                listClassName="min-w-[420px]"
                items={supportedCurrencies.map((item) => {
                  return {
                    text: item,
                    value: item,
                  };
                })}
                {...validation.register(`bookingInfo.${index}.currencyCode`, {
                  value:
                    defaultValues?.currencyCode ||
                    bookingInfo?.currencyCode ||
                    "",
                  onChange: () => setDirty(true),
                })}
              />
              <TextField
                id={`booking-amount-${index}`}
                label="Amount Received"
                type="text"
                dynamicWidth={true}
                containerClassName="w-4/6"
                required={true}
                error={error?.bookingReceived?.message}
                defaultValue={defaultValues?.amount}
                placeholder="Enter Value"
                onInput={() => setDirty(true)}
                {...validation.register(
                  `bookingInfo.${index}.bookingReceived`,
                  {
                    setValueAs: (v) => v.trim(),
                  }
                )}
              />
            </div>

            <DropdownField
              id={`booking-payment-method-${index}`}
              key={`booking-payment-method-${index}`}
              label="Payment Method"
              error={error?.paymentMethod?.message}
              defaultValue={
                defaultValues?.paymentMethod || bookingInfo?.paymentMethod || ""
              }
              placeholder="Select Payment Method"
              items={bookingInfoPaymentMethods.map((item) => {
                return {
                  text: paymentMethodName(item),
                  value: item,
                };
              })}
              {...validation.register(`bookingInfo.${index}.paymentMethod`, {
                value:
                  defaultValues?.paymentMethod ||
                  bookingInfo?.paymentMethod ||
                  "",
                onChange: () => setDirty(true),
              })}
            />
          </div>
        </div>

        <Line className="mb-8" />

        <FileUploadForm
          id={`${value}-uploader`}
          files={proofOfPayment}
          title="Proof of Payment"
          acceptedFileTypes={["jpg", "jpeg", "png", "pdf"]}
          error={error?.attachments}
          validation={validation}
          fieldPrefix={`bookingInfo.${index}`}
          dispatchFiles={dispatchProofOfPayment}
        />

        <Line className="my-8" />

        <Textarea
          id={`booking-remarks-${index}`}
          rows={5}
          label="Remarks"
          error={error?.remarks?.message}
          required={false}
          maxLength={500}
          defaultValue={defaultValues?.remarks || ""}
          {...validation.register(`bookingInfo.${index}.remarks`, {
            setValueAs: (v) => v.trim(),
          })}
        />
      </ExpandableCard>
    );
  }
);

export default BookingInfoAccordian;
