export const MAX_CUSTOMERS = 4;
export const MAX_BOOKINGS = 5;
export const MAX_PERSONS = 2;

export const bookingInfoPaymentMethods = [
  "bank-transfer",
  "cash",
  "cheque",
  "credit-card",
  "cryptocurrency",
  "telegraphic-transfer",
  "future-collections",
] as const;

export type BookingInfoPaymentMethod = typeof bookingInfoPaymentMethods[number];

export const paymentMethodName = (
  paymentMethod: BookingInfoPaymentMethod
): string => {
  const paymentMethodNames = {
    "bank-transfer": "Bank Transfer",
    cash: "Cash",
    cheque: "Cheque",
    "credit-card": "Credit Card",
    cryptocurrency: "Cryptocurrency",
    "telegraphic-transfer": "Telegraphic Transfer",
    "future-collections": "Future Collections",
  };

  return paymentMethodNames[paymentMethod];
};

export const supportedCurrencies = [
  "MYR",
  "SGD",
  "USD",
  "TWD",
  "HKD",
  "RMB",
  "JPY",
] as const;

export type SupportedCurrencies = typeof supportedCurrencies[number];
