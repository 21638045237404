/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Slider from "../General/Slider";
import { formatNumber, rounding } from "../../utils/CommonUtils";
import { IFormSchema } from "../../common/Interfaces";
import { Validation } from "../../hooks/UseValidation";

interface PercentageRebateCardProps<T extends IFormSchema> {
  validation: Validation<T>;
  recommendedRebate: number;
  totalGrossPrice: number;
  selectedSalesPackage: string;
  nettSellingPrice: number;
  nettPsfPrice: number;
  onChangeNettSellingPrice: (price: number) => void;
}

const PercentageRebateCard: React.FC<PercentageRebateCardProps<any>> = (
  props
) => {
  const {
    validation,
    recommendedRebate,
    totalGrossPrice,
    selectedSalesPackage,
    nettSellingPrice,
    nettPsfPrice,
    onChangeNettSellingPrice,
  } = props;

  const salesPackage = validation.watch("salesPackage");

  const handleRebateValue = (selectedRebate: number) => {
    const processedRebate =
      selectedRebate < 0 || !selectedRebate
        ? 0
        : selectedRebate > 50
        ? 50
        : selectedRebate;

    validation.setValue(`salesPackage.rebate`, processedRebate);

    calculateNettSellingPrice(totalGrossPrice, processedRebate);
  };

  const calculateNettSellingPrice = (unitPrice: number, unitRebate: number) => {
    const discountedPrice =
      unitRebate < 0 ? unitPrice : unitPrice * ((100 - unitRebate) / 100);

    onChangeNettSellingPrice(rounding(discountedPrice, "round"));
  };

  useEffect(() => {
    if (totalGrossPrice)
      calculateNettSellingPrice(totalGrossPrice, salesPackage?.rebate);
  }, [totalGrossPrice]);

  return (
    <div className="col w-full tablet:w-[498px]">
      {selectedSalesPackage === "grr" && (
        <div className="mb-5">
          <label className="w-full uppercase">Enter Rebate Amount</label>
        </div>
      )}

      <div className="w-full min-h-[140px] bg-[#F0E7E780] px-4 py-2 flex flex-wrap gap-2 items-center">
        <Slider
          id="rebate-percentage"
          value={salesPackage?.rebate || 0}
          min={0}
          max={50}
          symbol="%"
          threshold={recommendedRebate}
          thresholdText="Maximum Recommended Rebate"
          hasField={true}
          handleChange={handleRebateValue}
        />
        <div className="w-full mt-4">
          <div className="row w-full text-sm">
            <span>
              Nett Selling Price ={" "}
              <strong>RM{formatNumber(nettSellingPrice.toString())}</strong>
            </span>
          </div>
          <div className="row w-full text-sm">
            <span>
              Nett Price PSF ={" "}
              <strong>RM{formatNumber(nettPsfPrice.toString())}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PercentageRebateCard;
