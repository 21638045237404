import React from "react";
import classNames from "classnames";
import {
  ConsideredSoldUnitStatus,
  consideredSoldUnitStatus,
} from "../../common/Enums";

interface DotProps {
  status: string;
  text?: string;
  isDisabled?: boolean;
  className?: string;
}

const Dot: React.FC<DotProps> = ({
  status,
  text,
  className,
  isDisabled = false,
  children,
}) => {
  const statusLc = status.toLowerCase();

  return (
    <span
      className={classNames(
        {
          "mr-5": text,
          "text-[#1D8D0B]": statusLc === "available",
          "text-[#D8830F]": statusLc === "reserved",
          "text-[#B11116]": statusLc === "blocked",
          "text-[#416FB8]": statusLc === "booked",
        },
        className
      )}
    >
      <span
        className={classNames("dot", {
          "bg-[#1D8D0B]": statusLc === "available",
          "bg-[#D8830F]": statusLc === "reserved",
          "bg-[#B11116]": statusLc === "blocked",
          "bg-[#416FB8]": statusLc === "booked",
          "bg-black": consideredSoldUnitStatus.includes(
            statusLc as ConsideredSoldUnitStatus
          ),
          "bg-[#1d8d0b4d]": statusLc === "available" && !text && isDisabled,
          "bg-[#ef99234d]": statusLc === "reserved" && !text && isDisabled,
          "bg-[#b111164d]": statusLc === "blocked" && !text && isDisabled,
          "bg-[#416fb880]": statusLc === "booked" && !text && isDisabled,
        })}
      />{" "}
      {text}
    </span>
  );
};

export default Dot;
