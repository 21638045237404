import React from "react";
import ReactDOM from "react-dom";
import SideBar from "../Navs/SideBar";

interface TabletSideBarProps {
  show: boolean;
  onHide: React.MouseEventHandler;
  dismissible?: boolean;
}

const TabletSideBar: React.FC<TabletSideBarProps> = (props) => {
  if (!props.show) return <></>;

  return ReactDOM.createPortal(
    <div
      onClick={props.dismissible ?? true ? props.onHide : () => {}}
      className="z-50 fixed bg-[#000] bg-opacity-50 left-0 right-0 top-0 bottom-0 overflow-y-hidden"
    >
      <div onClick={(e) => e.stopPropagation()} className="left-0">
        <SideBar onHide={props.onHide} />
      </div>
    </div>,
    document.body
  );
};

export default TabletSideBar;
