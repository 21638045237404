export const sources = [
  "agent",
  "kora",
  "digital",
  "bgb",
  "existing-buyer",
  "walk-in",
  "business-associate",
  "brand-collaboration",
] as const;

export type Source = typeof sources[number];

export const sourceName = (source: Source): string => {
  const sourceNames = {
    agent: "Agent",
    kora: "KORA",
    digital: "Digital",
    bgb: "Owner's Club",
    "existing-buyer": "Existing Buyer",
    "walk-in": "Walk-in",
    "business-associate": "Business Associate",
    "brand-collaboration": "Brand Collaboration",
  };

  return sourceNames[source];
};

export const DIGITAL_SOURCE_COMMISSION = 2500;
export const SOLD_UNIT_SOURCE_COMMISSION = 5;
