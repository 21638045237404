import React from "react";

interface HeaderTextProps {
  title: string;
}

const HeaderText: React.FC<HeaderTextProps> = (props) => {
  return (
    <div className="mx-7 h-20 col justify-center">
      <h2 className="capitalize">{props.title}</h2>
    </div>
  );
};

export default HeaderText;
