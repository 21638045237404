import React, { useState } from "react";
import { useNavigate } from "react-router";
import { object, ref, SchemaOf } from "yup";
import Path from "../../common/Path";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import ValidationMessageCard from "../../components/Cards/ValidationMessageCard";
import TextField from "../../components/Fields/TextField";
import Modal from "../../components/Modals/Modal";
import BottomBar from "../../components/Navs/BottomBar";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useValidation } from "../../hooks/UseValidation";
import api from "../../services/PabloApiService";
import { baseString } from "../../utils/SchemaUtil";

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema: SchemaOf<ChangePasswordForm> = object().shape({
  oldPassword: baseString().required(),
  newPassword: baseString()
    .required()
    .matches(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W|_])/),
  confirmPassword: baseString()
    .required()
    .oneOf(
      [ref("newPassword")],
      "Confirmation password must be the same as your new password."
    ),
});

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const validation = useValidation<ChangePasswordForm>(schema);
  const loadingPage = useLoadingPageContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const changePassword = async (body: ChangePasswordForm) => {
    loadingPage.start();

    const { success } = await api.post("/update-password", body);

    if (success) setShowModal(true);

    loadingPage.end();
  };

  const redirect = () => {
    setShowModal(false);
    navigate(Path.units);
  };

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") validation.handleSubmit(changePassword)();
  };

  return (
    <>
      {/* page */}
      <div className="col justify-between h-full">
        <div>
          <BackButton text="Go back" />

          <FormCard title="Account Settings">
            <h3>Change password</h3>

            <div className="col mt-3">
              <TextField
                label="Current Password"
                type="password"
                error={validation.errors.oldPassword?.message}
                onKeyPress={onKeyDown}
                {...validation.register("oldPassword")}
              />
              <div className="mb-5" />

              <TextField
                label="New Password"
                type="password"
                hasError={validation.errors.newPassword?.message !== undefined}
                onKeyPress={onKeyDown}
                {...validation.register("newPassword")}
              />
              <div className="mb-2" />
              <ValidationMessageCard
                value={validation.watch("newPassword")}
                hasError={validation.errors.newPassword?.message !== undefined}
              />
              <div className="mb-5" />

              <TextField
                label="Confirm Password"
                type="password"
                error={validation.errors.confirmPassword?.message}
                onKeyPress={onKeyDown}
                {...validation.register("confirmPassword")}
              />
              <div className="mb-5" />
            </div>
          </FormCard>
        </div>
      </div>

      <BottomBar>
        <button
          className="primary-button"
          onClick={validation.handleSubmit(changePassword)}
        >
          Update
        </button>
      </BottomBar>

      {/* modal */}
      <Modal show={showModal} type="success" onHide={() => setShowModal(false)}>
        <h2 className="mb-2">Your password has been changed successfully.</h2>
        <div className="flex-grow" />
        <button
          onClick={redirect}
          className="primary-button h-[48px] w-[218px]"
        >
          Back to main page
        </button>
      </Modal>
    </>
  );
};

export default ChangePasswordPage;
