/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { SchemaOf, object } from "yup";
import { MAX_NUMBER_OF_APPROVALS } from "../../common/Approval";
import { ProposalStatus } from "../../common/Enums";
import { CATALYST_CLOSING_FEE } from "../../common/ManagementApproval";
import { Source, sourceName } from "../../common/OpportunitySource";
import Path from "../../common/Path";
import { PackageableType, packageableType } from "../../common/SalesPackage";
import { Approval, Proposal } from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import UnitCostOfSalesCard from "../../components/Cards/UnitCostOfSalesCard";
import Line from "../../components/General/Line";
import Textarea from "../../components/General/Textarea";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import BottomBar from "../../components/Navs/BottomBar";
import ManagementApprovalHistoryDetails from "../../components/Shared/ManagementApprovalHistoryDetails";
import ManagementApprovalTabs from "../../components/Shared/ManagementApprovalTabs";
import { useValidation } from "../../hooks/UseValidation";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useManagementApprovalListContext } from "../../hooks/contexts/ManagementApprovalListContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import {
  calculateNumberOfApprovals,
  determineApprovalStage,
} from "../../utils/ApprovalUtils";
import {
  calculatePsfValue,
  calculateRebatePercentage,
  calculateRebateValue,
  formatNumber,
  rounding,
} from "../../utils/CommonUtils";
import { baseString } from "../../utils/SchemaUtil";
import LimboPage from "../generals/LimboPage";

type DeclineModal = {
  show: boolean;
  type?: "decline" | "resubmit";
};

interface ManagementApprovalForm {
  remarks: string;
}

const schema: SchemaOf<ManagementApprovalForm> = object().shape({
  remarks: baseString().required().label("Remarks").max(500),
});

const ManagementApprovalFormPage: React.FC = () => {
  const { proposalId } = useParams();
  const auth = useAuthContext();
  const approvalList = useManagementApprovalListContext();
  const {
    show,
    update,
    getUnitDiscountChart,
    getDevelopmentImpact,
    isLoading,
    loadingItems,
    unitDiscountChart,
    reviewDevelopmentImpact,
    reviewPriceDistribution,
  } = approvalList;
  const loadingPage = useLoadingPageContext();
  const modal = useModalContext();
  const navigate = useNavigate();
  const tabRef = useRef<HTMLDivElement>(null);
  const validation = useValidation<ManagementApprovalForm>(schema);
  const managementApprovalForm = validation.watch();

  /** States */
  const [proposal, setProposal] = useState<Proposal | undefined>(
    useLocation().state
  );
  const [openedTabs, setOpenedTabs] = useState<number[]>([0]);
  const [showDeclineModal, setShowDeclineModal] = useState<DeclineModal>({
    show: false,
  });

  /** Approval Related Variables */
  const latestApprovals = determineApprovalStage(
    proposal?.allApprovals,
    proposal?.stage
  );
  const latestApprovalCategories = latestApprovals?.[0]?.categories || [];

  /** Form Variables from API */
  const grossSellingPrice = proposal?.salesPackage?.grossSellingPrice || 0;
  const nettSellingPrice = proposal?.salesPackage?.nettSellingPrice || 0;
  const commissionFixedFee =
    proposal?.opportunitySource?.updatedCommissionFixedFee || 0;
  const commissionRate =
    proposal?.opportunitySource?.updatedCommissionRate || 0;
  const grrYield = proposal?.salesPackage?.packageable?.rate || 0;
  const grrYear = proposal?.salesPackage?.packageable?.numberOfYears || 0;
  const buildUp = proposal?.unit?.type?.buildUp || 0;

  /** Form Variables after calculations */
  const rebatePercentage = calculateRebatePercentage(
    grossSellingPrice,
    nettSellingPrice
  );
  const rebateAbsValue = calculateRebateValue(
    grossSellingPrice,
    nettSellingPrice
  );
  const commissionPercentage =
    proposal?.opportunitySource?.type === "digital"
      ? (commissionFixedFee / grossSellingPrice) * 100
      : commissionRate;
  const grrValue = grrYield * grrYear;
  const nettNettSellingPrice =
    proposal?.opportunitySource?.type === "digital"
      ? nettSellingPrice -
        commissionFixedFee -
        grossSellingPrice * (grrValue / 100) -
        CATALYST_CLOSING_FEE
      : nettSellingPrice -
        nettSellingPrice * (commissionPercentage / 100) -
        grossSellingPrice * (grrValue / 100) -
        CATALYST_CLOSING_FEE;
  const unitCostOfSalesValue = rounding(
    rebatePercentage +
      commissionPercentage +
      grrValue +
      (CATALYST_CLOSING_FEE / grossSellingPrice) * 100,
    "round"
  );

  const userHasApproved = latestApprovals?.some((approval) => {
    return approval.givenBy?.id === auth.user?.id;
  });

  const isPendingApproval = proposal?.status === "pending-approval" || proposal?.status === "resubmit-approval"

  useEffect(() => {
    if (!proposal) showProposal();
  }, []);

  useEffect(() => {
    if (latestApprovalCategories.includes("rebate")) {
      const salesPackageType = packageableType(
        proposal?.salesPackage?.packageableType as PackageableType
      );

      const body = {
        tower: proposal?.unit?.building?.name,
        units: proposal?.unit?.id ? [proposal.unit.id] : [],
        commission:
          proposal?.opportunitySource?.type === "digital"
            ? commissionFixedFee
            : commissionRate,
        sales: {
          package: salesPackageType,
          rebate: rebatePercentage,
          abs_rebate: proposal?.salesPackage?.nettSellingPrice,
          details: {
            grr_rate:
              salesPackageType === "grr"
                ? proposal?.salesPackage?.packageable?.rate
                : 0,
            grr_years:
              salesPackageType === "grr"
                ? proposal?.salesPackage?.packageable?.numberOfYears
                : 0,
          },
        },
        redis_price: false,
      };

      getUnitDiscountChart(body, proposal?.latestRecord?.recordable);
      getDevelopmentImpact(body, proposal?.latestRecord?.recordable);
    }
  }, [latestApprovalCategories]);

  useEffect(() => {
    if (loadingItems.length !== 0 || !proposal) loadingPage.start();
    else loadingPage.end();
  }, [loadingItems]);

  const handleOpenedTabs = (index: number) => {
    !openedTabs.includes(index) && setOpenedTabs([...openedTabs, index]);
  };

  const checkExceededApprovals = (
    approvals: Approval[],
    status: ProposalStatus
  ) => {
    const numberOfApprovals = approvals
      ? calculateNumberOfApprovals(
          approvals,
          status === "declined-approval" ? "declined" : "approved"
        )
      : 0;

    return numberOfApprovals >= MAX_NUMBER_OF_APPROVALS ? true : false;
  };

  const showProposal = async () => {
    loadingPage.start();

    const tempProposal = await show(parseInt(proposalId!));

    if (tempProposal) {
      setProposal(tempProposal);

      let tempLatestApprovals = determineApprovalStage(
        tempProposal?.allApprovals,
        tempProposal?.stage
      );

      checkExceededApprovals(tempLatestApprovals ?? [], tempProposal.status) &&
        modal.info({
          title: `This proposal has already been ${
            tempProposal.status === "declined-approval"
              ? "declined"
              : "approved"
          }`,
          onConfirm: {
            text: "Ok",
            action: () => modal.setShow(false),
          },
        });
    }

    loadingPage.end();
  };

  if (!proposal && !isLoading) {
    return <LimboPage type="not-found" />;
  }

  if (!proposal) {
    return <></>;
  }

  const approve = async () => {
    loadingPage.start();

    const body = latestApprovalCategories.includes("rebate")
      ? {
          record: {
            unitDiscount: unitDiscountChart,
            developmentImpact: reviewDevelopmentImpact,
            avgGrossPsf: reviewPriceDistribution.averageUnsoldGrossPricePsf,
            avgGrossAbs: reviewPriceDistribution.averageUnsoldGrossPrice,
            unsoldPricePsfCoordinates:
              reviewPriceDistribution.unsoldUnitPricePsf,
            unsoldPriceAbsCoordinates: reviewPriceDistribution.unsoldUnitPrice,
          },
        }
      : {};

    const success: boolean = await update("approve", proposal!.id, body);

    if (success) {
      navigate(Path.managementApprovals);
      toast.success("Successfully approved a proposal");
    }

    loadingPage.end();
  };

  const decline = async (fields: ManagementApprovalForm) => {
    const result = await validation.trigger();

    if (result) {
      loadingPage.start();

      const body = latestApprovalCategories.includes("rebate")
        ? {
            remarks: fields.remarks,
            record: {
              unitDiscount: unitDiscountChart,
              developmentImpact: reviewDevelopmentImpact,
              avgGrossPsf: reviewPriceDistribution.averageUnsoldGrossPricePsf,
              avgGrossAbs: reviewPriceDistribution.averageUnsoldGrossPrice,
              unsoldPricePsfCoordinates:
                reviewPriceDistribution.unsoldUnitPricePsf,
              unsoldPriceAbsCoordinates:
                reviewPriceDistribution.unsoldUnitPrice,
            },
          }
        : { remarks: fields.remarks };

      const success: boolean = await update("decline", proposal!.id, body);

      if (success) {
        setShowDeclineModal({ show: false, type: "decline" });
        navigate(Path.managementApprovals);
        toast.success("Proposal approvals declined.");
      }

      loadingPage.end();
    }
  };

  const resubmit = async (fields: ManagementApprovalForm) => {
    const result = await validation.trigger();

    if (result) {
      loadingPage.start();

      const body = { remarks: fields.remarks };

      const success: boolean = await update("resubmit", proposal!.id, body);

      if (success) {
        setShowDeclineModal({ show: false, type: "resubmit" });
        navigate(Path.managementApprovals);
        toast.success("Requested proposal for resubmission.");
      }

      loadingPage.end();
    }
  };

  const validate = (modalType: "approve" | "decline" | "resubmit") => {
    const hasCheckedAll = latestApprovalCategories.length === openedTabs.length;
    const isApprove = modalType === "approve";

    if (hasCheckedAll) {
      isApprove
        ? modal.confirmation({
            title: `Approve Proposal`,
            description: `Are you sure you want to approve this proposal?`,
            onConfirm: {
              text: "Confirm",
              action: () => approve(),
            },
          })
        : setShowDeclineModal({
            show: true,
            type: modalType === "resubmit" ? "resubmit" : "decline",
          });
    } else {
      modal.info({
        title:
          "Please ensure that you have reviewed all the details submitted for the proposal",
        onConfirm: {
          text: "Ok",
          action: () => {
            modal.setShow(false);
            tabRef.current?.offsetTop &&
              window.scrollTo({
                behavior: "smooth",
                top: tabRef.current.offsetTop - 55,
              });
          },
        },
      });
    }
  };

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton
            to={Path.managementApprovals}
            text="Back to management approval listing"
          />

          <FormCard showRequired={false} title="">
            {proposal?.approvalHistory?.some((history) => {
              return history?.approval.some((approval) => {
                return approval.status && approval.givenBy && approval.givenAt;
              });
            }) && (
              <>
                <h2 className="mb-4">Approval History</h2>
                <ManagementApprovalHistoryDetails
                  tower={proposal?.unit?.building?.name}
                  opportunitySource={proposal?.opportunitySource}
                  approvalHistory={proposal?.approvalHistory}
                />
                <Line className="my-4" />
              </>
            )}

            <h2 className="mb-4">Approval Summary</h2>
            <div className="overflow-y-auto overflow-x-hidden pb-2">
              <div className="px-5 pt-3 pb-6 mb-2 bg-section-background">
                <h4 className="uppercase mb-4">Buyer Information</h4>

                <table className="bg-transparent table-fixed w-full">
                  <thead>
                    <tr className="h-6 border-none">
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Customer Name
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Unit No.
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Build Up
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Sales PIC
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className="h-6 border-none">
                      <td className="text-sm font-bold p-0">
                        {(proposal?.customers ?? []).length > 0
                          ? proposal?.customers?.[0]?.fullName
                          : proposal?.companies?.[0]?.name}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {proposal?.unit?.number}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {formatNumber(buildUp)}
                      </td>
                      <td className="capitalize text-sm font-bold p-0">
                        {proposal?.user?.fullName}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="px-5 pt-3 pb-6 mb-2 bg-section-background">
                <h4 className="uppercase mb-4">Channel</h4>

                <table className="bg-transparent table-fixed w-full">
                  <thead>
                    <tr className="h-6 border-none">
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        Channel
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        {proposal?.opportunitySource?.type === "agent"
                          ? "Agency"
                          : proposal?.opportunitySource?.type === "digital"
                          ? "Name"
                          : proposal?.opportunitySource?.type === "kora"
                          ? "Referrer"
                          : proposal?.opportunitySource?.type === "bgb" ||
                            proposal?.opportunitySource?.type ===
                              "existing-buyer"
                          ? "Unit No."
                          : proposal?.opportunitySource?.type ===
                            "business-associate"
                          ? "Company / Individual Name"
                          : proposal?.opportunitySource?.type ===
                            "brand-collaboration"
                          ? "Event Name"
                          : ""}
                      </th>
                      <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                        {proposal?.opportunitySource?.type === "agent" &&
                          "Agent"}
                      </th>
                      <th className="p-0 min-w-[250px] desktop:w-[250px]" />
                    </tr>
                  </thead>

                  <tbody>
                    <tr className="h-6 border-none">
                      <td className="text-sm font-bold p-0">
                        {sourceName(
                          proposal?.opportunitySource?.type as Source
                        )}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {proposal?.opportunitySource?.type === "kora" ||
                        proposal?.opportunitySource?.type === "digital"
                          ? proposal?.opportunitySource?.fullName
                          : proposal?.opportunitySource?.title || ""}
                      </td>
                      <td className="text-sm font-bold p-0">
                        {proposal?.opportunitySource?.type === "agent" &&
                          (proposal?.opportunitySource?.fullName || `--`)}
                      </td>
                      <td className="p-0" />
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mb-3">
                <div className="px-5 pt-3 pb-6 bg-section-background">
                  <h4 className="uppercase mb-4">Unit Pricing</h4>

                  <table className="bg-transparent table-fixed w-full">
                    <thead>
                      <tr className="h-6 border-none">
                        <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Gross Selling Price
                        </th>
                        <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Nett Selling Price *
                        </th>
                        <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Nett Nett Selling Price **
                        </th>
                        <th className="p-0 min-w-[250px] desktop:w-[250px]" />
                      </tr>
                    </thead>

                    <tbody>
                      <tr className="h-6 border-none">
                        <td className="text-sm font-bold p-0">
                          RM{" "}
                          {formatNumber(rounding(grossSellingPrice, "round"))}
                        </td>
                        <td className="text-sm font-bold p-0">
                          RM {formatNumber(rounding(nettSellingPrice, "round"))}
                        </td>
                        <td className="text-sm font-bold p-0">
                          RM{" "}
                          {formatNumber(
                            rounding(nettNettSellingPrice, "round")
                          )}
                        </td>
                        <td className="p-0" />
                      </tr>

                      <tr className="h-6 border-none">
                        <th className="pt-3 text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Gross Selling Price PSF
                        </th>
                        <th className="pt-3 text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Nett Price PSF
                        </th>
                        <th className="pt-3 text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Nett Nett Price PSF
                        </th>
                        <th className="p-0 pt-3 min-w-[250px] desktop:w-[250px]" />
                      </tr>

                      <tr className="h-6 border-none">
                        <td className="pt-2 text-sm font-bold p-0">
                          RM{" "}
                          {formatNumber(
                            calculatePsfValue(
                              grossSellingPrice,
                              buildUp,
                              "round"
                            )
                          )}
                        </td>
                        <td className="pt-2 text-sm font-bold p-0">
                          RM{" "}
                          {formatNumber(
                            calculatePsfValue(
                              nettSellingPrice,
                              buildUp,
                              "round"
                            )
                          )}
                        </td>
                        <td className="pt-2 text-sm font-bold p-0">
                          RM{" "}
                          {formatNumber(
                            calculatePsfValue(
                              nettNettSellingPrice,
                              buildUp,
                              "round"
                            )
                          )}
                        </td>
                        <td className="pt-2 p-0" />
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="pt-1 text-xs font-light text-[#AAAAAA] italic transition-all duration-200">
                  * Nett selling price = Unit Gross Price - Rebate
                </div>
                <div className="text-xs font-light text-[#AAAAAA] italic transition-all duration-200">
                  ** Nett nett selling price = Nett Selling Price - Commission -
                  Additional Sales Package Cost - Catalyst Closing Fee
                </div>
              </div>

              {!latestApprovalCategories.includes("rebate") && (
                <div className="px-5 pt-3 pb-6 mb-3 bg-section-background">
                  <UnitCostOfSalesCard
                    unitCostOfSalesValue={unitCostOfSalesValue}
                    rebate={
                      proposal?.salesPackage?.rebateType === "percentage"
                        ? `${rebatePercentage}%`
                        : `RM ${formatNumber(rebateAbsValue)}`
                    }
                    commission={
                      proposal?.opportunitySource?.type === "digital"
                        ? formatNumber(commissionFixedFee)
                        : formatNumber(commissionRate)
                    }
                    grr={
                      packageableType(
                        proposal?.salesPackage
                          ?.packageableType as PackageableType
                      ) === "grr"
                        ? `${formatNumber(
                            grrYield * grrYear
                          )}% (${grrYield}%, ${grrYear} year${
                            grrYear > 1 ? "s" : ""
                          })`
                        : "--"
                    }
                    salesPackage={proposal?.salesPackage}
                    opportunitySource={proposal?.opportunitySource}
                    reviewable={latestApprovalCategories.includes("rebate")}
                  />
                </div>
              )}

              <h2 className="mr-4 mt-8">
                {isPendingApproval && !userHasApproved
                  ? "Approval Required"
                  : "Latest Approval"}
              </h2>

              <div ref={tabRef}>
                <ManagementApprovalTabs
                  tower={proposal?.unit?.building?.name}
                  salesPackage={proposal?.salesPackage}
                  opportunitySource={proposal?.opportunitySource}
                  approvalCategories={latestApprovalCategories}
                  hasIndicator={
                    isPendingApproval && !userHasApproved
                  }
                  unitDiscountChart={unitDiscountChart}
                  reviewDevelopmentImpact={reviewDevelopmentImpact}
                  reviewPriceDistribution={reviewPriceDistribution}
                  indexOnChanged={handleOpenedTabs}
                />
              </div>
            </div>
          </FormCard>
        </div>

        {isPendingApproval && !userHasApproved && (
          <BottomBar>
            <button
              id="request-management-approval-resubmission-button"
              className="secondary-button mr-auto"
              onClick={() => validate("resubmit")}
            >
              Request for Resubmission
            </button>

            <button
              id="decline-management-approval-button"
              className="secondary-button mr-4"
              onClick={() => validate("decline")}
            >
              Decline
            </button>

            <button
              id="approve-management-approval-button"
              className="primary-button"
              onClick={() => validate("approve")}
            >
              Approve
            </button>
          </BottomBar>
        )}

        <ConfirmationModal
          show={showDeclineModal.show}
          dismissible={true}
          onHide={() => {}}
          type="warning"
          title={
            showDeclineModal?.type === "resubmit"
              ? "Request for Resubmission"
              : "Decline Proposal"
          }
          description={
            showDeclineModal?.type === "resubmit"
              ? "Are you sure you want to request this proposal for resubmission?"
              : "Are you sure you want to decline this proposal?"
          }
          content={
            <div className="mb-6">
              <Textarea
                id="management-approval-remarks"
                rows={3}
                label="Remarks"
                dynamicWidth={true}
                containerClassName="w-[498px]"
                error={validation.errors.remarks?.message}
                required={true}
                maxLength={500}
                placeholder="Please provide reasons"
                {...validation.register("remarks")}
              />
            </div>
          }
          onConfirm={{
            text: "Confirm",
            action: () => {
              showDeclineModal?.type === "resubmit"
                ? resubmit(managementApprovalForm)
                : decline(managementApprovalForm);
            },
          }}
          onCancel={{
            text: "Cancel",
            action: () => {
              validation.clearErrors();
              validation.setValue("remarks", "");
              setShowDeclineModal({ show: false });
            },
          }}
        />
      </div>
    </>
  );
};

export default ManagementApprovalFormPage;
