/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Accordion from "../Accordion/Accordion";
import CheckField from "../Fields/CheckField";
import Building from "../../common/Building";
import Line from "../General/Line";
import { usePropertyContext } from "../../hooks/contexts/PropertyContext";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import { filterUnitList } from "../../utils/FilterUtils";
import PabloIcon from "../Icons/PabloIcon";
import Icon from "../Icons/Icon";

interface FilterUnitPopUpProps {
  filterCount: number;
  onClear: () => void;
}

const FilterUnitPopUp: React.FC<FilterUnitPopUpProps> = (props) => {
  const property = usePropertyContext();
  const unitList = useUnitListContext();

  const [openKey, setOpenKey] = useState<string | null>("orientation");
  const allUnitTypes = Building.allUnitTypes[property.selectedBuilding.value];

  const handleOpenAccordion = (key: string) => {
    setOpenKey(openKey !== key ? key : null);
  };

  const clearFilters = () => {
    unitList.setFilters({
      unitType: [],
      orientation: [],
      theme: [],
      wholeFloor: false,
      status: [],
      noOfBedrooms: [],
      floors: [],
    });
    unitList.handleOnClearUnitSelection();
    unitList.handleOnClearFilteredUnits();
  };

  const applyFilters = () => {
    const filteredUnits = filterUnitList(unitList.units, unitList.filters);
    unitList.handleOnFilter(filteredUnits);
  };

  const addFilter = (filterType: string, value: string) => {
    unitList.setFilters((prevState: any) => {
      return {
        ...prevState,
        [`${filterType}`]: [...prevState[filterType], value],
      };
    });
  };

  const removeFilter = (filterType: string, value: string) => {
    unitList.setFilters((prevState: any) => {
      return {
        ...prevState,
        [`${filterType}`]: prevState[filterType].filter((f: string) => {
          return f !== value;
        }),
      };
    });
  };

  const toggleCheckbox = (filterType: string, index: number) => {
    switch (filterType) {
      case "unitType":
        const unitTypeFilters = allUnitTypes[index].value;
        unitList.filters[filterType].includes(unitTypeFilters)
          ? removeFilter(filterType, unitTypeFilters)
          : addFilter(filterType, unitTypeFilters);
        break;

      case "orientation":
        const orientationFilters =
          Building.allOrientations[property.selectedBuilding.value][index]
            .value;
        unitList.filters[filterType].includes(orientationFilters)
          ? removeFilter(filterType, orientationFilters)
          : addFilter(filterType, orientationFilters);
        break;

      case "noOfBedrooms":
        const bedroomFilters = Building.allNoOfBedrooms[index].value;
        unitList.filters[filterType].includes(bedroomFilters)
          ? removeFilter(filterType, bedroomFilters)
          : addFilter(filterType, bedroomFilters);
        break;

      case "floors":
        const floorFilters = Building.allFloorLevels[index].value;
        unitList.filters[filterType].includes(floorFilters)
          ? removeFilter(filterType, floorFilters)
          : addFilter(filterType, floorFilters);
        break;

      case "theme":
        const themeFilters =
          Building.allThemes[property.selectedBuilding.value][index];
        unitList.filters[filterType].includes(themeFilters)
          ? removeFilter(filterType, themeFilters)
          : addFilter(filterType, themeFilters);
        break;

      case "wholeFloor":
        unitList.setFilters((prevState: any) => {
          return {
            ...prevState,
            wholeFloor: !prevState.wholeFloor,
          };
        });
        break;

      case "status":
        const statusFilters = Building.allStatuses[index];
        unitList.filters[filterType].includes(statusFilters)
          ? removeFilter(filterType, statusFilters)
          : addFilter(filterType, statusFilters);
        break;

      default:
        return;
    }
  };

  useEffect(() => {
    clearFilters();
  }, [property.selectedBuilding]);

  useEffect(() => {
    props.filterCount <= 0 ? clearFilters() : applyFilters();
  }, [props.filterCount, unitList.units]);

  return (
    <div className="w-[709px] mt-1 border border-primary">
      <div className="row bg-background p-2 justify-end">
        <div className="row flex h-8 uppercase text-[#B11116B2] justify-center items-center text-sm px-6 font-bold">
          <div
            className="row cursor-pointer gap-2 hover:opacity-50 transition-all duration-150"
            onClick={clearFilters}
          >
            <span>Clear Filters</span>
            <PabloIcon
              icon={Icon.remove}
              className="w-[20px] h-[20px] primary-icon"
            />
          </div>
        </div>
      </div>
      <Accordion
        name="orientation"
        title="Orientation"
        toggle={handleOpenAccordion}
        open={openKey === "orientation"}
      >
        <div className="grid grid-cols-2">
          {Building.allOrientations[property.selectedBuilding.value].map(
            (orientation: any, index: number) => {
              return (
                <CheckField
                  key={`${orientation.name}-${index}`}
                  label={orientation.name}
                  onChange={() => toggleCheckbox("orientation", index)}
                  innerClassName="text-xs"
                  checked={unitList.filters.orientation.includes(
                    orientation.value
                  )}
                />
              );
            }
          )}
        </div>
      </Accordion>

      <Accordion
        name="unitType"
        title="Unit Type / Bedrooms / Size"
        toggle={handleOpenAccordion}
        open={openKey === "unitType"}
      >
        {allUnitTypes.map((unitType: any, index: number) => {
          return (
            <div key={`${unitType.name}-${index}`}>
              {index === 0 ? (
                <div className="w-64 float-left">
                  <CheckField
                    label={unitType.name}
                    onChange={() => toggleCheckbox("unitType", index)}
                    innerClassName="text-xs"
                    checked={unitList.filters.unitType.includes(unitType.value)}
                  />
                </div>
              ) : unitType.type === allUnitTypes[index - 1].type ? (
                <div className="w-64 float-left">
                  <CheckField
                    label={unitType.name}
                    onChange={() => toggleCheckbox("unitType", index)}
                    innerClassName="text-xs"
                    checked={unitList.filters.unitType.includes(unitType.value)}
                  />
                </div>
              ) : (
                <>
                  <div className="clear-both">
                    <Line className="my-1" />
                  </div>
                  <div
                    key={`${unitType.name}-${index}`}
                    className="clear-left float-left w-64"
                  >
                    <CheckField
                      label={unitType.name}
                      onChange={() => toggleCheckbox("unitType", index)}
                      innerClassName="text-xs"
                      checked={unitList.filters.unitType.includes(
                        unitType.value
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </Accordion>

      <Accordion
        name="theme"
        title="ID Theme"
        toggle={handleOpenAccordion}
        open={openKey === "theme"}
      >
        <div className="grid grid-cols-3">
          {Building.allThemes[property.selectedBuilding.value].map(
            (theme: string, index: number) => {
              return (
                <CheckField
                  key={`${theme}-${index}`}
                  label={theme}
                  onChange={() => toggleCheckbox("theme", index)}
                  innerClassName="text-xs"
                  checked={unitList.filters.theme.includes(theme)}
                />
              );
            }
          )}
        </div>
      </Accordion>

      <Accordion
        name="wholeFloor"
        title="Whole Floor Available"
        toggle={handleOpenAccordion}
        open={openKey === "wholeFloor"}
      >
        <div className="grid grid-cols-3">
          <CheckField
            label="Whole Floor Available"
            onChange={() => toggleCheckbox("wholeFloor", 0)}
            innerClassName="text-xs"
            checked={unitList.filters.wholeFloor}
          />
        </div>
      </Accordion>

      <Accordion
        name="status"
        title="Unit Status"
        toggle={handleOpenAccordion}
        open={openKey === "status"}
      >
        <div className="grid grid-cols-3">
          {Building.allStatuses.map((status: string, index: number) => {
            return (
              <CheckField
                key={`${status}-${index}`}
                label={status}
                onChange={() => toggleCheckbox("status", index)}
                innerClassName="text-xs"
                checked={unitList.filters.status.includes(status)}
              />
            );
          })}
        </div>
      </Accordion>

      <Accordion
        name="noOfBedrooms"
        title="Number of Bedrooms"
        toggle={handleOpenAccordion}
        open={openKey === "noOfBedrooms"}
      >
        <div className="grid grid-cols-3">
          {Building.allNoOfBedrooms.map((noOfBedrooms: any, index: number) => {
            return (
              <CheckField
                key={`${noOfBedrooms.name}-${index}`}
                label={noOfBedrooms.name}
                onChange={() => toggleCheckbox("noOfBedrooms", index)}
                innerClassName="text-xs"
                checked={unitList.filters.noOfBedrooms.includes(
                  noOfBedrooms.value
                )}
              />
            );
          })}
        </div>
      </Accordion>

      <Accordion
        name="floors"
        title="Floors"
        toggle={handleOpenAccordion}
        open={openKey === "floors"}
      >
        <div className="grid grid-cols-3">
          {Building.allFloorLevels.map((floors: any, index: number) => {
            return (
              <CheckField
                key={`${floors.name}-${index}`}
                label={floors.name}
                onChange={() => toggleCheckbox("floors", index)}
                innerClassName="text-xs min-h-[56px]"
                checked={unitList.filters.floors.includes(floors.value)}
              />
            );
          })}
        </div>
      </Accordion>
    </div>
  );
};

export default FilterUnitPopUp;
