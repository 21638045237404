/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import { SortingOption } from "../common/Types";

interface SortConfig {
  field: string;
  order: SortingOption;
  callback?: Function;
}

export const useFilter = <T>(items: T[]) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: "",
    order: "asc",
  });

  const sort = (field: any, callback?: Function) => {
    let order: SortingOption = "asc";

    if (
      sortConfig &&
      sortConfig.field === field &&
      sortConfig.order === "asc"
    ) {
      order = "desc";
    }

    setSortConfig({ field, order, callback });
  };

  const getNestedObject = function (o: T, s: string, callback?: Function): any {
    const commaItem = s.split(",");
    var result = o as any;

    for (let m in commaItem) {
      let n = commaItem[m];
      n = n.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      n = n.replace(/^\./, ""); // strip a leading dot
      const a = n.split(".");

      for (let i in a) {
        let k = a[i];

        if (result[k]) {
          result = result[k as keyof T];
        } else {
          result = o;
          break;
        }
      }

      if (typeof result === "string" && result) break;
    }

    return callback ? callback(result) : result;
  };

  const sortedItems = useMemo(() => {
    let sortedItemsArr = [...items];

    if (sortConfig.field) {
      sortedItemsArr.sort((a, b) => {
        let aValue = getNestedObject(a, sortConfig.field, sortConfig.callback);
        let bValue = getNestedObject(b, sortConfig.field, sortConfig.callback);

        if (typeof aValue === "string" && typeof bValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.order === "asc" ? -1 : 1;
        } else if (aValue > bValue) {
          return sortConfig.order === "asc" ? 1 : -1;
        }

        return 0;
      });
    }

    return sortedItemsArr;
  }, [items, sortConfig]);

  return { items: sortedItems, sort };
};
