import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";

type AreaChartDataset = {
  fill: boolean;
  label: string;
  data: any;
  borderColor: string;
  backgroundColor: string;
  tension: number;
};

interface AreaChartProps {
  labels: any;
  xAxisName?: string;
  yAxisName?: string;
  datasets: AreaChartDataset[];
}

const AreaChart: React.FC<AreaChartProps> = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: props?.xAxisName || "",
        },
        ticks: {
          stepSize: 100,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: props?.yAxisName || "",
        },
      },
    },
  };

  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  return <Line options={options} data={data} />;
};

export default AreaChart;
