import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useDeviceContext } from "../../hooks/contexts/DeviceContext";
import SideBar from "./SideBar";
import TopBar from "./TopBar";

const Layout: React.FC = ({ children }) => {
  const location = useLocation();
  const device = useDeviceContext();
  const auth = useAuthContext();
  const excludeSideBar = [
    "/login",
    "/forgot-password",
    "/reset-password",
    "/social/google/auth",
    "/calculator",
  ].includes(location.pathname);

  return (
    <>
      {excludeSideBar ? (
        children
      ) : (
        <>
          <TopBar user={auth.user} />
          {!device.isTablet && <SideBar />}
          <main
            className={classNames("relative h-screen pt-[55px]", {
              "pl-[235px]": !device.isTablet,
            })}
          >
            {children}
          </main>
        </>
      )}
    </>
  );
};

export default Layout;
