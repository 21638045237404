import dotenv from "dotenv";
import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "./hooks/contexts/AppContext";
import Router from "./routers/Router";
import "./styles/app.css";

dotenv.config();

const App: React.FC = () => {
  return (
    <div className="app">
      <ToastContainer
        style={{ width: "500px" }}
        pauseOnHover={false}
        limit={2}
        newestOnTop={true}
        transition={Zoom}
      />
      <AppContext>
        <Router />
      </AppContext>
    </div>
  );
};

export default App;
