import { Building } from "../common/Types";

const baseKey = "p";
const version = "1";

const formatKey = (key: string) => {
  return `${baseKey}.${version}.${key}`;
};

const get = (key: string) => {
  const data = localStorage.getItem(formatKey(key));
  if (data === undefined) return null;
  return JSON.parse(data || `null`);
};

const set = (key: string, value: any) => {
  if (value !== undefined) {
    localStorage.setItem(formatKey(key), JSON.stringify(value));
  }
};

class StorageService {
  clear = () => localStorage.clear();

  setToken = (value: string) => set("t", value);
  getToken = (): string => get("t");

  setSelectedBuilding = (value: Building) => set("b", value);
  getSelectedBuilding = (): Building | null => get("b");
}

const storage = new StorageService();

export default storage;
