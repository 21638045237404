/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Moment } from "moment";
import Table from "../Tables/Table";
import { formatNumber, forceDecimal } from "../../utils/CommonUtils";
import { months } from "../../common/DateTime";
import { dateDiff } from "../../utils/TimeDateUtils";

interface InstalmentTableProps {
  initialPayment?: number;
  instalmentPayment: number;
  outstandingPayment?: number;
  paymentStartDate: Moment;
  paymentEndDate: Moment;
  pricePerMonth: number;
  pricePerMonthRemainder: number;
  isModal?: boolean;
  editable?: boolean;
}

const InstalmentTable: React.FC<InstalmentTableProps> = (props) => {
  const {
    initialPayment,
    instalmentPayment,
    outstandingPayment,
    paymentStartDate,
    paymentEndDate,
    pricePerMonth,
    pricePerMonthRemainder,
    isModal,
    editable,
  } = props;

  const [repaymentYears, setRepaymentYears] = useState<number>(
    dateDiff(paymentStartDate.clone().startOf("year"), paymentEndDate, "years")
  );

  const calculateMonthlyInstalment = (month: number, year: number): number => {
    let monthlyInstalment = 0;

    if (year === 0 && month < paymentStartDate.month())
      return monthlyInstalment;

    if (paymentStartDate.year() + year < paymentEndDate.year())
      monthlyInstalment =
        paymentEndDate.month() === 0 && month === 11
          ? pricePerMonth + pricePerMonthRemainder
          : pricePerMonth;

    if (paymentStartDate.year() + year === paymentEndDate.year())
      monthlyInstalment =
        month === paymentEndDate.month() - 1
          ? pricePerMonth + pricePerMonthRemainder
          : month < paymentEndDate.month() - 1
          ? pricePerMonth
          : 0;

    return monthlyInstalment;
  };

  const displayMonthlyInstalment = (month: number, year: number): string => {
    const monthlyInstalment = calculateMonthlyInstalment(month, year);
    return formatNumber(monthlyInstalment);
  };

  const displayYearSubtotal = (year: number): string => {
    let monthlyInstalment = 0;
    let subtotal = 0;

    for (let month = 0; month < months.length; month++) {
      monthlyInstalment = calculateMonthlyInstalment(month, year);
      subtotal += monthlyInstalment;
    }

    return formatNumber(subtotal);
  };

  useEffect(() => {
    const tempNumOfYears = dateDiff(
      paymentStartDate.clone().startOf("year"),
      paymentEndDate,
      "years"
    );

    setRepaymentYears(tempNumOfYears);
  }, [paymentEndDate]);

  return (
    <>
      {!editable && (
        <div className="col w-full bg-white p-3 mt-5 justify-between gap-3">
          <div className="row w-full gap-3">
            <div className="h-full w-full bg-[#3077841A] uppercase py-3 pl-4">
              <div className="font-normal text-sm">Initial Amount</div>
              <div className="font-bold text-lg">
                {initialPayment ? `RM ${formatNumber(initialPayment)}` : "--"}
              </div>
            </div>

            <div className="h-full w-full bg-[#3077841A] uppercase py-3 pl-4">
              <div className="font-normal text-sm">Instalment Amount</div>
              <div className="font-bold text-lg">
                {instalmentPayment
                  ? `RM ${formatNumber(instalmentPayment)}`
                  : "--"}
              </div>
            </div>

            <div className="h-full w-full bg-[#3077841A] uppercase py-3 pl-4">
              <div className="font-normal text-sm">Outstanding Amount</div>
              <div className="font-bold text-lg">
                {outstandingPayment
                  ? `RM ${formatNumber(outstandingPayment)}`
                  : "--"}
              </div>
            </div>
          </div>

          <div className="row w-full gap-3">
            <div className="h-full w-full bg-[#3077841A] uppercase py-3 pl-4">
              <div className="font-normal text-sm">Payment Start Date</div>
              <div className="font-bold text-lg">
                {paymentStartDate
                  ? paymentStartDate.clone().format("MM/YYYY")
                  : "--"}
              </div>
            </div>

            <div className="h-full w-full bg-[#3077841A] uppercase py-3 pl-4">
              <div className="font-normal text-sm">Months of Repayment</div>
              <div className="font-bold text-lg">
                {paymentStartDate && paymentEndDate
                  ? dateDiff(
                      paymentStartDate.clone().startOf("month"),
                      paymentEndDate,
                      "months"
                    )
                  : "--"}
              </div>
            </div>

            <div className="h-full w-full py-3 pl-4" />
          </div>
        </div>
      )}

      <Table
        isLoading={false}
        onSearching={() => {}}
        searchable={false}
        searchHint=""
        showPagination={false}
        containerClassName={isModal ? "py-5" : ""}
        headers={[...Array(repaymentYears)].map((value, index) => {
          return (
            <th
              key={`year-${paymentStartDate.year() + index}`}
              className={classNames("w-4/12 h-14 uppercase text-center", {
                "border-l border-section-background": index !== 0,
              })}
              colSpan={2}
            >
              {paymentStartDate.year() + index}
            </th>
          );
        })}
        body={[
          <tr key={`repayment-body`} className="border-0">
            {[...Array(repaymentYears)].map((year, yearIndex) => {
              return (
                <td
                  key={`year-${yearIndex}`}
                  className={classNames("p-0", {
                    "border-l border-section-background": yearIndex !== 0,
                  })}
                  colSpan={2}
                >
                  <table>
                    <tbody>
                      <tr>
                        <td className="w-2/12 uppercase font-extrabold text-xs text-center">
                          Month
                        </td>
                        <td className="w-2/12 uppercase font-extrabold text-xs text-right">
                          Amount Payable (RM)
                        </td>
                      </tr>

                      {months.map((month, index) => {
                        return (
                          <tr
                            key={`year-${yearIndex}-month-${index}`}
                            className={classNames({
                              "border-0": index === 0 || index === 11,
                            })}
                          >
                            <td className="w-1/2 text-xs text-center">
                              {month}
                            </td>
                            <td className="w-1/2 text-xs text-right">
                              {displayMonthlyInstalment(index, yearIndex)}
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td className="font-extrabold text-xs text-center">
                          Subtotal FY {paymentStartDate.year() + yearIndex}
                        </td>
                        <td className="font-extrabold text-xs text-right">
                          {displayYearSubtotal(yearIndex)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              );
            })}
          </tr>,
          <tr key="total-price" className="border-0">
            {repaymentYears > 1 && (
              <td colSpan={repaymentYears * 2 - 2}>&nbsp;</td>
            )}

            <td>
              <div className="w-full flex justify-between items-stretch gap-8">
                <div className="w-1/2 uppercase font-extrabold text-xs text-center">
                  Total (RM)
                </div>
                <div className="w-1/2 uppercase font-extrabold text-xs text-right">
                  {formatNumber(forceDecimal(instalmentPayment))}
                </div>
              </div>
            </td>
          </tr>,
        ]}
      />
    </>
  );
};

export default InstalmentTable;
