import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useDebounceState = <T>(
  initialValue: T,
  delay: number
): [T, Dispatch<SetStateAction<T>>] => {
  const [actualValue, setActualValue] = useState<T>(initialValue);
  const [debounceValue, setDebounceValue] = useState<T>(initialValue);

  useEffect(() => {
    const debounceId = setTimeout(() => setDebounceValue(actualValue), delay);
    return () => clearTimeout(debounceId);
  }, [actualValue, delay]);

  return [debounceValue, setActualValue];
};
