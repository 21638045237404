import { Validation } from "../../hooks/UseValidation";
import DropdownField from "../Fields/DropdownField";
import TextField from "../Fields/TextField";
import Country from "../../common/Country";
import { IFormSchema } from "../../common/Interfaces";
import { Address } from "../../common/Types";

interface AddressFormProps<T extends IFormSchema> {
  validation: Validation<T>;
  index?: number;
  error: any;
  fieldPrefix: string;
  defaultValues?: Address;
  setDirty: Function;
}

const AddressForm: React.FC<AddressFormProps<any>> = (props) => {
  const { error, fieldPrefix, index, defaultValues, setDirty } = props;
  const register = props.validation.register;

  return (
    <>
      <h4 className="font-bold">Address Details</h4>
      <div className="row -mb-1 gap-4">
        <TextField
          id={`${fieldPrefix}-address-line-1-${index ?? ""}`}
          label="Address 1"
          required={true}
          type="text"
          error={error?.addressInfo?.addressLine1?.message}
          defaultValue={defaultValues?.line1 || ""}
          onInput={() => setDirty(true)}
          {...register(`${fieldPrefix}.addressInfo.addressLine1`, {
            setValueAs: (v) => v.trim(),
          })}
        />
        <TextField
          id={`${fieldPrefix}-address-line-2-${index ?? ""}`}
          label="Address 2"
          type="text"
          required={false}
          error={error?.addressInfo?.addressLine2?.message}
          defaultValue={defaultValues?.line2 || ""}
          onInput={() => setDirty(true)}
          {...register(`${fieldPrefix}.addressInfo.addressLine2`, {
            setValueAs: (v) => v.trim(),
          })}
        />
      </div>

      <div className="row -mb-1 gap-4">
        <TextField
          id={`${fieldPrefix}-address-postcode-${index ?? ""}`}
          label="Postal Code"
          required={true}
          type="text"
          error={error?.addressInfo?.postCode?.message}
          defaultValue={defaultValues?.postcode || ""}
          onInput={() => setDirty(true)}
          {...register(`${fieldPrefix}.addressInfo.postCode`, {
            setValueAs: (v) => v.trim(),
          })}
        />
        <TextField
          id={`${fieldPrefix}-address-city-${index ?? ""}`}
          label="City"
          required={true}
          type="text"
          error={error?.addressInfo?.city?.message}
          defaultValue={defaultValues?.city || ""}
          onInput={() => setDirty(true)}
          {...register(`${fieldPrefix}.addressInfo.city`, {
            setValueAs: (v) => v.trim(),
          })}
        />
      </div>

      <div className="row -mb-1 gap-4">
        <TextField
          id={`${fieldPrefix}-address-state-${index ?? ""}`}
          label="State"
          required={true}
          type="text"
          error={error?.addressInfo?.state?.message}
          defaultValue={defaultValues?.state || ""}
          onInput={() => setDirty(true)}
          {...register(`${fieldPrefix}.addressInfo.state`, {
            setValueAs: (v) => v.trim(),
          })}
        />
        <DropdownField
          id={`${fieldPrefix}-address-country-${index ?? ""}`}
          key={`${fieldPrefix}-address-country-${index ?? ""}`}
          label="Country"
          error={error?.addressInfo?.country?.message}
          placeholder="Select Country"
          items={Country.countryData.map((country) => {
            return {
              text: country.name,
              value: country.alpha2Code,
            };
          })}
          searchable="true"
          defaultValue={defaultValues?.country || ""}
          {...register(`${fieldPrefix}.addressInfo.country`, {
            value: defaultValues?.country || "",
            onChange: () => setDirty(true),
          })}
        />
      </div>
    </>
  );
};

export default AddressForm;
