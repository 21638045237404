import { array, number, object, SchemaOf } from "yup";
import { salutations } from "../common/Enums";
import { sources } from "../common/OpportunitySource";
import { paymentStructures } from "../common/PaymentStructure";
import {
  grrRatesSelection,
  grrYearsSelection,
  paymentMethods,
  rebateTypes,
  salesPackages,
} from "../common/SalesPackage";
import {
  FlexibleInstalmentDetails,
  GrrValue,
  Hook,
  InstalmentFormDetails,
} from "../common/Types";
import { baseString } from "../utils/SchemaUtil";

export interface ProposalForm {
  opportunitySource: {
    type: string;
    title?: string;
    fullName?: string;
    phoneCode?: string;
    phoneNumber?: string;
    email?: string | null;
    commissionRate?: number;
    updatedCommissionRate?: number;
  };
  customerBasicInfo: {
    id?: number;
    salutation: string;
    fullName: string;
    phoneCode: string;
    phoneNumber: string;
    country: string;
    email?: string;
  };
  salesPackage: {
    paymentMethod: string;
    selectedSalesPackage: string;
    rebateType: string;
    rebate: number;
    nettSellingPrice: number;
    grrValues?: GrrValue;
    hooks?: Hook[];
    remarks?: string;
  };
  paymentStructure: {
    type: string;
    fixedInstalmentDetails?: InstalmentFormDetails;
    flexibleInstalmentDetails?: FlexibleInstalmentDetails[];
  };
  remarks?: string;
}

export const schema: SchemaOf<ProposalForm> = object().shape({
  opportunitySource: object().shape({
    type: baseString().oneOf(Array.from(sources)).required(),
    title: baseString()
      .when("type", {
        is: "agent",
        then: baseString().required().label("Agency"),
      })
      .when("type", {
        is: "bgb",
        then: baseString().required().label("Unit number"),
      })
      .when("type", {
        is: "existing-buyer",
        then: baseString().required().label("Unit number"),
      })
      .when("type", {
        is: "business-associate",
        then: baseString().required().label("Company / individual name"),
      })
      .when("type", {
        is: "brand-collaboration",
        then: baseString().required().label("Event name"),
      }),
    fullName: baseString()
      .when("type", {
        is: "agent",
        then: baseString().required().label("Agent name"),
      })
      .when("type", {
        is: "kora",
        then: baseString().required().label("Referrer name"),
      })
      .when("type", {
        is: "digital",
        then: baseString().required().label("Caller name"),
      }),
    phoneCode: baseString()
      .when("type", {
        is: "agent",
        then: baseString().required().label("Dial code"),
      })
      .when("type", {
        is: "kora",
        then: baseString().required().label("Dial code"),
      })
      .when("type", {
        is: "digital",
        then: baseString().required().label("Dial code"),
      }),
    phoneNumber: baseString()
      .when("type", {
        is: "agent",
        then: baseString().required().label("Contact number"),
      })
      .when("type", {
        is: "kora",
        then: baseString().required().label("Contact number"),
      })
      .when("type", {
        is: "digital",
        then: baseString()
          .trim()
          .matches(/^[0-9]*$/, "Contact number must be in numbers.")
          .required()
          .label("Contact number"),
      }),
    email: baseString()
      .when("type", {
        is: "agent",
        then: baseString().required().email().label("Email"),
      })
      .when("type", {
        is: "kora",
        then: baseString().email().nullable().label("Email"),
      })
      .when("type", {
        is: "digital",
        then: baseString()
          .trim()
          .matches(
            /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
            "Email format is invalid"
          )
          .required()
          .label("Email"),
      }),
    commissionRate: number().required().label("Commission"),
    updatedCommissionRate: number().required(),
  }),
  customerBasicInfo: object().shape({
    id: number(),
    salutation: baseString()
      .oneOf(Array.from(salutations))
      .required()
      .label("Salutation"),
    fullName: baseString().required().label("Customer name"),
    phoneCode: baseString().required().label("Dial code"),
    phoneNumber: baseString()
      .matches(/^[0-9]*$/, "Contact number must be in numbers.")
      .required()
      .label("Contact number"),
    country: baseString().required().label("Country of origin"),
    email: baseString()
      .matches(
        /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
        "Email format is invalid"
      )
      .label("Email"),
  }),
  salesPackage: object().shape({
    paymentMethod: baseString().required().default(paymentMethods[0]),
    selectedSalesPackage: baseString()
      .oneOf(Array.from(salesPackages))
      .required()
      .default(salesPackages[0]),
    rebateType: baseString()
      .oneOf(Array.from(rebateTypes))
      .required()
      .default(rebateTypes[0]),
    rebate: number()
      .required()
      .default(0)
      .min(0)
      .max(50, "Nett selling price cannot be 50% lower than the gross price"),
    nettSellingPrice: number()
      .required()
      .min(1, "Nett selling price cannot be 0")
      .label("Nett selling price"),
    grrValues: object().when("selectedSalesPackage", {
      is: "grr",
      then: object().shape({
        rate: number()
          .oneOf(Array.from(grrRatesSelection))
          .required()
          .default(6),
        year: number()
          .oneOf(Array.from(grrYearsSelection))
          .required()
          .default(2),
      }),
    }),
    hooks: array()
      .of(
        object().shape({
          item: baseString().label("Item"),
          value: number().when("item", {
            is: (value: string) => !!value,
            then: number()
              .min(1)
              .label("Value")
              .required()
              .transform((currentValue, originalValue) =>
                originalValue === "" ? null : currentValue
              )
              .typeError("Must be a number"),
            otherwise: number()
              .min(1)
              .label("Value")
              .transform((currentValue, originalValue) =>
                originalValue === "" ? null : currentValue
              )
              .nullable()
              .typeError("Must be a number"),
          }),
        })
      )
      .default([0])
      .ensure(),
    remarks: baseString().label("Remarks").max(500),
  }),
  paymentStructure: object().shape({
    type: baseString()
      .oneOf(Array.from(paymentStructures))
      .required()
      .label("Payment structure type")
      .default(paymentStructures[0]),
    fixedInstalmentDetails: object().when("type", {
      is: "instalment",
      then: object().shape({
        initialPayment: number()
          .required()
          .typeError("Initial amount must be a number.")
          .integer()
          .min(1)
          .label("Initial amount"),
        instalmentPayment: number()
          .typeError("Instalment amount must be a number")
          .default(0)
          .transform((currentValue, originalValue) =>
            originalValue === "" ? 0 : currentValue
          )
          .nullable()
          .label("Instalment amount"),
        outstandingPayment: number()
          .typeError("Outstanding amount must be a number.")
          .default(0)
          .transform((currentValue, originalValue) =>
            originalValue === "" ? 0 : currentValue
          )
          .nullable()
          .label("Outstanding amount"),
      }),
    }),
    flexibleInstalmentDetails: array().when("type", {
      is: "flexible-instalment",
      then: array().of(
        object().shape({
          item: baseString().required().label("Item"),
          datePayable: baseString().required().label("Date payable"),
          amountPayable: number()
            .required()
            .positive()
            .integer()
            .label("Amount payable")
            .typeError("Amount payable must be a number."),
          remarks: baseString().nullable().label("Remarks"),
        })
      ),
    }),
  }),
  remarks: baseString().label("Remarks").max(500),
});

export interface EditProposalForm {
  customerBasicInfo: {
    id?: number;
    salutation: string;
    fullName: string;
    phoneCode: string;
    phoneNumber: string;
    country: string;
    email?: string;
  };
  salesPackage: {
    selectedSalesPackage: string;
    paymentMethod: string;
    rebateType: string;
    rebate: number;
    nettSellingPrice: number;
    grrValues?: GrrValue;
    hooks?: Hook[];
    remarks?: string;
  };
  paymentStructure: {
    type: string;
    fixedInstalmentDetails?: InstalmentFormDetails;
    flexibleInstalmentDetails?: FlexibleInstalmentDetails[];
  };
  remarks?: string;
}

export const editProposalSchema: SchemaOf<EditProposalForm> = object().shape({
  customerBasicInfo: object().shape({
    id: number(),
    salutation: baseString()
      .oneOf(Array.from(salutations))
      .required()
      .label("Salutation"),
    fullName: baseString().required().label("Customer name"),
    phoneCode: baseString().required().label("Dial code"),
    phoneNumber: baseString()
      .matches(/^[0-9]*$/, "Contact number must be in numbers.")
      .required()
      .label("Contact number"),
    country: baseString().required().label("Country of origin"),
    email: baseString()
      .matches(
        /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
        "Email format is invalid"
      )
      .label("Email"),
  }),
  salesPackage: object().shape({
    paymentMethod: baseString().required(),
    selectedSalesPackage: baseString()
      .oneOf(Array.from(salesPackages))
      .required(),
    rebateType: baseString().oneOf(Array.from(rebateTypes)).required(),
    rebate: number()
      .required()
      .default(0)
      .min(0)
      .max(50, "Nett selling price cannot be 50% lower than the gross price"),
    nettSellingPrice: number()
      .required()
      .min(1, "Nett selling price cannot be 0")
      .label("Nett selling price"),
    grrValues: object().when("selectedSalesPackage", {
      is: "grr",
      then: object().shape({
        rate: number().oneOf(Array.from(grrRatesSelection)).required(),
        year: number().oneOf(Array.from(grrYearsSelection)).required(),
      }),
    }),
    hooks: array()
      .of(
        object().shape({
          item: baseString().label("Item"),
          value: number().when("item", {
            is: (value: string) => !!value,
            then: number()
              .min(1)
              .label("Value")
              .required()
              .transform((currentValue, originalValue) =>
                originalValue === "" ? null : currentValue
              )
              .typeError("Must be a number"),
            otherwise: number()
              .min(1)
              .label("Value")
              .transform((currentValue, originalValue) =>
                originalValue === "" ? null : currentValue
              )
              .nullable()
              .typeError("Must be a number"),
          }),
        })
      )
      .default([0])
      .ensure(),
    remarks: baseString().label("Remarks").max(500),
  }),
  paymentStructure: object().shape({
    type: baseString()
      .oneOf(Array.from(paymentStructures))
      .required()
      .label("Payment structure type")
      .default(paymentStructures[0]),
    fixedInstalmentDetails: object().when("type", {
      is: "instalment",
      then: object().shape({
        initialPayment: number()
          .required()
          .typeError("Initial amount must be a number.")
          .positive()
          .integer()
          .min(1)
          .label("Initial amount"),
        instalmentPayment: number()
          .typeError("Instalment amount must be a number")
          .default(0)
          .transform((currentValue, originalValue) =>
            originalValue === "" ? 0 : currentValue
          )
          .nullable()
          .label("Instalment amount"),
        outstandingPayment: number()
          .typeError("Outstanding amount must be a number.")
          .default(0)
          .transform((currentValue, originalValue) =>
            originalValue === "" ? 0 : currentValue
          )
          .nullable()
          .label("Outstanding amount"),
      }),
    }),
    flexibleInstalmentDetails: array().when("type", {
      is: "flexible-instalment",
      then: array().of(
        object().shape({
          item: baseString().required().label("Item"),
          datePayable: baseString().required().label("Date payable"),
          amountPayable: number()
            .required()
            .label("Amount payable")
            .typeError("Amount payable must be a number."),
          remarks: baseString().nullable().label("Remarks"),
        })
      ),
    }),
  }),
  remarks: baseString().label("Remarks").max(500),
});
