import moment, { isMoment, Moment } from "moment";
import { Fragment } from "react";
import { ApprovalCategory } from "../../common/Approval";
import { CATALYST_CLOSING_FEE } from "../../common/ManagementApproval";
import { PackageableType, packageableType } from "../../common/SalesPackage";
import {
  Rebate,
  SalesPackage,
  OpportunitySource,
  UnitDiscountChart,
  ReviewDevelopmentImpact,
  ReviewPriceDistributionCoordinates,
} from "../../common/Types";
import {
  calculateRebatePercentage,
  calculateRebateValue,
  capitalize,
  formatNumber,
  rounding,
} from "../../utils/CommonUtils";
import UnitCostOfSalesCard from "../Cards/UnitCostOfSalesCard";
import InstalmentTable from "../Tables/InstalmentTable";
import ScheduleHTable from "../Tables/ScheduleHTable";
import TabBar from "../Tabs/TabBar";
import OpportunitySourceSummary from "./OpportunitySourceSummary";
import ReviewDevelopmentImpactTable from "./ReviewDevelopmentImpactTable";
import ReviewUnitCostOfSalesSlider from "./ReviewUnitCostOfSalesSlider";
import ReviewUnsoldUnitPriceDistribution from "./ReviewUnsoldUnitPriceDistribution";
import FlexibleInstalmentTableDisplay from "../Tables/FlexibleInstalmentTableDisplay";

interface ManagementApprovalTabsProps {
  tower?: string;
  salesPackage?: SalesPackage;
  opportunitySource?: OpportunitySource;
  approvalCategories: ApprovalCategory[];
  hasIndicator?: boolean;
  unitDiscountChart?: UnitDiscountChart;
  reviewDevelopmentImpact?: ReviewDevelopmentImpact;
  reviewPriceDistribution?: ReviewPriceDistributionCoordinates;
  indexOnChanged?: (index: number) => void;
}

const ManagementApprovalTabs: React.FC<ManagementApprovalTabsProps> = (
  props
) => {
  const {
    tower,
    salesPackage,
    opportunitySource,
    approvalCategories,
    hasIndicator,
    unitDiscountChart,
    reviewDevelopmentImpact,
    reviewPriceDistribution,
    indexOnChanged,
  } = props;

  /** Form Variables from API */
  const grossSellingPrice = salesPackage?.grossSellingPrice || 0;
  const nettSellingPrice = salesPackage?.nettSellingPrice || 0;
  const commissionFixedFee = opportunitySource?.updatedCommissionFixedFee || 0;
  const commissionRate = opportunitySource?.updatedCommissionRate || 0;
  const grrYield = salesPackage?.packageable?.rate || 0;
  const grrYear = salesPackage?.packageable?.numberOfYears || 0;

  /** Form Variables after calculations */
  const rebatePercentage = calculateRebatePercentage(
    grossSellingPrice,
    nettSellingPrice
  );
  const rebateAbsValue = calculateRebateValue(
    grossSellingPrice,
    nettSellingPrice
  );
  const commissionPercentage =
    opportunitySource?.type === "digital"
      ? (commissionFixedFee / grossSellingPrice) * 100
      : commissionRate;
  const grrValue = grrYield * grrYear;
  const unitCostOfSalesValue = rounding(
    rebatePercentage +
      commissionPercentage +
      grrValue +
      (CATALYST_CLOSING_FEE / grossSellingPrice) * 100,
    "round"
  );

  /** Instalment Variables */
  const paymentStartDate = isMoment(
    salesPackage?.structurable?.paymentStartDate
  )
    ? salesPackage?.structurable?.paymentStartDate
    : moment(salesPackage?.structurable?.paymentStartDate);
  const paymentEndDate = paymentStartDate
    ?.clone()
    .add(salesPackage?.structurable?.totalRepaymentMonths, "month");

  const tabs = approvalCategories.map((approval) => {
    let tabName = "";

    switch (approval) {
      case "instalment":
      case "flexible-instalment":
      case "progress-payment-stages":
        tabName = "Payment Structure";
        break;

      case "hooks":
        tabName = "Complimentary Package";
        break;

      case "rebate":
        tabName = "Exceed Cost of Sales";
        break;

      case "commission-change":
        tabName = "Commission Change";
        break;

      default:
        break;
    }

    return tabName;
  });

  return (
    <div className="overflow-y-auto">
      <TabBar
        tabs={tabs}
        hasIndicator={hasIndicator}
        className="pt-6 pb-3"
        indexOnChanged={indexOnChanged}
      >
        {approvalCategories?.map((approval) => {
          if (approval === "rebate")
            return (
              <Fragment key={`rebate-approval`}>
                <div className="row gap-2">
                  <div className="col px-5 pt-3 pb-6 mb-2 w-[65%] min-h-[250px] bg-section-background">
                    <h4 className="uppercase mb-4">
                      Review Unit Cost of Sales
                    </h4>

                    <div className="h-full justify-between">
                      <div className="items-center">
                        <ReviewUnitCostOfSalesSlider
                          value={unitCostOfSalesValue}
                          unitDiscountChart={unitDiscountChart}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col px-5 pt-3 pb-6 mb-2 w-[35%] min-h-[250px] bg-section-background">
                    <UnitCostOfSalesCard
                      unitCostOfSalesValue={unitCostOfSalesValue}
                      rebate={
                        salesPackage?.rebateType === "percentage"
                          ? `${rebatePercentage}%`
                          : `RM ${formatNumber(rebateAbsValue)}`
                      }
                      commission={
                        opportunitySource?.type === "digital"
                          ? formatNumber(commissionFixedFee)
                          : formatNumber(commissionRate)
                      }
                      grr={
                        packageableType(
                          salesPackage?.packageableType as PackageableType
                        ) === "grr"
                          ? `${formatNumber(
                              grrYield * grrYear
                            )}% (${grrYield}%, ${grrYear} year${
                              grrYear > 1 ? "s" : ""
                            })`
                          : "--"
                      }
                      salesPackage={salesPackage}
                      opportunitySource={opportunitySource}
                      reviewable={approvalCategories.includes("rebate")}
                    />
                  </div>
                </div>

                <div className="px-5 pt-3 pb-6 mb-2 bg-section-background">
                  <ReviewDevelopmentImpactTable
                    tower={tower}
                    reviewDevelopmentImpact={reviewDevelopmentImpact}
                  />
                </div>

                <div className="px-5 pt-3 pb-6 mb-2 bg-section-background">
                  <ReviewUnsoldUnitPriceDistribution
                    tower={tower}
                    reviewPriceDistribution={reviewPriceDistribution}
                  />
                </div>
              </Fragment>
            );

          if (
            approval === "instalment" ||
            approval === "flexible-instalment" ||
            approval === "progress-payment-stages"
          )
            return (
              <div
                key={`payment-structure-approval`}
                className="px-5 pt-3 bg-section-background mb-2"
              >
                <h4 className="uppercase">
                  Payment Structure{" "}
                  {`${
                    approvalCategories.includes("instalment")
                      ? `(Fixed Instalment)`
                      : approvalCategories.includes("flexible-instalment")
                      ? `(Flexible Instalment)`
                      : `(Progressive Payment)`
                  }`}
                </h4>

                <div className="w-full">
                  {approvalCategories.includes("instalment") && (
                    <InstalmentTable
                      initialPayment={
                        salesPackage?.structurable?.initialPayment as number
                      }
                      instalmentPayment={
                        salesPackage?.structurable?.instalmentPayment as number
                      }
                      outstandingPayment={
                        salesPackage?.structurable?.outstandingPayment as number
                      }
                      paymentStartDate={paymentStartDate as Moment}
                      paymentEndDate={paymentEndDate as Moment}
                      pricePerMonth={rounding(
                        (salesPackage?.structurable
                          ?.instalmentPayment as number) /
                          (salesPackage?.structurable
                            ?.totalRepaymentMonths as number),
                        "floor"
                      )}
                      pricePerMonthRemainder={
                        (salesPackage?.structurable
                          ?.instalmentPayment as number) %
                        (salesPackage?.structurable
                          ?.totalRepaymentMonths as number)
                      }
                      isModal={true}
                      editable={false}
                    />
                  )}

                  {approvalCategories.includes("flexible-instalment") && (
                    <FlexibleInstalmentTableDisplay
                      grossSellingPrice={salesPackage?.grossSellingPrice}
                      details={salesPackage?.structurable?.details}
                    />
                  )}

                  {approvalCategories.includes("progress-payment-stages") && (
                    <ScheduleHTable
                      totalPrice={grossSellingPrice}
                      nettSellingPrice={nettSellingPrice}
                      totalRebate={
                        salesPackage?.rebateType === "percentage"
                          ? rebatePercentage
                          : rebateAbsValue
                      }
                      progressRebates={
                        salesPackage?.structurable?.rebates as Rebate[]
                      }
                      editable={false}
                      isModal={true}
                      setProgressRebates={() => {}}
                      setRemainingRebate={() => {}}
                      rebateType={salesPackage?.rebateType || ""}
                    />
                  )}
                </div>
              </div>
            );

          if (approval === "hooks" && (salesPackage?.hooks?.length || 0) > 0)
            return (
              <Fragment key={`hooks-approval`}>
                <div className="px-5 pt-3 pb-6 mb-2 bg-section-background">
                  <h4 className="uppercase mb-4">Complimentary Package</h4>

                  <table className="bg-transparent table-fixed w-full">
                    <thead>
                      <tr className="h-6 border-none">
                        <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Item
                        </th>
                        <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                          Value
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {salesPackage?.hooks?.map((hook, index) => {
                        return (
                          <tr
                            key={`unit-no-${index}`}
                            className="h-6 border-none"
                          >
                            <td className="text-sm font-bold p-0 py-2 whitespace-normal">
                              {capitalize(hook?.item || "", true)}
                            </td>
                            <td className="text-sm font-bold p-0 py-2">
                              RM {formatNumber(hook?.value || 0)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Fragment>
            );

          if (approval === "commission-change")
            return (
              <Fragment key={`commission-change-approval`}>
                <OpportunitySourceSummary
                  opportunitySource={opportunitySource as OpportunitySource}
                  className="px-5 pt-3 pb-6 mb-2 bg-section-background"
                  titleClassName="uppercase"
                />
              </Fragment>
            );

          return <></>;
        })}
      </TabBar>
    </div>
  );
};

export default ManagementApprovalTabs;
