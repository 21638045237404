import React from "react";
import TextField from "../Fields/TextField";
import PabloIcon from "../Icons/PabloIcon";
import Icon from "../Icons/Icon";
import classNames from "classnames";
import { Validation } from "../../hooks/UseValidation";
import { useArray } from "../../hooks/UseArray";
import { Hook } from "../../common/Types";
import { IFormSchema } from "../../common/Interfaces";

interface HooksFormCardProps<T extends IFormSchema> {
  validation: Validation<T>;
  defaultValues?: Hook[];
}

const HooksFormCard: React.FC<HooksFormCardProps<any>> = (props) => {
  const MAX_HOOKS = 5;
  const { validation, defaultValues } = props;
  const error = validation.errors?.salesPackage?.hooks;

  const [hookIndexes, dispatchHookIndexes] = useArray<number>(
    defaultValues?.length
      ? Array.from(defaultValues?.filter((v) => v).keys())
      : [0]
  );

  const onAddHookField = () => {
    dispatchHookIndexes({
      type: "add",
      value: hookIndexes[hookIndexes.length - 1] + 1,
    });
  };

  const onClearHookField = (index: number) => {
    validation.setValue(`salesPackage.hooks.${index}`, {
      item: "",
      value: undefined,
    });
  };

  const onDeleteHookField = (index: number) => {
    onClearHookField(index);
    dispatchHookIndexes({
      type: "remove",
      value: hookIndexes.find((v) => v === index) as number,
    });
    validation.unregister(`salesPackage.hooks.${index}`);
  };

  return (
    <>
      <div
        className={classNames(
          "col w-full tablet:w-[498px] border border-solid border-[#A9695B33] flex justify-start gap-2 py-2",
          {
            "pb-4": hookIndexes.length >= MAX_HOOKS,
          }
        )}
      >
        <div className="row flex justify-start gap-3 px-4 text-sm font-bold">
          <div className="w-[70%]">Item</div>
          <div className="w-[25%]">Value (RM)</div>
          <div className="w-[5%]">&nbsp;</div>
        </div>

        {hookIndexes.map((element, index) => (
          <div
            key={`hook-${index}`}
            className="row flex justify-start gap-3 px-4"
          >
            <div className="w-[70%]">
              <TextField
                id={`hook-item-${index}`}
                type="text"
                error={error?.[element]?.item?.message}
                innerClassName="w-full text-xs h-9 py-0 px-2"
                placeholder="Enter Item"
                dynamicWidth={true}
                defaultValue={defaultValues?.[index]?.item}
                {...validation.register(`salesPackage.hooks.${element}.item`)}
              />
            </div>
            <div className="w-[25%]">
              <TextField
                id={`hook-value-${index}`}
                type="text"
                error={error?.[element]?.value?.message}
                innerClassName="w-full text-xs h-9 py-0 px-2"
                placeholder="Enter Value"
                dynamicWidth={true}
                defaultValue={defaultValues?.[index]?.value}
                {...validation.register(`salesPackage.hooks.${element}.value`)}
              />
            </div>

            <div
              id={`hook-delete-icon-${index}`}
              className="w-[5%] my-auto cursor-pointer"
              onClick={() =>
                index === 0
                  ? onClearHookField(element)
                  : onDeleteHookField(element)
              }
            >
              <PabloIcon
                icon={Icon.remove}
                className="w-[20px] h-[20px] gray-icon"
              />
            </div>
          </div>
        ))}

        {hookIndexes.length < MAX_HOOKS && (
          <div className="row w-full px-4">
            <button
              id="add-hook-button"
              className="text-xs text-[#0880e3] hover:text-[#084de3]"
              onClick={onAddHookField}
            >
              + Add Complimentary Package
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default HooksFormCard;
