import React, { useState } from "react";
import { Role, roleName } from "../../common/Permission";
import { User } from "../../common/Types";
import { useDeviceContext } from "../../hooks/contexts/DeviceContext";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import Avatar from "../Images/Avatar";

interface AccountTileProps {
  user: User | undefined;
}

const AccountTile: React.FC<AccountTileProps> = ({ user, children }) => {
  const device = useDeviceContext();
  const [expanded, setExpanded] = useState(false);

  const close = () => {
    setExpanded(false);
  };

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      tabIndex={-1}
      onBlur={close}
      className="relative cursor-pointer focus:outline-none"
    >
      <button
        onClick={toggle}
        className="row justify-start items-center py-2 gap-3"
      >
        {device.isPortrait ? (
          <Avatar user={user} />
        ) : (
          <>
            <Avatar user={user} />
            <div className="col items-start">
              <span className="font-bold text-xs truncate max-w-[200px]">
                {user?.fullName}
              </span>
              <span className="text-xs">
                {user?.roles && roleName(user.roles[0] as Role)}
              </span>
            </div>
            <PabloIcon icon={Icon.arrow} className="transform -rotate-90" />
          </>
        )}
      </button>

      <div
        hidden={!expanded}
        className="z-10 origin-top-right absolute right-0 mt-2 border-[1px] w-56 border-secondary bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div
          className="col"
          onMouseDown={(e) => e.preventDefault()}
          onClick={toggle}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AccountTile;
