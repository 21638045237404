import classNames from "classnames";
import React, { createContext, useContext, useState } from "react";

type LoadingPageContextType = {
  start: () => void;
  end: () => void;
  wrap: (callback: () => Promise<any>) => any;
};

const defaultContext: LoadingPageContextType = {
  start: () => {},
  end: () => {},
  wrap: (callback: () => Promise<any>) => {},
};

const LoadingPageContext =
  createContext<LoadingPageContextType>(defaultContext);

export const useLoadingPageContext = () => useContext(LoadingPageContext);

export const LoadingPageContextProvider: React.FC = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const start = () => setLoading(true);

  const end = () => setLoading(false);

  const wrap = async (callback: Function) => {
    setLoading(true);
    await callback();
    setLoading(false);
  };

  return (
    <LoadingPageContext.Provider value={{ start, end, wrap }}>
      {isLoading && (
        <div className="fixed col items-center justify-center z-[200] bg-white bg-opacity-40 left-0 right-0 top-0 bottom-0 overflow-hidden">
          <div className="animate-spin h-28 w-28 rounded-full border-r-4 border-primary" />
        </div>
      )}
      <div
        className={classNames({
          "overflow-hidden": isLoading,
        })}
      >
        {children}
      </div>
    </LoadingPageContext.Provider>
  );
};
