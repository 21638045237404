import React from "react";
import { AgencyListContextProvider } from "./AgencyListContext";
import { AgentListContextProvider } from "./AgentListContext";
import { BookingApprovalListContextProvider } from "./BookingApprovalListContext";
import { AuthContextProvider } from "./AuthContext";
import { DeviceContextProvider } from "./DeviceContext";
import { LegacyProposalListContextProvider } from "./LegacyProposalListContext";
import { LoadingBarContextProvider } from "./LoadingBarContext";
import { LoadingPageContextProvider } from "./LoadingPageContext";
import { ManagementApprovalListContextProvider } from "./ManagementApprovalListContext";
import { ModalContextProvider } from "./ModalContext";
import { MyProposalListContextProvider } from "./MyProposalListContext";
import { PropertyContextProvider } from "./PropertyContext";
import { ProposalListContextProvider } from "./ProposalListContext";
import { UnitListContextProvider } from "./UnitListContext";
import { UserListContextProvider } from "./UserListContext";
import { LeadManagementContextProvider } from "./LeadManagementContext";

const AppContext: React.FC = ({ children }) => {
  return (
    <DeviceContextProvider>
      <PropertyContextProvider>
        <LoadingPageContextProvider>
          <LoadingBarContextProvider>
            <AuthContextProvider>
              {/* Add new provider here */}
              <ModalContextProvider>
                <UnitListContextProvider>
                  <ProposalListContextProvider>
                    <MyProposalListContextProvider>
                      <LegacyProposalListContextProvider>
                        <UserListContextProvider>
                          <AgencyListContextProvider>
                            <AgentListContextProvider>
                              <BookingApprovalListContextProvider>
                                <ManagementApprovalListContextProvider>
                                  <LeadManagementContextProvider>
                                    {children}
                                  </LeadManagementContextProvider>
                                </ManagementApprovalListContextProvider>
                              </BookingApprovalListContextProvider>
                            </AgentListContextProvider>
                          </AgencyListContextProvider>
                        </UserListContextProvider>
                      </LegacyProposalListContextProvider>
                    </MyProposalListContextProvider>
                  </ProposalListContextProvider>
                </UnitListContextProvider>
              </ModalContextProvider>
              {/* Add new provider here */}
            </AuthContextProvider>
          </LoadingBarContextProvider>
        </LoadingPageContextProvider>
      </PropertyContextProvider>
    </DeviceContextProvider>
  );
};

export default AppContext;
