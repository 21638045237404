import PabloIcon from "../Icons/PabloIcon";
import Icon from "../Icons/Icon";
import classNames from "classnames";

interface ErrorTextProps {
  text: string;
  className?: string;
}

const ErrorText: React.FC<ErrorTextProps> = (props) => {
  return (
    <div
      className={classNames(
        "flex items-center tablet:items-end justify-start gap-2 w-full",
        props.className
      )}
    >
      <div className="w-[20px] my-auto">
        <PabloIcon
          icon={Icon.info}
          className="w-[20px] h-[20px] primary-icon"
        />
      </div>
      <div className="w-full text-sm text-[#B11116]">{props.text}</div>
    </div>
  );
};

export default ErrorText;
