import { Path } from "react-hook-form";
import { number, string } from "yup";
import { IFormSchema } from "../common/Interfaces";
import { Attachment } from "../common/Types";
import { Validation } from "../hooks/UseValidation";

export const baseString = () =>
  string()
    .trim()
    .max(255)
    .matches(/^$|[a-zA-Z0-9]+/, "Must contain at least one letter or number.");

export const twoDecimalsNumber = (label: string) =>
  number()
    .typeError(label + " must contain a number.")
    .test(
      "is-two-decimal",
      label + " must contain 2 decimal places only",
      (val: any) => {
        const regex = /^(?:[1-9]\d+|\d)(?:\.(\d\d|\d))?$/;

        if (val) {
          return regex.test(val);
        }

        return true;
      }
    )
    .min(0)
    .max(2000000000);

export const clearAttachments = <T extends IFormSchema>(
  items: Attachment[],
  dispatcher: Function,
  fieldPrefix: Path<T>,
  validation: Validation<T>
): void => {
  if (items.length) {
    items.forEach((item, itemIndex) => {
      URL.revokeObjectURL(item.blobPath);
      validation.unregister(`${fieldPrefix}.${itemIndex}` as Path<T>);
    });

    dispatcher({
      type: "clear",
      value: {} as Attachment,
    });
  }
};
