/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import { object, SchemaOf, array, number } from "yup";
import { Proposal } from "../../common/Types";
import BookingInfoAccordian from "../../components/Accordion/BookingInfoAccordian";
import AddItemButton from "../../components/Buttons/AddItemButton";
import BackButton from "../../components/Buttons/BackButton";
import { GroupExpandableCard } from "../../components/Cards/ExpandableCard";
import FormCard from "../../components/Cards/FormCard";
import DatePickerField from "../../components/Fields/DatePickerField";
import BottomBar from "../../components/Navs/BottomBar";
import { useBookingApprovalListContext } from "../../hooks/contexts/BookingApprovalListContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { useArray } from "../../hooks/UseArray";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import { baseString } from "../../utils/SchemaUtil";
import LimboPage from "../generals/LimboPage";
import { MAX_BOOKINGS } from "../../common/Booking";
import { useMultipleRefs } from "../../hooks/useMultipleRefs";
import Line from "../../components/General/Line";
import TextField from "../../components/Fields/TextField";
import DropdownField from "../../components/Fields/DropdownField";
import Textarea from "../../components/General/Textarea";
import classNames from "classnames";
import PabloIcon from "../../components/Icons/PabloIcon";
import Icon from "../../components/Icons/Icon";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import CheckField from "../../components/Fields/CheckField";

interface ConvertToSoldForm {
  spaSignedDate: string;
  paidDate: string;
  bookingInfo: {
    bookingReceived: number;
    paymentMethod: string;
    currencyCode: string;
    attachments: any[];
    remarks?: string;
  }[];
  catalystsInvolved?: {
    id?: string;
  }[];
  remarks?: string;
}

const schema: SchemaOf<ConvertToSoldForm> = object().shape({
  spaSignedDate: baseString().required().label("SPA signed date"),
  paidDate: baseString().required().label("Paid date"),
  bookingInfo: array()
    .of(
      object().shape({
        bookingReceived: number()
          .typeError("Payment amount received must contain a number.")
          .test(
            "is-two-decimal",
            "Payment amount must contain 2 decimal places only",
            (val: any) => {
              const regex = /^(?:[1-9]\d+|\d)(?:\.(\d\d|\d))?$/;

              if (val) {
                return regex.test(val);
              }

              return true;
            }
          )
          .min(0)
          .max(2000000000)
          .required()
          .label("Payment amount received"),
        paymentMethod: baseString().required().label("Payment method"),
        currencyCode: baseString().required().label("Currency"),
        attachments: array().min(1).required().label("Proof of payment"),
        remarks: baseString().label("Payment Remarks").max(500),
      })
    )
    .default([0])
    .ensure(),
  catalystsInvolved: array()
    .of(
      object().shape({
        id: baseString().label("Catalysts involved"),
      })
    )
    .ensure(),
  remarks: baseString().label("Remarks").max(500),
});

const ConvertToSoldFormPage: React.FC = () => {
  const MAX_CATALYSTS_INVOLVED = 2;
  const now = moment().format("YYYY-MM-DD");
  const { proposalId } = useParams();
  const navigate = useNavigate();
  const bookingApprovalList = useBookingApprovalListContext();
  const modal = useModalContext();
  const loadingBar = useLoadingBarContext();
  const loadingPage = useLoadingPageContext();
  const { setDirty } = useUnload();
  const paymentRefs: React.RefObject<any>[] = useMultipleRefs(MAX_BOOKINGS);

  const validation = useValidation<ConvertToSoldForm>(schema);
  const { register, unregister, resetField, watch, handleSubmit, errors } =
    validation;
  const { scrollOnError } = useScrollToError<ConvertToSoldForm>();
  const convertToSoldForm = watch();

  const [proposal, setProposal] = useState<Proposal | undefined>(
    useLocation().state
  );
  const [paymentInfoIndexes, dispatchPaymentInfoIndexes] = useArray<number>([
    0,
  ]);
  const [catalystIndexes, dispatchCatalystIndexes] = useArray<number>([]);
  const [isBulkDeal, setBulkDeal] = useState<boolean>(false);
  const [businessAssociate, setBusinessAssociate] = useState<string>("");

  useEffect(() => {
    if (!proposal) showProposal();
    bookingApprovalList.getBusinessAssociates();
  }, []);

  useEffect(() => {
    scrollOnError(errors);
  }, [errors]);

  const showProposal = async () => {
    loadingPage.start();

    const selectedProposal = await bookingApprovalList.show(
      parseInt(proposalId!)
    );

    setProposal(selectedProposal);

    loadingPage.end();
  };

  if (!proposal && !bookingApprovalList.isLoading) {
    return <LimboPage type="not-found" />;
  }

  if (!proposal) {
    return <></>;
  }

  const submitForm = () => {
    setDirty(false);

    modal.confirmation({
      title: "You're about to convert this proposal to sold",
      description:
        "You won’t be able to make changes to this proposal once you've submitted. Please ensure that the details are correct before proceeding with your submission.",
      onConfirm: {
        text: "Submit",
        action: convert,
      },
    });
  };

  const convert = async () => {
    loadingPage.start();

    const { spaSignedDate, paidDate, bookingInfo, catalystsInvolved, remarks } =
      convertToSoldForm;
    const formData = new FormData();

    formData.append("spa_signed_at", spaSignedDate);
    formData.append("paid_at", paidDate);
    remarks && formData.append("remarks", remarks);

    /** Catalysts Involved */
    catalystsInvolved?.forEach((catalyst, index, arr) => {
      if (catalyst?.id && arr.findIndex((x) => catalyst.id === x.id) === index)
        formData.append(`users[${index}]`, catalyst.id);
    });

    /** Payment Info */
    bookingInfo?.forEach((payment, index) => {
      payment?.paymentMethod &&
        formData.append(
          `payment[${index}][payment_method]`,
          payment?.paymentMethod
        );
      payment?.currencyCode &&
        formData.append(
          `payment[${index}][currency_code]`,
          payment.currencyCode
        );
      payment?.bookingReceived &&
        formData.append(
          `payment[${index}][amount]`,
          payment?.bookingReceived.toString()
        );
      payment?.remarks &&
        formData.append(`payment[${index}][remarks]`, payment?.remarks);

      /** Payment Proof of Payment */
      payment?.attachments?.forEach((attachment, attachmentIndex) => {
        formData.append(
          `payment[${index}][attachments][${attachmentIndex}][id]`,
          attachment.id
        );
        formData.append(
          `payment[${index}][attachments][${attachmentIndex}][file]`,
          attachment.blob
        );
      });
    });

    /** Bulk Deal Info */
    if (isBulkDeal) {
      formData.append(`bulk_deal[is_bulk_deal]`, isBulkDeal ? "1" : "0");

      businessAssociate &&
        formData.append(`bulk_deal[entity]`, businessAssociate);
    }

    const result = await bookingApprovalList.convertToSold(
      proposal.id,
      formData
    );

    loadingPage.end();

    if (result) toast.success("Proposal has been converted to sold.");
    else toast.error("Conversion to sold failed");

    navigate(Path.bookingApprovals);
  };

  const onAddCatalyst = async () => {
    if (bookingApprovalList.handlers.length <= 0) {
      loadingBar.start();
      await bookingApprovalList.getHandlers();
      loadingBar.end();
    }

    dispatchCatalystIndexes({
      type: "add",
      value: catalystIndexes.length
        ? catalystIndexes[catalystIndexes.length - 1] + 1
        : 0,
    });
  };

  const onDeleteCatalyst = async (index: number) => {
    setDirty(true);

    dispatchCatalystIndexes({
      type: "remove",
      value: catalystIndexes.find((v) => v === index) as number,
    });

    resetField(`catalystsInvolved.${index}`);
    unregister(`catalystsInvolved.${index}`);
  };

  const onDeletePaymentInfoField = (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Delete Payment Information",
      description: "Are you sure you want to delete this payment information?",
      onConfirm: {
        text: "Delete",
        action: () => {
          setDirty(true);
          paymentRefs[realIndex].current.onClearAttachments();

          dispatchPaymentInfoIndexes({
            type: "remove",
            value: paymentInfoIndexes.find((v) => v === index) as number,
          });

          resetField(`bookingInfo.${index}`);
          unregister(`bookingInfo.${index}`);
        },
      },
    });
  };

  const onClearPaymentInfoField = async (index: number, realIndex: number) => {
    modal.confirmation({
      title: "Clear Payment Information",
      description: "Are you sure you want to proceed with removing the values?",
      onConfirm: {
        text: "Clear",
        action: () => {
          setDirty(true);
          paymentRefs[realIndex].current.onClearAttachments();

          dispatchPaymentInfoIndexes({
            type: "add",
            value: paymentInfoIndexes[paymentInfoIndexes.length - 1] + 1,
          });

          setTimeout(() => {
            dispatchPaymentInfoIndexes({
              type: "remove",
              value: paymentInfoIndexes.find((v) => v === index) as number,
            });

            resetField(`bookingInfo.${index}`);
            unregister(`bookingInfo.${index}`);
          }, 1);
        },
      },
    });
  };

  const handleSoldDate = () => {
    let soldDate = "";

    if (convertToSoldForm?.spaSignedDate)
      soldDate = convertToSoldForm.spaSignedDate;

    if (convertToSoldForm?.paidDate) soldDate = convertToSoldForm.paidDate;

    if (convertToSoldForm?.spaSignedDate && convertToSoldForm?.paidDate) {
      soldDate = moment(convertToSoldForm.spaSignedDate).isSameOrAfter(
        convertToSoldForm.paidDate
      )
        ? convertToSoldForm.spaSignedDate
        : convertToSoldForm.paidDate;
    }

    return soldDate ? moment(soldDate).format("DD/MM/YYYY") : "";
  };

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton text="Back to CE approval listing" />

          <FormCard
            showRequired={false}
            title={`Convert to Sold (${proposal.unit?.number})`}
          >
            <div className="col gap-5 mb-2">
              <h4 className="font-bold">Sold Details</h4>
              <div className="row -mb-1 gap-4">
                <DatePickerField
                  id="spa-signed-date"
                  label="SPA Signed Date"
                  required={true}
                  error={errors.spaSignedDate?.message}
                  max={now}
                  onInput={() => setDirty(true)}
                  {...register("spaSignedDate")}
                />

                <DatePickerField
                  id="paid-date"
                  label="1st 10% Paid On"
                  required={true}
                  error={errors.paidDate?.message}
                  max={now}
                  onInput={() => setDirty(true)}
                  {...register("paidDate")}
                />
              </div>

              <div className="row -mb-1 gap-4">
                <TextField
                  id="sold-date"
                  label="Sold Date"
                  required={false}
                  type="text"
                  placeholder="SPA Date or Paid Date (whichever is later)"
                  disabled={true}
                  value={handleSoldDate()}
                />

                <div className="col mb-5 w-full tablet:w-[498px]" />
              </div>
            </div>

            <Line />

            <div className="col gap-0">
              <div className="col">
                <h4 className="font-bold">Payment Details</h4>

                <GroupExpandableCard
                  defaultValue="payment-info-1"
                  onExpand={() => {}}
                >
                  {paymentInfoIndexes.map((paymentInfo, index) => (
                    <BookingInfoAccordian
                      ref={paymentRefs[index]}
                      key={`payment-info-${paymentInfo}`}
                      value={`payment-info-${index + 1}`}
                      title={`Payment Information ${index + 1}`}
                      index={paymentInfo}
                      showRequired={true}
                      validation={validation}
                      className="shadow-none mx-0 mt-4 mb-0 border border-[#53565A33]"
                      onDeleteAction={
                        paymentInfoIndexes.length > 1
                          ? () => onDeletePaymentInfoField(paymentInfo, index)
                          : () => onClearPaymentInfoField(paymentInfo, index)
                      }
                      setDirty={setDirty}
                    />
                  ))}

                  {paymentInfoIndexes.length < MAX_BOOKINGS && (
                    <AddItemButton
                      id="add-payment-info-button"
                      text="+ Add new payment information"
                      onClick={() =>
                        dispatchPaymentInfoIndexes({
                          type: "add",
                          value:
                            paymentInfoIndexes[paymentInfoIndexes.length - 1] +
                            1,
                        })
                      }
                    />
                  )}
                </GroupExpandableCard>
              </div>
            </div>

            <Line />

            <div className="col gap-4">
              <h4 className="font-bold">Catalyst Details</h4>

              {bookingApprovalList.handlers.length > 0 && (
                <>
                  {catalystIndexes.map((catalyst, index) => (
                    <div
                      key={`catalyst-container-${catalyst}`}
                      className="row -mb-1 justify-start"
                    >
                      <DropdownField
                        id={`catalyst-${catalyst}`}
                        key={`catalyst-${catalyst}`}
                        label={`Catalyst ${index + 1}`}
                        error=""
                        placeholder="Select Catalyst"
                        required={false}
                        items={bookingApprovalList.handlers
                          .filter((user) => user.id !== proposal.user?.id)
                          .map((user) => {
                            return {
                              text: user.name,
                              value: user.id.toString(),
                            };
                          })}
                        defaultValue={
                          convertToSoldForm?.catalystsInvolved?.[catalyst]
                            ?.id || ""
                        }
                        searchable="true"
                        {...register(`catalystsInvolved.${catalyst}.id`, {
                          value:
                            convertToSoldForm?.catalystsInvolved?.[catalyst]
                              ?.id || "",
                          onChange: () => setDirty(true),
                        })}
                      />
                      <div className="col">
                        <PabloIcon
                          icon={Icon.remove}
                          className={classNames(
                            "transition-all duration-200 hover:gray-icon  cursor-pointer my-6 ml-6"
                          )}
                          onClick={() => onDeleteCatalyst(catalyst)}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}

              <AddItemButton
                id="add-catalyst-involved-button"
                text="+ Add Catalysts Involved"
                containerClassName={classNames("w-full tablet:w-[498px] mt-0", {
                  hidden: catalystIndexes.length >= MAX_CATALYSTS_INVOLVED,
                })}
                onClick={() => onAddCatalyst()}
              />
            </div>

            <Line />

            <div className="col gap-4">
              <h4 className="font-bold">Bulk Deal Indicator</h4>

              <CheckField
                label="Bulk Deal"
                checked={isBulkDeal}
                onChange={() => setBulkDeal(!isBulkDeal)}
              />

              {isBulkDeal && (
                <DropdownField
                  id={`business-associate-dropdown`}
                  key={`business-associate-dropdown`}
                  label="Business Associate"
                  error=""
                  placeholder="Select business associate"
                  required={false}
                  items={bookingApprovalList.businessAssociates.map(
                    (associate) => {
                      return {
                        text: associate.name,
                        value: associate.name,
                      };
                    }
                  )}
                  searchable="true"
                  value={businessAssociate}
                  onChange={(e) => {
                    setBusinessAssociate(e.target.value);
                  }}
                />
              )}
            </div>

            <Line />

            <div className="col gap-5 mb-4">
              <h4 className="font-bold">Other Details</h4>

              <div className="row -mb-1">
                <Textarea
                  id="sold-remarks"
                  rows={5}
                  label="Remarks"
                  error={errors?.remarks?.message}
                  required={false}
                  maxLength={500}
                  {...validation.register("remarks", {
                    setValueAs: (v) => v.trim(),
                  })}
                />
              </div>
            </div>
          </FormCard>
        </div>

        <BottomBar>
          <button
            id="convert-to-sold-button"
            className="primary-button"
            onClick={handleSubmit(submitForm)}
          >
            Convert to Sold
          </button>
        </BottomBar>
      </div>
    </>
  );
};

export default ConvertToSoldFormPage;
