/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { number, object, SchemaOf } from "yup";
import Country from "../../common/Country";
import { agentStatuses } from "../../common/Enums";
import Path from "../../common/Path";
import { Agency, Agent } from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import DropdownField from "../../components/Fields/DropdownField";
import TextField from "../../components/Fields/TextField";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import BottomBar from "../../components/Navs/BottomBar";
import { useAgentListContext } from "../../hooks/contexts/AgentListContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import { capitalize } from "../../utils/CommonUtils";
import { baseString } from "../../utils/SchemaUtil";

interface AgentForm {
  agencyId: number;
  fullName: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  status: string;
}

const AgentFormPage: React.FC = () => {
  const { agentId } = useParams();
  const { state } = useLocation();
  const agent: Agent | null = state?.agent;
  const agencies: Agency[] | [] = state.agencies;

  const schema: SchemaOf<AgentForm> = object().shape({
    agencyId: number()
      .required()
      .min(0, "Agency is required")
      .default(agent?.agency?.id)
      .label("Agency"),
    fullName: baseString()
      .required()
      .default(agent?.fullName)
      .label("Agent name"),
    email: baseString()
      .matches(
        /^$|^[a-zA-Z0-9+._%-+]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+/,
        "Email format is invalid"
      )
      .required()
      .default(agent?.email)
      .label("Email"),
    phoneCode: baseString()
      .required()
      .default(agent?.phoneCode.toString())
      .label("Dial code"),
    phoneNumber: baseString()
      .matches(/^[0-9]*$/, "Contact number must be in numbers.")
      .required()
      .default(agent?.phoneNumber)
      .label("Contact number"),
    status: baseString()
      .oneOf(Array.from(agentStatuses))
      .required()
      .default(agent?.status)
      .label("Agent status"),
  });

  const navigate = useNavigate();
  const agentList = useAgentListContext();
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<AgentForm>(schema);
  const { scrollOnError } = useScrollToError<AgentForm>();
  const { setDirty } = useUnload();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const submitForm = async (body: AgentForm) => {
    let success = false;
    loadingPage.start();

    if (!agentId) {
      success = await agentList.store(body);
    } else {
      success = await agentList.update(parseInt(agentId), body);
    }

    loadingPage.end();
    setShowSuccess(success);
  };

  useEffect(() => {
    scrollOnError(validation.errors);
  }, [validation.errors]);

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton text="Back to manage agent" />

          <FormCard title={agentId ? "Edit agent" : "Add new agent"}>
            <div className="gap-3 col">
              <h3>Agent details</h3>
              <div className="col gap-5 mt-2">
                <div className="tablet:row w-full gap-4">
                  <TextField
                    id="agent-name"
                    label="Agent Name"
                    type="text"
                    defaultValue={agent?.fullName}
                    error={validation.errors.fullName?.message}
                    onInput={() => setDirty(true)}
                    {...validation.register("fullName", {
                      setValueAs: (v) => v.trim(),
                    })}
                  />
                  <div className="mb-5" />

                  <DropdownField
                    id="agent-agency"
                    label="Agency"
                    error={validation.errors.agencyId?.message}
                    placeholder="Select Agency"
                    items={agencies.map((agency) => {
                      return {
                        text: agency.name,
                        value: agency.id.toString(),
                      };
                    })}
                    searchable="true"
                    defaultValue={agent?.agency?.id.toString()}
                    {...validation.register("agencyId", {
                      value: agent?.agency?.id || -1,
                      onChange: () => setDirty(true),
                    })}
                  />
                </div>

                <div className="tablet:row w-full gap-4">
                  <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
                    <DropdownField
                      id="agent-dial-code"
                      label="Dial Code"
                      error={validation.errors.phoneCode?.message}
                      placeholder="Select Dial Code"
                      items={Country.countryData.map((country) => {
                        return {
                          text: `+${country.dialCode} - ${country.name}`,
                          value: country.dialCode,
                        };
                      })}
                      searchable="true"
                      dynamicWidth={true}
                      containerClassName="w-2/6"
                      listClassName="min-w-[420px]"
                      defaultValue={agent?.phoneCode.toString()}
                      showValue={true}
                      {...validation.register("phoneCode", {
                        value: agent?.phoneCode.toString() || "",
                        onChange: () => setDirty(true),
                      })}
                    />
                    <TextField
                      id="agent-contact-number"
                      label="Contact No."
                      type="tel"
                      defaultValue={agent?.phoneNumber}
                      dynamicWidth={true}
                      containerClassName="w-4/6"
                      error={validation.errors.phoneNumber?.message}
                      onInput={() => setDirty(true)}
                      {...validation.register("phoneNumber", {
                        setValueAs: (v) => v.trim(),
                      })}
                    />
                  </div>
                  <div className="mb-5" />

                  <TextField
                    id="agent-email"
                    label="Email"
                    type="text"
                    defaultValue={agent?.email}
                    error={validation.errors.email?.message}
                    onInput={() => setDirty(true)}
                    {...validation.register("email", {
                      setValueAs: (v) => v.trim().toLowerCase(),
                    })}
                  />
                </div>

                <div className="tablet:row w-full gap-4">
                  <DropdownField
                    id="agent-status"
                    label="Status"
                    error={validation.errors.status?.message}
                    placeholder="Select Status"
                    items={agentStatuses.map((status) => {
                      return {
                        text: capitalize(status),
                        value: status,
                      };
                    })}
                    defaultValue={agent?.status}
                    {...validation.register("status", {
                      value: agent?.status || "",
                      onChange: () => setDirty(true),
                    })}
                  />
                  <div className="mb-5" />
                  <div className="col mb-5 w-full tablet:w-[498px]" />
                </div>
              </div>
            </div>
          </FormCard>
        </div>

        <BottomBar>
          <button
            id="submit-agent-button"
            className="primary-button"
            onClick={validation.handleSubmit(() => {
              setDirty(false);
              setShowConfirmation(true);
            })}
          >
            {agentId ? "Save" : "Add"}
          </button>
        </BottomBar>
      </div>

      {/* modal */}
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        type="warning"
        title={
          agentId
            ? "Are you sure you want to update the agent details?"
            : "Are you sure you want to add this new agent?"
        }
        onConfirm={{
          text: agentId ? "Update" : "Add",
          action: validation.handleSubmit(submitForm),
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        dismissible={false}
        onHide={() => setShowSuccess(false)}
        type="success"
        title={
          agentId
            ? "Agent details has successfully been updated."
            : "You have successfully added this agent."
        }
        onConfirm={{
          text: "Manage agent",
          action: () => navigate(Path.agents),
        }}
        onCancel={{
          text: "Back to main page",
          action: () => navigate(Path.main),
        }}
      />
    </>
  );
};

export default AgentFormPage;
