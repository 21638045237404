import React from "react";

const KskLogo: React.FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = ({ ...props }) => {
  return (
    <img
      src="/assets/ksk-logo.png"
      alt="KSK Land"
      className="w-12"
      {...props}
    />
  );
};

export default KskLogo;
