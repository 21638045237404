/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import Path from "../../common/Path";
import {
  Booking,
  Company,
  Customer,
  OpportunitySource,
  PEUnit,
  Proposal,
  SalesPackage,
} from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import ProposalSummary from "../../components/ProposalSummary/ProposalSummary";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useProposalListContext } from "../../hooks/contexts/ProposalListContext";
import { determineApprovalStage } from "../../utils/ApprovalUtils";
import LimboPage from "../generals/LimboPage";

const ProposalSummaryPage: React.FC = () => {
  const { proposalId } = useParams();
  const proposalList = useProposalListContext();
  const loadingPage = useLoadingPageContext();

  const [proposal, setProposal] = useState<Proposal | undefined>(
    useLocation().state
  );

  const latestApprovals = determineApprovalStage(
    proposal?.allApprovals,
    proposal?.stage
  );

  useEffect(() => {
    if (!proposal) showProposal();
  }, []);

  const showProposal = async () => {
    loadingPage.start();

    const selectedProposal = await proposalList.show(parseInt(proposalId!));

    setProposal(selectedProposal);

    loadingPage.end();
  };

  if (!proposal && !proposalList.isLoading) {
    return <LimboPage type="not-found" />;
  }

  if (!proposal) {
    return <></>;
  }

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton to={Path.proposals} text="Back to proposal listing" />

          <FormCard showRequired={false} title="">
            <h2 className="mb-4">Proposal Summary</h2>

            <ProposalSummary
              title=""
              summarized={false}
              units={
                [
                  {
                    buildup: proposal?.unit?.type?.buildUp || 0,
                    grossPrice: 0,
                    idTheme: proposal?.unit?.theme,
                    orientation:
                      proposal?.unit?.type?.orientation.toString() as string,
                    pricePsf: 0,
                    status: "",
                    type: proposal?.unit?.type?.name.toString() as string,
                    unitNo: proposal?.unit?.number,
                    level: proposal?.unit?.level,
                  },
                ] as PEUnit[]
              }
              customers={proposal?.customers as Customer[]}
              companies={proposal?.companies as Company[]}
              opportunitySource={
                proposal?.opportunitySource as OpportunitySource
              }
              solicitor={proposal.solicitor}
              salesPicInfo={proposal.user}
              salesPackage={proposal?.salesPackage as SalesPackage}
              bookingInfo={proposal?.booking as Booking}
              remarks={proposal?.remarks}
              approvals={
                latestApprovals?.length
                  ? latestApprovals
                  : determineApprovalStage(proposal?.allApprovals, "creation")
              }
              soldInfo={proposal?.soldDetail}
              cancelledInfo={proposal?.cancelledDetail}
              withdrawnInfo={proposal?.withdrawnDetail}
              ceActionsHistory={proposal?.ceActionsHistory}
            />
          </FormCard>
        </div>
      </div>
    </>
  );
};

export default ProposalSummaryPage;
