/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import { Proposal } from "../../common/Types";
import SquareButton from "../../components/Buttons/SquareButton";
import BaseCard from "../../components/Cards/BaseCard";
import ProposalStatusChip from "../../components/Chips/ProposalStatusChip";
import Icon from "../../components/Icons/Icon";
import SortCaret from "../../components/Tables/SortCaret";
import Table from "../../components/Tables/Table";
import HeaderText from "../../components/Texts/HeaderText";
import Format from "../../helpers/Format";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useProposalListContext } from "../../hooks/contexts/ProposalListContext";
import { useFilter } from "../../hooks/Filter";
import { formatNumber } from "../../utils/CommonUtils";

const ProposalListPage: React.FC = () => {
  const navigate = useNavigate();
  const proposalList = useProposalListContext();
  const loadingBar = useLoadingBarContext();
  const { items, sort } = useFilter<Proposal>(proposalList.proposals);

  const view = (proposal: Proposal) => {
    navigate(Path.viewProposal(proposal.id), { state: proposal });
  };

  useEffect(() => {
    proposalList.index(["customers", "units"]);
  }, []);

  useEffect(() => {
    if (proposalList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [proposalList.isLoading]);

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="Proposal Listing" />

        <BaseCard>
          <Table
            isLoading={proposalList.isLoading}
            onSearching={proposalList.search}
            searchHint="Search by customer, unit or status"
            headers={[
              <SortCaret
                key="customer-name"
                header="Buyer name"
                onToggle={() => sort("customers[0].fullName,companies[0].name")}
              />,
              <SortCaret
                key="created-by"
                header="Created by"
                onToggle={() => sort("user.fullName")}
              />,
              <SortCaret
                key="unit"
                header="Unit"
                onToggle={() => sort("unit.number")}
              />,
              <SortCaret
                key="buildup"
                header="Build up"
                onToggle={() => sort("unit.type.buildUp")}
              />,
              <SortCaret
                key="type"
                header="Type"
                onToggle={() => sort("unit.type.name")}
              />,
              <SortCaret
                key="creation-date"
                header="Creation date"
                onToggle={() => sort("createdAt")}
              />,
              <SortCaret
                key="proposal-status"
                header="Proposal Status"
                onToggle={() => sort("status")}
              />,
            ]}
            body={items.map((proposal, index) => (
              <tr
                key={`proposal-${proposal.id}-${index}`}
                className={classNames("h-0 align-top", {
                  "bg-opacity-50 bg-ink-well": proposal.status === "sold",
                })}
              >
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal.customers?.length
                    ? proposal.customers?.map((customer, cusomterIndex) => (
                        <div key={`customer-${cusomterIndex}`}>
                          {customer.fullName}
                        </div>
                      ))
                    : proposal.companies?.map((company, companyIndex) => (
                        <div key={`company-${companyIndex}`}>
                          {company.name}
                        </div>
                      ))}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal.user?.fullName}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal?.unit?.number}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {formatNumber(proposal?.unit?.type?.buildUp || "-")}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal?.unit?.type?.name || "-"}
                </td>
                <td
                  className={classNames("py-3", {
                    "opacity-50": proposal.status === "sold",
                  })}
                >
                  {proposal.createdAt ? Format.date(proposal.createdAt) : "-"}
                </td>
                <td className="py-2">
                  <ProposalStatusChip status={proposal.status} />
                </td>
                <td className="flex justify-end items-center h-11 pl-0">
                  <SquareButton
                    icon={Icon.eye}
                    onclick={() => view(proposal)}
                  />
                </td>
              </tr>
            ))}
          />
        </BaseCard>
      </div>
    </>
  );
};

export default ProposalListPage;
