import { Outlet } from "react-router-dom";
import { Permission } from "../common/Permission";
import { useAuthContext } from "../hooks/contexts/AuthContext";
import LimboPage from "../views/generals/LimboPage";

interface RequirePermissionProps {
  permissions: Permission[];
}

const RequirePermission: React.FC<RequirePermissionProps> = ({
  permissions,
}) => {
  const auth = useAuthContext();

  if (auth.can(permissions)) return <Outlet />;

  return <LimboPage type="no-permission" />;
};

export default RequirePermission;
