export const defaultChartPsfValues = [
  { x: 2550, y: 0 },
  { x: 2650, y: 0 },
  { x: 2750, y: 0 },
  { x: 2850, y: 0 },
  { x: 2950, y: 0 },
  { x: 3050, y: 0 },
  { x: 3150, y: 0 },
  { x: 3250, y: 0 },
  { x: 3350, y: 0 },
  { x: 3450, y: 0 },
  { x: 3550, y: 0 },
  { x: 3650, y: 0 },
  { x: 3750, y: 0 },
  { x: 3850, y: 0 },
  { x: 3950, y: 0 },
  { x: 4050, y: 0 },
  { x: 4150, y: 0 },
  { x: 4250, y: 0 },
  { x: 4350, y: 0 },
  { x: 4450, y: 0 },
];

export const defaultChartAbsValues = [
  { x: 2.1, y: 0 },
  { x: 2.3, y: 0 },
  { x: 2.5, y: 0 },
  { x: 2.7, y: 0 },
  { x: 2.9, y: 0 },
  { x: 3.1, y: 0 },
  { x: 3.3, y: 0 },
  { x: 3.5, y: 0 },
  { x: 3.7, y: 0 },
  { x: 3.9, y: 0 },
  { x: 4.1, y: 0 },
  { x: 4.3, y: 0 },
  { x: 4.5, y: 0 },
  { x: 4.7, y: 0 },
  { x: 4.9, y: 0 },
  { x: 5.1, y: 0 },
  { x: 5.3, y: 0 },
  { x: 5.5, y: 0 },
];

export const CATALYST_CLOSING_FEE = 30000;
