/* eslint-disable react-hooks/exhaustive-deps */
import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Source } from "../../common/OpportunitySource";
import Path from "../../common/Path";
import {
  defaultStages,
  paymentStructures,
  PaymentStructureType,
  reverseStructurableType,
} from "../../common/PaymentStructure";
import { Customer, FormErrors, Hook, PEUnit, Rebate } from "../../common/Types";
import CustomerBasicInfoAccordian from "../../components/Accordion/CustomerBasicInfoAccordian";
import OpportunitySourceAccordian from "../../components/Accordion/OpportunitySourceAccordian";
import PaymentStructureAccordian from "../../components/Accordion/PaymentStructureAccordian";
import SalesPackageAccordian from "../../components/Accordion/SalesPackageAccordian";
import BackButton from "../../components/Buttons/BackButton";
import { GroupExpandableCard } from "../../components/Cards/ExpandableCard";
import SectionCard from "../../components/Cards/SectionCard";
import UnitSelectionCard from "../../components/Cards/UnitSelectionCard";
import Textarea from "../../components/General/Textarea";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import BottomBar from "../../components/Navs/BottomBar";
import ProposalSummary from "../../components/ProposalSummary/ProposalSummary";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { usePropertyContext } from "../../hooks/contexts/PropertyContext";
import { useProposalListContext } from "../../hooks/contexts/ProposalListContext";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import { useArray } from "../../hooks/UseArray";
import { useScrollToError } from "../../hooks/UseScrollToError";
import { useUnload } from "../../hooks/UseUnload";
import { useValidation } from "../../hooks/UseValidation";
import { ProposalForm, schema } from "../../schemas/ProposalSchema";
import { calculateRebateValue } from "../../utils/CommonUtils";
import { now } from "../../utils/TimeDateUtils";
import api from "../../services/PabloApiService";
import { CustomerResponse } from "../../common/Responses";

const ProposalFormPage: React.FC = (props) => {
  const navigate = useNavigate();
  const validation = useValidation<ProposalForm>(schema);
  const { scrollOnError } = useScrollToError<ProposalForm>();
  const property = usePropertyContext();
  const unitList = useUnitListContext();
  const proposalList = useProposalListContext();
  const loadingPage = useLoadingPageContext();
  const modal = useModalContext();
  const tower: string = property?.selectedBuilding?.value ?? "";
  const { state } = useLocation() as { state?: PEUnit[] };
  const { setDirty } = useUnload();
  const units: PEUnit[] = state ?? [];
  const { unitPrices, maxRecRebate, loadingItems, getPrices, dispatch } =
    unitList;
  const proposal = validation.watch();

  /** General States */
  const [source, setSource] = useState<Source>();
  const [commission, setCommission] = useState<number>(0);
  const [formErrors, setFormErrors] = useState<FormErrors>({
    scheduleH: "",
  });
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [hasSalesPackageOpened, setHasSalesPackageOpened] =
    useState<boolean>(false);
  const [hasPaymentStructureOpened, setHasPaymentStructureOpened] =
    useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  /** General Payment Structure Accordian States */
  const [paymentStructureType, setPaymentStructureType] =
    useState<PaymentStructureType>(paymentStructures[0]);

  /** Instalment Payment Structure Accordian States */
  const [repaymentMonths, setRepaymentMonths] = useState<number>(1);
  const [paymentStartDate, setPaymentStartDate] = useState<Moment>(
    now.clone().add(1, "month")
  );
  const [paymentEndDate, setPaymentEndDate] = useState<Moment>(
    paymentStartDate.clone().add(repaymentMonths, "month")
  );

  /** Schedule H Payment Structure Accordian States */
  const [progressRebates, setProgressRebates] = useState<Rebate[]>(
    defaultStages.map((stage) => {
      return {
        stage: stage.stage,
        value: stage.value,
        stringValue: stage.stringValue,
      };
    })
  );
  const [remainingRebate, setRemainingRebate] = useState<number>(0);

  /** Flexible Instalment States */
  const [detailsIndexes, dispatchDetailsIndexes] = useArray<number>([0, 1, 2]);

  /** Prospect List State */
  const [prospects, setProspects] = useState<Customer[]>([]);

  const onPaymentStructureTypeChange = (selectedType: PaymentStructureType) => {
    setPaymentStructureType(selectedType);
    validation.setValue(`paymentStructure.type`, selectedType);
  };

  const handleCustomValidation = () => {
    let errorCount = 0;
    let errors = formErrors;

    if (paymentStructureType === "schedule-h" && remainingRebate > 0) {
      errors.scheduleH = "Please apply all the remaining rebate";
      errorCount++;
    } else if (paymentStructureType === "schedule-h" && remainingRebate < 0) {
      errors.scheduleH =
        "Rebate has exceeded the maximum rebate amount selected";
      errorCount++;
    } else {
      errors.scheduleH = "";
    }

    setFormErrors(errors);
    return errorCount > 0 ? true : false;
  };

  const onPreview = async () => {
    setHasSalesPackageOpened(true);
    setHasPaymentStructureOpened(true);
    const hasErrors = handleCustomValidation();
    const result = await validation.trigger();

    if (
      result &&
      Object.keys(validation.errors).length === 0 &&
      !hasErrors &&
      loadingItems.length === 0
    ) {
      setDirty(false);

      modal.view({
        title: "",
        containerClassName: "w-10/12 h-[90%]",
        modalClassName: "w-full px-5 pt-5",
        content: (
          <>
            <ProposalSummary
              title="Proposal review"
              summarized={true}
              units={units}
              customers={[
                {
                  id: 0,
                  ...proposal?.customerBasicInfo,
                  phoneCode: parseInt(proposal?.customerBasicInfo?.phoneCode),
                },
              ]}
              opportunitySource={{
                id: 0,
                ...proposal?.opportunitySource,
                commissionFixedFee: proposal?.opportunitySource?.commissionRate,
                updatedCommissionFixedFee:
                  proposal?.opportunitySource?.updatedCommissionRate,
                phoneCode: parseInt(
                  proposal?.opportunitySource?.phoneCode ?? ""
                ),
              }}
              salesPackage={{
                id: 0,
                recommendedNettSellingPrice: maxRecRebate?.recAbsNettPrice,
                recommendedMaxRebate: maxRecRebate?.maxRecDisc,
                grossSellingPrice: unitPrices?.totalGrossPrice,
                packageableType: proposal?.salesPackage?.selectedSalesPackage,
                structurableType: reverseStructurableType(paymentStructureType),
                structurable: {
                  id: 0,
                  initialPayment:
                    proposal?.paymentStructure?.fixedInstalmentDetails
                      ?.initialPayment || 0,
                  instalmentPayment:
                    proposal?.paymentStructure?.fixedInstalmentDetails
                      ?.instalmentPayment || 0,
                  outstandingPayment:
                    proposal?.paymentStructure?.fixedInstalmentDetails
                      ?.outstandingPayment || 0,
                  totalRepaymentMonths: repaymentMonths,
                  paymentStartDate: paymentStartDate,
                  rebates: progressRebates,
                  details:
                    proposal?.paymentStructure?.flexibleInstalmentDetails || [],
                },
                ...proposal?.salesPackage,
              }}
              remarks={proposal?.remarks}
            />

            <BottomBar innerClassName="pr-0">
              <button
                id="submit-proposal-button"
                className="primary-button"
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                Submit proposal
              </button>
            </BottomBar>
          </>
        ),
      });
    }
  };

  const submitForm = async () => {
    let success = false;
    loadingPage.start();

    let paymentStructureBody =
      paymentStructureType === "instalment"
        ? {
            initialPayment:
              proposal?.paymentStructure?.fixedInstalmentDetails
                ?.initialPayment || 0,
            instalmentPayment:
              proposal?.paymentStructure?.fixedInstalmentDetails
                ?.instalmentPayment || 0,
            outstandingPayment:
              proposal?.paymentStructure?.fixedInstalmentDetails
                ?.outstandingPayment || 0,
            paymentStartDate: paymentStartDate
              .clone()
              .startOf("month")
              .format("YYYY-MM-DD"),
            totalRepaymentMonths: repaymentMonths,
          }
        : paymentStructureType === "flexible-instalment"
        ? {
            details: proposal?.paymentStructure?.flexibleInstalmentDetails
              ?.filter((detail) => detail)
              ?.map((detail) => {
                return {
                  item: detail.item,
                  datePayable: detail?.datePayable || "",
                  amountPayable: detail.amountPayable,
                  remarks: detail?.remarks || "",
                };
              }),
          }
        : {
            rebates: progressRebates
              .filter((progress) => progress.value > 0)
              .map((progress) => {
                return { stage: progress.stage, value: progress.value };
              }),
          };

    let grrBody =
      proposal?.salesPackage?.selectedSalesPackage === "grr"
        ? {
            rate: proposal?.salesPackage?.grrValues?.rate,
            numberOfYears: proposal?.salesPackage?.grrValues?.year,
          }
        : {};

    const proposalBody = {
      unit: {
        projectId: units[0]?.projectId,
        sectionId: units[0]?.sectionId,
        unitId: units[0]?.unitId,
        unitNumber: units[0]?.unitNo,
      },
      remarks: proposal?.remarks,
      opportunitySource: {
        type: proposal?.opportunitySource?.type,
        title: proposal?.opportunitySource?.title || "",
        fullName: proposal?.opportunitySource?.fullName || "",
        email: proposal?.opportunitySource?.email || "",
        phoneCode: proposal?.opportunitySource?.phoneCode || "",
        phoneNumber: proposal?.opportunitySource?.phoneNumber || "",
        commissionRate:
          source !== "digital"
            ? proposal?.opportunitySource?.commissionRate || 0
            : null,
        commissionFixedFee:
          source === "digital"
            ? proposal?.opportunitySource?.commissionRate
            : null,
        updatedCommissionRate:
          source !== "digital"
            ? proposal?.opportunitySource?.updatedCommissionRate || 0
            : null,
        updatedCommissionFixedFee:
          source === "digital"
            ? proposal?.opportunitySource?.updatedCommissionRate
            : null,
      },
      customer: proposal?.customerBasicInfo,
      salesPackage: {
        paymentMethod: proposal?.salesPackage?.paymentMethod,
        package: {
          type: proposal?.salesPackage?.selectedSalesPackage,
          ...grrBody,
        },
        recommendedNettSellingPrice: maxRecRebate?.recAbsNettPrice,
        recommendedMaxRebate: maxRecRebate?.maxRecDisc,
        grossSellingPrice: unitPrices?.totalGrossPrice,
        nettSellingPrice: proposal?.salesPackage?.nettSellingPrice,
        rebateType: proposal?.salesPackage?.rebateType,
        hooks: proposal?.salesPackage?.hooks
          ?.filter((hook: Hook) => hook?.item?.trim() && hook?.value)
          .map((hook: Hook) => {
            return { item: hook?.item?.trim(), value: hook?.value };
          }),
        remarks: proposal?.salesPackage?.remarks,
        paymentStructure: {
          type: paymentStructureType,
          ...paymentStructureBody,
        },
      },
    };

    success = await proposalList.store(proposalBody);

    loadingPage.end();
    setShowSuccess(success);
  };

  const handleProspects = async () => {
    if (prospects.length <= 0) {
      await getProspects();
    }
  };

  const getProspects = async (): Promise<boolean> => {
    dispatch({ type: "add", value: "getProspects" });

    const { data, success } = await api.get<CustomerResponse>("/customer");

    if (success) {
      setProspects(data.data);
    }

    dispatch({ type: "remove", value: "getProspects" });

    return true;
  };

  useEffect(() => {
    !tower && navigate(Path.main);
    !units?.[0]?.unitNo.includes(tower) && navigate(Path.main);
    units.length <= 0 && navigate(Path.main);
    validation.setValue(`salesPackage.rebate`, 0);
    validation.setValue(`salesPackage.nettSellingPrice`, 0);
    validation.setValue(`paymentStructure.type`, paymentStructures[0]);
    getPrices(units?.[0]?.grossPrice || 0);
    handleProspects();
  }, []);

  useEffect(() => {
    setProgressRebates(
      defaultStages.map((stage) => {
        return {
          stage: stage.stage,
          value: stage.value,
          stringValue: stage.stringValue,
        };
      })
    );
  }, [
    proposal?.salesPackage?.rebateType,
    source,
    proposal?.salesPackage?.rebate,
  ]);

  useEffect(() => {
    setDirty(true);
  }, [proposal]);

  useEffect(() => {
    scrollOnError(validation.errors);
  }, [validation.errors]);

  useEffect(() => {
    if (loadingItems.length !== 0) loadingPage.start();
    else loadingPage.end();
  }, [loadingItems]);

  return (
    <>
      {/* page */}
      <div className="col min-h-screen h-max justify-between">
        <div className="mb-20">
          <BackButton text="Back to unit selection" />

          <UnitSelectionCard units={units} />

          <GroupExpandableCard
            defaultValue="opportunity-source"
            onExpand={(accordian) =>
              accordian === "sales-packages"
                ? setHasSalesPackageOpened(true)
                : accordian === "payment-structure"
                ? setHasPaymentStructureOpened(true)
                : {}
            }
          >
            <OpportunitySourceAccordian
              validation={validation}
              source={source}
              setSource={setSource}
              setCommission={setCommission}
            />

            <SalesPackageAccordian
              validation={validation}
              tower={tower}
              units={units}
              commission={commission}
              hasSalesPackageOpened={hasSalesPackageOpened}
            />

            <PaymentStructureAccordian
              validation={validation}
              type={paymentStructureType}
              onTypeChange={onPaymentStructureTypeChange}
              formErrors={formErrors}
              nettSellingPrice={proposal?.salesPackage?.nettSellingPrice}
              repaymentMonths={repaymentMonths}
              paymentStartDate={paymentStartDate}
              paymentEndDate={paymentEndDate}
              hasPaymentStructureOpened={hasPaymentStructureOpened}
              setFormErrors={setFormErrors}
              setRepaymentMonths={setRepaymentMonths}
              setPaymentStartDate={setPaymentStartDate}
              setPaymentEndDate={setPaymentEndDate}
              totalRebate={
                proposal?.salesPackage?.rebateType === "percentage"
                  ? proposal?.salesPackage?.rebate
                  : calculateRebateValue(
                      unitPrices?.totalGrossPrice,
                      proposal?.salesPackage?.nettSellingPrice
                    )
              }
              remainingRebate={remainingRebate}
              progressRebates={progressRebates}
              setProgressRebates={setProgressRebates}
              setRemainingRebate={setRemainingRebate}
              rebateType={proposal?.salesPackage?.rebateType}
              detailsIndexes={detailsIndexes}
              dispatchDetailsIndexes={dispatchDetailsIndexes}
            />

            <CustomerBasicInfoAccordian
              validation={validation}
              prospectList={prospects}
            />
          </GroupExpandableCard>

          <SectionCard
            title="Remarks (Optional)"
            className="col shadow rounded-sm mx-7 my-4 relative bg-white px-8 py-5"
            titleClassName="capitalize text-lg font-semibold"
          >
            <Textarea
              id="proposal-general-remarks"
              rows={5}
              error={validation.errors.remarks?.message}
              required={false}
              maxLength={500}
              {...validation.register("remarks")}
            />
          </SectionCard>
        </div>

        <BottomBar>
          <button
            id="save-as-draft-button"
            className="secondary-button mr-auto group cursor-not-allowed opacity-50"
            onClick={() => {}}
          >
            Save as draft
          </button>

          <button
            id="preview-proposal-button"
            className="primary-button"
            onClick={() => {
              onPreview();
            }}
          >
            Preview
          </button>
        </BottomBar>
      </div>

      <ConfirmationModal
        show={showConfirmation}
        dismissible={false}
        onHide={() => {}}
        type="warning"
        title="Are you sure you want to submit this new proposal?"
        onConfirm={{
          text: "Submit",
          action: submitForm,
        }}
        onCancel={{
          text: "Cancel",
          action: () => setShowConfirmation(false),
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        dismissible={false}
        onHide={() => setShowSuccess(false)}
        type="success"
        title="You have successfully added this proposal."
        onConfirm={{
          text: "My Proposals",
          action: () => {
            modal.setShow(false);
            navigate(Path.myProposals);
          },
        }}
        onCancel={{
          text: "Back to main page",
          action: () => {
            modal.setShow(false);
            navigate(Path.main);
          },
        }}
      />
    </>
  );
};

export default ProposalFormPage;
