/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Path from "../../common/Path";
import { Role } from "../../common/Permission";
import { User } from "../../common/Types";
import MoreButton from "../../components/Buttons/MoreButton";
import BaseCard from "../../components/Cards/BaseCard";
import RoleChip from "../../components/Chips/RoleChip";
import Icon from "../../components/Icons/Icon";
import PabloIcon from "../../components/Icons/PabloIcon";
import Avatar from "../../components/Images/Avatar";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import PopUpItem from "../../components/PopUp/PopUpItem";
import SortCaret from "../../components/Tables/SortCaret";
import Table from "../../components/Tables/Table";
import HeaderText from "../../components/Texts/HeaderText";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useUserListContext } from "../../hooks/contexts/UserListContext";
import { useFilter } from "../../hooks/Filter";

const UserListPage: React.FC = () => {
  const navigate = useNavigate();
  const userList = useUserListContext(); // 1
  const loadingBar = useLoadingBarContext(); // 2
  const [deletingUser, setDeletingUser] = useState<User | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const loadingPage = useLoadingPageContext();
  const { items, sort } = useFilter<User>(userList.users); // 2

  useEffect(() => {
    userList.index();
  }, []);

  useEffect(() => {
    if (userList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [userList.isLoading]);

  const viewUser = (user: User) => {
    navigate(Path.editUser(user.id), { state: user });
  };

  const addNewUser = () => {
    navigate(Path.newUser);
  };

  const deleteUser = async () => {
    loadingPage.start();
    const success = await userList.destroy(deletingUser!.id);
    setShowSuccess(success);
    loadingPage.end();
    userList.index();
  };

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="Manage user" />

        <BaseCard>
          <div className="row justify-end items-center mb-8">
            <button className="secondary-button group" onClick={addNewUser}>
              <PabloIcon icon={Icon.plus} className="secondary-icon" />
              Add new user
            </button>
          </div>

          <Table
            isLoading={userList.isLoading}
            onSearching={userList.search}
            searchHint="Search by name, email or role"
            headers={[
              <SortCaret
                key={"name"}
                header="Name"
                onToggle={() => sort("fullName")}
              />,
              <SortCaret
                key={"email"}
                header="Email"
                onToggle={() => sort("email")}
              />,
              <SortCaret
                key={"title"}
                header="Job Title"
                onToggle={() => sort("title")}
              />,
              <SortCaret
                key={"roles"}
                header="Roles"
                onToggle={() => sort("roles")}
              />,
            ]}
            body={items.map((user, index) => {
              return (
                <tr key={`${user.email}-${index}`} className="m-5">
                  <td
                    className="hover:cursor-pointer hover:text-primary"
                    onClick={() => viewUser(user)}
                  >
                    <div className="row items-center overflow-ellipsis gap-2">
                      <Avatar user={user} />
                      <span className="truncate">{user.fullName}</span>
                    </div>
                  </td>
                  <td>{user.email}</td>
                  <td>{user.title}</td>
                  <td>
                    <RoleChip role={(user?.roles?.[0] as Role) || ""} />
                  </td>
                  <td className="flex justify-end items-center h-11">
                    <MoreButton>
                      <PopUpItem
                        icon={Icon.edit}
                        label="Edit"
                        onClick={() => viewUser(user)}
                      />
                      <PopUpItem
                        icon={Icon.remove}
                        label="Delete"
                        onClick={() => setDeletingUser(user)}
                      />
                    </MoreButton>
                  </td>
                </tr>
              );
            })}
          />
        </BaseCard>
      </div>

      {/* modal */}
      <ConfirmationModal
        show={deletingUser ? true : false}
        title="Are you sure you want to delete this user?"
        type="warning"
        onHide={() => setDeletingUser(null)}
        onConfirm={{
          text: "Delete",
          action: deleteUser,
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        title="You have successfully deleted this user."
        type="success"
        onHide={() => setShowSuccess(false)}
        onConfirm={{
          text: "Manage user",
          action: () => navigate(Path.users),
        }}
        onCancel={{
          text: "Back to main page",
          action: () => navigate(Path.main),
        }}
      />
    </>
  );
};

export default UserListPage;
