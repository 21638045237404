import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldValues,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnyObjectSchema } from "yup";

export type Validation<T extends FieldValues> = {
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  handleSubmit: UseFormHandleSubmit<T>;
  errors: DeepMap<DeepPartial<T>, FieldError>;
  isValid: boolean;
  setError: UseFormSetError<T>;
  clearErrors: UseFormClearErrors<T>;
  reset: UseFormReset<T>;
  getValues: UseFormGetValues<T>;
  trigger: UseFormTrigger<T>;
  unregister: UseFormUnregister<T>;
  resetField: UseFormResetField<T>;
};

export const useValidation = <T extends FieldValues>(
  schema: AnyObjectSchema
): Validation<T> => {
  const resolver = yupResolver(schema);
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors, isValid },
    getValues,
    trigger,
    unregister,
    resetField,
  } = useForm<T>({ resolver, mode: "onChange" });

  return {
    watch,
    setValue,
    register,
    handleSubmit,
    errors,
    isValid,
    setError,
    clearErrors,
    reset,
    getValues,
    trigger,
    unregister,
    resetField,
  };
};
