import Path from "../common/Path";
import { PageType } from "../common/Types";

type PageParameters = {
  type: PageType;
  path: string;
  backToText: string;
};

export const usePageHandler = (type?: PageType): PageParameters => {
  let pageTypeParameters = {
    type: type ?? "my-proposal",
    path: Path.myProposals,
    backToText: "Back to my proposal",
  };

  switch (type) {
    case "ce":
      pageTypeParameters = {
        type: type,
        path: Path.bookingApprovals,
        backToText: "Back to CE approval listing",
      };
      break;

    case "legacy-proposal":
      pageTypeParameters = {
        type: type,
        path: Path.legacyProposals,
        backToText: "Back to legacy proposal listing",
      };
      break;
  }

  return pageTypeParameters;
};
