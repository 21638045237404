/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { number, object, SchemaOf } from "yup";
import { SalesPackage } from "../../common/Types";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useValidation } from "../../hooks/UseValidation";
import BottomBar from "../Navs/BottomBar";
import api from "../../services/PabloApiService";
import { GeneralMessageResponse } from "../../common/Responses";
import TextField from "../Fields/TextField";

interface UpdateSpecialRebate {
  specialRebate: number;
}

const schema: SchemaOf<UpdateSpecialRebate> = object().shape({
  specialRebate: number()
    .required()
    .min(1, "Special rebate cannot be 0")
    .label("Special rebate"),
});

interface UpdateSpecialRebateFormProps {
  proposalId: number;
  detail?: SalesPackage;
  closeModal: Function;
  onCompleteCallback: () => void;
}

const UpdateSpecialRebateForm: React.FC<UpdateSpecialRebateFormProps> = (
  props
) => {
  const { proposalId, closeModal, detail, onCompleteCallback } = props;
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<UpdateSpecialRebate>(schema);
  const { register, watch, handleSubmit, errors } = validation;
  const specialRebateForm = watch();

  const submitForm = async () => {
    loadingPage.start();

    const body = {
      specialRebate: specialRebateForm?.specialRebate,
    };

    const { success } = await api.patch<GeneralMessageResponse>(
      `/proposals/${proposalId}/update-special-rebate`,
      body
    );

    closeModal();

    await onCompleteCallback();

    loadingPage.end();

    return success;
  };

  return (
    <>
      <div className="overflow-auto pb-5 h-full">
        <TextField
          id="special-rebate-value"
          label="Special Rebate"
          type="text"
          required={true}
          error={errors?.specialRebate?.message}
          defaultValue={detail?.specialRebate}
          placeholder="Enter Value"
          frontLabel="RM"
          dynamicWidth={true}
          {...register("specialRebate")}
        />
      </div>

      <BottomBar innerClassName="pr-0 gap-4">
        <button
          id="cancel-updated-special-rebate-button"
          className="secondary-button"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          id="submit-updated-special-rebate-button"
          className="primary-button"
          onClick={handleSubmit(submitForm)}
        >
          Submit
        </button>
      </BottomBar>
    </>
  );
};

export default UpdateSpecialRebateForm;
