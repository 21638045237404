import React from "react";

const BaseCard: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <div className="col pb-6 pt-8 px-8 bg-white shadow rounded-sm mx-7 mb-20" {...props}>
      {children}
    </div>
  );
};

export default BaseCard;
