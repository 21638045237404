import { Navigate, Outlet, useLocation } from "react-router-dom";
import Path from "../common/Path";
import { useAuthContext } from "../hooks/contexts/AuthContext";
import LimboPage from "../views/generals/LimboPage";

const RequireAuth: React.FC = () => {
  const location = useLocation();
  const auth = useAuthContext();

  switch (auth.status) {
    case "loggedOut":
      return <Navigate to={Path.login} state={{ from: location }} />;

    case "expired":
      return <Navigate to="/login?expired=true" state={{ from: location }} />;

    case "unauthorized":
      return <LimboPage type="no-permission" />;

    default:
      return <Outlet />;
  }
};

export default RequireAuth;
