import classNames from "classnames";
import React from "react";
import { User } from "../../common/Types";

interface AvatarProps {
  user?: User | null;
  size?: "extra-small" | "small" | "medium" | "large";
}

const Avatar: React.FC<AvatarProps> = ({ user, size = "small" }) => {
  const diameter =
    size === "extra-small"
      ? "w-5 h-5"
      : size === "small"
      ? "w-8 h-8"
      : size === "medium"
      ? "w-12 h-12"
      : "w-16 h-16";
  const className = `rounded-full object-cover ${diameter}`;

  const getInitials = (name: string) => {
    const splittedName = name.split(" ");

    if (splittedName.length > 1) {
      return `${splittedName[0][0]}${splittedName[1][0]}`;
    }

    return name[0];
  };

  if (!user?.avatarUrl) {
    return (
      <div
        className={classNames(
          `${className} flex justify-center items-center uppercase`,
          {
            "bg-[#B111164D] text-white":
              user?.fullName && user.fullName !== "Unassigned",
            "bg-ink-well text-black": user?.fullName === "Unassigned",
            "text-[0.625rem]": size === "extra-small",
          }
        )}
      >
        {user?.fullName && user.fullName !== "Unassigned"
          ? getInitials(user.fullName)
          : "?"}
      </div>
    );
  }

  return <img className={className} src={user.avatarUrl} alt="Avatar" />;
};

export default Avatar;
