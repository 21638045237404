import { Source, sourceName } from "../../common/OpportunitySource";
import { OpportunitySource } from "../../common/Types";
import { formatNumber } from "../../utils/CommonUtils";
import SectionCard from "../Cards/SectionCard";

interface OpportunitySourceSummaryProps {
  opportunitySource: OpportunitySource;
  className?: string;
  titleClassName?: string;
}

const OpportunitySourceSummary: React.FC<OpportunitySourceSummaryProps> = (
  props
) => {
  const { opportunitySource, className, titleClassName } = props;

  return (
    <SectionCard
      title="Channel"
      className={className}
      titleClassName={titleClassName}
    >
      <table className="bg-transparent table-fixed w-full">
        <thead>
          <tr className="h-6 border-none">
            <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
              Channel
            </th>
            <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
              {opportunitySource?.type === "agent"
                ? "Agency"
                : opportunitySource?.type === "digital"
                ? "Name"
                : opportunitySource?.type === "kora"
                ? "Referrer"
                : opportunitySource?.type === "bgb" ||
                  opportunitySource?.type === "existing-buyer"
                ? "Unit No."
                : opportunitySource?.type === "business-associate"
                ? "Company / Individual Name"
                : opportunitySource?.type === "brand-collaboration"
                ? "Event Name"
                : "Source"}
            </th>
            <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
              Original Commission
            </th>
            <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
              Updated Commission
            </th>
          </tr>
        </thead>

        <tbody>
          <tr key={`unit-no-1`} className="h-6 border-none">
            <td className="text-sm font-bold p-0 py-2 whitespace-normal">
              {sourceName(opportunitySource?.type as Source)}
            </td>
            <td className="text-sm font-bold p-0 py-2 whitespace-normal">
              {opportunitySource?.type === "kora" ||
              opportunitySource?.type === "digital"
                ? opportunitySource?.fullName
                : opportunitySource?.title || `--`}
            </td>
            <td className="text-sm font-bold p-0 py-2 whitespace-normal">
              {opportunitySource?.type === "digital" ? "RM" : ""}
              {opportunitySource?.type === "digital"
                ? formatNumber(opportunitySource?.commissionFixedFee || 0)
                : formatNumber(opportunitySource?.commissionRate || 0)}
              {opportunitySource?.type !== "digital" ? "%" : ""}
            </td>
            <td className="text-sm font-bold p-0 py-2">
              {opportunitySource?.type === "digital" ? "RM" : ""}
              {opportunitySource?.type === "digital"
                ? formatNumber(
                    opportunitySource?.updatedCommissionFixedFee || 0
                  )
                : formatNumber(opportunitySource?.updatedCommissionRate || 0)}
              {opportunitySource?.type !== "digital" ? "%" : ""}
            </td>
          </tr>
        </tbody>
      </table>
    </SectionCard>
  );
};

export default OpportunitySourceSummary;
