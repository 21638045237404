import classNames from "classnames";
import React from "react";

interface LineProps {
  className?: string;
}

const Line: React.FC<LineProps> = (props) => {
  return (
    <hr
      className={classNames(
        "border-t-1 border-[#53565A33]",
        {
          "my-7": !props.className,
        },
        props.className
      )}
    />
  );
};

export default Line;
