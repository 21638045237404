import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import PabloIcon from "../Icons/PabloIcon";
import Icon from "../Icons/Icon";
import { UnitDiscountChart } from "../../common/Types";

interface ReviewUnitCostOfSalesSliderProps {
  value: number;
  unitDiscountChart?: UnitDiscountChart;
}

const ReviewUnitCostOfSalesSlider: React.FC<
  ReviewUnitCostOfSalesSliderProps
> = (props) => {
  const { value, unitDiscountChart } = props;

  const recMinSellingPrice =
    unitDiscountChart?.totalUnitRecMinSellingPrice?.percentage || 0;
  const baseSellingPrice =
    unitDiscountChart?.totalUnitBaseSellingPrice?.percentage || 0;
  const breakevenPriceValue =
    unitDiscountChart?.totalUnitActualSunkCost?.percentage || 0;
  const breakevenPrice = breakevenPriceValue > 50 ? 50 : breakevenPriceValue;

  return (
    <>
      <div className="row w-full flex gap-2.5 items-center justify-between mt-[100px]">
        <span className="mr-4 text-sm text-[#1F1F1FB2] font-normal invisible">
          50
        </span>
        <div className="w-full relative">
          <div
            style={{ left: `${(50 - recMinSellingPrice) * 2}%` }}
            className="absolute h-full w-full top-[-7px] z-30"
          >
            <div className="border-l-4 border-solid border-[#B11116] h-[16px] flex">
              <div
                data-tip
                data-for="rec-min-price"
                className={classNames(
                  "absolute text-[10px] top-[-20px] h-[40px] row justify-start opacity-50 hover:opacity-100",
                  {
                    "left-[-10px]": recMinSellingPrice < 10,
                    "left-[-14px]": recMinSellingPrice >= 10,
                  }
                )}
              >
                <div className="pr-[1px]">{recMinSellingPrice}%</div>
                <div className="pt-[2px]">
                  <PabloIcon
                    icon={Icon.info}
                    className="w-[11px] h-[11px] rotate-180"
                  />
                </div>
              </div>

              <ReactTooltip id="rec-min-price" effect="solid">
                <strong className="underline">
                  Pablo Recommended Minimum Price
                </strong>
                <br />
                <ul className="list-disc">
                  Unit Gross Selling Price minus:
                  <li className="ml-5">Commission</li>
                  <li className="ml-5">Rebate Given</li>
                  <li className="ml-5">Catalyst Closing Fee</li>
                  <li className="ml-5">Additional Sales Package Cost</li>
                </ul>
              </ReactTooltip>
            </div>

            <div
              style={{
                width: `calc(${recMinSellingPrice * 2}% - 2px)`,
              }}
              className="h-px border-t-4 border-solid border-[#4EB000] text-[#1F1F1FB2] mt-6 ml-0.5 pt-1 text-[10px] text-center leading-tight"
            >
              Healthy Cost Limit
            </div>
          </div>

          <div
            style={{ left: `${(50 - baseSellingPrice) * 2}%` }}
            className="absolute h-full w-full top-[-7px] z-20"
          >
            <div className="border-l-4 border-solid border-[#B11116] h-[16px] flex">
              <div
                data-tip
                data-for="base-selling-price"
                className={classNames(
                  "absolute text-[10px] top-[-20px] h-[40px] row justify-start opacity-50 hover:opacity-100",
                  {
                    "left-[-10px]": baseSellingPrice < 10,
                    "left-[-14px]": baseSellingPrice >= 10,
                  }
                )}
              >
                <div className="pr-[1px]">{baseSellingPrice}%</div>
                <div className="pt-[2px]">
                  <PabloIcon
                    icon={Icon.info}
                    className="w-[11px] h-[11px] rotate-180"
                  />
                </div>
              </div>

              <ReactTooltip id="base-selling-price" effect="solid">
                <strong className="underline">Base Selling Price</strong>
                <br />
                Breakeven price loaded up with locked
                <br />
                minimum profit margin
              </ReactTooltip>
            </div>

            <div
              style={{
                width: `calc(${
                  (50 - recMinSellingPrice) * 2 - (50 - baseSellingPrice) * 2
                }% - 2px)`,
              }}
              className="h-px border-t-4 border-solid border-[#ffdd42] text-[#1F1F1FB2] mt-6 ml-0.5 pt-1 text-[10px] text-center leading-tight"
            >
              Moderate Cost Incurred
            </div>
          </div>

          <div
            style={{ left: `${(50 - breakevenPrice) * 2}%` }}
            className="absolute h-full w-full top-[-7px] z-10"
          >
            <div className="border-l-4 border-solid border-[#B11116] h-[16px] flex">
              <div
                data-tip
                data-for="breakeven-price"
                className={classNames(
                  "absolute text-[10px] top-[-20px] h-[40px] row justify-start opacity-50 hover:opacity-100",
                  {
                    "left-[-10px]": breakevenPrice < 10,
                    "left-[-14px]": breakevenPrice >= 10,
                  }
                )}
              >
                <div className="pr-[1px]">{breakevenPriceValue}%</div>
                <div className="pt-[2px]">
                  <PabloIcon
                    icon={Icon.info}
                    className="w-[11px] h-[11px] rotate-180"
                  />
                </div>
              </div>

              <ReactTooltip id="breakeven-price" effect="solid">
                <strong className="underline">Breakeven Price</strong>
                <br />
                Cost incurred to build unit
              </ReactTooltip>
            </div>

            <div
              style={{
                width: `calc(${
                  (50 - baseSellingPrice) * 2 - (50 - breakevenPrice) * 2
                }% - 2px)`,
              }}
              className="h-px border-t-4 border-solid border-[#ffbe42] text-[#1F1F1FB2] mt-6 ml-0.5 pt-1 text-[10px] text-center leading-tight"
            >
              High Cost Incurred
            </div>
          </div>

          <div className="absolute h-full w-full top-[-7px]">
            &nbsp;
            <div
              style={{
                width: `${
                  breakevenPrice === 50
                    ? "1px"
                    : (50 - breakevenPrice) * 2 + "%"
                }`,
              }}
              className="h-px border-t-4 border-solid border-[#ff7042] text-[#1F1F1FB2] mt-4 pt-1 text-[10px] text-center leading-tight"
            >
              Selling at a loss
            </div>
          </div>

          <div className="h-0.5 w-full bg-[#1f1f1f4d] rounded-sm relative">
            <div className="w-3 h-3 border-l-2 border-b-2 border-l-[#1f1f1f4d] border-b-[#1f1f1f4d] rotate-45 absolute top-[-5px]" />
            <div
              style={{ left: `${(50 - value - 0.2) * 2}%` }}
              className="w-[12px] h-[12px] rounded-full bg-black absolute top-[-5px]"
            >
              <span
                className={classNames("absolute text-[10px] top-[16px]", {
                  "left-[-1px]": value < 10,
                  "left-[-4px]": value >= 10,
                })}
              >
                {value}%
              </span>
            </div>
          </div>
        </div>
        <span className="ml-4 text-sm text-[#1F1F1FB2] font-normal">0</span>
      </div>
    </>
  );
};

export default ReviewUnitCostOfSalesSlider;
