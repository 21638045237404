import { createContext, useContext, useState } from "react";
import { SalesJourney, SourceChannelDropdown, User } from "../../common/Types";
import { IndexGeneralDataResponse } from "../../common/Responses";
import api from "../../services/PabloApiService";
import { ColumnType } from "../../common/Types/DragAndDrop";

type LeadManagementResponse<T> = {
  data: { data: T[] };
  success: boolean;
};

type LeadManagementContextType = {
  catalysts: User[];
  salesJourneys: SalesJourney[];
  channels: SourceChannelDropdown[];
  unitNumbers: string[];
  getCatalysts: () => Promise<LeadManagementResponse<User>>;
  getSalesJourneyStatuses: () => Promise<LeadManagementResponse<ColumnType>>;
  getChannels: () => Promise<boolean>;
  getUnitList: () => Promise<boolean>;
};

const defaultContext: LeadManagementContextType = {
  catalysts: [],
  salesJourneys: [],
  channels: [],
  unitNumbers: [],
  getCatalysts: async () => {
    return { data: { data: [] }, success: false };
  },
  getSalesJourneyStatuses: async () => {
    return { data: { data: [] }, success: false };
  },
  getChannels: async () => false,
  getUnitList: async () => false,
};

const LeadManagementContext =
  createContext<LeadManagementContextType>(defaultContext);

export const useLeadManagementContext = () => useContext(LeadManagementContext);

export const LeadManagementContextProvider: React.FC = ({ children }) => {
  const [salesJourneys, setSalesJourneys] = useState<SalesJourney[]>([]);
  const [catalysts, setCatalysts] = useState<User[]>([]);
  const [channels, setChannels] = useState<SourceChannelDropdown[]>([]);
  const [unitNumbers, setUnitNumbers] = useState<string[]>([]);

  const getSalesJourneyStatuses = async (): Promise<
    LeadManagementResponse<ColumnType>
  > => {
    const response = await api.get<IndexGeneralDataResponse<ColumnType>>(
      `/dropdown/sales-journey`
    );

    if (response.success) {
      setSalesJourneys(
        response.data.data.map((salesJourney: ColumnType) => {
          return {
            id: parseInt(salesJourney.id),
            value: salesJourney.value,
            displayName: salesJourney.displayName,
          };
        })
      );
    }

    return response;
  };

  const getCatalysts = async (): Promise<LeadManagementResponse<User>> => {
    const response = await api.get<IndexGeneralDataResponse<User>>(
      `/dropdown/catalyst`
    );

    if (response.success) {
      setCatalysts(response.data.data);
    }

    return response;
  };

  const getChannels = async (): Promise<boolean> => {
    const { data, success } = await api.get<
      IndexGeneralDataResponse<SourceChannelDropdown>
    >(`/dropdown/source-channel`);

    if (success) {
      setChannels(data.data);
    }

    return success;
  };

  const getUnitList = async (): Promise<boolean> => {
    const { data, success } = await api.get<IndexGeneralDataResponse<string>>(
      `/dropdown/unit-number`
    );

    if (success) {
      setUnitNumbers(data.data);
    }

    return success;
  };

  return (
    <LeadManagementContext.Provider
      value={{
        catalysts,
        salesJourneys,
        channels,
        unitNumbers,
        getCatalysts,
        getSalesJourneyStatuses,
        getChannels,
        getUnitList,
      }}
    >
      {children}
    </LeadManagementContext.Provider>
  );
};
