import React from "react";
interface NavSectionProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title?: string;
  show: boolean;
}

const NavSection: React.FC<NavSectionProps> = ({ title, children, show }) => {
  if (!show) return null;

  return (
    <div className="col mb-4 gap-1">
      <label
        hidden={!title}
        className="uppercase text-nav-label font-bold select-none"
      >
        {title}
      </label>
      {children}
    </div>
  );
};

export default NavSection;
