/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from "react";

type DeviceContextType = {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isLandscape: boolean;
  isPortrait: boolean;
};

const defaultContext: DeviceContextType = {
  isMobile: false,
  isTablet: false,
  isLaptop: false,
  isDesktop: false,
  isLandscape: false,
  isPortrait: false,
};

const DeviceContext = createContext<DeviceContextType>(defaultContext);

export const useDeviceContext = () => useContext(DeviceContext);

export const DeviceContextProvider: React.FC = ({ children }) => {
  // changed to singe state to minimise re-rendering
  const [device, setDevice] = useState<DeviceContextType>({
    isMobile: window.innerWidth <= 768,
    isTablet: window.innerWidth > 768 && window.innerWidth <= 1024,
    isLaptop: window.innerWidth > 1024 && window.innerWidth <= 1280,
    isDesktop: window.innerWidth > 1280,
    isLandscape: window.innerHeight < window.innerWidth,
    isPortrait: window.innerHeight >= window.innerWidth,
  });
  const mobileOnlyPages = ["calculator", "login", "social"];
  const isMobileOnlyPage = mobileOnlyPages.some((page) =>
    window.location.pathname.includes(page)
  );

  device.isPortrait &&
    device.isMobile &&
    window.location.pathname === "/" &&
    window.location.replace(window.location.origin + "/calculator");

  const handleWindowResize = () => {
    setDevice({
      isMobile: window.innerWidth <= 768,
      isTablet: window.innerWidth > 768 && window.innerWidth <= 1024,
      isLaptop: window.innerWidth > 1024 && window.innerWidth <= 1280,
      isDesktop: window.innerWidth > 1280,
      isLandscape: window.innerHeight < window.innerWidth,
      isPortrait: window.innerHeight >= window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <DeviceContext.Provider value={device}>
      {device.isPortrait && !isMobileOnlyPage && (
        <div className="fixed col items-center justify-center overflow-auto z-50 bg-primary left-0 right-0 top-0 bottom-0">
          <img
            src="https://yoo8kempinski.8conlay.com/themes/default/assets/images/rotate-device.png"
            alt="rotate_device"
            className="w-2/12 mb-4"
          />

          <span className="text-white font-bold text-center tablet:text-2xl tablet:w-8/12">
            Please rotate your device to landscape.
          </span>

          <img
            src="https://yoo8kempinski.8conlay.com/themes/default/assets/images/screen_lock.png"
            alt="unlock_orientation"
            className="w-1/12 mb-4 mt-10"
          />

          <span className="text-white font-bold text-center tablet:text-2xl tablet:w-7/12">
            If screen does not rotate, make sure your screen orientation is
            unlocked.
          </span>
        </div>
      )}
      {children}
    </DeviceContext.Provider>
  );
};
