import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface BackButtonProps {
  to?: string;
  text: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    if (props.to === undefined) {
      navigate(-1);
    } else {
      navigate(props.to);
    }
  };

  return (
    <div
      id="back-button"
      onClick={navigateTo}
      className="group row items-center cursor-pointer font-light text-sm w-[fit-content] mx-7 h-20 hover:text-secondary"
    >
      <PabloIcon
        icon={Icon.arrow}
        className="-ml-1 group-hover:secondary-icon"
      />
      <span className="uppercase ml-1">{props.text}</span>
    </div>
  );
};

export default BackButton;
