const watermark = require("watermarkjs");

export const addWatermark = async (file: File): Promise<Blob> => {
  const watermarkedFile = watermark([file])
    .blob(
      watermark.text.center("For KSK use only", "64px Arial", "#000000", 0.3)
    )
    .then((img: Blob) => {
      return img;
    });

  watermark.destroy();

  return watermarkedFile;
};
