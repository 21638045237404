import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PabloIcon from "../Icons/PabloIcon";

interface NavItemProps {
  icon: string;
  label: string;
  to: string;
  show: boolean;
  isExternal?: boolean;
  target?: string;
  rel?: string;
  onHide?: React.MouseEventHandler;
}

const NavItem: React.FC<NavItemProps> = (props) => {
  const location = useLocation();
  const isActive = location.pathname.includes(props.to);
  const [style, setStyle] = useState<string>("");

  useEffect(() => {
    setStyle(
      classNames(
        "row items-center text-white p-2 rounded text-sm transition-all ease-in-out select-none",
        {
          "font-bold bg-[#494949]": isActive,
          "font-light hover:bg-[#494949]": !isActive,
        }
      )
    );
  }, [isActive]);

  if (!props.show) return null;

  return (
    <>
      {props.isExternal ? (
        <a
          href={props.to}
          target={props?.target}
          rel={props?.rel}
          className={style}
          onClick={props.onHide}
        >
          <PabloIcon icon={props.icon} />
          <span className={"ml-3 truncate"}>{props.label}</span>
        </a>
      ) : (
        <Link
          to={props.to}
          target={props?.target}
          rel={props?.rel}
          className={style}
          onClick={props.onHide}
        >
          <PabloIcon icon={props.icon} />
          <span className={"ml-3 truncate"}>{props.label}</span>
        </Link>
      )}
    </>
  );
};

export default NavItem;
