import { createContext, useContext, useState } from "react";
import {
  defaultChartAbsValues,
  defaultChartPsfValues,
} from "../../common/ManagementApproval";
import {
  IndexProposalResponse,
  IndexReviewDevelopmentImpact,
  ShowProposalResponse,
  StoreProposalResponse,
} from "../../common/Responses";
import {
  ApprovalOption,
  Proposal,
  RebateRecord,
  ReviewDevelopmentImpact,
  ReviewPriceDistribution,
  ReviewPriceDistributionCoordinates,
  UnitDiscountChart,
} from "../../common/Types";
import api from "../../services/PabloApiService";
import { default as peApi } from "../../services/PricingEngineApiService";
import { rounding } from "../../utils/CommonUtils";
import { useArray } from "../UseArray";

type ManagementApprovalListContextType = {
  proposals: Proposal[];
  isLoading: boolean;
  loadingItems: string[];
  unitDiscountChart: UnitDiscountChart;
  reviewDevelopmentImpact: ReviewDevelopmentImpact;
  reviewPriceDistribution: ReviewPriceDistributionCoordinates;
  index: (append?: Array<string>) => Promise<boolean>;
  show: (id: number) => Promise<Proposal | undefined>;
  search: (keyword: string) => void;
  update: (action: ApprovalOption, id: number, body?: any) => Promise<boolean>;
  getUnitDiscountChart: (body: any, record?: RebateRecord) => void;
  getDevelopmentImpact: (body: any, record?: RebateRecord) => void;
};

const defaultContext: ManagementApprovalListContextType = {
  proposals: [],
  isLoading: true,
  loadingItems: [],
  unitDiscountChart: {
    totalUnitGrossSellingPrice: {
      absolute: 0,
      percentage: 0,
    },
    totalUnitRecMinSellingPrice: {
      absolute: 0,
      percentage: 0,
    },
    totalUnitBaseSellingPrice: {
      absolute: 0,
      percentage: 0,
    },
    totalUnitActualSunkCost: {
      absolute: 0,
      percentage: 0,
    },
  },
  reviewDevelopmentImpact: {
    current: {
      soldNettNettPricePsf: 0,
      unsoldNettNettPricePsf: 0,
      averageNettNettPricePsf: 0,
      soldNettNettDevelopmentValue: 0,
      unsoldNettNettDevelopmentValue: 0,
      totalNettNettDevelopmentValue: 0,
      nettNettProfitMargin: 0,
    },
    postApproval: {
      soldNettNettPricePsf: 0,
      unsoldNettNettPricePsf: 0,
      averageNettNettPricePsf: 0,
      soldNettNettDevelopmentValue: 0,
      unsoldNettNettDevelopmentValue: 0,
      totalNettNettDevelopmentValue: 0,
      nettNettProfitMargin: 0,
    },
  },
  reviewPriceDistribution: {
    averageUnsoldGrossPricePsf: 0,
    averageUnsoldGrossPrice: 0,
    unsoldUnitPricePsf: { current: [], postApproval: [] },
    unsoldUnitPrice: { current: [], postApproval: [] },
  },
  index: async (append?: Array<string>) => false,
  show: async (id: number) => undefined,
  search: (keyword: string) => {},
  update: async (action: ApprovalOption, id: number, body?: any) => false,
  getUnitDiscountChart: (body: any, record?: RebateRecord) => {},
  getDevelopmentImpact: (body: any, record?: RebateRecord) => {},
};

const ManagementApprovalListContext =
  createContext<ManagementApprovalListContextType>(defaultContext);

export const useManagementApprovalListContext = () =>
  useContext(ManagementApprovalListContext);

export const ManagementApprovalListContextProvider: React.FC = ({
  children,
}) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [tempProposals, setTempProposals] = useState<Proposal[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [unitDiscountChart, setUnitDiscountChart] = useState<UnitDiscountChart>(
    {
      totalUnitGrossSellingPrice: {
        absolute: 0,
        percentage: 0,
      },
      totalUnitRecMinSellingPrice: {
        absolute: 0,
        percentage: 0,
      },
      totalUnitBaseSellingPrice: {
        absolute: 0,
        percentage: 0,
      },
      totalUnitActualSunkCost: {
        absolute: 0,
        percentage: 0,
      },
    }
  );
  const [reviewDevelopmentImpact, setReviewDevelopmentImpact] =
    useState<ReviewDevelopmentImpact>({
      current: {
        soldNettNettPricePsf: 0,
        unsoldNettNettPricePsf: 0,
        averageNettNettPricePsf: 0,
        soldNettNettDevelopmentValue: 0,
        unsoldNettNettDevelopmentValue: 0,
        totalNettNettDevelopmentValue: 0,
        nettNettProfitMargin: 0,
      },
      postApproval: {
        soldNettNettPricePsf: 0,
        unsoldNettNettPricePsf: 0,
        averageNettNettPricePsf: 0,
        soldNettNettDevelopmentValue: 0,
        unsoldNettNettDevelopmentValue: 0,
        totalNettNettDevelopmentValue: 0,
        nettNettProfitMargin: 0,
      },
    });
  const [reviewPriceDistribution, setReviewPriceDistribution] =
    useState<ReviewPriceDistributionCoordinates>({
      averageUnsoldGrossPricePsf: 0,
      averageUnsoldGrossPrice: 0,
      unsoldUnitPricePsf: {
        current: defaultChartPsfValues.map((value) => value),
        postApproval: defaultChartPsfValues.map((value) => value),
      },
      unsoldUnitPrice: {
        current: defaultChartAbsValues.map((value) => value),
        postApproval: defaultChartAbsValues.map((value) => value),
      },
    });
  const [loadingItems, dispatch] = useArray<string>([]);

  const index = async (): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.get<IndexProposalResponse>(
      "/management-approvals"
    );

    if (success) {
      setProposals(data.proposals);
      setTempProposals(data.proposals);
    }

    setLoading(false);

    return true;
  };

  const show = async (id: number): Promise<Proposal | undefined> => {
    setLoading(true);

    const { data, success } = await api.get<ShowProposalResponse>(
      `/management-approvals/${id}`
    );

    setLoading(false);

    return success ? data.proposal : undefined;
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const results = tempProposals.filter((proposal) => {
        const sameName = proposal?.customers?.some((customer) => {
          return customer.fullName.toLowerCase().includes(formattedKeyword);
        });
        const sameCompanyName = proposal?.companies?.some((company) => {
          return company.name.toLowerCase().includes(formattedKeyword);
        });
        const sameUnitNo = proposal?.unit?.number
          .toLowerCase()
          .includes(formattedKeyword);
        return sameName || sameCompanyName || sameUnitNo;
      });

      setProposals(results);
    } else {
      setProposals(tempProposals);
    }
  };

  const update = async (
    action: ApprovalOption,
    id: number,
    body?: any
  ): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.patch<StoreProposalResponse>(
      `/management-approvals/${id}/${action}`,
      body
    );

    if (success) {
      let temp = proposals.slice();
      const updatingUser = temp.filter((proposal) => proposal.id === id)[0];
      const tempIndex = temp.indexOf(updatingUser);
      temp[tempIndex] = data.proposal;
      setProposals(temp);
    }

    setLoading(false);

    return success;
  };

  const getUnitDiscountChart = async (body: any, record?: RebateRecord) => {
    dispatch({ type: "add", value: "/review_unit_discount" });

    if (record) {
      setUnitDiscountChart(record?.unitDiscount);
    } else {
      const { data, success } = await peApi.post<UnitDiscountChart>(
        "/proposal-review/discount",
        body
      );

      if (success) {
        setUnitDiscountChart(data);
      }
    }

    dispatch({ type: "remove", value: "/review_unit_discount" });
  };

  const getDevelopmentImpact = async (body: any, record?: RebateRecord) => {
    dispatch({ type: "add", value: "/review_development_impact" });

    if (record) {
      setReviewDevelopmentImpact(record?.developmentImpact);
      setReviewPriceDistribution({
        averageUnsoldGrossPricePsf: record?.avgGrossPsf,
        averageUnsoldGrossPrice: record?.avgGrossAbs,
        unsoldUnitPricePsf: record?.unsoldPricePsfCoordinates,
        unsoldUnitPrice: record?.unsoldPriceAbsCoordinates,
      });
    } else {
      const { data, success } = await peApi.post<IndexReviewDevelopmentImpact>(
        "/proposal-review/dev-impact",
        body
      );

      if (success) {
        setReviewDevelopmentImpact(data.reviewDevelopmentImpact);
        assignChartCoordinates(data.reviewPriceDistribution);
      }
    }

    dispatch({ type: "remove", value: "/review_development_impact" });
  };

  const assignChartCoordinates = (data: ReviewPriceDistribution) => {
    let tempChartPsfValues = {
      current: reviewPriceDistribution.unsoldUnitPricePsf.current.map(
        (value) => {
          return { x: value.x, y: 0 };
        }
      ),
      postApproval: reviewPriceDistribution.unsoldUnitPricePsf.postApproval.map(
        (value) => {
          return { x: value.x, y: 0 };
        }
      ),
    };

    let tempChartAbsValues = {
      current: reviewPriceDistribution.unsoldUnitPrice.current.map((value) => {
        return { x: value.x, y: 0 };
      }),
      postApproval: reviewPriceDistribution.unsoldUnitPrice.postApproval.map(
        (value) => {
          return { x: value.x, y: 0 };
        }
      ),
    };

    data.unsoldUnitPricePsf.forEach((price) => {
      const currentKey = rounding(price.current / 100, "ceil") * 100 - 50;
      const postKey = rounding(price.postApproval / 100, "ceil") * 100 - 50;

      tempChartPsfValues.current.forEach((value) => {
        value.x === currentKey && value.y++;
      });

      tempChartPsfValues.postApproval.forEach((value) => {
        value.x === postKey && value.y++;
      });
    });

    data.unsoldUnitPrice.forEach((price) => {
      const currentKey = parseFloat(
        (rounding(price.current / 200000, "ceil") * 0.2 - 0.1).toFixed(1)
      );
      const postKey = parseFloat(
        (rounding(price.postApproval / 200000, "ceil") * 0.2 - 0.1).toFixed(1)
      );

      tempChartAbsValues.current.forEach((value) => {
        value.x === currentKey && value.y++;
      });

      tempChartAbsValues.postApproval.forEach((value) => {
        value.x === postKey && value.y++;
      });
    });

    setReviewPriceDistribution({
      averageUnsoldGrossPricePsf: data.averageUnsoldGrossPricePsf,
      averageUnsoldGrossPrice: data.averageUnsoldGrossPrice,
      unsoldUnitPricePsf: tempChartPsfValues,
      unsoldUnitPrice: tempChartAbsValues,
    });
  };

  return (
    <ManagementApprovalListContext.Provider
      value={{
        proposals,
        isLoading,
        loadingItems,
        unitDiscountChart,
        reviewDevelopmentImpact,
        reviewPriceDistribution,
        index,
        show,
        search,
        update,
        getUnitDiscountChart,
        getDevelopmentImpact,
      }}
    >
      {children}
    </ManagementApprovalListContext.Provider>
  );
};
