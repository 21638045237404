/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState, useRef } from "react";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import classNames from "classnames";
import { formatErrorMessage, sortDropdown } from "../../utils/CommonUtils";
import { useOnOutsideClick } from "../../hooks/UseOnOutsideClick";
import { DropdownItem, SortingOption } from "../../common/Types";

interface DropdownFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  id: string;
  label?: string;
  items: DropdownItem[];
  searchable?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  error?: string;
  dynamicWidth?: boolean;
  innerClassName?: string;
  containerClassName?: string;
  listContainerClassName?: string;
  listClassName?: string;
  showValue?: boolean;
  disabled?: boolean;
  autoFillable?: boolean;
  sortOption?: SortingOption;
}

const DropdownField = forwardRef<HTMLSelectElement, DropdownFieldProps>(
  (
    {
      id,
      label,
      items,
      searchable,
      placeholder,
      searchPlaceholder,
      error,
      dynamicWidth,
      innerClassName,
      containerClassName,
      listContainerClassName,
      listClassName,
      showValue,
      disabled,
      sortOption,
      autoFillable,
      ...props
    },
    ref
  ) => {
    items = sortDropdown(items, sortOption);
    const containerRef = useRef<HTMLDivElement>(null);
    const searchableInput = useRef<HTMLInputElement>(null);
    const defaultItem = items.find((item) => item.value === props.defaultValue);
    const input = document.getElementById(id) as HTMLSelectElement;

    const [filteredItems, setFilteredItems] = useState<DropdownItem[]>(items);
    const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
      defaultItem
    );
    const [keyword, setKeyword] = useState<string>("");
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
      const formatString = (value: string) => {
        return value?.toLowerCase()?.replaceAll(/[-_ ]/g, "");
      };

      const filterredItems = items.filter((item) => {
        return formatString(item.text)?.includes(formatString(keyword));
      });

      setFilteredItems(filterredItems);
    }, [keyword]);

    useEffect(() => {
      if (autoFillable)
        if (input?.value) {
          let tempSelectedItem = items.find(
            (item) => item.value === input.value
          );
          setSelectedItem(tempSelectedItem);
        } else {
          defaultItem
            ? setSelectedItem(defaultItem)
            : setSelectedItem(undefined);
        }
    }, [input?.value]);

    const toggle = () => {
      setKeyword("");
      setExpanded(!expanded);
    };

    // this is a stupid function, do not try to understand it
    const itemOnChanged = (item: DropdownItem) => {
      // this will inject to yup validation value to do validation
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLSelectElement.prototype,
        "value"
      )?.set;
      nativeInputValueSetter?.call(input, item.value);
      // disable and enable it again after `change` event has been trigger, disabled element unable to trigger the `change` event
      // the `change` event is important for yup validation
      input.disabled = false;
      const event = new Event("change", { bubbles: true });
      input.dispatchEvent(event);
      input.disabled = true;
      // set selected item and collapse the dropdown, this part is normal
      setKeyword("");
      setSelectedItem(item);
      setExpanded(false);
    };

    useOnOutsideClick(containerRef, () => setExpanded(false));

    return (
      <div
        ref={containerRef}
        className={classNames(
          "col relative",
          {
            "w-full tablet:w-[498px]": !dynamicWidth,
            "opacity-50": disabled,
          },
          containerClassName
        )}
      >
        {/* Input */}
        <button
          onClick={toggle}
          disabled={disabled}
          className={classNames(
            "input-border w-full cursor-pointer text-base mobile:text-sm focus:outline-none",
            {
              "border-primary": error,
              "cursor-not-allowed": disabled,
            },
            innerClassName
          )}
        >
          {label && (
            <div className="row gap-1">
              <label className="uppercase block mb-1">{label}</label>
              {(props.required ?? true) && (
                <span className="text-dark-red">*</span>
              )}
            </div>
          )}

          <div className="row justify-between items-center font-semibold w-full">
            <span
              className={classNames(
                "",
                {
                  "text-[#9ca3af]": !selectedItem?.text,
                },
                selectedItem?.className
              )}
            >
              {showValue
                ? selectedItem?.value
                : selectedItem?.text ?? placeholder}
            </span>
            <select
              ref={ref}
              id={id}
              className={classNames(
                "invisible w-0 h-0 appearance-none disabled:opacity-100 bg-transparent font-semibold focus:outline-none cursor-pointer",
                {
                  "text-[#9ca3af]": selectedItem?.value === undefined,
                }
              )}
              {...props}
            >
              {items.map((item, index) => {
                return (
                  <option
                    key={`${label}-${item.value}-${index}`}
                    value={item.value}
                  >
                    {item.text}
                  </option>
                );
              })}
            </select>
            <PabloIcon
              icon={Icon.arrow}
              className={classNames("transition-all duration-300 -rotate-90", {
                "rotate-90": expanded,
              })}
            />
          </div>
        </button>

        {/* Error message */}
        <span
          hidden={!error}
          className="text-sm font-light text-primary pt-2 transition-all duration-200"
        >
          {formatErrorMessage(error)}
        </span>

        {/* Info text */}
        <span
          hidden={!selectedItem?.description}
          className="text-sm font-light text-[#AAAAAA] italic pt-2 transition-all duration-200"
        >
          {selectedItem?.description}
        </span>

        {/* Pop up */}
        <div
          hidden={!expanded}
          onMouseDown={(e) => e.preventDefault()}
          className={classNames(
            "z-30 absolute mt-2 border-[1px] border-input-border bg-white",
            {
              "w-full": !listClassName,
              "top-[59px]": !listContainerClassName,
            },
            listContainerClassName
          )}
        >
          <div className="p-2" hidden={!searchable}>
            <input
              type="text"
              id={label}
              autoComplete="off"
              placeholder={searchPlaceholder ?? "Search"}
              onMouseDown={(e) => e.stopPropagation()}
              value={keyword}
              autoFocus={expanded}
              ref={searchableInput}
              onChange={(e) => setKeyword(e.target.value)}
              className="w-full font-semibold focus:outline-none text-base mobile:text-sm px-3 h-10 border-[1px] border-input-border"
            />
          </div>

          <div className={classNames("max-h-48 overflow-auto", listClassName)}>
            <div className="col">
              {filteredItems.length <= 0 ? (
                <span className="text-base mobile:text-sm px-5 pr-10 h-10 gap-2 row items-center">
                  No result
                </span>
              ) : (
                filteredItems.map((item, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => itemOnChanged(item)}
                      onKeyPress={(e) =>
                        e.key === "Enter" && itemOnChanged(item)
                      }
                      tabIndex={0}
                      className={classNames(
                        "text-base mobile:text-sm px-5 pr-10 py-2 mobile:py-0 mobile:h-10 hover:bg-ink-well gap-2 row items-center cursor-pointer focus:outline-none focus:bg-ink-well",
                        {
                          "bg-light-red": selectedItem?.value === item.value,
                        },
                        item?.className
                      )}
                    >
                      {item.text}
                    </span>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default DropdownField;
