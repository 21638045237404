import classNames from "classnames";
import React from "react";
import { AgencyStatus, AgentStatus } from "../../common/Enums";

interface StatusChipProps {
  status: AgencyStatus | AgentStatus;
}

const AgencyStatusChip: React.FC<StatusChipProps> = ({ status }) => {
  let className = "px-3 py-1 text-center w-[fit-content] truncate ";

  switch (status) {
    case "active":
      className += "bg-light-green text-dark-green";
      break;

    case "inactive":
      className += "bg-light-red text-dark-red";
      break;

    case "adhoc":
      className += "bg-light-orange text-dark-orange";
      break;

    default:
      break;
  }

  return <div className={classNames("capitalize", className)}>{status}</div>;
};

export default AgencyStatusChip;
