export const roles = [
  "catalyst",
  "commercial-lead",
  "customer-engagement",
  "exco",
  "admin",
] as const;

export const permissions = [
  "create-proposal", // 0
  "my-proposals", // 1
  "tableau-dashboard", // 2
  "management-approval", // 3
  "profit-margin", // 4
  "exco-variables-listing", // 5
  "proposal-listing", // 6
  "reserved-extension", // 7
  "manage-agent", // 8
  "manage-user", // 9
  "booking-approval", // 10
  "cancellation-approval", // 12
] as const;

export type Role = typeof roles[number];

export type Permission = typeof permissions[number];

export const defaultPermissions = (role: Role): Permission[] => {
  switch (role) {
    case "admin":
      return [...permissions];

    case "exco":
      return [
        ...permissions.slice(0, 3),
        ...permissions.slice(4, 8),
        ...permissions.slice(11, 12),
      ];

    case "commercial-lead":
      return [
        ...permissions.slice(0, 3),
        ...permissions.slice(6, 8),
        ...permissions.slice(11, 12),
      ];

    case "customer-engagement":
      return [
        ...permissions.slice(0, 2),
        ...permissions.slice(6, 7),
        ...permissions.slice(10, 12),
      ];

    default:
      return [...permissions.slice(0, 3)];
  }
};

export const blockedPermissions = (role: Role): Permission[] => {
  switch (role) {
    case "catalyst":
      return [...permissions.slice(4, 8), ...permissions.slice(9, 12)];

    case "customer-engagement":
      return [
        ...permissions.slice(3, 6),
        ...permissions.slice(7, 8),
        ...permissions.slice(9, 10),
      ];

    case "commercial-lead":
      return [...permissions.slice(4, 6)];

    default:
      return [];
  }
};

export const roleName = (role: string): string => {
  const tempRoleName = {
    exco: "Management",
    catalyst: "Catalyst",
    "commercial-lead": "Commercial Lead",
    admin: "Admin",
    "customer-engagement": "Customer Engagement",
  }[role];

  return tempRoleName ?? "";
};
