import classNames from "classnames";
import React from "react";

interface RequiredFieldProps {
  show?: boolean;
}

const RequiredField: React.FC<RequiredFieldProps> = ({ show = true }) => {
  return (
    <div
      className={classNames(
        "font-light text-sm italic transition-opacity duration-300 select-none",
        {
          "opacity-0": !show,
          "opacity-100": show,
        }
      )}
    >
      <span className="text-dark-red mr-1">*</span>
      <span>required fields</span>
    </div>
  );
};

export default RequiredField;
