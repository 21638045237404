/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  Booking,
  Company,
  Customer,
  OpportunitySource,
  PageType,
  PEUnit,
  Proposal,
  SalesPackage,
} from "../../common/Types";
import BackButton from "../../components/Buttons/BackButton";
import FormCard from "../../components/Cards/FormCard";
import BottomBar from "../../components/Navs/BottomBar";
import ProposalSummary from "../../components/ProposalSummary/ProposalSummary";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import LimboPage from "../generals/LimboPage";
import api from "../../services/PabloApiService";
import { ShowGeneralDataResponse } from "../../common/Responses";
import { useMyProposalListContext } from "../../hooks/contexts/MyProposalListContext";
import { determineApprovalStage } from "../../utils/ApprovalUtils";
import { useBookingApprovalListContext } from "../../hooks/contexts/BookingApprovalListContext";
import { usePageHandler } from "../../hooks/UsePageHandler";
import { useLegacyProposalListContext } from "../../hooks/contexts/LegacyProposalListContext";
import DropdownField from "../../components/Fields/DropdownField";
import Line from "../../components/General/Line";
import { boolean, object, SchemaOf } from "yup";
import { baseString } from "../../utils/SchemaUtil";
import { useValidation } from "../../hooks/UseValidation";

interface SalesPICForm {
  isLegacy: boolean;
  salesPicId?: string;
}

interface GenerateLettersViewPageProps {
  pageType?: PageType;
}

const schema: SchemaOf<SalesPICForm> = object().shape({
  isLegacy: boolean().required().default(false),
  salesPicId: baseString().when("isLegacy", {
    is: true,
    then: baseString().required().label("Sales Pic"),
  }),
});

const GenerateLettersViewPage: React.FC<GenerateLettersViewPageProps> = ({
  pageType = "my-proposal",
}) => {
  const page = usePageHandler(pageType);
  const { proposalId } = useParams();
  const myProposal = useMyProposalListContext();
  const bookingApprovalList = useBookingApprovalListContext();
  const legacyProposalList = useLegacyProposalListContext();
  const loadingPage = useLoadingPageContext();
  const navigate = useNavigate();
  const modal = useModalContext();
  const validation = useValidation<SalesPICForm>(schema);
  const salesPicForm = validation.watch();

  const [proposal, setProposal] = useState<Proposal | undefined>(
    useLocation().state
  );

  const latestApprovals = determineApprovalStage(
    proposal?.allApprovals,
    proposal?.stage
  );

  const isSalesPicUnassigned =
    page.type === "legacy-proposal" &&
    proposal?.user?.email === "undefined.user@kskgroup.com";

  useEffect(() => {
    if (proposal) {
      bookingApprovalList.handlers.length <= 0 &&
        isSalesPicUnassigned &&
        bookingApprovalList.getHandlers();
      validation.setValue("isLegacy", isSalesPicUnassigned ? true : false);
    } else {
      showProposal();
    }
  }, []);

  const showProposal = async () => {
    loadingPage.start();

    const tempProposal =
      page.type === "ce"
        ? await bookingApprovalList.show(parseInt(proposalId!))
        : page.type === "my-proposal"
        ? await myProposal.show(parseInt(proposalId!))
        : await legacyProposalList.show(parseInt(proposalId!));

    setProposal(tempProposal);

    const isSalesPicUnassignedTemp =
      page.type === "legacy-proposal" &&
      tempProposal?.user?.email === "undefined.user@kskgroup.com";

    isSalesPicUnassignedTemp && (await bookingApprovalList.getHandlers());

    validation.setValue("isLegacy", isSalesPicUnassignedTemp ? true : false);

    loadingPage.end();
  };

  if (!proposal) {
    if (page.type === "ce" && !bookingApprovalList.isLoading)
      return <LimboPage type="not-found" />;

    if (page.type === "my-proposal" && !myProposal.isLoading)
      return <LimboPage type="not-found" />;

    if (page.type === "legacy-proposal" && !legacyProposalList.isLoading)
      return <LimboPage type="not-found" />;

    return <></>;
  }

  const showConfirmation = () => {
    modal.confirmation({
      title: "Are you sure you want to generate 7 letters for this proposal?",
      description: "",
      onConfirm: {
        text: "Confirm",
        action: () => generateLetters(),
      },
    });
  };

  const generateLetters = async () => {
    loadingPage.start();

    const { success } = isSalesPicUnassigned
      ? await api.patch(
          `proposals/generate-letters/${proposalId}/update/${
            salesPicForm?.salesPicId || 0
          }/sales-pic`
        )
      : await api.get<ShowGeneralDataResponse<string>>(
          `proposals/generate-letters/${proposalId}/send`
        );

    if (success) {
      navigate(page.path);
      toast.success("Letters have been generated and sent to your email.");
    }

    loadingPage.end();
  };

  const validate = async () => {
    if (isSalesPicUnassigned) {
      const result = await validation.trigger();

      if (result) showConfirmation();
    } else {
      showConfirmation();
    }
  };

  return (
    <>
      {/* page */}
      <div className="col h-full justify-between">
        <div>
          <BackButton to={page.path} text={page.backToText} />

          <FormCard
            showRequired={false}
            title={isSalesPicUnassigned ? "Assign a Sales PIC" : ""}
          >
            {isSalesPicUnassigned && bookingApprovalList.handlers.length > 0 && (
              <>
                <DropdownField
                  id="salesPicId"
                  label="Sales PIC"
                  placeholder="Select a sales PIC"
                  error={validation.errors.salesPicId?.message}
                  {...validation.register("salesPicId", { value: "" })}
                  items={bookingApprovalList.handlers.map((handler) => {
                    return {
                      text: handler.name,
                      value: handler.id.toString(),
                    };
                  })}
                />

                <Line />
              </>
            )}

            <h2 className="mb-4">Proposal Summary</h2>

            <ProposalSummary
              title=""
              summarized={false}
              units={
                [
                  {
                    buildup: proposal?.unit?.type?.buildUp || 0,
                    grossPrice: 0,
                    idTheme: proposal?.unit?.theme,
                    orientation:
                      proposal?.unit?.type?.orientation.toString() as string,
                    pricePsf: 0,
                    status: "",
                    type: proposal?.unit?.type?.name.toString() as string,
                    unitNo: proposal?.unit?.number,
                    level: proposal?.unit?.level,
                  },
                ] as PEUnit[]
              }
              customers={proposal?.customers as Customer[]}
              companies={proposal?.companies as Company[]}
              opportunitySource={
                proposal?.opportunitySource as OpportunitySource
              }
              solicitor={proposal.solicitor}
              salesPicInfo={proposal.user}
              salesPackage={proposal?.salesPackage as SalesPackage}
              bookingInfo={proposal?.booking as Booking}
              remarks={proposal?.remarks}
              approvals={
                latestApprovals?.length
                  ? latestApprovals
                  : determineApprovalStage(proposal?.allApprovals, "creation")
              }
              soldInfo={proposal?.soldDetail}
              cancelledInfo={proposal?.cancelledDetail}
              withdrawnInfo={proposal?.withdrawnDetail}
              ceActionsHistory={proposal?.ceActionsHistory}
            />
          </FormCard>
        </div>

        <BottomBar>
          <button className="primary-button" onClick={validate}>
            Generate 7 Letters
          </button>
        </BottomBar>
      </div>
    </>
  );
};

export default GenerateLettersViewPage;
