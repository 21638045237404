import React from "react";
import RequiredField from "../../views/generals/RequiredField";
import BaseCard from "./BaseCard";

interface FormCardProps {
  title: string;
  showRequired?: boolean;
}

const FormCard: React.FC<FormCardProps> = ({
  title,
  showRequired = true,
  children,
}) => {
  return (
    <BaseCard>
      {title && (
        <div className="row items-center mb-4">
          <h2 className="mr-4">{title}</h2>
          {showRequired && <RequiredField />}
        </div>
      )}
      {children}
    </BaseCard>
  );
};

export default FormCard;
