import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "../../common/Path";

const limboType = ["not-found", "under-construction", "no-permission"] as const;

export type LimboType = typeof limboType[number];

interface LimboPageProps {
  type: LimboType;
}

const LimboPage: React.FC<LimboPageProps> = ({ type }) => {
  const navigate = useNavigate();
  let title: string;
  let subtitle: string;

  switch (type) {
    case "not-found":
      title = "404 - Page Not Found";
      subtitle = "The page your are looking for does not exist";
      break;

    case "under-construction":
      title = "Under Construction";
      subtitle =
        "This page is currently under construction but we will be going live soon! Stay tuned!";
      break;

    default:
      title = "403 - You Need Permission";
      subtitle = "Looks like you do not have permission to access this page";
      break;
  }

  return (
    <div className="h-[93vh] col justify-center items-center gap-5">
      <span className="text-4xl font-bold">{title}</span>
      <span className="text-disabled">{subtitle}</span>
      <button
        className="primary-button mt-5"
        onClick={() => navigate(Path.main)}
      >
        Go to Unit Price List
      </button>
    </div>
  );
};

export default LimboPage;
