/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Country from "../../common/Country";
import { AgencyStatus } from "../../common/Enums";
import Path from "../../common/Path";
import { Agency } from "../../common/Types";
import MoreButton from "../../components/Buttons/MoreButton";
import BaseCard from "../../components/Cards/BaseCard";
import AgencyStatusChip from "../../components/Chips/AgencyStatusChip";
import Icon from "../../components/Icons/Icon";
import PabloIcon from "../../components/Icons/PabloIcon";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import PopUpItem from "../../components/PopUp/PopUpItem";
import SortCaret from "../../components/Tables/SortCaret";
import Table from "../../components/Tables/Table";
import HeaderText from "../../components/Texts/HeaderText";
import { useAgencyListContext } from "../../hooks/contexts/AgencyListContext";
import { useLoadingBarContext } from "../../hooks/contexts/LoadingBarContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useFilter } from "../../hooks/Filter";

const AgencyListPage: React.FC = () => {
  const navigate = useNavigate();
  const agencyList = useAgencyListContext();
  const loadingBar = useLoadingBarContext();
  const [deletingAgency, setDeletingAgency] = useState<Agency | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const loadingPage = useLoadingPageContext();
  const { items, sort } = useFilter<Agency>(agencyList.agencies);

  useEffect(() => {
    agencyList.index("", ["salesRep"]);
  }, []);

  useEffect(() => {
    if (agencyList.isLoading) loadingBar.start();
    else loadingBar.end();
  }, [agencyList.isLoading]);

  const viewAgency = (agency: Agency) => {
    navigate(Path.editAgency(agency.id), {
      state: { agency: agency, salesReps: agencyList.salesReps },
    });
  };

  const addNewAgency = () => {
    navigate(Path.newAgency, {
      state: { salesReps: agencyList.salesReps },
    });
  };

  const deleteAgency = async () => {
    loadingPage.start();
    const success = await agencyList.destroy(deletingAgency!.id);
    setShowSuccess(success);
    loadingPage.end();
    agencyList.index("", ["salesRep"]);
  };

  return (
    <>
      {/* page */}
      <div className="col">
        <HeaderText title="Manage agency" />

        <BaseCard>
          <div className="row justify-end items-center mb-8">
            <button className="secondary-button group" onClick={addNewAgency}>
              <PabloIcon icon={Icon.plus} className="secondary-icon" />
              Add new agency
            </button>
          </div>

          <Table
            isLoading={agencyList.isLoading}
            onSearching={agencyList.search}
            searchHint="Search by Agency Name or Sales PIC"
            headers={[
              <SortCaret
                key={"name"}
                header="Agency"
                onToggle={() => sort("name")}
              />,
              <SortCaret
                key={"country"}
                header="Country"
                onToggle={() => sort("country", Country.countryName)}
              />,
              <SortCaret
                key={"loa-period"}
                header="LOA Period"
                onToggle={() => sort("loaStartDate")}
              />,
              <SortCaret
                key={"tier"}
                header="Tier"
                onToggle={() => sort("tier")}
              />,
              <SortCaret
                key={"commission"}
                header="Commission"
                onToggle={() => sort("commissionRate")}
              />,
              <SortCaret
                key={"sales-pic"}
                header="Sales PIC"
                onToggle={() => sort("salesRep.fullName")}
              />,
              <SortCaret
                key={"status"}
                header="Status"
                onToggle={() => sort("status")}
              />,
            ]}
            body={items.map((agency, index) => {
              return (
                <tr key={`${agency.id}-${index}`} className="m-5">
                  <td
                    className="hover:cursor-pointer hover:text-primary"
                    onClick={() => viewAgency(agency)}
                  >
                    <div className="row items-center overflow-ellipsis gap-2">
                      <span className="truncate">{agency.name}</span>
                    </div>
                  </td>
                  <td>{Country.countryName(agency.country)}</td>
                  <td>{`${agency.loaStartDate} - ${agency.loaEndDate}`}</td>
                  <td>{agency.tier}</td>
                  <td>{agency.commissionRate}%</td>
                  <td>{agency.salesRep?.fullName}</td>
                  <td>
                    <AgencyStatusChip status={agency.status as AgencyStatus} />
                  </td>

                  <td className="flex justify-end items-center h-11">
                    <MoreButton>
                      <PopUpItem
                        icon={Icon.edit}
                        label="Edit"
                        onClick={() => viewAgency(agency)}
                      />
                      <PopUpItem
                        icon={Icon.remove}
                        label="Delete"
                        onClick={() => setDeletingAgency(agency)}
                      />
                    </MoreButton>
                  </td>
                </tr>
              );
            })}
          />
        </BaseCard>
      </div>

      {/* modal */}
      <ConfirmationModal
        show={deletingAgency ? true : false}
        title="Are you sure you want to delete this agency?"
        type="warning"
        onHide={() => setDeletingAgency(null)}
        onConfirm={{
          text: "Delete",
          action: deleteAgency,
        }}
      />

      <ConfirmationModal
        show={showSuccess}
        title="You have successfully deleted this agency."
        type="success"
        onHide={() => setShowSuccess(false)}
        onConfirm={{
          text: "Manage agency",
          action: () => navigate(Path.agencies),
        }}
        onCancel={{
          text: "Back to main page",
          action: () => navigate(Path.main),
        }}
      />
    </>
  );
};

export default AgencyListPage;
