import moment, { Moment, unitOfTime } from "moment";

export const now = moment().startOf("month");

export const today = moment();

export const dateDiff = (
  startDate: Moment,
  endDate: Moment,
  unit: unitOfTime.Diff,
  isRoundUp: boolean = true
): number => {
  const diff = endDate.diff(startDate, unit, true);
  return isRoundUp ? Math.ceil(diff) : diff;
};
