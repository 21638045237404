import classNames from "classnames";
import TextField from "../Fields/TextField";
import "../../styles/sales-package.css";

interface SliderProps {
  id?: string;
  value: number;
  min: number;
  max: number;
  symbol?: string;
  hasField?: boolean;
  threshold?: number;
  thresholdText?: string;
  handleChange: (num: number) => void;
}

const Slider: React.FC<SliderProps> = (props) => {
  const {
    id,
    value,
    min,
    max,
    symbol,
    hasField,
    threshold,
    thresholdText,
    handleChange,
  } = props;

  return (
    <>
      <div className="row w-full flex gap-2.5 items-center justify-between">
        <span className="text-sm text-[#1F1F1FB2] font-normal">
          {min}
          {symbol ?? ""}
        </span>
        <div className="w-[400px] relative">
          {threshold !== undefined && threshold >= 0 && (
            <>
              <div
                style={{ left: `${threshold * 2}%` }}
                className="absolute h-full w-full top-[11px]"
              >
                <div className="border-l-2 border-solid border-[#B11116] h-[16px] flex">
                  <span
                    className={classNames(
                      "absolute text-[10px] top-[-16px] left-[-6px]",
                      {
                        "left-[-9px]": threshold > 9,
                      }
                    )}
                  >
                    {threshold}
                    {symbol ?? ""}
                  </span>
                </div>
              </div>

              {thresholdText && (
                <div
                  style={{
                    left: `${
                      threshold < 15
                        ? 0
                        : threshold >= 35
                        ? 39
                        : threshold * 2 - 30
                    }%`,
                  }}
                  className="absolute top-[40px] font-normal text-xs text-[#1F1F1FB2] flex"
                >
                  {thresholdText}
                </div>
              )}
            </>
          )}

          <div className="relative w-full">
            <input
              id={`${id}-slider`}
              type="range"
              name="rebate"
              className="w-full"
              min={min}
              max={max}
              onChange={(e) => {
                handleChange(parseInt(e.currentTarget.value));
              }}
              value={value}
            />
          </div>
        </div>
        <span className="text-sm text-[#1F1F1FB2] font-normal">
          {max}
          {symbol ?? ""}
        </span>

        {hasField && (
          <TextField
            id={`${id}-slider-text`}
            placeholder="Enter Value"
            type="text"
            error=""
            innerClassName="max-w-[110px] text-xs h-9 py-0 px-2"
            backLabel={symbol}
            value={value}
            onInput={(e) => {
              handleChange(parseInt(e.currentTarget.value));
            }}
            dynamicWidth={true}
          />
        )}
      </div>
    </>
  );
};

export default Slider;
