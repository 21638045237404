import classNames from "classnames";

interface ValidationMessageCardProps {
  value: string;
  hasError: boolean;
}

const ValidationMessageCard: React.FC<ValidationMessageCardProps> = (props) => {
  const match = (pattern: string | RegExp): string => {
    return classNames("", {
      "text-dark-green": props.value
        ? RegExp(pattern).test(props.value)
        : false,
    });
  };

  return (
    <div
      className={classNames("text-sm col w-full tablet:w-[498px]", {
        "text-dark-red": props.hasError,
        "text-gray": !props.hasError,
      })}
    >
      <span className={match(/^(?=.{8,})/)}>{"✔"} Minimum 8 characters</span>
      <span className={match(/^(?=.*[a-z])/)}>{"✔"} At least 1 lowercase</span>
      <span className={match(/^(?=.*[A-Z])/)}>{"✔"} At least 1 uppercase</span>
      <span className={match(/^(?=.*[0-9])/)}>{"✔"} At least 1 number</span>
      <span className={match(/^(?=.*[\W|_])/)}>
        {"✔"} At least 1 special character
      </span>
    </div>
  );
};

export default ValidationMessageCard;
