/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import Country from "../../common/Country";
import Path from "../../common/Path";
import {
  GeneralMessageResponse,
  IndexGeneralDataResponse,
} from "../../common/Responses";
import { Customer, User } from "../../common/Types";
import { ColumnType } from "../../common/Types/DragAndDrop";
import BaseCard from "../../components/Cards/BaseCard";
import Column from "../../components/DragAndDrop/Column";
import SearchField from "../../components/Fields/SearchField";
import Icon from "../../components/Icons/Icon";
import PabloIcon from "../../components/Icons/PabloIcon";
import HeaderText from "../../components/Texts/HeaderText";
import { useLeadManagementContext } from "../../hooks/contexts/LeadManagementContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import api from "../../services/PabloApiService";

const LeadBoardPage: React.FC = () => {
  const loadingPage = useLoadingPageContext();
  const navigate = useNavigate();
  const leadManagement = useLeadManagementContext();

  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [prospects, setProspects] = useState<Customer[]>([]);

  const assignColumns = (
    columns: ColumnType[],
    tasks: Customer[],
    catalysts: User[]
  ): ColumnType[] => {
    let columnsData = Array.from(columns);
    let tasksData = Array.from(tasks);

    columnsData.forEach((column) => {
      let columnTasks = tasksData.filter(
        (task) => column.value === task.latestSalesJourneyStatus
      );

      column.id = column.id.toString();
      column.taskIds = columnTasks.map((task) => task.id.toString());
      column.tasks = columnTasks.map((task) => {
        return {
          id: task.id.toString(),
          content: {
            name: task?.fullName || "",
            country: task?.country
              ? Country.countryName(task.country) || ""
              : "",
            dueDate: task?.dueDate || "",
            priority: task?.priority || "Medium",
            salesPic:
              catalysts.find((user) => user.id === task.primarySalesPersonId) ||
              catalysts[0],
          },
        };
      });
    });

    return columnsData;
  };

  const init = async () => {
    loadingPage.start();

    const salesJourneyResponse = await leadManagement.getSalesJourneyStatuses();
    const catalystsResponse = await leadManagement.getCatalysts();
    const customerResponse = await api.get<IndexGeneralDataResponse<Customer>>(
      "/customer"
    );

    if (
      salesJourneyResponse.success &&
      catalystsResponse.success &&
      customerResponse.success
    ) {
      setProspects(customerResponse.data.data);

      const columnsWithData = assignColumns(
        salesJourneyResponse.data.data,
        customerResponse.data.data,
        catalystsResponse.data.data
      );

      setColumns(columnsWithData);
    }

    loadingPage.end();
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const filteredProspects = prospects.filter((prospect) => {
        const sameName = prospect?.fullName
          .toLowerCase()
          .includes(formattedKeyword);
        const sameCountry = Country.countryName(prospect?.country || "")
          ?.toLowerCase()
          .includes(formattedKeyword);
        const filteredCatalysts = leadManagement.catalysts.filter((catalyst) =>
          catalyst.fullName.toLowerCase().includes(formattedKeyword)
        );
        const sameCatalyst = filteredCatalysts.some(
          (user) => user.id === prospect?.primarySalesPersonId
        );

        return sameName || sameCountry || sameCatalyst;
      });
      const filteredColumnsWithData = assignColumns(
        columns,
        filteredProspects,
        leadManagement.catalysts
      );
      setColumns(filteredColumnsWithData);
    } else {
      const columnsWithData = assignColumns(
        columns,
        prospects,
        leadManagement.catalysts
      );
      setColumns(columnsWithData);
    }
  };

  const handleOnDragEnd = async (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const start = columns.find(
      (column: ColumnType) => column.id === source.droppableId
    );
    const finish = columns.find(
      (column: ColumnType) => column.id === destination.droppableId
    );

    if (start === finish) {
      const newTaskIds = Array.from(start?.taskIds || []);
      const newTasks: any = [];

      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      newTaskIds.forEach((id) => {
        const selectedTask = start?.tasks.find((task: any) => task.id === id);
        newTasks.push(selectedTask);
      });

      if (start)
        setColumns((prevColumns: any) => {
          const modifiedIndex = columns.indexOf(start);

          prevColumns[modifiedIndex].taskIds = newTaskIds;
          prevColumns[modifiedIndex].tasks = newTasks;

          return [...prevColumns];
        });

      return;
    }

    const startTaskIds = Array.from(start?.taskIds || []);
    const startTasks: any = [];
    const movedTask = start?.tasks.find(
      (task: any) => task.id === startTaskIds[source.index]
    );

    startTaskIds.splice(source.index, 1);
    startTaskIds.forEach((id) => {
      const selectedTask = start?.tasks.find((task: any) => task.id === id);
      startTasks.push(selectedTask);
    });

    const finishTaskIds = Array.from(finish?.taskIds || []);
    const finishTasks: any = [];

    finishTaskIds.splice(destination.index, 0, draggableId);
    finishTaskIds.forEach((id) => {
      const selectedTask = finish?.tasks.find((task: any) => task.id === id);
      finishTasks.push(selectedTask);
    });
    finishTasks[destination.index] = movedTask;

    if (start && finish) {
      setColumns((prevColumns: any) => {
        const modifiedStartIndex = columns.indexOf(start);
        const modifiedFinishIndex = columns.indexOf(finish);

        prevColumns[modifiedStartIndex].taskIds = startTaskIds;
        prevColumns[modifiedStartIndex].tasks = startTasks;
        prevColumns[modifiedFinishIndex].taskIds = finishTaskIds;
        prevColumns[modifiedFinishIndex].tasks = finishTasks;

        return [...prevColumns];
      });

      if (movedTask?.id) {
        await api.patch<GeneralMessageResponse>(
          `/customer/${movedTask.id}/sales-journey-status/${finish.value}`
        );

        setProspects((prevProspects: Customer[]) => {
          const modifiedProspectIndex = prevProspects.findIndex(
            (prospect) => prospect.id === parseInt(movedTask.id)
          );

          prevProspects[modifiedProspectIndex] = {
            ...prevProspects[modifiedProspectIndex],
            latestSalesJourneyStatus: finish.value,
          };

          return [...prevProspects];
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="col">
      <HeaderText title="Board" />

      <BaseCard>
        <div className="row justify-between items-center mb-8">
          <div className="relative focus:outline-none select-none" />

          <div className="row gap-4">
            <button className="secondary-button group cursor-not-allowed opacity-50">
              <PabloIcon icon={Icon.download} className="secondary-icon" />
              Export Sales Report
            </button>

            <button
              className="secondary-button"
              onClick={() => navigate(Path.newLeadDetails)}
            >
              Create
            </button>
          </div>
        </div>

        <div className="p-5 bg-section-background gap-5 col h-full min-h-screen">
          <div className="row justify-between items-center">
            <SearchField
              hint="Search by prospect name, sales catalyst or country"
              onChange={search}
            />
          </div>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="justify-start flex flex-grow overflow-x-auto">
              {columns.map((column: ColumnType, index: number) => {
                return (
                  <Column
                    key={column.id}
                    id={column.id}
                    title={column.displayName}
                    tasks={column.tasks}
                    hasCreateButton={index === 0}
                  />
                );
              })}
            </div>
          </DragDropContext>
        </div>
      </BaseCard>
    </div>
  );
};

export default LeadBoardPage;
