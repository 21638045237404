/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { PaginatedMeta } from "../../common/Types";
import { useDebounceState } from "../../hooks/useDebounceState";
import SearchField from "../Fields/SearchField";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";

interface PaginateNavProps {
  currentPage: number;
  maxPage: number;
  isLoading: boolean;
  handlePage: (page?: number) => Promise<void>;
}

interface TableV2Props {
  isLoading: boolean;
  searchHint: string;
  headers: ReactElement[];
  body: ReactElement[];
  paginationApiCallback: (page?: number) => Promise<boolean>;
  paginationDetails: PaginatedMeta;
  searchApiCallback: (keyword: string, page?: number) => Promise<boolean>;
  limit?: number;
  searchable?: boolean;
  containerClassName?: string;
}

const PaginateNav: React.FC<PaginateNavProps> = ({
  currentPage,
  maxPage,
  isLoading,
  handlePage,
}) => {
  const previous = async () => {
    if (currentPage > 1) await handlePage(currentPage - 1);
  };

  const next = async () => {
    if (currentPage < maxPage) await handlePage(currentPage + 1);
  };

  const customPage = async (selectedPage: number) => {
    await handlePage(selectedPage);
  };

  const generatePaginationRange = (rangeSize: number) => {
    const range = [];
    const rangeStart = Math.max(1, currentPage - Math.floor(rangeSize / 2));
    const rangeEnd = Math.min(rangeStart + rangeSize - 1, maxPage);

    for (let i = rangeStart; i <= rangeEnd; i++) {
      range.push(i);
    }

    return range;
  };

  return (
    <div className="row justify-center items-center">
      {currentPage > 1 && (
        <>
          <button
            className={classNames(
              "h-9 w-9 col items-center justify-center hover:bg-ink-well rounded",
              {
                "pointer-events-none disabled:cursor-not-allowed": isLoading,
              }
            )}
            onClick={previous}
          >
            <PabloIcon
              icon={Icon.arrow}
              className={classNames({
                "gray-icon": isLoading,
              })}
            />
          </button>
        </>
      )}

      {!generatePaginationRange(5).includes(1) && (
        <>
          <button
            key={`page-first`}
            disabled={isLoading}
            className={classNames(
              "h-7 w-7 col items-center justify-center rounded disabled:cursor-not-allowed",
              {
                "hover:bg-ink-well": !isLoading,
                "pointer-events-none disabled:cursor-not-allowed": isLoading,
              }
            )}
            onClick={() => customPage(1)}
          >
            1
          </button>
          <span className="px-0.5">...</span>
        </>
      )}

      {generatePaginationRange(5).map((page, index) => {
        return (
          <button
            key={`page-${index}`}
            disabled={isLoading || currentPage === page}
            className={classNames(
              "h-7 w-7 col items-center justify-center rounded disabled:cursor-not-allowed",
              {
                "text-base font-bold": currentPage === page,
                "hover:bg-ink-well": !isLoading && currentPage !== page,
                "pointer-events-none disabled:cursor-not-allowed":
                  isLoading || currentPage === page,
              }
            )}
            onClick={() => customPage(page)}
          >
            {page}
          </button>
        );
      })}

      {!generatePaginationRange(5).includes(maxPage) && (
        <>
          <span className="px-0.5">...</span>
          <button
            key={`page-last`}
            disabled={isLoading}
            className={classNames(
              "h-7 w-7 col items-center justify-center rounded disabled:cursor-not-allowed",
              {
                "hover:bg-ink-well": !isLoading,
                "pointer-events-none disabled:cursor-not-allowed": isLoading,
              }
            )}
            onClick={() => customPage(maxPage)}
          >
            {maxPage}
          </button>
        </>
      )}

      {currentPage < maxPage && (
        <>
          <button
            disabled={currentPage >= maxPage}
            className={classNames(
              "h-9 w-9 col items-center justify-center rounded disabled:cursor-not-allowed",
              {
                "hover:bg-ink-well": currentPage < maxPage,
                "pointer-events-none disabled:cursor-not-allowed": isLoading,
              }
            )}
            onClick={next}
          >
            <PabloIcon
              icon={Icon.arrow}
              className={classNames("rotate-180", {
                "gray-icon": currentPage >= maxPage || isLoading,
              })}
            />
          </button>
        </>
      )}
    </div>
  );
};

const TableV2: React.FC<TableV2Props> = ({
  isLoading,
  searchHint,
  headers,
  body,
  paginationApiCallback,
  paginationDetails,
  searchApiCallback,
  searchable = true,
  containerClassName = "",
}) => {
  const [isTableLoading, setTableLoading] = useState<boolean>(isLoading);
  const [keyword, setKeyword] = useDebounceState<string>("", 1000);
  const { currentPage, lastPage, from, to, total } = paginationDetails;
  const maxPage = lastPage;

  useEffect(() => {
    setTableLoading(isLoading);

    if (isLoading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isLoading]);

  useEffect(() => {
    keyword ? searchApiCallback(keyword) : paginationApiCallback();
  }, [keyword]);

  const callPage = async (page?: number) => {
    setTableLoading(true);
    keyword
      ? await searchApiCallback(keyword, page)
      : await paginationApiCallback(page);
    setTableLoading(false);
  };

  return (
    <>
      {body.length > 0 && (
        <div className="row text-sm justify-end mt-4 font-thin mb-2">
          <PaginateNav
            currentPage={currentPage}
            maxPage={maxPage}
            isLoading={isTableLoading}
            handlePage={callPage}
          />
        </div>
      )}

      <div
        className={classNames(
          "bg-section-background gap-5 col",
          {
            "p-5": !containerClassName,
          },
          containerClassName
        )}
      >
        {searchable && (
          <div className="row justify-between items-center">
            <SearchField
              disabled={isLoading}
              hint={searchHint}
              onChange={setKeyword}
            />
          </div>
        )}

        <div className="overflow-x-auto">
          <table>
            <thead>
              <tr>{headers}</tr>
            </thead>

            <tbody
              className={classNames({
                "blur-sm pointer-events-none": isTableLoading,
              })}
            >
              {body}
            </tbody>
          </table>

          {body.length <= 0 && (
            <div className="row justify-center items-center bg-white h-20 border-b-[1px] border-t-[1px] border-section-background">
              {isTableLoading ? "Loading..." : "No result"}
            </div>
          )}

          {body.length > 0 && isTableLoading && (
            <div className="absolute col items-center justify-center z-[200] bg-white bg-opacity-40 left-0 right-0 top-0 bottom-0 overflow-hidden">
              <div className="animate-spin h-28 w-28 rounded-full border-r-4 border-primary" />
            </div>
          )}
        </div>
      </div>

      {body.length > 0 && (
        <div className="row text-sm justify-between mt-4 font-thin">
          <div className="flex items-center">
            <span className="pr-2 font-bold">
              {from} - {""}
              {to}
            </span>{" "}
            of {total}
          </div>

          <PaginateNav
            currentPage={currentPage}
            maxPage={maxPage}
            isLoading={isTableLoading}
            handlePage={callPage}
          />
        </div>
      )}
    </>
  );
};

export default TableV2;
