import React from "react";
import { Role, roleName } from "../../common/Permission";

interface RoleChipProps {
  role: Role;
}

const RoleChip: React.FC<RoleChipProps> = ({ role }) => {
  let className = "px-3 py-1 text-center w-[fit-content] truncate ";

  switch (role) {
    case "exco":
      className += "bg-light-green text-dark-green";
      break;

    case "admin":
      className += "bg-ink-well text-black";
      break;

    case "catalyst":
      className += "bg-light-brown text-dark-brown";
      break;

    case "commercial-lead":
      className += "bg-light-blue text-dark-blue";
      break;

    case "customer-engagement":
      className += "bg-light-red text-dark-red";
      break;

    default:
      break;
  }

  return <div className={className}>{roleName(role)}</div>;
};

export default RoleChip;
