/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { object, SchemaOf } from "yup";
import Path from "../../common/Path";
import { LoginResponse } from "../../common/Responses";
import TextField from "../../components/Fields/TextField";
import KskLogo from "../../components/Images/KskLogo";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import { useValidation } from "../../hooks/UseValidation";
import api from "../../services/PabloApiService";
import { baseString } from "../../utils/SchemaUtil";
import GoogleButton from "react-google-button";
import LineTitle from "../../components/General/LineTitle";
import classNames from "classnames";
import { useDeviceContext } from "../../hooks/contexts/DeviceContext";

interface LoginForm {
  email: string;
  password: string;
}

const schema: SchemaOf<LoginForm> = object().shape({
  email: baseString().email().required(),
  password: baseString().required(),
});

const LoginPage: React.FC = () => {
  const auth = useAuthContext();
  const device = useDeviceContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const loadingPage = useLoadingPageContext();
  const validation = useValidation<LoginForm>(schema);
  const url = new URL(window.location.href);
  const isProd = process.env.REACT_APP_ENV === "PROD";
  const kskLogoProdStyle = { margin: "auto", paddingBottom: 16 };

  useEffect(() => {
    if (url.searchParams.get("expired")) {
      toast.error("Session expired. Please login again.");
      navigate(Path.login);
    }

    if (auth.status === "loggedIn") {
      navigate(device.isPortrait ? Path.calculator : Path.main);
    }
  }, []);

  const login = async (body: LoginForm) => {
    loadingPage.start();

    const { data, success } = await api.post<LoginResponse>("/login", body);

    if (success) {
      auth.onLogin(data);
      navigate(
        state?.from?.pathname ?? device.isPortrait ? Path.calculator : Path.main
      );
    }

    loadingPage.end();
  };

  const socialLogin = async () => {
    loadingPage.start();

    const { data, success } = await api.get<any>("/social/google");

    if (success) {
      window.location.href = data.redirect;
    }

    loadingPage.end();
  };

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") validation.handleSubmit(login)();
  };

  return (
    <div
      className={classNames({
        "auth-background": !device.isPortrait,
        "auth-background-mobile": device.isPortrait,
      })}
    >
      <div
        className={classNames({
          "auth-card-mobile": device.isPortrait,
          "auth-card": !isProd && !device.isPortrait,
          "auth-card-sso-only": isProd && !device.isPortrait,
        })}
      >
        <KskLogo style={isProd || device.isPortrait ? kskLogoProdStyle : {}} />

        {!isProd && (
          <>
            <div
              className={classNames("col", {
                "px-2 mt-4": device.isPortrait,
                "mt-12": !device.isPortrait,
              })}
            >
              <h1 className="mb-5">Log In to Your Account</h1>

              <TextField
                label="Email address"
                type="email"
                error={validation.errors.email?.message}
                onKeyPress={onKeyDown}
                {...validation.register("email")}
              />
              <div className="mb-5" />

              <TextField
                label="Password"
                type="password"
                error={validation.errors.password?.message}
                onKeyPress={onKeyDown}
                {...validation.register("password")}
              />
              <div className="mb-5" />
            </div>

            <div className={classNames({ "flex-grow": !device.isPortrait })} />

            <div
              className={classNames({
                "w-[385px]": !device.isPortrait,
                "w-full px-2": device.isPortrait,
              })}
            >
              <button
                onClick={validation.handleSubmit(login)}
                className={classNames("primary-button h-[58px] mt-1 w-full")}
              >
                Log In
              </button>
            </div>

            <div
              className={classNames("h-[35px] my-2", {
                "w-[385px]": !device.isPortrait,
                "w-full px-2": device.isPortrait,
              })}
            >
              <LineTitle text="OR" />
            </div>
          </>
        )}

        <div
          className={classNames({
            "w-[385px]": !device.isPortrait,
            "w-full px-2": device.isPortrait,
          })}
        >
          <GoogleButton style={{ width: "100%" }} onClick={socialLogin} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
