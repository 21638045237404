/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Referrer } from "../../common/Types";
import DropdownField from "../../components/Fields/DropdownField";
import { formatNumber } from "../../utils/CommonUtils";
import { useLoadingPageContext } from "../../hooks/contexts/LoadingPageContext";
import EditableField from "../../components/Fields/EditableField";
import classNames from "classnames";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";
import { Source, sourceName } from "../../common/OpportunitySource";
import { useAgentListContext } from "../../hooks/contexts/AgentListContext";
import {
  IndexReferrerResponse,
  IndexTitanUnitsResponse,
} from "../../common/Responses";
import koraApi from "../../services/KoraApiService";
import TopBar from "../../components/Navs/TopBar";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useOnOutsideClick } from "../../hooks/UseOnOutsideClick";
import titanUnitsApi from "../../services/UnitsApiService";
import { TitanUnit } from "../../common/Types/TitanUnits";

type MobileFooterType = {
  expanded?: boolean;
  setExpanded?: Function;
  isRequiredApproval?: boolean;
};

type CalculatorUnit = {
  id: number;
  unitNo: string;
  grossPrice: number;
  buildup: number;
};

const MobileFooter: React.FC<MobileFooterType> = ({
  children,
  expanded,
  setExpanded,
  isRequiredApproval,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnOutsideClick(ref, () => setExpanded && setExpanded(false));

  return (
    <div id="mobile-footer-container">
      <div
        id="phantom"
        className={classNames("w-full px-3 py-5 block", {
          "h-[100px]": !expanded,
          "h-[360px]": setExpanded && expanded,
        })}
      />
      <div
        id="footer"
        ref={ref}
        className={classNames(
          "w-full px-3 pt-3 fixed left-0 bottom-0 shadow transition-all duration-300",
          {
            "bg-[#EAF3E8]": !isRequiredApproval,
            "bg-[#F4E1DE]": isRequiredApproval,
            "h-[80px]": !expanded,
            "h-[340px]": setExpanded && expanded,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

const RebateCalculatorPage: React.FC = () => {
  const auth = useAuthContext();
  const loadingPage = useLoadingPageContext();
  const agentList = useAgentListContext();
  const unitList = useUnitListContext();
  const { loadingItems, maxRecRebate, getMaxRecRebate } = unitList;
  const unitMeasurementDetails = [
    {
      name: "Per Square Feet",
      pricePerUnit: "psf",
      unit: "sqft",
      measurement: 1,
    },
    {
      name: "Per Square Meter",
      pricePerUnit: "psm",
      unit: "sqm",
      measurement: 0.09290304,
    },
    {
      name: "Per Ping",
      pricePerUnit: "ping",
      unit: "ping",
      measurement: 0.028109845688351,
    },
  ];
  const channelDetails = [
    { name: "agent", commission: 0 },
    { name: "kora", commission: 0 },
    { name: "digital", commission: 2500 },
    { name: "bgb", commission: 5 },
    { name: "existing-buyer", commission: 5 },
    { name: "walk-in", commission: 0 },
    { name: "business-associate", commission: 0 },
    { name: "brand-collaboration", commission: 0 },
  ];

  const [loaded, setLoaded] = useState(false);
  const [units, setUnits] = useState<TitanUnit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<CalculatorUnit>();
  const [nettSellingPrice, setNettSellingPrice] = useState<number>(0);
  const [perSquareUnit, setPerSquareUnit] = useState(unitMeasurementDetails[0]);
  const [channel, setChannel] = useState<Source>();
  const [referrers, setReferrers] = useState<Referrer[]>([]);
  const [commission, setCommission] = useState(0);
  const [rebate, setRebate] = useState(0);
  const [tempEditedRebate, setTempEditedRebate] = useState<number>(0);
  const [isEditRebate, setIsEditRebate] = useState<boolean>(false);

  const retrieveUnits = async () => {
    loadingPage.start();

    let tempArr: TitanUnit[] = [];

    const towerAResponse = await titanUnitsApi.get<IndexTitanUnitsResponse>(
      `/projects/1/sections/1/units/?append=unit_type&status=Available,Reserved`
    );

    if (towerAResponse.success)
      tempArr = [...towerAResponse.data.data.map((unit) => unit)];

    const towerBResponse = await titanUnitsApi.get<IndexTitanUnitsResponse>(
      `/projects/1/sections/2/units/?append=unit_type&status=Available,Reserved`
    );

    if (towerBResponse.success) {
      tempArr = [...tempArr, ...towerBResponse.data.data.map((unit) => unit)];
      setUnits(tempArr);
      setLoaded(true);
    }

    loadingPage.end();
  };

  const getReferrers = async () => {
    const { data, success } =
      process.env.REACT_APP_ENV === "LOCAL"
        ? {
            data: {
              referrers: [
                {
                  name: "Cynthia",
                  email: null,
                  contactCode: "60",
                  phoneNumber: "60175728568",
                  referrerCommission: 5,
                  uplineCommission: 0,
                  totalCommission: 5,
                  contactNumber: "175728568",
                },
                {
                  name: "Ben",
                  email: "ben.test@kskgroup.com",
                  contactCode: "60",
                  phoneNumber: "6099999999",
                  referrerCommission: 8,
                  uplineCommission: 0,
                  totalCommission: 8,
                  contactNumber: "99999999",
                },
              ],
            },
            success: true,
          }
        : await koraApi.get<IndexReferrerResponse>("/pablo/user");

    if (success) setReferrers(data.referrers);
  };

  const parseDropdownItem = (value: string) => {
    return value.split("|");
  };

  const onConfirmValueChange = (
    updatedValue: number,
    type: "commission" | "rebate"
  ) => {
    const tempValue = updatedValue || 0;

    /** Might add more types */
    switch (type) {
      case "rebate":
        setRebate(tempValue);
        setIsEditRebate(false);
        break;
    }
  };

  const calculateNettSellingPrice = (price: number) => {
    const tempValue = Math.round(
      rebate < 0 ? price : price * ((100 - rebate) / 100)
    );
    setNettSellingPrice(tempValue);
    return tempValue;
  };

  const calculatePerSquareUnitPrice = (price: number) => {
    return (
      Math.round(
        price / ((selectedUnit?.buildup || 0) * perSquareUnit.measurement)
      ) || 0
    );
  };

  useEffect(() => {
    retrieveUnits();
    agentList.index("active");
    getReferrers();
  }, []);

  useEffect(() => {
    setCommission(0);
    setRebate(0);
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedUnit) {
      getMaxRecRebate({
        tower: selectedUnit.unitNo.charAt(0),
        units: [selectedUnit.id],
        commission: commission,
        package: "higher_rebate",
        rebate: 0,
        abs_rebate: 0,
        grr_rate: 0,
        grr_years: 0,
        option: 0,
      });
    }
  }, [selectedUnit, commission]);

  useEffect(() => {
    if (selectedUnit) calculateNettSellingPrice(selectedUnit.grossPrice);
  }, [selectedUnit, rebate]);

  useEffect(() => {
    if (loaded) {
      if (loadingItems.length !== 0) loadingPage.start();
      else loadingPage.end();
    }
  }, [loadingItems]);

  return (
    <>
      <TopBar user={auth.user} />
      <div className="w-full px-3">
        <div className="h-14">&nbsp;</div>

        <div className="row h-10 mt-2 mb-4 justify-between">
          <h2 className="col font-bold self-center">Rebate Calculator</h2>
          <DropdownField
            id="measurement-dropdown"
            key="measurement-dropdown"
            required={true}
            error=""
            items={unitMeasurementDetails.map((detail) => {
              return {
                text: detail.pricePerUnit,
                value: `${detail.name}|${detail.pricePerUnit}|${detail.unit}|${detail.measurement}`,
              };
            })}
            dynamicWidth={true}
            containerClassName="col w-1/3 h-10"
            listContainerClassName="top-[32px]"
            defaultValue={`${perSquareUnit.name}|${perSquareUnit.pricePerUnit}|${perSquareUnit.unit}|${perSquareUnit.measurement}`}
            onChange={(e) => {
              const tempArr = parseDropdownItem(e.target.value);
              setPerSquareUnit({
                name: tempArr[0],
                pricePerUnit: tempArr[1],
                unit: tempArr[2],
                measurement: parseFloat(tempArr[3]),
              });
            }}
          />
        </div>

        <div className="row mt-6 justify-start flex flex-wrap gap-3">
          {loaded && (
            <>
              <DropdownField
                id="unit-dropdown"
                key="unit-dropdown"
                label="Unit No."
                required={true}
                error=""
                items={units.map((unit) => {
                  return {
                    text: unit.unitNumber,
                    value: `${unit.id}|${unit.unitNumber}|${unit.grossPrice}|${
                      unit?.unitType?.buildup || 0
                    }`,
                  };
                })}
                placeholder="Select Unit"
                searchable="true"
                dynamicWidth={true}
                containerClassName="w-full"
                onChange={(e) => {
                  const tempArr = parseDropdownItem(e.target.value);
                  setSelectedUnit({
                    id: parseInt(tempArr[0]),
                    unitNo: tempArr[1],
                    grossPrice: parseInt(tempArr[2]),
                    buildup: parseInt(tempArr[3]),
                  });
                }}
              />

              {selectedUnit && (
                <>
                  <div className="col w-full">
                    <div className="w-full input-border">
                      <label className="uppercase">Build Up</label>
                      <div className="mt-1">
                        {formatNumber(
                          Math.round(
                            (selectedUnit?.buildup || 0) *
                              perSquareUnit.measurement
                          )
                        )}{" "}
                        {perSquareUnit.unit}
                      </div>
                    </div>
                  </div>

                  <div className="col w-full">
                    <div className="w-full input-border">
                      <label className="uppercase">Gross Selling Price</label>
                      <div className="mt-1">
                        RM {formatNumber(selectedUnit?.grossPrice || 0)}{" "}
                        <span className="text-xs italic">
                          (RM{" "}
                          {formatNumber(
                            calculatePerSquareUnitPrice(
                              selectedUnit?.grossPrice || 0
                            )
                          )}{" "}
                          {perSquareUnit.pricePerUnit})
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col w-full">
                    <DropdownField
                      id="channel-dropdown"
                      key="channel-dropdown"
                      label="Channel"
                      required={true}
                      error=""
                      items={channelDetails.map((channel) => {
                        return {
                          text: sourceName(channel.name as Source),
                          value: `${
                            channel.name
                          }|${channel.commission.toString()}`,
                        };
                      })}
                      placeholder="Select Channel"
                      searchable="true"
                      dynamicWidth={true}
                      containerClassName="w-full"
                      onChange={(e) => {
                        const tempArr = parseDropdownItem(e.target.value);
                        setChannel(tempArr[0] as Source);
                        setCommission(parseInt(tempArr[1]));
                      }}
                    />
                  </div>

                  <div
                    className={classNames("col w-full", {
                      hidden: channel !== "agent" && channel !== "kora",
                    })}
                  >
                    {channel === "agent" ? (
                      <DropdownField
                        id="agent-dropdown"
                        key="agent-dropdown"
                        label="Agency / Agent Name"
                        required={true}
                        error=""
                        items={agentList.agents.map((agent) => {
                          return {
                            text: `${agent.agency.name} - ${agent.fullName}`,
                            value: `${agent.id.toString()}|${agent.agency.commissionRate.toString()}`,
                          };
                        })}
                        placeholder="Select an agent"
                        searchPlaceholder="Search agency - agent name"
                        searchable="true"
                        dynamicWidth={true}
                        containerClassName="w-full"
                        onChange={(e) => {
                          const tempArr = parseDropdownItem(e.target.value);
                          setCommission(parseInt(tempArr[1]));
                        }}
                        sortOption="asc"
                      />
                    ) : channel === "kora" ? (
                      <DropdownField
                        id="referrer-dropdown"
                        key="referrer-dropdown"
                        label="Referrer Name"
                        required={true}
                        error=""
                        items={referrers.map((referrer, index) => {
                          return {
                            text: referrer.name,
                            value: `${index}|${referrer.totalCommission.toString()}`,
                          };
                        })}
                        placeholder="Select a referrer"
                        searchPlaceholder="Search referrer name"
                        searchable="true"
                        dynamicWidth={true}
                        containerClassName="w-full"
                        onChange={(e) => {
                          const tempArr = parseDropdownItem(e.target.value);
                          setCommission(parseInt(tempArr[1]));
                        }}
                        sortOption="asc"
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="col w-full">
                    <div
                      className={classNames("w-full", {
                        "input-border px-0": !isEditRebate,
                      })}
                    >
                      <EditableField
                        id="edit-rebate"
                        title="Rebate"
                        isEditing={isEditRebate}
                        value={rebate}
                        channel="business-associate"
                        fieldContainerClassName="flex-grow"
                        onValueChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const parsedNumber = parseInt(e.currentTarget.value);

                          setTempEditedRebate(
                            parsedNumber < 0
                              ? 0
                              : parsedNumber > 100
                              ? 100
                              : parsedNumber
                          );
                        }}
                        onValueConfirm={() =>
                          onConfirmValueChange(tempEditedRebate, "rebate")
                        }
                        onEditClick={() => setIsEditRebate(true)}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <MobileFooter isRequiredApproval={rebate > maxRecRebate.maxRecDisc}>
          <div className="row justify-between mt-2">
            <div className="col flex-grow">
              <label className="uppercase font-semibold">
                Nett Selling Price
              </label>
              <div>
                RM {formatNumber(nettSellingPrice)}{" "}
                <span className="text-xs italic">
                  (RM{" "}
                  {formatNumber(
                    calculatePerSquareUnitPrice(nettSellingPrice || 0)
                  )}{" "}
                  {perSquareUnit.pricePerUnit})
                </span>
              </div>
            </div>
          </div>
        </MobileFooter>
      </div>
    </>
  );
};

export default RebateCalculatorPage;
