import { createContext, useContext, useState } from "react";
import { Proposal } from "../../common/Types";
import {
  GeneralMessageResponse,
  IndexProposalResponse,
  ShowProposalResponse,
} from "../../common/Responses";
import api from "../../services/PabloApiService";
import { proposalStatusName } from "../../common/Enums";

type MyProposalListContextType = {
  proposals: Proposal[];
  isLoading: boolean;
  index: (append?: Array<string>) => Promise<boolean>;
  show: (id: number) => Promise<Proposal | undefined>;
  search: (keyword: string) => void;
  book: (id: number, body: any) => Promise<boolean>;
  resubmit: (id: number, body: any) => Promise<boolean>;
  updateBuyers: (id: number, body: any) => Promise<boolean>;
  withdraw: (id: number, body: any) => Promise<boolean>;
};

const defaultContext: MyProposalListContextType = {
  proposals: [],
  isLoading: true,
  index: async (append?: Array<string>) => false,
  show: async (id: number) => undefined,
  search: (keyword: string) => {},
  book: async (id: number, body: any) => false,
  resubmit: async (id: number, body: any) => false,
  updateBuyers: async (id: number, body: any) => false,
  withdraw: async (id: number, body: any) => false,
};

const MyProposalListContext =
  createContext<MyProposalListContextType>(defaultContext);

export const useMyProposalListContext = () => useContext(MyProposalListContext);

export const MyProposalListContextProvider: React.FC = ({ children }) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [tempProposals, setTempProposals] = useState<Proposal[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const index = async (append?: Array<string>): Promise<boolean> => {
    const commaSeparatedText = append?.toString();

    setLoading(true);

    const { data, success } = await api.get<IndexProposalResponse>(
      `/my-proposals?append=${commaSeparatedText ?? ""}`
    );

    if (success) {
      setProposals(data.proposals);
      setTempProposals(data.proposals);
    }

    setLoading(false);

    return true;
  };

  const show = async (id: number): Promise<Proposal | undefined> => {
    setLoading(true);

    const { data, success } = await api.get<ShowProposalResponse>(
      `/my-proposals/${id}`
    );

    setLoading(false);

    return success ? data.proposal : undefined;
  };

  const search = (keyword: string) => {
    if (keyword) {
      const formattedKeyword = keyword.trim().toLowerCase();
      const results = tempProposals.filter((proposal) => {
        const sameName = proposal?.customers?.some((customer) => {
          return customer.fullName.toLowerCase().includes(formattedKeyword);
        });
        const sameCompanyName = proposal?.companies?.some((company) => {
          return company.name.toLowerCase().includes(formattedKeyword);
        });
        const sameUnitNo = proposal?.unit?.number
          .toLowerCase()
          .includes(formattedKeyword);
        const sameStatus = proposalStatusName(proposal.status || "")
          .toLowerCase()
          .includes(formattedKeyword);
        return sameName || sameCompanyName || sameUnitNo || sameStatus;
      });

      setProposals(results);
    } else {
      setProposals(tempProposals);
    }
  };

  const book = async (id: number, body: FormData) => {
    setLoading(true);

    const { success } = await api.postFormData<ShowProposalResponse>(
      `proposals/${id}/book?_method=put`,
      body
    );

    setLoading(false);

    return success;
  };

  const resubmit = async (id: number, body: FormData) => {
    setLoading(true);

    const { success } = await api.postFormData<ShowProposalResponse>(
      `proposals/${id}/update?_method=put`,
      body
    );

    setLoading(false);

    return success;
  };

  const updateBuyers = async (id: number, body: FormData) => {
    setLoading(true);

    const { success } = await api.postFormData<ShowProposalResponse>(
      `proposals/${id}/update-buyer-details?_method=put`,
      body
    );

    setLoading(false);

    return success;
  };

  const withdraw = async (id: number, body: any): Promise<boolean> => {
    setLoading(true);

    const { success } = await api.post<GeneralMessageResponse>(
      `/withdraw/${id}`,
      body
    );

    setLoading(false);

    return success;
  };

  return (
    <MyProposalListContext.Provider
      value={{
        proposals,
        isLoading,
        index,
        show,
        search,
        book,
        resubmit,
        updateBuyers,
        withdraw,
      }}
    >
      {children}
    </MyProposalListContext.Provider>
  );
};
