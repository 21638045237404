import { AxiosError } from "axios";
import { toast } from "react-toastify";
import Path from "../common/Path";
import { Response } from "../common/Types";
import { ApiClient } from "./ApiClient";

class KoraApiService extends ApiClient {
  protected onErrorResponse<T>(error: AxiosError): Response<T> {
    if (error.response?.status === 401) {
      const redirectUrl = `${window.location.origin}${Path.login}?expired=true`;
      window.location.href = redirectUrl;
    } else {
      toast.error(
        error.response?.data?.message ?? "Something went wrong on Kora side."
      );
    }

    return super.onErrorResponse(error);
  }
}

const api = new KoraApiService({
  baseUrl: process.env.REACT_APP_KORA_API_BASE_URL,
  headers: {
    client_name: process.env.REACT_APP_KORA_API_CLIENT_NAME,
    client_key: process.env.REACT_APP_KORA_API_CLIENT_KEY,
  },
});

export default api;
