/* eslint-disable react-hooks/exhaustive-deps */
import Compressor from "compressorjs";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { IFormSchema } from "../../common/Interfaces";
import { Attachment } from "../../common/Types";
import Format from "../../helpers/Format";
import { useModalContext } from "../../hooks/contexts/ModalContext";
import { Validation } from "../../hooks/UseValidation";
import { addWatermark } from "../../utils/ImageUtils";
import Icon from "../Icons/Icon";
import PabloIcon from "../Icons/PabloIcon";
import ErrorText from "../Texts/ErrorText";

interface FileUploadFormProps<T extends IFormSchema> {
  id: string;
  files: Attachment[];
  title: string;
  watermark?: boolean;
  error?: any;
  validation: Validation<T>;
  fieldPrefix: string;
  acceptedFileTypes?: string[];
  maxAttachments?: number;
  maxFileSize?: number;
  dispatchFiles: Function;
}

const FileUploadForm: React.FC<FileUploadFormProps<any>> = ({
  acceptedFileTypes = ["jpg", "jpeg", "png"],
  watermark = false,
  ...props
}) => {
  const {
    id,
    title,
    error,
    files,
    dispatchFiles,
    fieldPrefix,
    validation,
    maxAttachments,
    maxFileSize,
    ...otherProps
  } = props;

  const modal = useModalContext();

  const viewImage = (blobPath: string | null) => {
    modal.view({
      title: "",
      containerClassName: "w-6/12 max-h-[90%]",
      modalClassName: "w-full px-5 py-5 overflow-y-auto",
      content: (
        <img
          src={blobPath!}
          alt="uploaded-file"
          className="border border-[#A9695B33]"
        />
      ),
    });
  };

  const checkIsImage = (file: File | Blob) => {
    return file.type.includes("image");
  };

  const handleBlob = async (file: File) => {
    let watermarkedFile: File | Blob = file;

    if (checkIsImage(file)) {
      watermarkedFile = watermark ? await addWatermark(file) : file;

      new Compressor(watermarkedFile, {
        quality: 0.6,
        convertTypes: ["image/png", "image/jpg", "image/jpeg"],
        convertSize: 1000000,
        success: async (result) => {
          dispatchFiles({
            type: "add",
            value: {
              name: file.name,
              blob: result,
              blobPath: URL.createObjectURL(watermarkedFile),
            },
          });
        },
      });
    } else {
      dispatchFiles({
        type: "add",
        value: {
          name: file.name,
          blob: watermarkedFile,
          blobPath: URL.createObjectURL(watermarkedFile),
        },
      });
    }
  };

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files) {
      toast.warning("No files selected");
      e.preventDefault();
      return;
    }

    if (
      maxAttachments &&
      e.target.files.length + files.length > maxAttachments
    ) {
      toast.warning(
        "Number of files selected has exceeded the maximum allowed"
      );
      e.preventDefault();
      return;
    }

    let tempFiles = Array.from(e.target.files);

    tempFiles
      .filter((img) => {
        if (!acceptedFileTypes.some((type) => img.type.includes(type))) {
          toast.info("Invalid file type");
        }

        if (maxFileSize && img.size > maxFileSize * 1000000) {
          toast.info("File size has exceededed the maximum allowed");
        }

        return (
          acceptedFileTypes.some((type) => img.type.includes(type)) &&
          (maxFileSize ? img.size <= maxFileSize * 1000000 : true)
        );
      })
      .forEach(async (img) => {
        await handleBlob(img);
      });

    validation.clearErrors();
    e.target.value = "";
  };

  const removeImage = (index: number) => {
    URL.revokeObjectURL(files[index].blobPath);

    dispatchFiles({
      type: "remove",
      value: files[index],
    });

    validation.unregister(`${fieldPrefix}.attachments.${index}`);
  };

  useEffect(() => {
    validation.setValue(
      `${fieldPrefix}.attachments`,
      files.map((item) => {
        return { id: item?.id || "", blob: item?.id ? "" : item.blob };
      })
    );
  }, [files]);

  return (
    <>
      <div className="row gap-4">
        <h4 className="uppercase text-sm font-bold">{title}</h4>
        {/* <RequiredField /> */}
        <span className="text-dark-red mr-1">*</span>
      </div>

      <div className="my-2 h-[50px] row justify-between items-center gap-8">
        <p className="text-sm font-normal opacity-70">
          {`Attachments should be in the standard format (.${acceptedFileTypes.join(
            " / ."
          )}) `}
          {!maxFileSize || !maxAttachments
            ? `and total attachment size should be no larger than 40MB`
            : `and no larger than ${maxFileSize}MB, with a maximum number of ${maxAttachments} attachments`}
        </p>
        <div className="col">
          <label className="secondary-button" htmlFor={id}>
            Upload files
          </label>
          <input
            id={id}
            name={id}
            type="file"
            accept={`.${acceptedFileTypes.join(",.")}`}
            onInput={onImageChange}
            multiple
            className="w-[0.1px] h-[0.1px] opacity-0 overflow-hidden -z-10"
            {...otherProps}
          />
        </div>
      </div>

      {error?.message && (
        <ErrorText text={error?.message} className="mb-4 mt-3" />
      )}

      {files.length > 0 && (
        <>
          <h5 className="capitalize text-sm font-bold mb-4">
            Uploaded Attachments
          </h5>

          {files.map((img, index) => {
            return (
              <div
                key={`image-${index}`}
                className="row flex justify-between items-center h-[100px] mb-3"
              >
                <div className="row flex items-center">
                  {img.blob.type.includes("image") ? (
                    <img
                      src={img.blobPath}
                      onClick={() => viewImage(img.blobPath)}
                      alt="uploaded-file"
                      className="border border-[#A9695B33] object-contain w-[100px] h-[100px] cursor-pointer"
                    />
                  ) : (
                    <div className="border border-[#A9695B33] w-[100px] h-[100px]">
                      <PabloIcon
                        icon={Icon.myProposal}
                        className="gray-icon w-[50px] h-full m-auto object-contain"
                      />
                    </div>
                  )}

                  <p className="ml-5">
                    <span className="text-sm font-bold">
                      {files[index].name} <br />
                    </span>

                    <span className="text-xs font-normal opacity-50">
                      {img.id ? (
                        <span className="italic">Existing file</span>
                      ) : (
                        Format.bytes(files[index].blob.size)
                      )}
                    </span>
                  </p>
                </div>

                <div
                  className="cursor-pointer"
                  onClick={() => removeImage(index)}
                >
                  <PabloIcon icon={Icon.remove} className="gray-icon" />
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default FileUploadForm;
