/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PEUnit } from "../../common/Types";
import UnitGridCell from "../../components/Grid/UnitGridCell";
import { useAuthContext } from "../../hooks/contexts/AuthContext";
import { useUnitListContext } from "../../hooks/contexts/UnitListContext";

interface UnitGridTableProps {}

const UnitGridTable: React.FC<UnitGridTableProps> = ({ ...props }) => {
  const auth = useAuthContext();
  const unitList = useUnitListContext();
  const hasPriceAccess =
    auth.role() === "exco" || auth.role() === "commercial-lead";
  const emptyFilters =
    unitList.filters.unitType.length === 0 &&
    unitList.filters.orientation.length === 0 &&
    unitList.filters.theme.length === 0 &&
    !unitList.filters.wholeFloor &&
    unitList.filters.status.length === 0 &&
    unitList.filters.noOfBedrooms.length === 0 &&
    unitList.filters.floors.length === 0;

  const showFullUnitList = () => {
    const reversedUnitList = unitList.units.slice(0).reverse();

    return (
      <>
        {unitList.units.length === 0 ? (
          <tr className="border-none">
            <td className="p-5">Loading units...</td>
          </tr>
        ) : (
          reversedUnitList.map((unitsInLevel: any, index: number) => {
            return (
              <tr
                key={`level-${index}`}
                className="h-[145px] text-xs border border-solid border-section-background"
              >
                <td className="min-w-[143px] border border-solid border-section-background pt-[1.125rem]">
                  Unit No. <br />
                  Type <br />
                  Theme <br />
                  Orientation <br />
                  Build Up (SF) <br />
                  Psf (RM) <br />
                  Selling Price (RM) <br />
                </td>

                {unitsInLevel.map((unit: PEUnit, unitIndex: number) => {
                  return (
                    <UnitGridCell
                      key={unitIndex}
                      unit={unit}
                      selected={
                        unitList.selectedUnits?.[0]?.unitNo === unit.unitNo
                      }
                      hasPriceAccess={hasPriceAccess}
                      handleOnClick={unitList.handleOnSelectUnit}
                    />
                  );
                })}
              </tr>
            );
          })
        )}
      </>
    );
  };

  const showFilteredUnitList = () => {
    const reversedFilteredUnitList = unitList.filteredUnits.slice(0).reverse();

    return (
      <>
        {unitList.filteredUnits.length === 0 ? (
          <tr className="border-none">
            <td className="p-5">No units available</td>
          </tr>
        ) : (
          reversedFilteredUnitList.map((unitsInLevel: any, index: number) => {
            return (
              <tr
                key={`filtered-level-${index}`}
                className="h-[145px] text-xs border border-solid border-section-background"
              >
                <td className="min-w-[143px] border border-solid border-section-background pt-[1.125rem]">
                  Unit No. <br />
                  Type <br />
                  Theme <br />
                  Orientation <br />
                  Build Up (SF) <br />
                  Psf (RM) <br />
                  Selling Price (RM) <br />
                </td>

                {unitsInLevel.map((unit: PEUnit, unitIndex: number) => {
                  return (
                    <UnitGridCell
                      key={unitIndex}
                      unit={unit}
                      selected={
                        unitList.selectedUnits?.[0]?.unitNo === unit.unitNo
                      }
                      hasPriceAccess={hasPriceAccess}
                      handleOnClick={unitList.handleOnSelectUnit}
                    />
                  );
                })}
              </tr>
            );
          })
        )}
      </>
    );
  };

  return (
    <table className="block h-screen overflow-scroll">
      <tbody>
        {emptyFilters && unitList.filteredUnits.length === 0
          ? showFullUnitList()
          : showFilteredUnitList()}
      </tbody>
    </table>
  );
};

export default UnitGridTable;
