import classNames from "classnames";
import React from "react";
import { stageName } from "../../common/ProposalStage";
import { ApprovalHistory, OpportunitySource } from "../../common/Types";
import ManagementApprovalHistory from "./ManagementApprovalHistory";

interface ManagementApprovalHistoryDetailsProps {
  tower?: string;
  opportunitySource?: OpportunitySource;
  approvalHistory: ApprovalHistory[];
}

const ManagementApprovalHistoryDetails: React.FC<
  ManagementApprovalHistoryDetailsProps
> = (props) => {
  const { tower, opportunitySource, approvalHistory } = props;

  return (
    <div className="px-5 pt-3 pb-6 mb-2 bg-section-background">
      <table className="table-auto w-full bg-transparent">
        {approvalHistory?.map((history, index) => {
          return (
            <tbody key={`approval-history-${index}`}>
              {history?.approval.length > 0 && (
                <>
                  <tr>
                    <td
                      colSpan={4}
                      className={classNames("p-0", {
                        "pt-8": index > 0,
                      })}
                    >
                      <h4 className="mb-4 text-base font-normal uppercase">
                        {history?.stage && stageName(history.stage)}
                      </h4>
                    </td>
                  </tr>

                  <ManagementApprovalHistory
                    approvals={history?.approval}
                    tower={tower}
                    opportunitySource={opportunitySource}
                    approvalHistory={history}
                  />
                </>
              )}
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default ManagementApprovalHistoryDetails;
