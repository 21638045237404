import React from "react";
import { UnitStatus } from "../../common/Enums";

interface UnitStatusChipProps {
  status: UnitStatus;
}

const UnitStatusChip: React.FC<UnitStatusChipProps> = ({ status }) => {
  let className = "px-3 py-1 text-center w-[fit-content] truncate ";
  let name = "";

  switch (status) {
    case "available":
      className += "text-dark-green bg-light-green";
      name = "Available";
      break;

    case "reserved":
      className += "text-dark-orange bg-light-orange";
      name = "Reserved";
      break;

    case "blocked":
      className += "text-dark-red bg-light-red";
      name = "Blocked";
      break;

    case "booked":
      className += "text-dark-blue bg-light-blue";
      name = "Booked";
      break;

    case "cancelled":
    case "cancellation_in_progress":
    case "blocked_special":
    case "sold":
      className += "text-black bg-gray";
      name = "Sold";
      break;

    default:
      break;
  }

  return <span className={className}>{name}</span>;
};

export default UnitStatusChip;
