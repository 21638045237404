/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Country from "../../common/Country";
import { salutations } from "../../common/Enums";
import { useArray } from "../../hooks/UseArray";
import FileUploadForm from "../Forms/FileUploadForm";
import { Attachment, Customer } from "../../common/Types";
import { Validation } from "../../hooks/UseValidation";
import { BookingForm } from "../../schemas/BookingSchema";
import {
  ExpandableCard,
  ExpandableCardValidated,
} from "../Cards/ExpandableCard";
import DropdownField from "../Fields/DropdownField";
import TextField from "../Fields/TextField";
import Line from "../General/Line";
import { clearAttachments } from "../../utils/SchemaUtil";

interface CompanyPersonalInfoAccordianProps {
  value: string;
  title: string;
  index: number;
  defaultValues?: Customer;
  showRequired?: boolean;
  validation: Validation<BookingForm>;
  className?: string;
  onDeleteAction?: () => void;
  setDirty: Function;
}

const CompanyPersonalInfoAccordian = forwardRef<
  any,
  CompanyPersonalInfoAccordianProps
>((props, ref) => {
  const { validation, index, defaultValues, setDirty } = props;
  const register = validation.register;
  const companyPersonalInfo = validation.watch(
    `companyInfo.personalInfo.${index}`
  );
  const error = validation?.errors?.companyInfo?.personalInfo?.[index];

  const [proofOfIdentities, dispatchProofOfIdentities] = useArray<Attachment>(
    defaultValues?.attachments?.map((attachment) => {
      return {
        id: attachment.id,
        name: attachment.name,
        blob: new Blob([attachment.path], {
          type: "image",
        }),
        blobPath: attachment.path,
      };
    }) || []
  );
  const [validated, setValidated] = useState<ExpandableCardValidated>("empty");

  useImperativeHandle(ref, () => ({
    onClearAttachments: () =>
      clearAttachments(
        proofOfIdentities,
        dispatchProofOfIdentities,
        `companyInfo.personalInfo.${index}.attachments`,
        validation
      ),
  }));

  useEffect(() => {
    validation.setValue(
      `companyInfo.personalInfo.${index}.id`,
      defaultValues?.id
    );
  }, []);

  useEffect(() => {
    if (
      companyPersonalInfo?.title &&
      companyPersonalInfo?.salutation &&
      companyPersonalInfo?.fullName &&
      companyPersonalInfo?.phoneCode &&
      companyPersonalInfo?.phoneNumber &&
      companyPersonalInfo?.email &&
      companyPersonalInfo?.attachments?.length
    ) {
      setValidated("success");
    } else {
      setValidated("empty");
    }

    if (error) setValidated("error");
  }, [error, validation.watch()]);

  return (
    <ExpandableCard
      value={props.value}
      title={props.title}
      showRequired={props.showRequired}
      className={props.className}
      onDeleteAction={props.onDeleteAction}
      validated={validated}
      autoScroll={false}
    >
      <div className="col gap-5 mb-9">
        <h4 className="font-bold">Authorised Person Information</h4>
        <div className="row -mb-1 gap-4">
          <DropdownField
            id={`company-personal-salutation-${index}`}
            key={`company-personal-salutation-${index}`}
            label="Salutation"
            placeholder="Select Salutation"
            error={error?.salutation?.message}
            items={salutations.map((salutation) => {
              return { text: salutation, value: salutation };
            })}
            searchable="true"
            defaultValue={
              defaultValues?.salutation || companyPersonalInfo?.salutation || ""
            }
            {...register(`companyInfo.personalInfo.${index}.salutation`, {
              value:
                defaultValues?.salutation ||
                companyPersonalInfo?.salutation ||
                "",
              onChange: () => setDirty(true),
            })}
          />
          <TextField
            id={`company-personal-full-name-${index}`}
            label="Full Name (as per IC/Passport)"
            type="text"
            error={error?.fullName?.message}
            defaultValue={defaultValues?.fullName || ""}
            onInput={() => setDirty(true)}
            {...register(`companyInfo.personalInfo.${index}.fullName`, {
              setValueAs: (v) => v.trim(),
            })}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
            <DropdownField
              id={`company-personal-dial-code-${index}`}
              key={`company-personal-dial-code-${index}`}
              label="Dial Code"
              error={error?.phoneCode?.message}
              items={Country.countryData.map((country) => {
                return {
                  text: `+${country.dialCode} - ${country.name}`,
                  value: country.dialCode,
                };
              })}
              placeholder="Select Dial Code"
              searchable="true"
              dynamicWidth={true}
              containerClassName="w-2/6"
              listClassName="min-w-[420px]"
              showValue={true}
              defaultValue={
                defaultValues?.phoneCode?.toString() ||
                companyPersonalInfo?.phoneCode ||
                ""
              }
              {...register(`companyInfo.personalInfo.${index}.phoneCode`, {
                value:
                  defaultValues?.phoneCode?.toString() ||
                  companyPersonalInfo?.phoneCode ||
                  "",
                onChange: () => setDirty(true),
              })}
            />
            <TextField
              id={`company-personal-contact-number-${index}`}
              label="Contact No."
              type="tel"
              dynamicWidth={true}
              containerClassName="w-4/6"
              error={error?.phoneNumber?.message}
              defaultValue={defaultValues?.phoneNumber || ""}
              onInput={() => setDirty(true)}
              {...register(`companyInfo.personalInfo.${index}.phoneNumber`, {
                setValueAs: (v) => v.trim(),
              })}
            />
          </div>

          <TextField
            id={`company-personal-nric-number-${index}`}
            label="IC No./Passport No"
            type="text"
            required={true}
            error={error?.identificationNumber?.message}
            defaultValue={defaultValues?.identificationNumber || ""}
            onInput={() => setDirty(true)}
            {...register(
              `companyInfo.personalInfo.${index}.identificationNumber`,
              {
                setValueAs: (v) => v.trim(),
              }
            )}
          />
        </div>

        <div className="row -mb-1 gap-4">
          <TextField
            id={`company-personal-email-${index}`}
            label="Email"
            type="email"
            required={true}
            error={error?.email?.message}
            defaultValue={defaultValues?.email || ""}
            onInput={() => setDirty(true)}
            {...register(`companyInfo.personalInfo.${index}.email`, {
              setValueAs: (v) => v.trim().toLowerCase(),
            })}
          />

          <TextField
            id={`company-personal-title-${index}`}
            label="Title"
            type="text"
            required={true}
            error={error?.title?.message}
            defaultValue={defaultValues?.title || ""}
            onInput={() => setDirty(true)}
            {...register(`companyInfo.personalInfo.${index}.title`, {
              setValueAs: (v) => v.trim().toLowerCase(),
            })}
          />
        </div>
      </div>

      <Line className="mb-8" />

      <div className="col">
        <FileUploadForm
          id={`${props.value}-uploader`}
          files={proofOfIdentities}
          title="Proof of Identity"
          error={error?.attachments}
          fieldPrefix={`companyInfo.personalInfo.${index}`}
          validation={validation}
          dispatchFiles={dispatchProofOfIdentities}
        />
      </div>
    </ExpandableCard>
  );
});

export default CompanyPersonalInfoAccordian;
