import classNames from "classnames";
import React from "react";
import {
  ConsideredSoldUnitStatus,
  consideredSoldUnitStatus,
} from "../../common/Enums";
import { PEUnit } from "../../common/Types";
import Dot from "../../components/General/Dot";
import { formatNumber, modifyNumberFour } from "../../utils/CommonUtils";

interface UnitGridCellProps {
  unit: PEUnit;
  selected?: boolean;
  hasPriceAccess: boolean;
  handleOnClick: (unit: PEUnit, isDisabled: boolean) => void;
}

const UnitGridCell: React.FC<UnitGridCellProps> = ({
  unit,
  selected = false,
  hasPriceAccess,
  handleOnClick,
  ...props
}) => {
  const statusLc = unit.status.toLowerCase();
  const isDisabled =
    statusLc === "blocked" ||
    statusLc === "booked" ||
    consideredSoldUnitStatus.includes(statusLc as ConsideredSoldUnitStatus);
  const isUnit = true;

  return (
    <td
      id={`unit-${modifyNumberFour(unit.unitNo, isUnit)}`}
      className={classNames(
        "border border-solid border-section-background min-w-[88px] text-center select-none px-[10px]",
        {
          "text-[#1f1f1f4d]": isDisabled,
          "bg-[#1d8d0b4d] border-2 border-solid border-[#1D8D0B]":
            statusLc === "available" && selected,
          "bg-[#ef99234d] border-2 border-solid border-[#D8830F]":
            statusLc === "reserved" && selected,
          "bg-[#1d8d0b1a] hover:bg-[#1d8d0b50]":
            statusLc === "available" && !selected,
          "bg-[#ef99231a] hover:bg-[#ef99234d]":
            statusLc === "reserved" && !selected,
          "bg-[#87352626]": statusLc === "blocked" && !selected,
          "bg-[#416fb84d]": statusLc === "booked" && !selected,
          "bg-[#F7F7F7]":
            consideredSoldUnitStatus.includes(
              statusLc as ConsideredSoldUnitStatus
            ) && !selected,
          "cursor-pointer": !isDisabled,
        }
      )}
      onClick={() => handleOnClick(unit, isDisabled)}
    >
      <div className="text-right">
        <Dot status={unit.status} isDisabled={isDisabled} />
      </div>
      {modifyNumberFour(unit.unitNo, isUnit)} <br />
      {unit.type} <br />
      {unit.idTheme} <br />
      {unit.orientation} <br />
      {formatNumber(unit.buildup)} <br />
      <span className="font-bold">
        {isDisabled && !hasPriceAccess ? "" : formatNumber(unit.pricePsf)}
      </span>
      <br />
      <span className="font-bold">
        {isDisabled && !hasPriceAccess ? "" : formatNumber(unit.grossPrice)}
      </span>
      <br />
    </td>
  );
};

export default UnitGridCell;
