import React from "react";

interface PabloIconProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  icon: string;
  type?: string;
}

const PabloIcon: React.FC<PabloIconProps> = ({
  icon,
  type = "svg",
  ...props
}) => {
  return <img src={`/icons/${icon}.${type}`} alt={icon} {...props} />;
};

export default PabloIcon;
