export const approvalCategories = [
  "rebate",
  "progress-payment-stages",
  "instalment",
  "flexible-instalment",
  "hooks",
  "commission-change",
] as const;

export type ApprovalCategory = typeof approvalCategories[number];

export const approvalCategoryName = (category: ApprovalCategory): string => {
  const categoryName = {
    rebate: "Exceed Cost of Sales",
    "progress-payment-stages": "Progressive Payment Structure",
    instalment: "Fixed Instalment Payment Structure",
    "flexible-instalment": "Flexible Instalment Payment Structure",
    hooks: "Complimentary Package",
    "commission-change": "Commission Change",
  };

  return categoryName[category];
};

export const MAX_NUMBER_OF_APPROVALS = 2;
