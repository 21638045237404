import classNames from "classnames";
import React, { createContext, useContext, useState } from "react";

interface RadioGroupProps {
  defaultValue?: any;
  label?: string;
  required?: boolean;
  onChange: (value: string | number) => void;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const [groupValue, setGroupValue] = useState<string | number>(
    props.defaultValue ?? ""
  );

  const onClick = (value: string | number) => {
    setGroupValue(value);
    props.onChange(value);
  };

  return (
    <RadioContext.Provider value={{ groupValue, onClick }}>
      {props.label ? (
        <div className="col relative w-full tablet:w-[498px]">
          <div className="flex-wrap flex items-start border border-[#A9695B33] px-5 py-2 h-[68px]">
            <div className="row gap-1 w-full">
              <label className="uppercase block mb-1">{props.label}</label>
              {props.required && <span className="text-dark-red">*</span>}
            </div>

            <div className="row">{props.children}</div>
          </div>
        </div>
      ) : (
        props.children
      )}
    </RadioContext.Provider>
  );
};

interface RadioProps {
  value: any;
}

export const Radio: React.FC<RadioProps> = (props) => {
  const { groupValue, onClick } = useRadioContext();

  return (
    <button
      className="border-[1px] border-black w-5 h-5 rounded-full cursor-pointer focus:outline-none row justify-center items-center"
      onClick={() => onClick(props.value)}
    >
      <div
        className={classNames(
          "bg-black rounded-full overflow-hidden transition-[height] duration-150",
          {
            "w-[14px] h-[14px]": groupValue === props.value,
            "w-0 h-0": groupValue !== props.value,
          }
        )}
      />
    </button>
  );
};

type RadioContextType = {
  groupValue: any;
  onClick: (value: any) => void;
};

const defaultContext: RadioContextType = {
  groupValue: "",
  onClick: (value: any) => {},
};

const RadioContext = createContext<RadioContextType>(defaultContext);

const useRadioContext = () => useContext(RadioContext);
