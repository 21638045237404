import classNames from "classnames";
import React, { MouseEventHandler, ReactElement } from "react";
import ReactTooltip from "react-tooltip";
import PabloIcon from "../Icons/PabloIcon";

type PopUpLink = {
  isLink: boolean;
  href?: string;
};

interface PopUpItemProps {
  icon: string;
  label: string;
  onClick: MouseEventHandler;
  link?: PopUpLink;
  type?: string;
  imageClassName?: string;
  disabled?: boolean;
  hasTooltip?: boolean;
  tooltipContent?: ReactElement;
}

const PopUpItem: React.FC<PopUpItemProps> = ({
  icon,
  label,
  onClick,
  imageClassName = "",
  link = { isLink: false },
  type = "svg",
  disabled = false,
  hasTooltip = false,
  tooltipContent = <></>,
}) => {
  const classes = classNames(
    "uppercase font-semibold text-xs px-4 pl-2 pr-10 h-10  hover:bg-ink-well rounded gap-2 row items-center select-none",
    {
      "bg-ink-well cursor-not-allowed opacity-50": disabled,
    }
  );

  const tooltip = hasTooltip
    ? {
        "data-tip": "",
        "data-for": "popup-tooltip",
      }
    : {};

  return (
    <>
      {link.isLink ? (
        <a
          href={link?.href}
          target="_blank"
          rel="noreferrer"
          onClick={disabled ? (e) => e.stopPropagation() : onClick}
          className={classes}
          {...tooltip}
        >
          <PabloIcon
            icon={icon}
            type={type}
            className={classNames(imageClassName)}
          />
          {label}
        </a>
      ) : (
        <span
          onClick={disabled ? (e) => e.stopPropagation() : onClick}
          className={classes}
          {...tooltip}
        >
          <PabloIcon
            icon={icon}
            type={type}
            className={classNames(imageClassName)}
          />
          {label}
        </span>
      )}

      {hasTooltip && (
        <ReactTooltip id="popup-tooltip" effect="solid">
          {tooltipContent}
        </ReactTooltip>
      )}
    </>
  );
};

export default PopUpItem;
