import classNames from "classnames";
import React from "react";
import ReactDOM from "react-dom";
import Icon from "../Icons/Icon";

interface ModalProps {
  show: boolean;
  onHide: React.MouseEventHandler;
  dismissible?: boolean;
  type?: "success" | "warning" | "error";
  modalClassName?: string;
  containerClassName?: string;
}

const Modal: React.FC<ModalProps> = (props) => {
  if (!props.show) return <></>;

  let color = "";
  let icon = "";
  let iconPosition = "";

  switch (props.type) {
    case "success":
      color = "bg-light-green";
      icon = Icon.success;
      iconPosition = "absolute -right-3 bottom-14";
      break;

    case "warning":
      color = "bg-light-orange";
      icon = Icon.warning;
      iconPosition = "absolute right-20 bottom-14";
      break;

    case "error":
      color = "bg-light-red";
      icon = "error";
      iconPosition = "absolute right-20 bottom-14";
      break;
  }

  return ReactDOM.createPortal(
    <div
      onClick={props.dismissible ?? true ? props.onHide : () => {}}
      className="z-50 fixed flex items-center justify-center overflow-auto bg-[#000] bg-opacity-50 left-0 right-0 top-0 bottom-0"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames("row bg-white", props.containerClassName)}
      >
        {/* icon with color */}
        {props.type && (
          <div className={`relative min-w-[300px] min-h-[400px] ${color}`}>
            <img className={iconPosition} src={`/icons/${icon}.svg`} alt="" />
          </div>
        )}

        {/* content */}
        <div
          className={classNames(
            "col",
            {
              "w-[550px] p-20": !props.modalClassName,
            },
            props.modalClassName
          )}
        >
          {props.children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
