/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from "react";
import Country from "../../common/Country";
import {
  DIGITAL_SOURCE_COMMISSION,
  SOLD_UNIT_SOURCE_COMMISSION,
  Source,
  sourceName,
  sources,
} from "../../common/OpportunitySource";
import {
  IndexReferrerResponse,
  IndexTitanUnitsResponse,
} from "../../common/Responses";
import { Referrer } from "../../common/Types";
import { useAgentListContext } from "../../hooks/contexts/AgentListContext";
import { Validation } from "../../hooks/UseValidation";
import { ProposalForm } from "../../schemas/ProposalSchema";
import koraApi from "../../services/KoraApiService";
import titanUnitsApi from "../../services/UnitsApiService";
import {
  ExpandableCard,
  ExpandableCardValidated,
} from "../Cards/ExpandableCard";
import DropdownField from "../Fields/DropdownField";
import EditableField from "../Fields/EditableField";
import RadioField from "../Fields/RadioField";
import TextField from "../Fields/TextField";
import Line from "../General/Line";
import { RadioGroup } from "../General/Radio";

interface OpportunitySourceAccordianProps {
  validation: Validation<ProposalForm>;
  source?: Source;
  setSource: (value: Source) => void;
  setCommission: (value: number) => void;
}

const OpportunitySourceAccordian: React.FC<OpportunitySourceAccordianProps> = (
  props
) => {
  const { source, setSource, setCommission } = props;

  const agentList = useAgentListContext();
  const [validated, setValidated] = useState<ExpandableCardValidated>("empty");
  const [soldUnits, setSoldUnits] = useState<string[]>([]);
  const [referrers, setReferrers] = useState<Referrer[]>([]);
  const [brandCollaborations, setBrandCollaborations] = useState<string[]>([]);
  const [tempEditedCommission, setTempEditedCommission] = useState<number>(0);
  const [isEditCommission, setIsEditCommission] = useState<boolean>(false);

  const register = props.validation.register;
  const error = props.validation.errors.opportunitySource;
  const opportunitySource = props.validation.watch("opportunitySource");

  useEffect(() => {
    getAllSourceFields();
  }, []);

  useEffect(() => {
    if (error) setValidated("error");
  }, [error]);

  useEffect(() => {
    setCommission(opportunitySource?.updatedCommissionRate ?? 0);
  }, [opportunitySource]);

  const getAllSourceFields = () => {
    agentList.index("active");
    getReferrers();
    getSoldUnits();

    setBrandCollaborations([
      "Brand collab 1",
      "Brand collab 2",
      "Brand collab 3",
    ]);
  };

  const getReferrers = async () => {
    const { data, success } =
      process.env.REACT_APP_ENV === "LOCAL"
        ? {
            data: {
              referrers: [
                {
                  name: "Cynthia",
                  email: null,
                  contactCode: "60",
                  phoneNumber: "60175728568",
                  referrerCommission: 5,
                  uplineCommission: 0,
                  totalCommission: 5,
                  contactNumber: "175728568",
                },
                {
                  name: "Ben",
                  email: "ben.test@kskgroup.com",
                  contactCode: "60",
                  phoneNumber: "6099999999",
                  referrerCommission: 8,
                  uplineCommission: 0,
                  totalCommission: 8,
                  contactNumber: "99999999",
                },
              ],
            },
            success: true,
          }
        : await koraApi.get<IndexReferrerResponse>("/pablo/user");

    if (success) setReferrers(data.referrers);
  };

  const getSoldUnits = async () => {
    let tempArr: string[] = [];

    const towerAResponse = await titanUnitsApi.get<IndexTitanUnitsResponse>(
      `/projects/1/sections/1/units/?status=Sold`
    );

    if (towerAResponse.success)
      tempArr = [...towerAResponse.data.data.map((unit) => unit.unitNumber)];

    const towerBResponse = await titanUnitsApi.get<IndexTitanUnitsResponse>(
      `/projects/1/sections/2/units/?status=Sold`
    );

    if (towerBResponse.success) {
      tempArr = [
        ...tempArr,
        ...towerBResponse.data.data.map((unit) => unit.unitNumber),
      ];
      setSoldUnits(tempArr);
    }
  };

  const onRadioChanged = (value: any) => {
    if (source !== value) {
      props.validation.clearErrors("opportunitySource");
      props.validation.setValue("opportunitySource", {
        type: value as string,
        commissionRate: value === "digital" ? DIGITAL_SOURCE_COMMISSION : 0,
        updatedCommissionRate:
          value === "digital" ? DIGITAL_SOURCE_COMMISSION : 0,
      });
      setIsEditCommission(false);
      value === "digital" && setTempEditedCommission(DIGITAL_SOURCE_COMMISSION);
      value === "walk-in" ? setValidated("success") : setValidated("empty");
      setSource(value as Source);
    }
  };

  const onAgentChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.validation.clearErrors("opportunitySource");

    const agent = agentList.agents.find(
      (tempAgent) => tempAgent.id.toString() === e.target.value
    );

    props.validation.setValue("opportunitySource", {
      type: "agent",
      title: agent?.agency?.name,
      fullName: agent?.fullName,
      phoneCode: agent?.phoneCode,
      phoneNumber: agent?.phoneNumber,
      email: agent?.email,
      commissionRate: agent?.agency?.commissionRate,
      updatedCommissionRate: agent?.agency?.commissionRate,
    });

    setIsEditCommission(false);
    setTempEditedCommission(agent?.agency?.commissionRate || 0);
    setValidated("success");
  };

  const onKoraChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.validation.clearErrors("opportunitySource");

    const referrer = referrers.find(
      (tempReferrer) => tempReferrer.phoneNumber === e.target.value
    );

    props.validation.setValue("opportunitySource", {
      type: "kora",
      fullName: referrer?.name,
      phoneCode: referrer?.contactCode,
      phoneNumber: referrer?.contactNumber,
      email: referrer?.email || null,
      commissionRate: referrer?.totalCommission,
      updatedCommissionRate: referrer?.totalCommission,
    });

    setIsEditCommission(false);
    setTempEditedCommission(referrer?.totalCommission || 0);
    setValidated("success");
  };

  const onBrandCollaborationChanged = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    props.validation.clearErrors("opportunitySource");

    props.validation.setValue("opportunitySource", {
      type: "brand-collaboration",
      title: e.target.value,
      commissionRate: 0,
      updatedCommissionRate: 0,
    });

    setIsEditCommission(false);
    setTempEditedCommission(0);
    setValidated("success");
  };

  const onDigitalChanged = () => {
    if (
      opportunitySource.fullName &&
      opportunitySource.phoneCode &&
      opportunitySource.phoneNumber &&
      opportunitySource.email
    ) {
      setValidated(
        opportunitySource?.commissionRate !==
          opportunitySource?.updatedCommissionRate
          ? "warning"
          : "success"
      );
    } else {
      setValidated("empty");
    }
  };

  const onTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.validation.clearErrors("opportunitySource");

    if (opportunitySource.title) {
      setValidated("success");
    } else {
      setValidated("empty");
    }
  };

  const onPEUnitTitleChanged = (
    e: React.ChangeEvent<HTMLSelectElement>,
    tempSource: string
  ) => {
    props.validation.clearErrors("opportunitySource");

    props.validation.setValue("opportunitySource", {
      type: tempSource === "bgb" ? "bgb" : "existing-buyer",
      title: e.target.value,
      commissionRate: SOLD_UNIT_SOURCE_COMMISSION,
      updatedCommissionRate: SOLD_UNIT_SOURCE_COMMISSION,
    });

    setIsEditCommission(false);
    setTempEditedCommission(SOLD_UNIT_SOURCE_COMMISSION);
    setValidated("success");
  };

  const onConfirmCommissionChange = (updatedCommission: number) => {
    const tempCommission = updatedCommission || 0;
    const processedCommission =
      opportunitySource.type === "digital"
        ? Math.ceil(tempCommission)
        : parseFloat(tempCommission.toFixed(2));

    props.validation.setValue("opportunitySource", {
      type: opportunitySource.type,
      title: opportunitySource?.title || "",
      fullName: opportunitySource?.fullName,
      phoneCode: opportunitySource?.phoneCode,
      phoneNumber: opportunitySource?.phoneNumber,
      email: opportunitySource?.email,
      commissionRate: opportunitySource?.commissionRate,
      updatedCommissionRate: processedCommission,
    });

    setCommission(processedCommission);
    setIsEditCommission(false);

    if (
      validated === "success" &&
      opportunitySource?.commissionRate !== processedCommission
    )
      setValidated("warning");

    if (
      validated === "warning" &&
      opportunitySource?.commissionRate === processedCommission
    )
      setValidated("success");
  };

  const handleSources = (): ReactElement => {
    switch (source) {
      case "agent":
        return (
          <div className="mt-5 p-5 bg-section-background">
            <DropdownField
              id="existing-agent-agency"
              searchable="true"
              key="agent"
              label="Agency / Agent Name"
              placeholder="Select an agent"
              searchPlaceholder="Search agency - agent name"
              error={error?.title?.message}
              onChange={onAgentChanged}
              items={agentList.agents.map((agent) => {
                return {
                  text: `${agent.agency.name} - ${agent.fullName}`,
                  value: agent.id.toString(),
                };
              })}
              sortOption="asc"
            />

            {opportunitySource.title && (
              <>
                <Line className="mt-6 mb-3" />
                <div className="bg-transparent w-full mt-7">
                  <div className="row flex flex-wrap justify-start items-center gap-11 gap-y-6">
                    <div className="min-w-[150px]">
                      <p className="h-8 text-sm font-normal uppercase">
                        Agency
                      </p>
                      <div className="h-8 pt-0.5">
                        <span className="font-bold text-sm">
                          {opportunitySource.title}
                        </span>
                      </div>
                    </div>

                    <div className="min-w-[150px]">
                      <p className="h-8 text-sm font-normal uppercase">
                        Agent name
                      </p>
                      <div className="h-8 pt-0.5">
                        <span className="font-bold text-sm">
                          {opportunitySource.fullName}
                        </span>
                      </div>
                    </div>

                    <div className="min-w-[150px]">
                      <p className="h-8 text-sm font-normal uppercase">Phone</p>
                      <div className="h-8 pt-0.5">
                        <span className="font-bold text-sm">
                          {`+${opportunitySource.phoneCode}${opportunitySource.phoneNumber}`}
                        </span>
                      </div>
                    </div>

                    <div className="min-w-[150px]">
                      <p className="h-8 text-sm font-normal uppercase">Email</p>
                      <div className="h-8 pt-0.5">
                        <span className="font-bold text-sm">
                          {opportunitySource.email}
                        </span>
                      </div>
                    </div>

                    <div className="min-w-[150px]">
                      <p className="h-8 text-sm font-normal uppercase">
                        Commission
                      </p>

                      <EditableField
                        id="agent-edit-commission"
                        title="Commission"
                        isEditing={isEditCommission}
                        value={opportunitySource?.updatedCommissionRate}
                        channel={source}
                        onValueChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setTempEditedCommission(
                            parseFloat(e.currentTarget.value)
                          )
                        }
                        onValueConfirm={() =>
                          onConfirmCommissionChange(tempEditedCommission)
                        }
                        onEditClick={() => setIsEditCommission(true)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );

      case "kora":
        return (
          <div className="mt-5 p-5 bg-section-background">
            <DropdownField
              id="kora"
              searchable="true"
              key="kora"
              label="Referrer Name"
              placeholder="Select a referrer"
              searchPlaceholder="Search referrer name"
              error={error?.fullName?.message}
              onChange={onKoraChanged}
              items={referrers.map((referrer) => {
                return { text: referrer.name, value: referrer.phoneNumber };
              })}
            />

            {opportunitySource.fullName && (
              <>
                <Line className="mt-6 mb-3" />
                <div className="bg-transparent w-full mt-7">
                  <div className="row flex flex-wrap justify-start items-center gap-11 gap-y-6">
                    <div className="min-w-[200px]">
                      <p className="text-sm font-normal mb-3 uppercase">
                        Referrer name
                      </p>
                      <span className="font-bold text-sm">
                        {opportunitySource.fullName}
                      </span>
                    </div>

                    <div className="min-w-[200px]">
                      <p className="text-sm font-normal mb-3 uppercase">
                        Phone
                      </p>
                      <span className="font-bold text-sm">
                        {`+${opportunitySource.phoneCode}${opportunitySource.phoneNumber}`}
                      </span>
                    </div>

                    <div className="min-w-[200px]">
                      <p className="text-sm font-normal mb-3 uppercase">
                        Email
                      </p>
                      <span className="font-bold text-sm">
                        {opportunitySource?.email || "--"}
                      </span>
                    </div>

                    <div className="min-w-[200px]">
                      <p className="text-sm font-normal mb-3 uppercase">
                        Referral fee
                      </p>

                      <EditableField
                        id="kora-edit-commission"
                        title="Commission"
                        isEditing={isEditCommission}
                        value={opportunitySource?.updatedCommissionRate}
                        channel={source}
                        onValueChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setTempEditedCommission(
                            parseFloat(e.currentTarget.value)
                          )
                        }
                        onValueConfirm={() =>
                          onConfirmCommissionChange(tempEditedCommission)
                        }
                        onEditClick={() => setIsEditCommission(true)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );

      case "digital":
        return (
          <div className="mt-5 p-5 bg-section-background">
            <div className="col gap-5">
              <div className="row -mb-1 gap-4">
                <TextField
                  id="digital-caller-name"
                  label="Caller name"
                  type="text"
                  error={error?.fullName?.message}
                  onInput={onDigitalChanged}
                  {...register("opportunitySource.fullName", {
                    setValueAs: (v) => v?.trim(),
                  })}
                />

                <TextField
                  id="digital-email-address"
                  label="Email address"
                  type="email"
                  error={error?.email?.message}
                  onInput={onDigitalChanged}
                  {...register("opportunitySource.email", {
                    setValueAs: (v) => v?.trim().toLowerCase(),
                  })}
                />
              </div>

              <div className="row -mb-1 gap-4">
                <div className="row w-full tablet:w-[498px] flex justify-start gap-4">
                  <DropdownField
                    id="digital-phone-code"
                    label="Dial Code"
                    error={error?.phoneCode?.message}
                    items={Country.countryData.map((country) => {
                      return {
                        text: `+${country.dialCode} - ${country.name}`,
                        value: country.dialCode,
                      };
                    })}
                    searchable="true"
                    dynamicWidth={true}
                    containerClassName="w-2/6"
                    listClassName="min-w-[420px]"
                    defaultValue={Country.countryData[0].dialCode}
                    showValue={true}
                    {...register("opportunitySource.phoneCode")}
                  />

                  <TextField
                    id="digital-phone-number"
                    label="Contact No."
                    type="tel"
                    dynamicWidth={true}
                    containerClassName="w-4/6"
                    error={error?.phoneNumber?.message}
                    onInput={onDigitalChanged}
                    {...register("opportunitySource.phoneNumber", {
                      setValueAs: (v) => v?.trim(),
                    })}
                  />
                </div>
                <div className="col w-full tablet:w-[498px]">
                  <EditableField
                    id="digital-edit-commission"
                    title="Commission"
                    isEditing={isEditCommission}
                    value={opportunitySource?.updatedCommissionRate}
                    channel={source}
                    onValueChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTempEditedCommission(parseFloat(e.currentTarget.value))
                    }
                    onValueConfirm={() =>
                      onConfirmCommissionChange(tempEditedCommission)
                    }
                    onEditClick={() => setIsEditCommission(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case "existing-buyer":
      case "bgb":
        return (
          <div className="row gap-5 mt-5 p-5 bg-section-background">
            <div className="col w-1/2 tablet:w-[498px]">
              <DropdownField
                id="sold-units-dropdown"
                searchable="true"
                key={source}
                label="Unit No."
                placeholder="Select a unit number"
                dynamicWidth={true}
                containerClassName="w-full"
                searchPlaceholder="e.g. A-10-10"
                error={error?.title?.message}
                onChange={(e) => onPEUnitTitleChanged(e, source)}
                items={soldUnits.map((unit) => {
                  return { text: unit, value: unit };
                })}
              />
            </div>

            {opportunitySource?.title && (
              <div className="col w-1/2">
                <EditableField
                  id="sold-units-edit-commission"
                  title="Commission"
                  isEditing={isEditCommission}
                  value={opportunitySource?.updatedCommissionRate}
                  channel={source}
                  onValueChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTempEditedCommission(parseFloat(e.currentTarget.value))
                  }
                  onValueConfirm={() =>
                    onConfirmCommissionChange(tempEditedCommission)
                  }
                  onEditClick={() => setIsEditCommission(true)}
                />
              </div>
            )}
          </div>
        );

      case "business-associate":
        return (
          <div className="row gap-5 mt-5 p-5 bg-section-background">
            <div className="col w-1/2 tablet:w-[498px]">
              <TextField
                id="business-associate-name"
                key={source}
                onInput={onTitleChanged}
                type="text"
                error={error?.title?.message}
                label="Company / Individual name"
                {...register("opportunitySource.title")}
              />
            </div>

            <div className="col w-1/2">
              <EditableField
                id="business-associate-edit-commission"
                title="Commission"
                isEditing={isEditCommission}
                value={opportunitySource?.updatedCommissionRate}
                channel={source}
                onValueChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTempEditedCommission(parseFloat(e.currentTarget.value))
                }
                onValueConfirm={() =>
                  onConfirmCommissionChange(tempEditedCommission)
                }
                onEditClick={() => setIsEditCommission(true)}
              />
            </div>
          </div>
        );

      case "brand-collaboration":
        return (
          <div className="row gap-5 mt-5 p-5 bg-section-background">
            <div className="col w-1/2 tablet:w-[498px]">
              <DropdownField
                id="brand-collaboration"
                searchable="true"
                key="brand-collaboration"
                label="Event name"
                placeholder="Select an event"
                searchPlaceholder="Search event name"
                error={error?.title?.message}
                onChange={onBrandCollaborationChanged}
                items={brandCollaborations.map((brand) => {
                  return { text: brand, value: brand };
                })}
              />
            </div>

            <div className="col w-1/2">
              <EditableField
                id="brand-collaboration-edit-commission"
                title="Commission"
                isEditing={isEditCommission}
                value={opportunitySource?.updatedCommissionRate}
                channel={source}
                onValueChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTempEditedCommission(parseFloat(e.currentTarget.value))
                }
                onValueConfirm={() =>
                  onConfirmCommissionChange(tempEditedCommission)
                }
                onEditClick={() => setIsEditCommission(true)}
              />
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <ExpandableCard
      value="opportunity-source"
      title="Channel"
      validated={validated}
      showRequired
    >
      <div className="row gap-1 uppercase">
        <label>Select channel</label>
        <span className="text-dark-red">*</span>
      </div>

      <div className="flex-wrap flex mt-2 items-center justify-start gap-x-6">
        <RadioGroup onChange={onRadioChanged} defaultValue={source}>
          {sources.map((tempSource) => (
            <RadioField
              id={`${tempSource}-radio-button`}
              key={tempSource}
              label={sourceName(tempSource)}
              value={tempSource}
            />
          ))}
        </RadioGroup>
      </div>

      {handleSources()}
    </ExpandableCard>
  );
};

export default OpportunitySourceAccordian;
