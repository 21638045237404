/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useMemo } from "react";

export const useMultipleRefs = (max: number) => {
  return useMemo(
    () =>
      Array(max)
        .fill(0)
        .map((i) => createRef()),
    []
  );
};
