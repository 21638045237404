import {
  oneBedroomUnits,
  threeBedroomsUnits,
  twoBedroomsUnits,
} from "../common/Building";
import {
  ConsideredSoldUnitStatus,
  consideredSoldUnitStatus,
} from "../common/Enums";

export const filterUnitList = (unitList: any, filterList: any) => {
  let tempArr: any = [],
    tempWholeFloorArr: any = [];

  Object.keys(unitList).forEach((unitsInLevel: string, level: any) => {
    let maxUnitsInLevel = unitList[unitsInLevel].length;
    let availableCount = 0;

    Object.keys(unitList[unitsInLevel]).forEach((unit: any, index: any) => {
      const unitStatus = consideredSoldUnitStatus.includes(
        unitList[unitsInLevel][
          unit
        ].status.toLowerCase() as ConsideredSoldUnitStatus
      )
        ? "Sold"
        : unitList[unitsInLevel][unit].status;

      if (
        (filterList.unitType.length === 0 ||
          filterList.unitType.includes(unitList[unitsInLevel][unit].type)) &&
        (filterList.orientation.length === 0 ||
          filterList.orientation.includes(
            unitList[unitsInLevel][unit].orientation
          )) &&
        (filterList.theme.length === 0 ||
          filterList.theme.includes(unitList[unitsInLevel][unit].idTheme)) &&
        (filterList.status.length === 0 ||
          filterList.status.includes(unitStatus)) &&
        (filterList.noOfBedrooms.length === 0 ||
          (filterList.noOfBedrooms.includes(1) &&
            oneBedroomUnits.includes(unitList[unitsInLevel][unit].type)) ||
          (filterList.noOfBedrooms.includes(2) &&
            twoBedroomsUnits.includes(unitList[unitsInLevel][unit].type)) ||
          (filterList.noOfBedrooms.includes(3) &&
            threeBedroomsUnits.includes(unitList[unitsInLevel][unit].type))) &&
        (filterList.floors.length === 0 ||
          (filterList.floors.includes("low") && parseInt(unitsInLevel) <= 24) ||
          (filterList.floors.includes("mid") &&
            parseInt(unitsInLevel) >= 27 &&
            parseInt(unitsInLevel) <= 42) ||
          (filterList.floors.includes("high") && parseInt(unitsInLevel) >= 45))
      ) {
        if (tempArr[unitsInLevel]) {
          tempArr[unitsInLevel].push(unitList[unitsInLevel][unit]);
        } else {
          tempArr[unitsInLevel] = [{ ...unitList[unitsInLevel][unit] }];
        }
      }

      if (
        filterList.wholeFloor &&
        (unitStatus === "Available" || unitStatus === "Blocked")
      ) {
        availableCount += 1;
      }
    });

    if (
      filterList.wholeFloor &&
      maxUnitsInLevel === availableCount &&
      tempArr[unitsInLevel] &&
      maxUnitsInLevel === tempArr[unitsInLevel].length
    ) {
      tempWholeFloorArr[unitsInLevel] = [...tempArr[unitsInLevel]];
    }

    availableCount = 0;
  });

  if (filterList.wholeFloor) {
    return tempWholeFloorArr;
  }

  return tempArr;
};
