import { createContext, useContext, useState } from "react";
import { PaginatedMeta, Proposal } from "../../common/Types";
import {
  GeneralPaginatedResponse,
  ShowGeneralDataResponse,
} from "../../common/Responses";
import api from "../../services/PabloApiService";

type LegacyProposalListContextType = {
  proposals: Proposal[];
  paginationDetails: PaginatedMeta;
  isLoading: boolean;
  index: (page?: number) => Promise<boolean>;
  show: (id: number) => Promise<Proposal | undefined>;
  search: (keyword: string, page?: number) => Promise<boolean>;
};

const defaultContext: LegacyProposalListContextType = {
  proposals: [],
  paginationDetails: {
    currentPage: 0,
    from: 0,
    lastPage: 0,
    links: [],
    path: "",
    perPage: 0,
    to: 0,
    total: 0,
  },
  isLoading: true,
  index: async (page: number = 1) => false,
  show: async (id: number) => undefined,
  search: async (keyword: string, page: number = 1) => false,
};

const LegacyProposalListContext =
  createContext<LegacyProposalListContextType>(defaultContext);

export const useLegacyProposalListContext = () =>
  useContext(LegacyProposalListContext);

export const LegacyProposalListContextProvider: React.FC = ({ children }) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<PaginatedMeta>({
    currentPage: 0,
    from: 0,
    lastPage: 0,
    links: [],
    path: "",
    perPage: 0,
    to: 0,
    total: 0,
  });
  const [isLoading, setLoading] = useState<boolean>(true);

  const index = async (page: number = 1): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await api.get<GeneralPaginatedResponse<Proposal>>(
      `/legacy-proposals?page=${page}`
    );

    if (success) {
      setProposals(data.data);
      setPaginationDetails(data.meta);
    }

    setLoading(false);

    return true;
  };

  const show = async (id: number): Promise<Proposal | undefined> => {
    setLoading(true);

    const { data, success } = await api.get<ShowGeneralDataResponse<Proposal>>(
      `/legacy-proposals/${id}`
    );

    setLoading(false);

    return success ? data.data : undefined;
  };

  const search = async (
    keyword: string,
    page: number = 1
  ): Promise<boolean> => {
    if (keyword) {
      setLoading(true);

      const { data, success } = await api.get<
        GeneralPaginatedResponse<Proposal>
      >(`/legacy-proposals/search/${keyword}?page=${page}`);

      if (success) {
        setProposals(data.data);
        setPaginationDetails(data.meta);
      }

      setLoading(false);
    }

    return true;
  };

  return (
    <LegacyProposalListContext.Provider
      value={{
        proposals,
        paginationDetails,
        isLoading,
        index,
        show,
        search,
      }}
    >
      {children}
    </LegacyProposalListContext.Provider>
  );
};
