import React, { forwardRef } from "react";
import classNames from "classnames";
import { formatErrorMessage } from "../../utils/CommonUtils";
import "../../styles/fields.css";

interface DatePickerFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  required?: boolean;
  error?: string;
  hasError?: boolean;
  innerClassName?: string;
  containerClassName?: string;
  dynamicWidth?: boolean;
  disabled?: boolean;
}

const DatePickerField = forwardRef<HTMLInputElement, DatePickerFieldProps>(
  (props, ref) => {
    const {
      innerClassName,
      containerClassName,
      dynamicWidth,
      disabled,
      ...otherProps
    } = props;

    return (
      <div
        className={classNames(
          "col",
          {
            "w-full tablet:w-[498px]": !dynamicWidth,
            "opacity-50": disabled,
          },
          containerClassName
        )}
      >
        <label
          className={classNames(
            "input-border w-full",
            {
              "border-primary": props.hasError || props.error,
              "cursor-default": disabled,
            },
            innerClassName
          )}
        >
          {props.label && (
            <div className="row gap-1">
              <label className="uppercase block mb-1">{props.label}</label>
              {(props.required ?? true) && (
                <span className="text-dark-red">*</span>
              )}
            </div>
          )}

          <input
            ref={ref}
            type="date"
            autoComplete="off"
            className={classNames("w-full font-semibold focus:outline-none", {
              "cursor-default pointer-events-none bg-white": disabled,
            })}
            disabled={disabled}
            {...otherProps}
          />
        </label>

        <span
          hidden={!props.error}
          className="font-light text-sm text-primary pt-2 transition-all duration-200"
        >
          {formatErrorMessage(props.error)}
        </span>
      </div>
    );
  }
);

export default DatePickerField;
