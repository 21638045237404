import classNames from "classnames";

interface SectionCardProps {
  title: string;
  className?: string;
  titleClassName?: string;
}

const SectionCard: React.FC<SectionCardProps> = ({
  children,
  title,
  className,
  titleClassName,
}) => {
  return (
    <div className={classNames(className)}>
      <h4 className={classNames("mb-4", titleClassName)}>{title}</h4>
      {children}
    </div>
  );
};

export default SectionCard;
