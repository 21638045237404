/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";

export const useUnload = (
  message: string = "Changes you made may not be saved."
) => {
  // const location = useLocation();
  const [isDirty, setDirty] = useState<boolean>(false);

  const triggerUnload = () => {
    if (isDirty) window.confirm(message);
  };

  // useEffect(() => {
  //   triggerUnload();
  // }, [location.pathname]);

  useEffect(() => {
    window.onbeforeunload = isDirty ? () => message : () => {};

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  return { isDirty, setDirty, triggerUnload };
};
