import React from "react";
import { ReviewDevelopmentImpact } from "../../common/Types";
import { formatNumber } from "../../utils/CommonUtils";

interface ReviewDevelopmentImpactTableProps {
  tower?: string;
  reviewDevelopmentImpact?: ReviewDevelopmentImpact;
}

const ReviewDevelopmentImpactTable: React.FC<
  ReviewDevelopmentImpactTableProps
> = (props) => {
  const { tower, reviewDevelopmentImpact } = props;

  return (
    <>
      <h4 className="uppercase mb-4">
        Review Development Impact (Tower {tower})
      </h4>

      <table>
        <thead>
          <tr>
            <td className="font-bold h-12">Metrics</td>
            <td className="font-bold h-12">Current Value</td>
            <td className="font-bold h-12">Post Approval Value</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="font-bold">Sold Nett Nett Price PSF</td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.current?.soldNettNettPricePsf || 0
              )}
            </td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.postApproval?.soldNettNettPricePsf || 0
              )}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Unsold Nett Nett Price PSF</td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.current?.unsoldNettNettPricePsf || 0
              )}
            </td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.postApproval?.unsoldNettNettPricePsf ||
                  0
              )}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Average Nett Nett Price PSF</td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.current?.averageNettNettPricePsf || 0
              )}
            </td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.postApproval
                  ?.averageNettNettPricePsf || 0
              )}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Sold Nett Nett Development Value</td>
            <td>
              RM{" "}
              {formatNumber(
                reviewDevelopmentImpact?.current
                  ?.soldNettNettDevelopmentValue || 0
              )}
            </td>
            <td>
              RM{" "}
              {formatNumber(
                reviewDevelopmentImpact?.postApproval
                  ?.soldNettNettDevelopmentValue || 0
              )}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Unsold Nett Nett Development Value</td>
            <td>
              RM{" "}
              {formatNumber(
                reviewDevelopmentImpact?.current
                  ?.unsoldNettNettDevelopmentValue || 0
              )}
            </td>
            <td>
              RM{" "}
              {formatNumber(
                reviewDevelopmentImpact?.postApproval
                  ?.unsoldNettNettDevelopmentValue || 0
              )}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Total Nett Nett Development Value</td>
            <td>
              RM{" "}
              {formatNumber(
                reviewDevelopmentImpact?.current
                  ?.totalNettNettDevelopmentValue || 0
              )}
            </td>
            <td>
              RM{" "}
              {formatNumber(
                reviewDevelopmentImpact?.postApproval
                  ?.totalNettNettDevelopmentValue || 0
              )}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Nett Nett Profit Margin</td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.current?.nettNettProfitMargin || 0
              )}
              %
            </td>
            <td>
              {formatNumber(
                reviewDevelopmentImpact?.postApproval?.nettNettProfitMargin || 0
              )}
              %
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ReviewDevelopmentImpactTable;
