import React from "react";
import { CATALYST_CLOSING_FEE } from "../../common/ManagementApproval";
import {
  PackageableType,
  packageableType,
  salesPackageName,
  SalesPackageType,
} from "../../common/SalesPackage";
import { OpportunitySource, SalesPackage } from "../../common/Types";
import { formatNumber } from "../../utils/CommonUtils";

interface UnitCostOfSalesCardProps {
  unitCostOfSalesValue: number;
  rebate: string;
  commission: string;
  grr: string;
  salesPackage?: SalesPackage;
  opportunitySource?: OpportunitySource;
  reviewable?: boolean;
}

const UnitCostOfSalesCard: React.FC<UnitCostOfSalesCardProps> = (props) => {
  const {
    unitCostOfSalesValue,
    rebate,
    commission,
    grr,
    salesPackage,
    opportunitySource,
    reviewable,
  } = props;

  return (
    <>
      <div className="mb-2">
        <h4 className="uppercase mb-2">
          Unit Cost Of Sales: <strong>{`${unitCostOfSalesValue}%`}</strong>
        </h4>
        <h4>Breakdown</h4>
      </div>

      {reviewable ? (
        <div className="border border-[#53565A33] p-2 pb-4">
          <table className="bg-transparent table-fixed w-full">
            <thead>
              <tr className="h-6 border-none">
                <th className="text-sm font-normal p-0 min-w-[250px]">
                  Sales Package
                </th>
                <th className="text-sm font-normal p-0 min-w-[250px] whitespace-normal">
                  Payment Method
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="h-6 border-none">
                <td className="text-sm font-bold p-0 whitespace-normal">
                  {salesPackageName(
                    packageableType(
                      salesPackage?.packageableType as PackageableType
                    ) ?? (salesPackage?.packageableType as SalesPackageType)
                  )}
                </td>
                <td className="capitalize text-sm font-bold p-0 whitespace-normal">
                  {salesPackage?.paymentMethod}
                </td>
              </tr>

              <tr className="h-6 border-none">
                <th className="text-sm font-normal p-0 pt-3 min-w-[250px]">
                  Rebate
                </th>
                <th className="text-sm font-normal p-0 pt-3 min-w-[250px]">
                  Commission
                </th>
              </tr>

              <tr className="h-6 border-none">
                <td className="text-sm font-bold p-0 pt-3">{rebate}</td>
                <td className="text-sm font-bold p-0 pt-3 whitespace-normal">
                  {opportunitySource?.type === "digital" ? "RM" : ""}
                  {commission}
                  {opportunitySource?.type !== "digital" ? "%" : ""}
                </td>
              </tr>

              <tr>
                <th className="text-sm font-normal p-0 pt-3 min-w-[250px]">
                  Catalyst Closing Fee
                </th>
                <th className="text-sm font-normal p-0 pt-3 min-w-[250px] whitespace-normal">
                  Additional GRR Cost
                </th>
              </tr>

              <tr>
                <td className="text-sm font-bold p-0 pt-3">
                  RM {formatNumber(CATALYST_CLOSING_FEE)}
                </td>
                <td className="capitalize text-sm font-bold p-0 pt-3">{grr}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <table className="bg-transparent table-fixed w-full">
          <thead>
            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Sales Package
              </th>
              <th className="text-sm font-normal p-0 min-w-[250px] desktop:w-[250px]">
                Payment Method
              </th>
              <th className="p-0 min-w-[250px] desktop:w-[250px]" />
              <th className="p-0 min-w-[250px] desktop:w-[250px]" />
            </tr>
          </thead>

          <tbody>
            <tr className="h-6 border-none">
              <td className="text-sm font-bold p-0">
                {salesPackageName(
                  packageableType(
                    salesPackage?.packageableType as PackageableType
                  ) ?? (salesPackage?.packageableType as SalesPackageType)
                )}
              </td>
              <td className="capitalize text-sm font-bold p-0">
                {salesPackage?.paymentMethod}
              </td>
              <td className="p-0" />
              <td className="p-0" />
            </tr>

            <tr className="h-6 border-none">
              <th className="text-sm font-normal p-0 pt-3 min-w-[250px] desktop:w-[250px]">
                Rebate
              </th>
              <th className="text-sm font-normal p-0 pt-3 min-w-[250px] desktop:w-[250px]">
                Additional GRR Cost
              </th>
              <th className="text-sm font-normal p-0 pt-3 min-w-[250px] desktop:w-[250px]">
                Commission
              </th>
              <th className="text-sm font-normal p-0 pt-3 min-w-[250px] desktop:w-[250px]">
                Catalyst Closing Fee
              </th>
            </tr>

            <tr className="h-6 border-none">
              <td className="text-sm font-bold p-0 pt-3">{rebate}</td>
              <td className="text-sm font-bold p-0 pt-3">{grr}</td>
              <td className="text-sm font-bold p-0 pt-3">
                {opportunitySource?.type === "digital" ? "RM" : ""}
                {commission}
                {opportunitySource?.type !== "digital" ? "%" : ""}
              </td>
              <td className="capitalize text-sm font-bold p-0 pt-3">
                RM {formatNumber(CATALYST_CLOSING_FEE)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default UnitCostOfSalesCard;
