import { DeepMap, DeepPartial, FieldError } from "react-hook-form";

type ScrollBehaviour<T> = {
  scrollOnError: (errors: DeepMap<DeepPartial<T>, FieldError>) => void;
};

export const useScrollToError = <T>(): ScrollBehaviour<T> => {
  const checkIsArray = (obj: any): any => {
    if (!obj) return undefined;

    return Array.isArray(obj)
      ? checkIsArray(obj?.[0])
      : Object.keys(obj).includes("ref")
      ? obj
      : checkIsArray(Object.values(obj)?.[0]);
  };

  const scrollOnError = (errors: DeepMap<DeepPartial<T>, FieldError>) => {
    if (Object.keys(errors).length) {
      const element = checkIsArray(Object.values(errors)?.[0]);
      const elementRef = element?.ref;

      document.querySelector(`[name="${elementRef?.name}"`)?.scrollIntoView({
        behavior: "smooth",
        block: elementRef?.type?.includes("select") ? "end" : "center",
      });
    }
  };

  return { scrollOnError };
};
