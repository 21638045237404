import React from "react";
import { ApprovalStatus } from "../../common/Enums";

interface ApprovalStatusChipProps {
  status: ApprovalStatus;
}

const ApprovalChip: React.FC<ApprovalStatusChipProps> = ({ status }) => {
  let className = "px-3 py-1 text-center w-[fit-content] truncate ";
  let name = "";

  switch (status) {
    case "approved":
      className += "text-dark-green bg-light-green";
      name = "Approved";
      break;

    case "declined":
      className += "text-dark-red bg-light-red";
      name = "Declined";
      break;

    default:
      break;
  }

  return <div className={className}>{name}</div>;
};

export default ApprovalChip;
